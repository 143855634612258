import jwt_decode from "jwt-decode";
import {IsLoadingActions} from "../store/reducers/isLoading";
import {AppDispatch, RootState, useAppDispatch} from "../store/reduxStore";
import {UserActions} from "../store/reducers/auth";
import {store_server, user_server} from "../helpers/config";
import {customFetchJson} from "../helpers/customFetch";
import {IUser} from "../types/user";
import {showPopupActions} from "../store/reducers/showPopup";
import {IResponse} from "../types/api";
import {HintActions} from "../store/reducers/hint";
import {NavigationActions} from "../store/reducers/navigation";

export const AuthAPI = {

    checkAuth: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        const dateStart = Date.now()
        try {
            const refreshToken = await localStorage.getItem('refreshToken')
            if (!refreshToken) {
                dispatch(UserActions.setUserAction({role: 'guest'}))
                return
            }

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/checkAuth`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken
                })
            })

            if (response.error) {
                await localStorage.removeItem(`accessToken`)
                await localStorage.removeItem(`refreshToken`)
                dispatch(UserActions.setGuestAction())

                return
            }

            await localStorage.setItem('accessToken',response.payload.accessToken)
            const userDecode: IUser = jwt_decode(response.payload.accessToken);
            const user: IUser = {
                id: userDecode.id,
                email: userDecode.email,
                role: userDecode.role,
                verifyStatus: userDecode.verifyStatus,
                name: userDecode.name
            }
            dispatch(UserActions.setUserAction(user))

        }
        catch (err) {
            dispatch(UserActions.setGuestAction())
            dispatch(NavigationActions.setPathAction(`/error-server`))
            // window.location.pathname = `/error-server`
            console.log(err)
        }
        finally {
            setTimeout(()=> {
                dispatch(IsLoadingActions.setLoadingStartApp(false))
            }, 1000 - (Date.now() - dateStart))
        }
    },
    registration: (name: string, email: string, password: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<{ accessToken:string, refreshToken:string, message: string}> = await customFetchJson(`${user_server}/api/auth/registration`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    password
                })
            })

            if (response.error) {
                dispatch(UserActions.setErrorAuthAction(`${response.payload.message}`))
                return
            }

            const {accessToken, refreshToken} = response.payload
            await localStorage.setItem('accessToken', accessToken)
            await localStorage.setItem('refreshToken', refreshToken)
            const userDecode: IUser = jwt_decode(accessToken);
            const user: IUser = {
                id: userDecode.id,
                email: userDecode.email,
                role: userDecode.role,
                verifyStatus: userDecode.verifyStatus,
                name: userDecode.name
            }
            dispatch(UserActions.setUserAction(user))
            dispatch(showPopupActions.setIsShowPopupAction({type:'registration', isShow: false}))

        }
        catch (error) {
            window.location.pathname = `/error-server`
            // navigate(`/error-server`)
            dispatch(showPopupActions.setIsShowPopupAction({type: "registration", isShow: false}))
            console.log('error client', error)
        }
        finally {

        }
    },
    login: (email: string, password: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<{ accessToken:string, refreshToken:string, message: string }> = await customFetchJson(`${user_server}/api/auth/login`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password
                })
            })

            if (response.error) {
                dispatch(UserActions.setErrorAuthAction(`${response.payload.message}`))
                return
            }

            const {accessToken, refreshToken} = response.payload
            await localStorage.setItem('accessToken', accessToken)
            await localStorage.setItem('refreshToken', refreshToken)
            const userDecode: IUser = jwt_decode(accessToken);
            const user: IUser = {
                id: userDecode.id,
                email: userDecode.email,
                role: userDecode.role,
                verifyStatus: userDecode.verifyStatus,
                name: userDecode.name
            }
            dispatch(UserActions.setUserAction(user))
            dispatch(showPopupActions.setIsShowPopupAction({type: "login", isShow: false}))

        }
        catch (error) {
            // window.location.pathname = `/error-server`
            // dispatch(showPopupActions.setIsShowPopupAction({type: "login", isShow: false}))

            console.log('error client', error)
        }
        finally {

        }
    },

    logout: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const refreshToken = await localStorage.getItem('refreshToken')
            if (!refreshToken) {
                dispatch(UserActions.setUserAction({role: 'guest'}))
                return
            }

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/logout`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken
                })
            })

            if (response.error) {
                return
            }
        }
        catch (error) {
            // navigate(`/error-server`)
            window.location.pathname = `/error-server`
            console.log('error client', error)
        }
        finally {
            // navigate('/')
            //
            await localStorage.removeItem(`accessToken`)
            await localStorage.removeItem(`refreshToken`)
            window.location.pathname = `/`
            // dispatch(UserActions.setUserAction({role: `guest`}))
        }
    },

    resetPassword: (email: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/resetPassword`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    type: 'error',
                    text: 'Ошибка на сервере'
                }))

                return
            }
            dispatch(HintActions.setHintApp({
                isShow: true,
                type: 'ok',
                text: 'Инструкция по востановлению пароля отправленна на e-mail'
            }))

        }
        catch (error) {
            // navigate(`/error-server`)
            window.location.pathname = `/error-server`
            console.log('error client', error)
        }
        finally {
            await  dispatch(showPopupActions.setIsShowPopupAction({type: "resetPassword", isShow: false}))
        }
    },

    recoverPassword: (email: string, link: string, password: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            const response: IResponse<{ accessToken:string, refreshToken:string }> = await customFetchJson(`${user_server}/api/auth/recoveryPassword`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    link,
                    password
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    type: 'error',
                    text: 'Ошибка на сервере'
                }))
                return
            }

            const {accessToken, refreshToken} = response.payload
            await localStorage.setItem('accessToken', accessToken)
            await localStorage.setItem('refreshToken', refreshToken)
            const userDecode: IUser = jwt_decode(accessToken);
            const user: IUser = {
                id: userDecode.id,
                email: userDecode.email,
                role: userDecode.role,
                verifyStatus: userDecode.verifyStatus,
                name: userDecode.name
            }

            dispatch(UserActions.setUserAction(user))

            window.location.pathname = `/`
            // navigate('/')
            dispatch(HintActions.setHintApp({
                isShow: true,
                type: 'ok',
                text: 'Новый пароль установлен'
            }))


        }
        catch (error) {
            // navigate(`/error-server`)
            window.location.pathname = `/error-server`
            console.log('error client', error)
        }
        finally {

        }
    },

    changePassword: (password: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/changePassword`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPassword: password
                })
            })
            if (response.error) {
                dispatch(UserActions.setErrorAuthAction(`${response.payload.message}`))
                return
            }
            dispatch(HintActions. setHintApp({
                isShow: true,
                type: 'ok',
                text: 'Пароль успешно изменён'
            }))

            // setIsShowPopupAction({type: 'changePassword', isShow: true})
        }
        catch (error) {
            // navigate(`/error-server`)
            window.location.pathname = `/error-server`
            console.log('error client', error)
        }
        finally {
            dispatch(showPopupActions.setIsShowPopupAction({type: 'changePassword', isShow: false}))

        }
    },
}
