import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {ProfileState} from "../../../../../store/reducers/profile";
import {IProfile} from "../../../../../types/profile";
import {ProfileAPI} from "../../../../../API/useProfile";
import {LoadingPage} from "../../../LoadingPage";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {useThunks} from "../../../../../helpers/useActions";

export const SettingMainEmailNotice = () => {

    const {profile} = useAppState(ProfileState)
    const [newProfile,setNewProfile] = useState<IProfile>()
    const {updateMyProfile} = useThunks(ProfileAPI)
    const [saveLoading,setSaveLoading] = useState(false)

    useEffect(()=>{
        return ()=>{
            setSaveLoading(false)
        }
    },[])

    useEffect(()=>{
        if (!!profile)
            setNewProfile(profile)
        setSaveLoading(false)
    },[profile])

    const saveChangedHandler = () => {
        if (!newProfile) return
        setSaveLoading(true)
        updateMyProfile(newProfile)
    }

    const changeToggleShowHandler = (field: any) => {
        // @ts-ignore
        setNewProfile({...newProfile, noticeSubscribe: {...newProfile.noticeSubscribe, [`${field}`]: !newProfile.noticeSubscribe[`${field}`]}})
    }

    return (
        <div className={css(s.SettingMainEmailNotice)}>
            <div className={css(s.emailNoticeTitle)}>
                Почтовые рассылки
            </div>
            <div className={css(s.emailNoticeMain)}>
                <div className={css(s.alerts)}>
                    <input type="checkbox" checked={!!newProfile?.noticeSubscribe.alerts} onChange={()=>changeToggleShowHandler(`alerts`)}/>
                    <label>Оповещения</label>
                </div>
                <div className={css(s.news)}>
                    <input type="checkbox" checked={!!newProfile?.noticeSubscribe.news} onChange={()=>changeToggleShowHandler(`news`)}/>
                    <label>Новости сайта и рекламная информация</label>
                </div>
                <div className={css(s.saveBtn)} onClick={saveChangedHandler}>
                    {!saveLoading
                        ? 'Сохранить'
                        : <img src={loadingGif} alt="loading"/>
                    }
                </div>
            </div>
        </div>
    )
}
