import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    balance: number | null

    totalHistoryList: number | null
    historyList: any

}
const initialState: stateType = {
    balance: null,

    totalHistoryList:  null,
    historyList: null,
}

export const slice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        setMyBalanceAction: (state, action: PayloadAction<number | null>) : void => {
            state.balance = action.payload
        },

        // historyList
        setHistoryListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.historyList = action.payload
        },
        addHistoryListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.historyList)
                state.historyList = [...state.historyList,...action.payload]
        },
        setTotalHistoryListAction: (state, action: PayloadAction<number | null>) : void => {
            state.totalHistoryList = action.payload
        },
    }
})

export const BillReducer = slice.reducer
export const BillActions = slice.actions
export const BillState = (state: RootState) => state.BillReducer


