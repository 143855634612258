import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Link} from "react-router-dom";
import HomePageBG from '../../../assets/images/HomePageBG.png'
import notepad from '../../../assets/images/notepad.png'
import {SetPlace} from "../../SetPlace";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {HintActions} from "../../../../store/reducers/hint";
import {useAppState} from "../../../../store/reduxStore";
import {AppState} from "../../../../store/reducers/app";
import {NavigationActions} from "../../../../store/reducers/navigation";
import {allCityList, allRegionList} from "../../../../helpers/regionAndCity";

export const HomePage = () => {

    const {setHintApp} = useActions(HintActions)
    const {content} = useAppState(AppState)
    const {setPathAction} = useActions(NavigationActions)

    const getPlace = async () => {
        // Если функциональность геолокации доступна,
        // пытаемся определить координаты посетителя
        if (navigator.geolocation) {
            // Передаем две функции
            navigator.geolocation.getCurrentPosition(
                async function (position:any) {
                    const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}&zoom=10`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.15673317516059}&lon=${61.30059957504273}&zoom=18`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.668291353993226}&lon=${38.10882568359376}&zoom=18`)

                    const data = await res.json()
                    let responseRegion = data.address.state ? data.address.state : ''
                    let responseCity = data.address.city ? data.address.city : data.address.town ? data.address.town : ''

                    // соответствие региона
                    if (responseRegion === `Москва` || responseRegion === `Московская область`) {
                        responseRegion = allRegionList()[0]
                    } else if (responseRegion === `Санкт-Петербург` || responseRegion === `Ленинградская область`) {
                        responseRegion = allRegionList()[1]
                    } else {
                        const compliance = allRegionList().find(reg => responseRegion.length && reg.startsWith(responseRegion))
                        responseRegion = !compliance ? '' : compliance
                    }
                    // соответствие города
                    const compliance = allCityList().find(c => responseCity.length && c.startsWith(responseCity))
                    responseCity = !compliance ? '' : compliance

                    setHintApp({
                        isShow: true,
                        type: 'location',
                        text: `Регион: ${responseRegion.length ? responseRegion : 'не определенно'}, Город: ${responseCity.length ? responseCity : 'не определенно'}`
                    })
                    setInputFields({
                        region: responseRegion,
                        city: responseCity
                    })
                },
                function (positionError:any) {
                    if(positionError.code === 1) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Не удалось определить местоположение, пожалуйста удостоверьтесь что получение Геоданных не заблокировано.`
                        })
                    }
                    else if(positionError.code === 2) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Проблемы с сетью или нельзя связаться со службой определения местоположения по каким-либо другим причинам.`
                        })
                    }
                    else if(positionError.code === 3) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `He удалось определить местоположение в течение установленного времени.`
                        })
                    }
                },
                {
                    enableHighAccuracy: true, // высокая точность поиска
                    timeout: 10000, // время запроса
                    maximumAge: 60000 // кешировать данные полученные 1 мин назад
                }
            )
            // Выводим результат
            // alert("Поиск начался")
        } else {
            // Выводим результат
            setHintApp({
                isShow: true,
                type: 'warning',
                text: `Ваш браузер не поддерживает геолокацию.`
            })
            return
        }
    }
    const [inputFields,setInputFields] = useState({
        region: ``,
        city: ``
    })
    const setRegionHandler = (region: string) => {
        setInputFields(prev=>({...prev, region: region}))
    }
    const setCityHandler = (city: string) => {
        setInputFields(prev=>({...prev, city: city}))
    }

    const searchExecutor = () => {
        setPathAction(`executor-list?region=${inputFields.region}&city=${inputFields.city}`)
    }

    return (
        <div className={css(s.HomePage)}>
            <div className={css(s.container)}>
                <div className={css(s.firstBlock)}>
                    <div className={css(s.firstBlockBG)}>
                        <img src={HomePageBG} alt="HomePageBG"/>
                    </div>
                    <div className={css(s.firstBlockInfo)}>
                        <h1 className={css(s.text)}>{content.headline}</h1>
                        <Link to={`/executor-list`} className={css(s.showExecutorList)}>Смотреть всех исполнителей</Link>
                    </div>
                    <div className={css(s.firstBlockImg)}>
                        <div className={css(s.imgBox)}>
                            <img src={notepad} alt="notepad"/>
                            <div className={css(s.labelTwo)}>
                                <svg viewBox="0 0 62 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M61.751 80H0V14.996L14.9987 0H61.751V80Z" fill="#DEE7F5"/>
                                    <path d="M14.996 14.9961L14.9775 0.0238647L0 14.9961H14.996Z" fill="#DEE7F5"/>
                                    <path d="M0 14.9961L16.0583 17.3881L14.9775 0.0238647L14.996 14.9961H0Z" fill="#CCCCCC"/>
                                    <path d="M47 51.9349C47 52.6453 46.4187 53.2266 45.7083 53.2266H17.2917C16.5812 53.2266 16 52.6453 16 51.9349V39.0182C16 38.3078 16.5812 37.7266 17.2917 37.7266H45.7083C46.4187 37.7266 47 38.3078 47 39.0182V51.9349Z" fill="#EBF1F8"/>
                                    <path d="M24.1247 49.351C23.4737 49.351 23.2154 48.7219 23.5874 48.2776L27.2647 43.3925H24.2164C23.3187 43.3925 23.391 42.1241 24.2164 42.1241H28.3794C29.1635 42.1241 29.3908 42.8655 28.8341 43.4661L25.3117 48.0399H28.6688C29.4954 48.0399 29.5988 49.3523 28.5874 49.3523H24.1247V49.351Z" fill="#212B36"/>
                                    <path d="M30.5342 42.8245C30.5342 41.9772 31.8982 41.9462 31.8982 42.8245V48.721C31.8982 49.5774 30.5342 49.5993 30.5342 48.721V42.8245Z" fill="#212B36"/>
                                    <path d="M33.7285 42.8247C33.7285 42.4837 33.9972 42.1117 34.4312 42.1117H36.8169C38.1602 42.1117 39.3692 43.0107 39.3692 44.7338C39.3692 46.3664 38.1602 47.2758 36.8169 47.2758H35.0925V48.6398C35.0925 49.0944 34.8032 49.3515 34.4312 49.3515C34.0902 49.3515 33.7285 49.0944 33.7285 48.6398V42.8247ZM35.0912 43.4124V45.9854H36.8156C37.5079 45.9854 38.0556 45.3744 38.0556 44.7338C38.0556 44.0117 37.5079 43.4124 36.8156 43.4124H35.0912Z" fill="#212B36"/>
                                </svg>
                            </div>
                        </div>
                        <div className={css(s.label)}>zemsite</div>
                    </div>
                </div>
                <div className={css(s.twoBlock)}>
                    <h2 className={css(s.twoBlockTitle)}>
                        Найдите профессионального исполнителя в сфере кадастровых услуг
                    </h2>
                    <div className={css(s.twoBlockSubTitle)}>
                        Мы проверяем каждого исполнителя
                    </div>
                    <div className={css(s.placeBlock)}>
                        <div className={css(s.getMyPlace)}>
                            <div className={css(s.getMyPlaceTitle)}>По моему местоположению</div>
                            <div className={css(s.getMyPlaceBtn)} onClick={getPlace}>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 0C6.15694 0 2.625 3.53194 2.625 7.875C2.625 13.3337 9.7335 20.5052 10.0354 20.8084C10.164 20.9357 10.332 21 10.5 21C10.668 21 10.836 20.9357 10.9646 20.8084C11.2665 20.5052 18.375 13.3337 18.375 7.875C18.375 3.53194 14.8431 0 10.5 0ZM10.5 19.3948C8.93288 17.7174 3.9375 12.0448 3.9375 7.875C3.9375 4.25644 6.88144 1.3125 10.5 1.3125C14.1186 1.3125 17.0625 4.25644 17.0625 7.875C17.0625 12.0409 12.0671 17.7174 10.5 19.3948Z" fill="#00AB55"/>
                                    <path d="M10.5 3.9375C8.32912 3.9375 6.5625 5.70412 6.5625 7.875C6.5625 10.0459 8.32912 11.8125 10.5 11.8125C12.6709 11.8125 14.4375 10.0459 14.4375 7.875C14.4375 5.70412 12.6709 3.9375 10.5 3.9375ZM10.5 10.5C9.05231 10.5 7.875 9.32269 7.875 7.875C7.875 6.42731 9.05231 5.25 10.5 5.25C11.9477 5.25 13.125 6.42731 13.125 7.875C13.125 9.32269 11.9477 10.5 10.5 10.5Z" fill="#00AB55"/>
                                </svg>
                                <span>Определить местоположение</span>
                            </div>
                        </div>
                        <div className={css(s.setPlace)}>
                            <div className={css(s.setPlaceTitle)}>По расположению</div>
                            <SetPlace region={inputFields.region} city={inputFields.city} setRegion={setRegionHandler} setCity={setCityHandler}/>
                        </div>
                        <div className={css(s.searchExecutorFilter)}
                            onClick={searchExecutor}
                        >
                            Найти
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
