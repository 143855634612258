import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {IProfile} from "../../../../../types/profile";
import {ToggleBtn} from "../../../ToggleBtn";
import {TextField} from "../../../TextField";

export const About = ({maxLength,changeMode,profile,setNewProfile}:{maxLength: number,changeMode:boolean, profile: IProfile, setNewProfile: Function}) => {

    const changeToggleShowHandler = (field: any) => {
        // @ts-ignore
        setNewProfile({...profile, private: {...profile.private, [`${field}`]: !profile.private[`${field}`]}})
    }

    const changeAboutHandler = (valueHtml: string) => {
        setNewProfile({...profile, about: valueHtml})
    }

    return (
        <div className={css(s.About)}>
            <div className={css(s.iconBox)}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0C3.1402 0 0 3.14024 0 7.00004C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7.00004C14 3.14024 10.8598 0 7 0ZM7 12.7273C3.84194 12.7273 1.27273 10.1581 1.27273 7.00004C1.27273 3.84202 3.84194 1.27273 7 1.27273C10.1581 1.27273 12.7273 3.84202 12.7273 7.00004C12.7273 10.1581 10.158 12.7273 7 12.7273Z" fill="white"/>
                    <path d="M6.99968 2.96973C6.53191 2.96973 6.15137 3.35053 6.15137 3.81859C6.15137 4.28624 6.53191 4.6667 6.99968 4.6667C7.46745 4.6667 7.848 4.28624 7.848 3.81859C7.848 3.35053 7.46745 2.96973 6.99968 2.96973Z" fill="white"/>
                    <path d="M7.00013 5.93945C6.64869 5.93945 6.36377 6.22437 6.36377 6.57582V10.394C6.36377 10.7454 6.64869 11.0304 7.00013 11.0304C7.35158 11.0304 7.6365 10.7454 7.6365 10.394V6.57582C7.6365 6.22437 7.35158 5.93945 7.00013 5.93945Z" fill="white"/>
                </svg>
            </div>
            <div className={css(s.main)}>
                <div className={css(s.title)}>Информация обо мне:</div>
                <TextField contentEditable={changeMode} maxLength={maxLength} text={profile.about} setText={changeAboutHandler}/>
            </div>
            {changeMode && <ToggleBtn checked={profile.private.about}
                                      toggle={() => changeToggleShowHandler('about')}/>}
        </div>
    )
}
