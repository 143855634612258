import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import aboutFrame from "../../../assets/images/aboutFrame.png";
import aboutFrameTwo from "../../../assets/images/aboutFrameTwo.png";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import {useAppState} from "../../../../store/reduxStore";
import {AppActions, AppState} from "../../../../store/reducers/app";
import loadingGif from '../../../assets/images/LoadingGif.gif'

export const AboutPage = () => {
    const {about} = useAppState(AppState)
    const {getAboutInfo} = useThunks(AppAPI)
    const {setAboutInfoAction} = useActions(AppActions)

    useEffect(() => {
        getAboutInfo()
        return () => {
            setAboutInfoAction(null)
        }
    }, [])

    return (
        <div className={css(s.AboutPage)}>
            <div className={css(s.container)}>
                <div className={css(s.firstBlock)}>
                    <div className={css(s.firstBlockInfo)}>
                        <div className={css(s.firstBlockInfoTitle)}>
                            О компании
                        </div>
                        <h1 className={css(s.firstBlockInfoSubTitle)}>
                            {about ? about.title : ``}
                        </h1>
                        <h2 className={css(s.firstBlockInfoText)}>
                            {about ? about.text :``}
                        </h2>
                    </div>
                    <div className={css(s.firstBlockImg)}>
                        <div className={css(s.imgBox)}>
                            <img src={aboutFrame} alt="aboutFrame"/>
                        </div>
                    </div>
                </div>
                <div className={css(s.twoBlock)}>
                    <div className={css(s.twoBlockTitle)}>
                        Zemsite — это
                    </div>
                    <div className={css(s.twoBlockMain)}>
                        <div className={css(s.twoBlockMainImg)}>
                            <div className={css(s.imgBox)}>
                                <img src={aboutFrameTwo} alt="aboutFrameTwo"/>
                            </div>
                        </div>
                        <div className={css(s.twoBlockMainInfo)}>
                            <div className={css(s.twoBlockMainInfoTitle)}>
                                Zemsite — это
                            </div>
                            {about &&
                                <div className={css(s.mainInfo)}>
                                    <div className={css(s.executorCount)}>
                                        <div className={css(s.count)}>{about.oneValue}</div>
                                        <div className={css(s.subTitle)}>{about.oneTitle}</div>
                                    </div>
                                    <div className={css(s.executorCount)}>
                                        <div className={css(s.count)}>{about.twoValue}</div>
                                        <div className={css(s.subTitle)}>{about.twoTitle}</div>
                                    </div>
                                    <div className={css(s.executorCount)}>
                                        <div className={css(s.count)}>{about.threeValue}</div>
                                        <div className={css(s.subTitle)}>{about.threeTitle}</div>
                                    </div>
                                </div>}
                            {!about && <div className={css(s.mainInfo)}>
                                <img className={css(s.load)} src={loadingGif} alt="loading"/>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
