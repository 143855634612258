import React from 'react'

export const SvgBasket = () => {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1712 8.1C13.1712 8.61857 12.7469 9.04286 12.2284 9.04286C11.7098 9.04286 11.2855 8.61857 11.2855 8.1V6.21429H9.39979C8.88122 6.21429 8.45693 5.79 8.45693 5.27143C8.45693 4.75286 8.88122 4.32857 9.39979 4.32857H11.2855V2.44286C11.2855 1.92429 11.7098 1.5 12.2284 1.5C12.7469 1.5 13.1712 1.92429 13.1712 2.44286V4.32857H15.0569C15.5755 4.32857 15.9998 4.75286 15.9998 5.27143C15.9998 5.79 15.5755 6.21429 15.0569 6.21429H13.1712V8.1ZM5.63757 19.4143C5.63757 18.3771 6.47671 17.5286 7.51386 17.5286C8.551 17.5286 9.39957 18.3771 9.39957 19.4143C9.39957 20.4514 8.551 21.3 7.51386 21.3C6.47671 21.3 5.63757 20.4514 5.63757 19.4143ZM16.9424 17.5286C15.9053 17.5286 15.0661 18.3771 15.0661 19.4143C15.0661 20.4514 15.9053 21.3 16.9424 21.3C17.9796 21.3 18.8281 20.4514 18.8281 19.4143C18.8281 18.3771 17.9796 17.5286 16.9424 17.5286ZM15.5755 12.8143H8.55122L7.51408 14.7H17.8855C18.4041 14.7 18.8284 15.1243 18.8284 15.6429C18.8284 16.1614 18.4041 16.5857 17.8855 16.5857H7.51408C6.08093 16.5857 5.17579 15.0489 5.86408 13.7854L7.13693 11.4849L3.74265 4.32857H2.79979C2.28122 4.32857 1.85693 3.90429 1.85693 3.38571C1.85693 2.86714 2.28122 2.44286 2.79979 2.44286H4.34608C4.70436 2.44286 5.04379 2.65029 5.19465 2.98029L8.95665 10.9286H15.5755L18.7718 5.14886C19.0169 4.69629 19.5921 4.536 20.0446 4.78114C20.4972 5.03571 20.6669 5.61086 20.4124 6.06343L17.2255 11.8431C16.9049 12.4277 16.2826 12.8143 15.5755 12.8143Z" fill="white"/>
        </svg>
    )
}
