import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {IProfile} from "../../../../../types/profile";
import {ToggleBtn} from "../../../ToggleBtn";
import {TextField} from "../../../TextField";
import {TextInputField} from "../../../TextInputField";

export const Address = ({
                            maxLength,
                            changeMode,
                            profile,
                            setNewProfile
                        }: { maxLength: number, changeMode: boolean, profile: IProfile, setNewProfile: Function }) => {

    const changeToggleShowHandler = (field: any) => {
        // @ts-ignore
        setNewProfile({...profile, private: {...profile.private, [`${field}`]: !profile.private[`${field}`]}})
    }

    const setTextHandler = (index: number, value: string) => {
        const newAddressList = [...profile.address]
        newAddressList[index] = value
        setNewProfile({...profile, address: newAddressList})
    }

    const addAddressHandler = () => {
        if (profile.address.length > 2) return
        setNewProfile({...profile, address: [...profile.address, '']})
    }

    return (
        <div className={css(s.Address)}>
            <div className={css(s.iconBox)}>
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.5 0.860352C3.81144 0.860352 1.625 3.04679 1.625 5.73535C1.625 9.11454 6.0255 13.554 6.21237 13.7417C6.292 13.8205 6.396 13.8604 6.5 13.8604C6.604 13.8604 6.708 13.8205 6.78763 13.7417C6.9745 13.554 11.375 9.11454 11.375 5.73535C11.375 3.04679 9.18856 0.860352 6.5 0.860352ZM6.5 12.8667C5.52988 11.8283 2.4375 8.31666 2.4375 5.73535C2.4375 3.49529 4.25994 1.67285 6.5 1.67285C8.74006 1.67285 10.5625 3.49529 10.5625 5.73535C10.5625 8.31423 7.47013 11.8283 6.5 12.8667Z"
                        fill="white"/>
                    <path
                        d="M6.5 3.29785C5.15612 3.29785 4.0625 4.39148 4.0625 5.73535C4.0625 7.07923 5.15612 8.17285 6.5 8.17285C7.84387 8.17285 8.9375 7.07923 8.9375 5.73535C8.9375 4.39148 7.84387 3.29785 6.5 3.29785ZM6.5 7.36035C5.60381 7.36035 4.875 6.63154 4.875 5.73535C4.875 4.83916 5.60381 4.11035 6.5 4.11035C7.39619 4.11035 8.125 4.83916 8.125 5.73535C8.125 6.63154 7.39619 7.36035 6.5 7.36035Z"
                        fill="white"/>
                    <defs>
                        <rect width="13" height="13" fill="white" transform="translate(0 0.860352)"/>
                    </defs>
                </svg>
            </div>
            <div className={css(s.main)}>
                <div className={css(s.title)}>Адрес:</div>
                {!profile.address.length && <div className={css(s.emptyAddressList)}>Нет добавленных адресов</div>}
                {profile.address.map((address, index) =>
                    <TextInputField key={index} contentEditable={changeMode} maxLength={maxLength} text={address}
                                    setText={(value: string) => setTextHandler(index, value)}/>
                )}
                {changeMode && !(profile.address.length > 2) &&
                    <div className={css(s.addAddress)} onClick={addAddressHandler}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.99997 0C3.58878 0 0 3.58878 0 7.99997C0 12.4112 3.58878 15.9999 7.99997 15.9999C12.4112 15.9999 15.9999 12.4112 15.9999 7.99997C15.9999 3.58875 12.4112 0 7.99997 0ZM7.99997 14.9999C4.14019 14.9999 1 11.8598 1 7.99997C1 4.14019 4.14019 1 7.99997 1C11.8598 1 14.9999 4.14016 14.9999 7.99997C14.9999 11.8598 11.8598 14.9999 7.99997 14.9999ZM12.4375 7.99997C12.4375 8.27613 12.2136 8.49997 11.9375 8.49997H8.49997V11.9375C8.49997 12.2136 8.27609 12.4375 7.99997 12.4375C7.72384 12.4375 7.49997 12.2136 7.49997 11.9375V8.49997H4.06247C3.78634 8.49997 3.56247 8.27613 3.56247 7.99997C3.56247 7.72381 3.78634 7.49997 4.06247 7.49997H7.49997V4.06247C7.49997 3.78631 7.72384 3.56247 7.99997 3.56247C8.27609 3.56247 8.49997 3.78631 8.49997 4.06247V7.49997H11.9375C12.2136 7.49997 12.4375 7.72384 12.4375 7.99997Z"/>
                        </svg>
                        <span>  Добавить адрес</span>
                    </div>}
            </div>
            {changeMode && <ToggleBtn checked={profile.private.address}
                                      toggle={() => changeToggleShowHandler('address')}/>}
        </div>
    )
}
