import {useAppDispatch} from "../store/reduxStore";
import {bindActionCreators} from "@reduxjs/toolkit";

export function useActions<T>(actions: T): T {
    const dispatch = useAppDispatch();
    return bindActionCreators(actions as any, dispatch);
}

export function useThunks<T>(actions: T ): T {
    const dispatch = useAppDispatch();
    return bindActionCreators(actions as any, dispatch);
}