import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    faqList: Array<any> | null
    about: any
    content: any
}
const initialState: stateType = {
    faqList: null,
    about: null,
    content: null,
}

export const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setFaqListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.faqList = action.payload
        },
        addNewFaqAction: (state, action: PayloadAction<any>) : void => {
            if (state.faqList)
                state.faqList = [action.payload,...state.faqList]
        },
        deleteFaqAction: (state, action: PayloadAction<number>) : void => {
            if (state.faqList)
                state.faqList = state.faqList.filter(item => item.id !== action.payload)
        },
        setAboutInfoAction: (state, action: PayloadAction<any>) : void => {
            state.about = action.payload
        },
        setContentAction: (state, action: PayloadAction<any>) : void => {
            state.content = action.payload
        },
    }
})

export const AppReducer = slice.reducer
export const AppActions = slice.actions
export const AppState = (state: RootState) => state.AppReducer


