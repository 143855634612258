import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";

interface stateType {
    noticeList: Array<any>
    messagesCount: number
}
const initialState: stateType = {
    noticeList: [],
    messagesCount: 0,
}

export const slice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
        setNoticeAction: (state, action: PayloadAction<Array<any>>) : void => {
            state.noticeList = action.payload
        },
        pushNoticeAction: (state, action: PayloadAction<any>) : void => {
            state.noticeList = [action.payload, ...state.noticeList]
        },
        pushOnlyNoticeAction: (state, action: PayloadAction<any>) : void => {
            console.log(state.noticeList.find(notice => notice.srcLink === action.payload.srcLink))
            if (!state.noticeList.find(notice => notice.srcLink === action.payload.srcLink)) {
                state.noticeList = [action.payload, ...state.noticeList]
            }
        },
        deleteNoticeAction: (state, action: PayloadAction<number>) : void => {
            state.noticeList = state.noticeList.filter(notice => notice.id !== action.payload)
        },
        clearNoticeAction: (state) : void => {
            state.noticeList = []
        },


        setDemonNoticeMessagesCountAction: (state, action: PayloadAction<number>) : void => {
            state.messagesCount = action.payload
        },
        deleteDemonNoticeMessagesCountAction: (state) : void => {
            state.messagesCount = 0
        },
    }
})

export const NoticeReducer = slice.reducer
export const NoticeActions = slice.actions
export const NoticeState = (state: RootState) => state.NoticeReducer


