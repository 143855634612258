import React from 'react'

export const SvgArrowTop = () => {

    return (
        <svg viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.67657 2.45765L8.53719 6.31833C8.62655 6.40763 8.67578 6.52682 8.67578 6.65392C8.67578 6.78102 8.62655 6.90022 8.53719 6.98951L8.25294 7.27382C8.06773 7.45883 7.7667 7.45883 7.58177 7.27382L4.33985 4.03191L1.09434 7.27742C1.00498 7.36671 0.885853 7.41602 0.758826 7.41602C0.631658 7.41602 0.512529 7.36671 0.423096 7.27742L0.138924 6.99311C0.0495605 6.90375 0.000329944 6.78462 0.000329938 6.65752C0.000329933 6.53042 0.0495605 6.41122 0.138924 6.32193L4.00307 2.45765C4.09271 2.36814 4.2124 2.31898 4.33964 2.31926C4.46737 2.31898 4.587 2.36814 4.67657 2.45765Z"
                />
            <clipPath id="clip0_392_12732">
                <rect width="8.67545" height="8.67545" fill="white"
                      transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 8.67578 9.20557)"/>
            </clipPath>
        </svg>
    )
}
