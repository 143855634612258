import React, {useEffect, useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../../helpers/useActions";
import {HintActions} from "../../../../../../store/reducers/hint";
import {MessengerAPI} from "../../../../../../API/MessengerAPI";
import {useAppState} from "../../../../../../store/reduxStore";
import {MessengerState} from "../../../../../../store/reducers/messenger";
import {ActiveChat} from "../../../ChatPage/ActiveChat";
import {UserState} from "../../../../../../store/reducers/auth";
import {IResponse} from "../../../../../../types/api";
import {customFetch, customFetchJson} from "../../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../../helpers/config";

export const ChatActiveTask = ({activeTask}: any) => {

    const {connectClient,closeClient,getTaskChat} = useThunks(MessengerAPI)
    const {setHintApp} = useActions(HintActions)

    const { companionId, connect, chatList } = useAppState(MessengerState)

    useEffect(() => {
        connectClient()
        // readDemonNotice(`messagesCount`)
        return () => {
            closeClient()
        }

    }, [])
    useEffect(()=>{
        if (connect) {
            getTaskChat(activeTask.id)
        }
    },[connect])

    return (
        <div className={css(s.ChatActiveTask)}>
            <ActiveChat activeTask={activeTask}/>
        </div>
    )
}
