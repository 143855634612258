import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import {useClickOutside} from "../../../helpers/useClickOutside";
import {cityList, allRegionList, getRegionForCity} from "../../../helpers/regionAndCity";

export const SetPlace = ({region, city, setRegion, setCity}:any) => {

    const [showPlaceBox,setShowPlaceBox] = useState<any>({
        region: false,
        city: false,
    })
    const changeShowPlaceBoxHandler = (field: string) => {
        setShowPlaceBox({...{
                region: false,
                city: false,
            }, [field]: true})
    }
    const clearShowRegionBoxHandler = (e?:any) => {
        if (showPlaceBox.region) {
            e?.stopPropagation()
            setShowPlaceBox((prev:any)=>({...prev,region: false}))
        }
    }
    const clearShowCityBoxHandler = (e?:any) => {
        if (showPlaceBox.city) {
            e?.stopPropagation()
            setShowPlaceBox((prev:any)=>({...prev,city: false}))
        }
    }
    useClickOutside(()=>{setShowPlaceBox({region: false, city: false,})},[`placeRegionHomePage`,`placeCityHomePage`])

    const [inputFields,setInputFields] = useState({
        region: ``,
        city: ``
    })
    useEffect(()=>{
        if (!showPlaceBox.region) setInputFields((prev:any)=>({...prev,region: ``}))
    },[showPlaceBox.region])
    useEffect(()=>{
        if (!showPlaceBox.city) setInputFields((prev:any)=>({...prev,city: ``}))
    },[showPlaceBox.city])

    const inputRegionHandler = (e:any) => {
        setInputFields(prev => ({...prev, region: e.target.value}))
    }
    const inputCityHandler = (e:any) => {
        setInputFields(prev => ({...prev, city: e.target.value}))
    }
    const setRegionHandler = (e:any,region: string) => {
        e.stopPropagation()
        setShowPlaceBox((prev:any)=>({...prev,region: false}))
        setRegion(region)
        setCity('')
        setInputFields((prev:any)=>({...prev,region: ``}))
    }
    const setCityHandler = (e:any,city: string) => {
        e.stopPropagation()
        setShowPlaceBox((prev:any)=>({...prev,city: false}))
        if (!region.length) {
            setRegion(getRegionForCity(city))
        }
        setCity(city)
        setInputFields((prev:any)=>({...prev,city: ``}))
    }

    return (
        <div className={css(s.setPlaceInputBox)}>
            <div id={`placeRegionHomePage`} className={css(s.placeRegion, showPlaceBox.region && s.active)} onClick={()=>changeShowPlaceBoxHandler(`region`)}>
                {!showPlaceBox.region && <span>{region.length ? region :`Выберите регион`}</span>}
                {showPlaceBox.region && <input type="text" value={inputFields.region} onChange={inputRegionHandler} placeholder={`Введите регион`} autoFocus={true}/>}
                <div className={css(s.placeRegionBtn)} onClick={clearShowRegionBoxHandler}><svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.07078 6.55696L0.17573 1.66182C0.0624222 1.5486 -3.93135e-08 1.39747 -3.36941e-08 1.23631C-2.80748e-08 1.07516 0.0624222 0.924022 0.17573 0.810804L0.536134 0.450311C0.770977 0.215736 1.15267 0.215736 1.38715 0.450311L5.49772 4.56088L9.61285 0.44575C9.72616 0.332532 9.8772 0.27002 10.0383 0.27002C10.1995 0.27002 10.3506 0.332532 10.464 0.44575L10.8243 0.806243C10.9376 0.919551 11 1.0706 11 1.23175C11 1.39291 10.9376 1.54404 10.8243 1.65726L5.92475 6.55696C5.81108 6.67045 5.65932 6.73278 5.49799 6.73242C5.33603 6.73278 5.18436 6.67045 5.07078 6.55696Z" fill="#212B36"/>
                </svg></div>
                <div className={css(s.placeRegionBox, showPlaceBox.region && s.show)}>
                    <div className={css(s.clearRegionBtn)} onClick={(e)=>setRegionHandler(e,'')}>очистить</div>
                    {allRegionList().filter(region=>region.toLowerCase().startsWith(inputFields.region.toLowerCase())).map((region, index) => <div className={css(s.placeBoxItem)} key={index} onClick={(e)=>setRegionHandler(e,region)}>{region}</div>)}
                    {!allRegionList().filter(region=>region.toLowerCase().startsWith(inputFields.region.toLowerCase())).length && <div className={css(s.emptyRegionList)}>Нет подходящего региона</div>}
                </div>
            </div>
            <div id={`placeCityHomePage`} className={css(s.placeCity, showPlaceBox.city && s.active)} onClick={()=>changeShowPlaceBoxHandler(`city`)}>
                {!showPlaceBox.city && <span>{city.length ? city :`Выберите город`}</span>}
                {showPlaceBox.city && <input type="text" value={inputFields.city} onChange={inputCityHandler} placeholder={`Введите город`} autoFocus={true}/>}
                <div className={css(s.placeCityBtn)} onClick={clearShowCityBoxHandler}><svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.07078 6.55696L0.17573 1.66182C0.0624222 1.5486 -3.93135e-08 1.39747 -3.36941e-08 1.23631C-2.80748e-08 1.07516 0.0624222 0.924022 0.17573 0.810804L0.536134 0.450311C0.770977 0.215736 1.15267 0.215736 1.38715 0.450311L5.49772 4.56088L9.61285 0.44575C9.72616 0.332532 9.8772 0.27002 10.0383 0.27002C10.1995 0.27002 10.3506 0.332532 10.464 0.44575L10.8243 0.806243C10.9376 0.919551 11 1.0706 11 1.23175C11 1.39291 10.9376 1.54404 10.8243 1.65726L5.92475 6.55696C5.81108 6.67045 5.65932 6.73278 5.49799 6.73242C5.33603 6.73278 5.18436 6.67045 5.07078 6.55696Z" fill="#212B36"/>
                </svg></div>
                <div className={css(s.placeCityBox, showPlaceBox.city && s.show)}>
                    <div className={css(s.clearCityBtn)} onClick={(e)=>setCityHandler(e,'')}>очистить</div>
                    {cityList(region)
                        .filter(city => city.toLowerCase().startsWith(inputFields.city.toLowerCase()))
                        .map((city, index) =>
                            <div
                                className={css(s.placeBoxItem)}
                                key={index}
                                onClick={(e)=>setCityHandler(e,city)}>
                                {city}
                            </div>
                        )}
                    {!cityList(region).filter(city=>city.toLowerCase().startsWith(inputFields.city.toLowerCase())).length && <div className={css(s.emptyRegionList)}>Нет подходящего города</div>}
                </div>
            </div>
        </div>
    )
}
