import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";

interface stateType {
    login: boolean
    registration: boolean
    resetPassword: boolean
    changePassword: boolean
    recoverPassword: boolean

}

const initialState: stateType = {
    login: false,
    registration: false,
    resetPassword: false,
    changePassword: false,
    recoverPassword: false,
}

export const slice = createSlice({
    name: 'showPopup',
    initialState,
    reducers: {
        setIsShowPopupAction: (state, action: PayloadAction<{type: keyof stateType, isShow: boolean}>) : void => {
            state[`${action.payload.type}`] = action.payload.isShow
        },
    }
})

export const showPopupReducer = slice.reducer
export const showPopupActions = slice.actions
export const showPopupState = (state: RootState) => state.showPopupReducer

export interface ShowPopupType extends stateType {}
