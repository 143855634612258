import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {IsLoadingReducer} from "./reducers/isLoading";
import {UserReducer} from "./reducers/auth";
import {showPopupReducer} from "./reducers/showPopup";
import {ProfileReducer} from "./reducers/profile";
import {VerificationReducer} from "./reducers/verification";
import {HintReducer} from "./reducers/hint";
import {AdminReducer} from "./reducers/admin";
import {ManagerReducer} from "./reducers/manager";
import {MessengerReducer} from "./reducers/messenger";
import {NoticeReducer} from "./reducers/notice";
import {TaskReducer} from "./reducers/task";
import {NavigationReducer} from "./reducers/navigation";
import {BillReducer} from "./reducers/bill";
import {AppReducer} from "./reducers/app";

const rootReducer = combineReducers({
    IsLoadingReducer,
    HintReducer,
    showPopupReducer,
    UserReducer,
    ProfileReducer,
    VerificationReducer,
    AdminReducer,
    ManagerReducer,
    MessengerReducer,
    NoticeReducer,
    TaskReducer,
    NavigationReducer,
    BillReducer,
    AppReducer,
})

export const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppState: TypedUseSelectorHook<RootState> = useSelector