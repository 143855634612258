import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {BillAPI} from "../../../../../API/BillAPI";
import loadingGif from '../../../../assets/images/LoadingGif.gif'
import {useAppState} from "../../../../../store/reduxStore";
import {IsLoadingActions, IsLoadingState} from "../../../../../store/reducers/isLoading";

export const TopUp = () => {

    const {addMyBalance} = useThunks(BillAPI)
    const {addBalance: addBalanceLoading} = useAppState(IsLoadingState)

    const [price,setPrice] = useState(0)

    const changePriceHandler = (valueHtml: string) => {
        const pattern = /^\d+(\.?)\d*$/g;
        if (!valueHtml.match(pattern)) {
            valueHtml = valueHtml.slice(0, -1)
        }
        const price = Math.abs(Number.parseInt(valueHtml))
        setPrice(isNaN(price) ? 0 : price)
    }

    const addBalanceHandler = (e:any) =>{
        e.preventDefault()
        setPrice(0)
        addMyBalance(price)
    }

    return (
        <form className={css(s.TopUp)} onSubmit={addBalanceHandler}>
            <div className={css(s.title)}>Банковская карта</div>
            <div>Комиссия 4%, к оплате: {`${price > 0 ? (price + (price/100*4)) : '0'} руб.`}</div>
            <input type="text" placeholder={`Сумма пополнения`} value={price > 0 ? price : ''} onChange={e=>{changePriceHandler(e.target.value)}}/>
            <button>
                {addBalanceLoading ? <img src={loadingGif} alt="loading"/> : `Пополнить`}
            </button>
        </form>
    )
}
