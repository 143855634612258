import React, {useEffect, useRef} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Outlet, useLocation} from "react-router-dom";
import {Footer} from "../../Footer";
import {PopupWrapper} from "../../PopupWrapper";
import {ChangePassword} from "../../PopupWrapper/ChangePassword";
import {RegularHeader} from "../../Header/RegularHeader";

export const RegularLayout = () => {

    const location = useLocation()
    const ref = useRef<any>(null)
    useEffect(()=>{
        if (ref?.current) {
            ref?.current.scrollTo(0, 0)
        }
    },[location,ref])

    return (
        <div className={css(s.RegularLayout)} ref={ref}>
            <RegularHeader />
            <Outlet />
            <Footer />
            <PopupWrapper><ChangePassword id={`changePasswordPopupMain`} type={`changePassword`}/></PopupWrapper>
        </div>
    )
}
