import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../../helpers/useActions";
import {useAppState} from "../../../../../../store/reduxStore";
import {VerificationState} from "../../../../../../store/reducers/verification";
import {SvgZipIcon} from "../../../../../assets/images/svg/SvgZipIcon";
import {SvgJpgIcon} from "../../../../../assets/images/svg/SvgJpgIcon";
import {SvgPdfIcon} from "../../../../../assets/images/svg/SvgPdfIcon";
import loadingGif from "../../../../../assets/images/LoadingGif.gif";
import {TaskAPI} from "../../../../../../API/TaskAPI";
import {TaskActions, TaskState} from "../../../../../../store/reducers/task";

export const AddFiles = ({taskId}:any) => {

    const {getAddedTaskFile, addTaskFile, deleteTaskFile} = useThunks(TaskAPI)
    const {addedFiles} = useAppState(TaskState)
    const {setAddedFileListAction} = useActions(TaskActions)

    const deleteFileHandler = (minFile:any) => {
        if (minFile.userId === 0) return
        deleteTaskFile(minFile)
    }

    useEffect(() => {
        getAddedTaskFile(taskId)
        return () => {
            setAddedFileListAction(null)
        }
    }, [])

    const addFileHandler = (e: any) => {
        const file = e.target.files[0]
        addTaskFile(file, taskId)
        e.target.value = ''
    }

    return (
        <div className={css(s.AddFiles)}>
            <div className={css(s.title)}>Файлы</div>
            <div className={css(s.limit)}>Загрузите до 5 файлов общим объемом до 20МБ</div>
            <div className={css(s.addedFileListMain)}>
                <label className={css(s.addFileBtn)}>
                    <input type="file" accept={`.png,.jpeg,.jpg,.zip,.pdf`} onInput={addFileHandler}/>
                    +
                </label>
                {addedFiles === null &&
                    <div className={css(s.loadingFileList)}>
                        loading...
                    </div>
                }
                {addedFiles !== null &&
                    <div className={css(s.fileList)}>
                        {addedFiles?.map((minFile: any) =>
                            <div className={css(s.addedFileItem)} key={minFile.id} title={minFile.fileName} onClick={()=>deleteFileHandler(minFile)}>
                                <div className={css(s.fileImgBox)}>
                                    {(()=>{
                                        switch (minFile.fileName.slice(minFile.fileName.lastIndexOf(`.`))){
                                            case `.zip`: return <SvgZipIcon />
                                            case `.jpeg`: return <SvgJpgIcon />
                                            case `.jpg`: return <SvgPdfIcon />
                                            case `.png`: return <SvgPdfIcon />
                                            case `.pdf`: return <SvgPdfIcon />
                                            default: return <SvgZipIcon />
                                        }
                                    })()}
                                </div>
                                <div className={css(s.fileSizeText)}>{`${Math.ceil(minFile.fileSize / 1000 / 1000)}mb`}</div>
                                <div >{minFile.fileName}</div>
                                {minFile.userId === 0 && <div className={css(s.upload)}>
                                    <img src={loadingGif} alt="loading"/>
                                </div>}
                                <div className={css(s.deleteFile)}>
                                    &#x2715;
                                </div>
                            </div>)}
                    </div>}
            </div>
        </div>
    )
}
