import {AppDispatch, RootState} from "../store/reduxStore";
import {bill_server} from "../helpers/config";
import {customFetchJson} from "../helpers/customFetch";
import {IResponse} from "../types/api";
import {HintActions} from "../store/reducers/hint";
import {BillActions} from "../store/reducers/bill";
import {IsLoadingActions, IsLoadingReducer, IsLoadingState} from "../store/reducers/isLoading";

export const BillAPI = {

    getMyBalance: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/my-balance`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: response.payload.massage,
                    type: `warning`
                }))
                return
            }

            dispatch(BillActions.setMyBalanceAction(response.payload.balance))

        }
        catch (err) {
            console.log(err)
        }
        finally {

        }
    },
    addMyBalance: (count:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setAddBalanceAction(true))
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/add-balance`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    count: count
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: response.payload.massage,
                    type: `warning`
                }))
                return
            }

            window.location.href = `${response.payload.paylink}`
            // dispatch(HintActions.setHintApp({
            //     isShow: true,
            //     text: `Запрос на пополнение баланса выполнен, ожидайте обновление баланса.`,
            //     type: `ok`
            // }))
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setTimeout(()=>{
                dispatch(IsLoadingActions.setAddBalanceAction(false))
            },1000)
        }
    },
    subtractBalance: (count:number, valueObj:{type: string, value: string}) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setSubtractBalanceAction(true))

            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/subtract-balance`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    count: count,
                    valueObj: valueObj
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: response.payload.message,
                    type: `warning`
                }))
                return
            }

            const balance = getState().BillReducer.balance
            dispatch(BillActions.setMyBalanceAction(Number(balance)-Number(count)))
            dispatch(HintActions.setHintApp({
                isShow: true,
                text: `Запрос на вывод средств в обработке, ожидайте поступления.`,
                type: `ok`
            }))
        }
        catch (err) {
            console.log(err)
        }
        finally {
            dispatch(IsLoadingActions.setSubtractBalanceAction(false))
        }
    },
    getBillHistory: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setBillHistoryListAction(true))

            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/get-bill-history`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: response.payload.message,
                    type: `warning`
                }))
                return
            }

            dispatch(BillActions.setHistoryListAction(response.payload.list))
            dispatch(BillActions.setTotalHistoryListAction(response.payload.total))

            // dispatch(HintActions.setHintApp({
            //     isShow: true,
            //     text: `Запрос на вывод средств в обработке, ожидайте поступления.`,
            //     type: `ok`
            // }))
        }
        catch (err) {
            console.log(err)
        }
        finally {
            dispatch(IsLoadingActions.setBillHistoryListAction(false))
        }
    },
    addBillHistory: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const lengthList = getState().BillReducer.historyList?.length
            if (!lengthList) return

            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/get-bill-history?offset=${lengthList}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: response.payload.message,
                    type: `warning`
                }))
                return
            }

            dispatch(BillActions.addHistoryListAction(response.payload.list))
            dispatch(BillActions.setTotalHistoryListAction(response.payload.total))

            // dispatch(HintActions.setHintApp({
            //     isShow: true,
            //     text: `Запрос на вывод средств в обработке, ожидайте поступления.`,
            //     type: `ok`
            // }))
        }
        catch (err) {
            console.log(err)
        }
        finally {
        }
    },
}
