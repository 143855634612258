import React from 'react'

export const SvgSearch = () => {

    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.45184 16.8964C10.3289 16.8965 12.1522 16.2702 13.633 15.1168L19.2197 20.7035C19.637 21.1065 20.302 21.095 20.7051 20.6776C21.0983 20.2705 21.0983 19.6251 20.7051 19.2181L15.1184 13.6314C17.9805 9.94717 17.314 4.64039 13.6298 1.77834C9.94554 -1.0837 4.6388 -0.417228 1.77676 3.267C-1.08529 6.95122 -0.418815 12.258 3.26541 15.12C4.74864 16.2723 6.5736 16.8974 8.45184 16.8964ZM3.963 3.96136C6.44215 1.48217 10.4616 1.48213 12.9408 3.96127C15.42 6.44041 15.4201 10.4599 12.9409 12.9391C10.4618 15.4183 6.44228 15.4183 3.96309 12.9392C3.96305 12.9391 3.96305 12.9391 3.963 12.9391C1.48386 10.478 1.46925 6.47317 3.93033 3.99403C3.94121 3.98311 3.95208 3.97223 3.963 3.96136Z" fill="#212B36"/>
        </svg>
    )
}
