import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import { Link } from 'react-router-dom';
import {useActions} from "../../../../helpers/useActions";
import {showPopupActions} from "../../../../store/reducers/showPopup";
import {StandardButton} from "../../Button/StandardButton";
import {SvgSearch} from "../../../assets/images/svg/SvgSearch";
import {SvgLogin} from "../../../assets/images/svg/SvgLogin";

export const GuestHeader = () => {

    const {setIsShowPopupAction} = useActions(showPopupActions)
    const [isShowMobileMenu,setIsShowMobileMenu] = useState(false)

    const showLoginPopupHandler = () => {
        setIsShowPopupAction({type: 'login', isShow: true})
    }
    const showRegistrationPopupHandler = () => {
        setIsShowPopupAction({type: 'registration', isShow: true})
    }

    return (
        <div className={css(s.GuestHeader)}>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    <div className={css(s.burgerBtn)} onClick={()=>setIsShowMobileMenu(prev => !prev)}>
                        <svg className={css(s.showBtn, !isShowMobileMenu && s.active)} viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9748 3.5H1.70893C0.848169 3.5 0.189941 2.85 0.189941 2C0.189941 1.15 0.848169 0.5 1.70893 0.5H22.9748C23.8355 0.5 24.4937 1.15 24.4937 2C24.4937 2.85 23.8355 3.5 22.9748 3.5Z" fill="#212B36"/>
                            <path d="M22.9748 10.5H1.70893C0.848169 10.5 0.189941 9.85 0.189941 9C0.189941 8.15 0.848169 7.5 1.70893 7.5H22.9748C23.8355 7.5 24.4937 8.15 24.4937 9C24.4937 9.85 23.8355 10.5 22.9748 10.5Z" fill="#212B36"/>
                            <path d="M22.9748 17.5H1.70893C0.848169 17.5 0.189941 16.85 0.189941 16C0.189941 15.15 0.848169 14.5 1.70893 14.5H22.9748C23.8355 14.5 24.4937 15.15 24.4937 16C24.4937 16.85 23.8355 17.5 22.9748 17.5Z" fill="#212B36"/>
                        </svg>
                        <svg className={css(s.hideBtn, isShowMobileMenu && s.active)} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.578 14.9057C13.9458 14.9271 14.3074 14.8048 14.5868 14.5647C15.1377 14.0105 15.1377 13.1155 14.5868 12.5613L2.52411 0.498628C1.95114 -0.0375224 1.05205 -0.00771814 0.515901 0.565255C0.0310774 1.08339 0.00281715 1.87978 0.449741 2.43095L12.5835 14.5647C12.8592 14.8013 13.2151 14.9234 13.578 14.9057Z" fill="#212B36"/>
                            <path d="M1.52868 14.9057C1.9014 14.9041 2.25863 14.7562 2.52327 14.4937L14.586 2.43093C15.0963 1.83494 15.027 0.938004 14.431 0.427588C13.899 -0.0279459 13.1145 -0.0279459 12.5826 0.427588L0.448895 12.4903C-0.123939 13.0266 -0.153556 13.9257 0.382735 14.4986C0.404071 14.5213 0.426108 14.5434 0.448895 14.5647C0.746002 14.8231 1.13701 14.9466 1.52868 14.9057Z" fill="#212B36"/>
                        </svg>
                    </div>
                    <Link to={'/'} className={css(s.logo)}>
                        <div className={css(s.title)}>zemsite</div>
                    </Link>
                    <div className={css(s.navigation)}>
                        <Link to={'/about'}>О компании</Link>
                        <Link to={'/executor-list'}>Каталог исполнителей</Link>
                        <Link to={'/contact'}>Контакты</Link>
                        <Link to={'/faq'}>FAQ</Link>
                    </div>
                    <div className={css(s.searchBtn)}>
                        <SvgSearch />
                    </div>
                    <div className={css(s.auth)}>
                        <div className={css(s.registrationBtn)} onClick={showRegistrationPopupHandler}>
                            Регистрация
                        </div>
                        <StandardButton onClick={showLoginPopupHandler} style={{minWidth: `122px`}} text={`Вход`} svgComponent={<SvgLogin/>}/>
                    </div>
                </div>
            </div>
            <div className={css(s.mobileMain, isShowMobileMenu && s.show)} >
                <div className={css(s.navigation)}>
                    <Link to={'/about'} onClick={e=>setIsShowMobileMenu(false)}>О компании</Link>
                    <Link to={'/executor-list'} onClick={e=>setIsShowMobileMenu(false)}>Каталог исполнителей</Link>
                    <Link to={'/contact'} onClick={e=>setIsShowMobileMenu(false)}>Контакты</Link>
                    <Link to={'/faq'} onClick={e=>setIsShowMobileMenu(false)}>FAQ</Link>
                </div>
            </div>
        </div>
    )
}
