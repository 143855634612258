
import {AppDispatch, RootState} from "../store/reduxStore";
import {application_server, bill_server, store_server, task_server, user_server} from "../helpers/config";
import {customFetchFile, customFetchJson} from "../helpers/customFetch";
import {IResponse} from "../types/api";
import {ManagerActions} from "../store/reducers/manager";
import {IMinFile} from "../types/file";
import {HintActions} from "../store/reducers/hint";
import {NavigationActions} from "../store/reducers/navigation";
import {IsLoadingActions} from "../store/reducers/isLoading";

export const ManagerAPI = {

    getUserList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.userList?.length
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/userList?offset=${offset?offset:0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ManagerActions.setUserListAction(response.payload.list))
            dispatch(ManagerActions.setTotalUserListAction(response.payload.total))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    addUserList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.userList?.length

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/userList?offset=${offset?offset:0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ManagerActions.addUserListAction(response.payload.list))
            dispatch(ManagerActions.setTotalUserListAction(response.payload.total))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    changeVerifyStatus: (id: number, value: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/changeVerifyStatus`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    value: value
                })
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(ManagerActions.updateUserAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    getVerificationList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.verificationList?.length

            const response = await customFetchJson(`${user_server}/api/verification/getList?offset=${offset?offset:0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(ManagerActions.setVerificationListAction([]))
                return
            }
            dispatch(ManagerActions.setVerificationListAction(response.payload.list))
            dispatch(ManagerActions.setTotalVerificationListAction(response.payload.total))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    addVerificationList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.verificationList?.length

            const response = await customFetchJson(`${user_server}/api/verification/getList?offset=${offset?offset:0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(ManagerActions.setVerificationListAction([]))
                return
            }
            dispatch(ManagerActions.addVerificationListAction(response.payload.list))
            dispatch(ManagerActions.setTotalVerificationListAction(response.payload.total))
        } catch (error) {
            console.log('error client', error)
        } finally {
            callback()
        }
    },
    getVerificationFileList: (id:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${store_server}/api/store/verification/fileList/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(ManagerActions.setActiveVerificationFilesAction(null))
                dispatch(NavigationActions.setPathAction(`/verify`))
                return
            }
            dispatch(ManagerActions.setActiveVerificationFilesAction(response.payload.list))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    downloadFile: (fileMin: IMinFile) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchFile(`${store_server}/api/store/verification/download/${fileMin.id}`, {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(NavigationActions.setPathAction(`/verify`))
                return
            }

            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.payload);
            a.download = fileMin.fileName;
            a.click();

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    changeStatusVerification: (value: string, id:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${user_server}/api/verification/changeVerifyStatus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                    value: value
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Ошибка, обратитесь к администратору. (${response.payload.message})`,
                    type: `warning`
                }))
                return
            }
        } catch (error) {
            console.log('error client', error)
        } finally {
            dispatch(NavigationActions.setPathAction(`/verify`))
        }
    },

    getArbitrageTaskList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.arbitrageTaskList?.length

            const response = await customFetchJson(`${task_server}/api/arbitrage/list?offset=${offset?offset:0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Ошибка, обратитесь к администратору. (${response.payload.message})`,
                    type: `warning`
                }))
                return
            }
            dispatch(ManagerActions.setArbitrageTaskListAction(response.payload.list))
            dispatch(ManagerActions.setTotalArbitrageTaskListAction(response.payload.total))

        } catch (error) {
            console.log('error client', error)
        } finally {

        }
    },
    addArbitrageTaskList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.arbitrageTaskList?.length

            const response = await customFetchJson(`${task_server}/api/arbitrage/list?offset=${offset?offset:0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Ошибка, обратитесь к администратору. (${response.payload.message})`,
                    type: `warning`
                }))
                return
            }
            dispatch(ManagerActions.addArbitrageTaskListAction(response.payload.list))
            dispatch(ManagerActions.setTotalArbitrageTaskListAction(response.payload.total))

        } catch (error) {
            console.log('error client', error)
        } finally {
            callback()
        }
    },
    getArbitrageTask: (taskId:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/arbitrage/task/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Ошибка, обратитесь к администратору. (${response.payload.message})`,
                    type: `warning`
                }))
                dispatch(NavigationActions.setPathAction(`/arbitrage`))
                return
            }
            dispatch(ManagerActions.setActiveArbitrageTaskAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {

        }
    },
    sendDecisionArbitrageTask: (taskId:number, answer: string, percent: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/arbitrage/decision`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId:taskId,
                    answer:answer,
                    percent:percent,
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Ошибка, обратитесь к администратору. (${response.payload.message})`,
                    type: `warning`
                }))
                dispatch(NavigationActions.setPathAction(`/arbitrage`))
                return
            }
            dispatch(HintActions.setHintApp({
                isShow: true,
                text: `${response.payload}`,
                type: `ok`
            }))
            // dispatch(ManagerActions.setActiveArbitrageTaskAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
            dispatch(NavigationActions.setPathAction(`/arbitrage`))
        }
    },

    getAppealList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setAppealListAction(true))
            const offset = getState().ManagerReducer.appealList?.length

            const response: IResponse<any> = await customFetchJson(`${application_server}/api/appeal/get?offset=${offset ? offset : 0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `${response.payload.message}`,
                    type: `error`
                }))
                dispatch(ManagerActions.setAppealListAction([]))
                return
            }

            dispatch(ManagerActions.setAppealListAction(response.payload.list))
            dispatch(ManagerActions.setTotalAppealListAction(response.payload.total))
        }
        catch (err) {
            dispatch(ManagerActions.setAppealListAction([]))
            console.log(err)
        }
        finally {
            dispatch(IsLoadingActions.setAppealListAction(false))
        }
    },
    addAppealList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.appealList?.length

            const response: IResponse<any> = await customFetchJson(`${application_server}/api/appeal/get?offset=${offset ? offset : 0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `${response.payload.message}`,
                    type: `error`
                }))
                dispatch(ManagerActions.setAppealListAction([]))
                return
            }

            dispatch(ManagerActions.addAppealListAction(response.payload.list))
            dispatch(ManagerActions.setTotalAppealListAction(response.payload.total))

        }
        catch (err) {
            dispatch(ManagerActions.setAppealListAction([]))
            console.log(err)
        }
        finally {
            callback()
        }
    },

    getWithdrawList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.withdrawList?.length
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getPendingWithdrawList?offset=${offset?offset:0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ManagerActions.setWithdrawListAction(response.payload.list))
            dispatch(ManagerActions.setTotalWithdrawListAction(response.payload.total))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    addWithdrawList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const offset = getState().ManagerReducer.withdrawList?.length
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getPendingWithdrawList?offset=${offset?offset:0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ManagerActions.addWithdrawListAction(response.payload.list))
            dispatch(ManagerActions.setTotalWithdrawListAction(response.payload.total))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },
    changeStatusWithdraw: (id: number, type: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/changeStatusWithdraw`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: type,
                    id: id
                })
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ManagerActions.changeWithdrawItemAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
        }
    },
}
