import {AppDispatch, RootState} from "../store/reduxStore";
import {store_server, task_server, user_server} from "../helpers/config";
import {customFetchFile, customFetchJson} from "../helpers/customFetch";
import {IResponse} from "../types/api";
import {IMinFile} from "../types/file";
import {TaskActions} from "../store/reducers/task";
import {IsLoadingActions} from "../store/reducers/isLoading";
import {useParams} from "react-router-dom";
import {useCallback} from "react";
import {NavigationActions} from "../store/reducers/navigation";
import {HintActions} from "../store/reducers/hint";
const endDateTime = (dateTime: number) => {
    let newDate = new Date(dateTime)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate.getTime() + 8.64e+7
}
export const TaskAPI = {

    // new task
    getNewTask: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/getNewTask`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setNewTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    publishNewTask: (newTask: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setPublishNewTaskAction(true))

            const response = await customFetchJson(`${task_server}/api/task/publishNewTask`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newTask)
            })

            if (response.error) {
                console.log(response.payload)
                return
            }

            // установить активную задачу...
            // callback()
            // , ()=>navigate(`/task-list/${newTaskState.id}`)
            dispatch(NavigationActions.setPathAction(`/task-list/${newTask.id}`))

        } catch (error) {
            console.log('error client', error)
        } finally {
            dispatch(IsLoadingActions.setPublishNewTaskAction(false))
        }
    },

    // files
    getAddedTaskFile: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<Array<IMinFile>> = await customFetchJson(`${store_server}/api/store/task/getAddedFileList/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                window.location.pathname = `/error`
                // navigate(`/error`)
                return
            }
            dispatch(TaskActions.setAddedFileListAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    getDownloadTaskFile: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<Array<IMinFile>> = await customFetchJson(`${store_server}/api/store/task/getDownloadTaskFile/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                // console.log(response.payload)
                // window.location.pathname = `/error`
                // navigate(`/error`)
                return
            }
            dispatch(TaskActions.setDownloadFileListAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    addTaskFile: (file: File, taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        const liteId = Date.now()
        const fakeFile = {
            id: liteId,
            userId: 0,
            fileName: file.name.toLowerCase(),
            fileSize: file.size
        }
        const addedFiles = getState().TaskReducer.addedFiles
        try {
            const fullSize = addedFiles ? addedFiles.reduce((sum, file) => {
                return sum + Number(file.fileSize)
            }, 0) : 0
            if (((fullSize + file.size) > 20000000) || (addedFiles && addedFiles.length > 4)) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Превышен лимит`,
                    type: 'warning'
                }))
                return
            }
            if (file.size > 20000000) {
                alert('Слишком большой файл')
                return
            }
            dispatch(TaskActions.addFileAction(fakeFile))

            const formData = new FormData();
            formData.append('file', file)

            const response: IResponse<IMinFile> = await customFetchJson(`${store_server}/api/store/task/addFile?taskId=${taskId}`, {
                method: 'POST',
                body: formData
            })

            if (response.error) {
                dispatch(TaskActions.deleteFileAction(fakeFile))
                const error: any = response.payload
                if (error.errorCode === 2.1) {
                    console.log(error.errorCode)
                    dispatch(HintActions.setHintApp({
                        isShow: true,
                        text: error.message,
                        type: 'warning'
                    }))
                    return
                }
                console.log(response.payload)
                // window.location.pathname = `/error`
                // navigate(`/error`)
                return
            }
            dispatch(TaskActions.deleteFileAction(fakeFile))
            dispatch(TaskActions.addFileAction(response.payload))
        } catch (error) {
            dispatch(TaskActions.deleteFileAction(fakeFile))
            console.log('error client', error)
        } finally {
        }
    },
    deleteTaskFile: (file: IMinFile) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(TaskActions.deleteFileAction(file))
            const response: IResponse<IMinFile> = await customFetchJson(`${store_server}/api/store/task/deleteFile/${file.id}`, {
                method: 'DELETE',
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(TaskActions.addFileAction(file))
                // navigate(`/error`)
                return
            }

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    // list
    getTaskList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const search = getState().TaskReducer.searchTaskList
            const filter = getState().TaskReducer.filterTaskList
            const countList = getState().TaskReducer.taskList !== null ? getState().TaskReducer.taskList?.length : 0

            let createDate = null
            let dT = 0
            switch (filter.createDate){
                case 0:
                    dT = 8.64e+7
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 1:
                    dT = 8.64e+7 * 2
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 2:
                    dT = 8.64e+7 * 3
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 3:
                    dT = 8.64e+7 * 5
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                default: createDate = null
            }
            let deadline = null
            let dlT = 0
            switch (filter.deadline){
                case 0:
                    dlT = 8.64e+7
                    deadline = endDateTime(Date.now()) + dlT
                    break
                case 1:
                    dlT = 8.64e+7 * 2
                    deadline = endDateTime(Date.now()) + dlT
                    break
                case 2:
                    dlT = 8.64e+7 * 3
                    deadline = endDateTime(Date.now()) + dlT
                    break
                case 3:
                    dlT = 8.64e+7 * 4
                    deadline = endDateTime(Date.now()) + dlT
                    break
                default: deadline = null
            }

            const response = await customFetchJson(`${task_server}/api/task/getTaskList?offset=${countList}&search=${search}&createDate=${createDate}&deadline=${deadline}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(TaskActions.setTaskListAction(null))
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(TaskActions.setTaskListAction(response.payload.list))
            dispatch(TaskActions.setTotalTaskListAction(response.payload.total))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    addExecutorList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const search = getState().TaskReducer.searchTaskList
            const filter = getState().TaskReducer.filterTaskList
            const countList = getState().TaskReducer.taskList !== null ? getState().TaskReducer.taskList?.length : 0

            let createDate = null
            let dT = 0
            switch (filter.createDate){
                case 0:
                    dT = 8.64e+7
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 1:
                    dT = 8.64e+7 * 2
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 2:
                    dT = 8.64e+7 * 3
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                case 3:
                    dT = 8.64e+7 * 5
                    createDate = new Date(Date.now() - dT + (new Date().getTimezoneOffset()*60000) - (new Date().getTimezoneOffset() * 60000)).toISOString().replace('T',' ').replace('Z','')
                    break
                default: createDate = null
            }
            let deadline = null
            let dlT = 0
            switch (filter.deadline){
                case 0:
                    dlT = 8.64e+7
                    createDate = endDateTime(Date.now()) + dlT
                    break
                case 1:
                    dlT = 8.64e+7 * 2
                    createDate = endDateTime(Date.now()) + dlT
                    break
                case 2:
                    dlT = 8.64e+7 * 3
                    createDate = endDateTime(Date.now()) + dlT
                    break
                case 3:
                    dlT = 8.64e+7 + 4
                    createDate = endDateTime(Date.now()) + dlT
                    break
                default: deadline = null
            }

            const response = await customFetchJson(`${task_server}/api/task/getTaskList?offset=${countList}&search=${search}&createDate=${createDate}&deadline=${deadline}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(TaskActions.setTaskListAction(null))
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(TaskActions.addTaskListAction(response.payload.list))
            dispatch(TaskActions.setTotalTaskListAction(response.payload.total))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    getActiveTaskList: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/getTask/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            if (response.payload === null) {
                dispatch(NavigationActions.setPathAction(`/task-list`))
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    getOwnerProfile: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(TaskActions.setOwnerProfileAction(null))
                // navigate(`/error`)
                return
            }
            dispatch(TaskActions.setOwnerProfileAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    respondOffer: (answer: string, deadline: number, price: number, taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/sendRespond`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId: taskId,
                    answer: answer,
                    deadline: +deadline,
                    price: +price,
                })
            })

            if (response.error) {
                dispatch(TaskActions.setMyRespondAction(null))
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setMyRespondAction(response.payload))
        } catch (error) {
            console.log('error client', error)
            dispatch(TaskActions.setMyRespondAction(null))
            dispatch(NavigationActions.setPathAction(`error sendRespond`))
        } finally {
        }
    },
    getUserOfferList: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/getUserOfferList/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setUserOfferListAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    getMyRespond: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/getMyRespond/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setMyRespondAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    deleteRespond: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/deleteRespond/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setMyRespondAction(null))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    downloadFile: (fileMin: IMinFile) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            //
            const response = await customFetchFile(`${store_server}/api/store/task/download/${fileMin.id}`, {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                // setActiveVerificationFilesAction(null)
                // navigate(`/verify`)
                return
            }

            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.payload);
            a.download = fileMin.fileName;
            a.click();

            // setActiveVerificationFilesAction(response.payload.list)
            // response.payload.total
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    selectOffer: (taskId: number, offerId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            dispatch(IsLoadingActions.setOfferItemBtnAction(true))

            const response = await customFetchJson(`${task_server}/api/task/selectOffer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId: taskId,
                    offerId: offerId
                })
            })

            if (response.error) {
                // setActiveVerificationFilesAction(null)
                // navigate(`/verify`)
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `${response.payload.message}`,
                    type: 'warning',
                }))

                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
            dispatch(IsLoadingActions.setOfferItemBtnAction(false))
        }
    },
    deselectOffer: (taskId: number, offerId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            //
            // dispatch(TaskActions.setActiveTaskAction(null))

            const response = await customFetchJson(`${task_server}/api/task/deselectOffer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId: taskId,
                    offerId: offerId
                })
            })

            if (response.error) {
                // setActiveVerificationFilesAction(null)
                // navigate(`/verify`)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    changePublic: (taskId: number, currentStatus: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            //
            // dispatch(TaskActions.setActiveTaskAction(null))

            const response = await customFetchJson(`${task_server}/api/task/changePublic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId: taskId,
                    status: currentStatus
                })
            })

            if (response.error) {
                // setActiveVerificationFilesAction(null)
                // navigate(`/verify`)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    deleteTask: (taskId: number, callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            //
            // dispatch(TaskActions.setActiveTaskAction(null))

            const response = await customFetchJson(`${task_server}/api/task/deleteTask/${taskId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(TaskActions.setActiveTaskAction(null))
                return
            }

            dispatch(TaskActions.setActiveTaskAction(null))

        } catch (error) {
            console.log('error client', error)
        } finally {
            callback(`/`)
        }
    },

    // взять в работу
    takeWorkTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/takeWorkTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    sendTestTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/sendTestTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    cancelTestTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/cancelTestTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    agreeTestTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/agreeTestTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    rejectTestTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/rejectTestTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    sendArbitrageTask: (taskId: number, data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(TaskActions.setArbitrageTaskAction(undefined))
            const response = await customFetchJson(`${task_server}/api/task/sendArbitrageTask`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    taskId: taskId,
                    comment: data.comment,
                    activeGeneral: data.activeGeneral,
                    general: data.general,
                    percent: +data.percent,
                })
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setActiveTaskAction(response.payload.task))
            dispatch(TaskActions.setArbitrageTaskAction(response.payload.arbitrage))
        } catch (error) {
            dispatch(TaskActions.setArbitrageTaskAction(null))
            console.log('error client', error)
        } finally {
        }
    },

    getArbitrageTask: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${task_server}/api/task/getArbitrageTask/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }

            dispatch(TaskActions.setArbitrageTaskAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    cancelMyArbitrage: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            dispatch(TaskActions.setArbitrageTaskAction(undefined))
            const response = await customFetchJson(`${task_server}/api/task/cancelMyArbitrage/${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }

            dispatch(TaskActions.setArbitrageTaskAction(response.payload))
        } catch (error) {
            dispatch(TaskActions.setArbitrageTaskAction(null))
            console.log('error client', error)
        } finally {
        }
    },

    // my Work
    getWorkTaskList: (status: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(TaskActions.setWorkTaskListAction(null))

            const response = await customFetchJson(`${task_server}/api/work/getWorkTaskList?status=${status}&offset=${0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setWorkTaskListAction(response.payload.list))
            dispatch(TaskActions.setStatisticWorkTaskAction(response.payload.statistic))
            dispatch(TaskActions.setTotalWorkTaskAction(response.payload.total))
        } catch (error) {
            dispatch(TaskActions.setWorkTaskListAction([]))
            console.log('error client', error)
        } finally {
        }
    },
    addWorkTaskList: (status: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            const lengthList = getState().TaskReducer.workTaskList?.length
            if (!lengthList) return

            const response = await customFetchJson(`${task_server}/api/work/getWorkTaskList?status=${status}&offset=${lengthList}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.addWorkTaskListAction(response.payload.list))
            dispatch(TaskActions.setStatisticWorkTaskAction(response.payload.statistic))
            dispatch(TaskActions.setTotalWorkTaskAction(response.payload.total))
        } catch (error) {
            dispatch(TaskActions.setWorkTaskListAction([]))
            console.log('error client', error)
        } finally {
        }
    },
    updateWorkTaskItem: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const filterWorkTask = getState().TaskReducer.filterWorkTask
            const response = await customFetchJson(`${task_server}/api/work/updateWorkTask?status=${filterWorkTask}&taskId=${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.updateWorkTaskAction({updateId: taskId, task: response.payload.task}))
            dispatch(TaskActions.setStatisticWorkTaskAction(response.payload.statistic))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    // my Offer
    getWorkOfferList: (status: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(TaskActions.setWorkOfferListAction(null))

            const response = await customFetchJson(`${task_server}/api/work/getWorkOfferList?status=${status}&offset=${0}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.setWorkOfferListAction(response.payload.list))
            dispatch(TaskActions.setStatisticWorkOfferAction(response.payload.statistic))
            dispatch(TaskActions.setTotalWorkOfferAction(response.payload.total))
        } catch (error) {
            dispatch(TaskActions.setWorkOfferListAction([]))
            console.log('error client', error)
        } finally {
        }
    },
    addWorkOfferList: (status: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const lengthList = getState().TaskReducer.workOfferList?.length
            if (!lengthList) return

            const response = await customFetchJson(`${task_server}/api/work/getWorkOfferList?status=${status}&offset=${lengthList}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.addWorkOfferListAction(response.payload.list))
            dispatch(TaskActions.setStatisticWorkOfferAction(response.payload.statistic))
            dispatch(TaskActions.setTotalWorkOfferAction(response.payload.total))
        } catch (error) {
            dispatch(TaskActions.setWorkOfferListAction([]))
            console.log('error client', error)
        } finally {
        }
    },
    updateWorkOfferItem: (taskId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const filterWorkOffer = getState().TaskReducer.filterWorkOffer
            const response = await customFetchJson(`${task_server}/api/work/updateWorkOffer?status=${filterWorkOffer}&taskId=${taskId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(TaskActions.updateWorkOfferAction({updateId: taskId, task: response.payload.task}))
            dispatch(TaskActions.setStatisticWorkOfferAction(response.payload.statistic))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
}
