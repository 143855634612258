import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {TextField} from "../../../../TextField";

export const DoMake = ({newTask, setNewTask}:any) => {

    const changeDoMakeHandler = (valueHtml: string) => {
        setNewTask({...newTask, doMake: valueHtml})
    }

    return (
        <div className={css(s.DoMake)}>
            <div className={css(s.title)}>Что нужно сделать</div>
            <TextField contentEditable={true} maxLength={5000} text={newTask.doMake} setText={changeDoMakeHandler}/>
        </div>
    )
}
