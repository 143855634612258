import React, {useEffect, useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {MessengerAPI} from "../../../../../API/MessengerAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {MessengerActions, MessengerState} from "../../../../../store/reducers/messenger";
import {UserState} from "../../../../../store/reducers/auth";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {StandardButton} from "../../../Button/StandardButton";
import {HintActions} from "../../../../../store/reducers/hint";
import {SvgMyProfile} from "../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {ProfileState} from "../../../../../store/reducers/profile";
import {customFetch, customFetchJson} from "../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../helpers/config";
import {IResponse} from "../../../../../types/api";
import {TaskState} from "../../../../../store/reducers/task";
import loadingGit from "../../../../assets/images/LoadingGif.gif";

export const ActiveChat = ({activeTask}:any) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { getMessageList, addMessageList,readChat} = useThunks(MessengerAPI)
    const { connect, activeMessageList, activeChat, totalMessageList} = useAppState(MessengerState)
    // const { activeTask } = useAppState(TaskState)
    const { user } = useAppState(UserState)
    const { profile } = useAppState(ProfileState)

    const {setActiveChatAction, setActiveMessageListAction} = useActions(MessengerActions)
    const params = useParams();

    const [addLoading, setAddLoading] = useState(false)

    useEffect(()=>{
        if (connect && !!activeChat) {
            getMessageList(+activeChat.id)
        }
    },[connect, activeChat])

    useEffect(()=>{
        return ()=>{
            setActiveChatAction(null)
            setActiveMessageListAction(null)
        }
    },[])

    const listRef = useRef<any>(null)

    useEffect(()=>{
        if (addLoading) {
            setAddLoading(false)
            if (activeScroll.current){
                listRef.current.scrollTop = listRef.current.scrollHeight - activeScroll.current
            }
            return;
        }
        if (!listRef.current) return
        listRef.current.scrollTop = Math.ceil(listRef.current.scrollHeight - listRef.current.clientHeight)
        if (params.chatId && +params.chatId !== -1) {
            readChat(+params.chatId)
        }
    },[activeMessageList])

    const [respondProfile, setRespondProfile] = useState<any>({
        loading: true,
        user: null,
        src: null
    })
    useEffect(() => {
        if(!activeChat) return
        const controller = new AbortController();
        const signal = controller.signal;

        (async () => {
            try {
                const companionId = activeChat.companionIdList.filter((id:any) =>id !== activeTask?.id).filter((id:any) =>id !== user.id)[0]
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${companionId}`, {
                    signal: signal,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                    return
                }

                let image: any = null
                if (!!response.payload.avatarLink.length) {
                    const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                        signal: signal,
                        method: 'GET',
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })

                    const blobData = await src.blob()
                    const urlCreator = window.URL || window.webkitURL;
                    image = urlCreator.createObjectURL(blobData);
                }

                setRespondProfile((prev: any) => ({
                    ...prev,
                    user: {
                        id: response.payload.id,
                        name: response.payload.name,
                        place: response.payload.place,
                    },
                    src: image,
                    loading: false
                }))
            } catch (error) {
                console.log('error client', error)
            } finally {
            }
        })()
        return () => {
            controller.abort()
            setRespondProfile({
                loading: true,
                user: null,
                src: null
            })
        }
    }, [activeChat])

    // добавление элементов при скроле
    const activeScroll = useRef<any>(null)
    const debounce = useRef<any>(null)
    function onScrollListHandler(e: any) {
        if (addLoading) return
        clearTimeout(debounce.current)
        debounce.current = setTimeout(()=>{
            // console.log(e.target.scrollTop, e.target.scrollHeight)
            // console.log(e.target.offsetHeight)
            // console.log(e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop)
            const scrollBottom = e.target.scrollTop < 50;
            if (activeChat && scrollBottom && !!activeMessageList?.length && totalMessageList && totalMessageList > activeMessageList.length) {
                setAddLoading(true)
                addMessageList(+activeChat.id)
                activeScroll.current = e.target.scrollHeight
            }
        },500)
    }

    return (
        <div className={css(s.ActiveChat)}>
            <div className={css(s.backChatList, location.pathname.includes(`/chat-list`) && s.active)} onClick={()=>navigate(`/chat-list`)}><span>Мои чаты</span></div>
            <div ref={listRef} className={css(s.messageList)} onScroll={onScrollListHandler}>
                {!!activeMessageList?.length && totalMessageList && totalMessageList > activeMessageList.length && <div className={css(s.addMessageListBtn)}>
                    <img src={loadingGit} alt="loading"/>
                </div>}
                {activeMessageList?.map(message =>{
                    switch (message.type) {
                        case 'user': return <Message key={message.id}
                                                     companionProfile={respondProfile}
                                                     profile={profile}
                                                     type={user.id === message.ownerId ? 'owner':'companion'}
                                                     message={message}
                        />
                        case 'task': return <TaskMessage key={message.id} message={message}/>
                        case 'arbitrage': return <ArbitrageMessage message={message} />
                        default: return null
                    }
                })}
            </div>
            <SendMessageBox activeTask={activeTask}/>
        </div>
    )
}
export const SendMessageBox = ({activeTask}:any) => {
    const {setHintApp} = useActions(HintActions)
    const { getMessageList,sendMessage,readChat} = useThunks(MessengerAPI)
    const { connect, activeMessageList, activeChat } = useAppState(MessengerState)
    const { user } = useAppState(UserState)
    // const { activeTask } = useAppState(TaskState)
    const location = useLocation()

    // files
    const [messageFileList, setMessageFileList] = useState<Array<any>>([])

    const addFileHandler = (e: any) => {
        if (!e.target.files[0]) return;
        if (e.target.files[0].size > 20000000) {
            setHintApp({
                type: 'warning',
                text: `Слишком большой файл`,
                isShow: true
            })
            return
        }
        if (messageFileList.length > 2) {
            setHintApp({
                type: 'warning',
                text: `Максимальное количество файлов в сообщении 3`,
                isShow: true
            })
            return
        }
        setMessageFileList((prev: Array<any>) => [...prev, {id: Date.now(), data: e.target.files[0]}])
        e.target.files = null
    }
    const deleteFileHandler = (id: number) => {
        setMessageFileList((prev: Array<any>) => prev.filter(file => file.id !== id))
    }

    // field
    const [text, setText] = useState('')
    const [contentEditable, setContentEditable] = useState(false)
    const changeText = (e: any) => {
        e.preventDefault()
        // управление отображением
        if (Number.parseInt(e.target.style.height) < 86) {
            e.target.style.overflowY = 'hidden'
        }
        e.target.style.height = 'auto';
        e.target.style.height = (e.target.scrollHeight < 52 ? 31: e.target.scrollHeight) + 'px';//////console.log(this.scrollHeight);
        if (Number.parseInt(e.target.style.height) > 86) {
            e.target.style.overflowY = 'scroll'
        } else {
            e.target.style.overflowY = 'hidden'
        }
        // управление обновлением текста
        if (e.target.value.length > 1000) return
        setText(e.target.value)
    }
    const keyDownHandler = (e:any) => {
        if (e.ctrlKey && e.keyCode == 13) {
            let val = e.target.value,
                start = e.target.selectionStart,
                end = e.target.selectionEnd,
                curs = val.substring(0, start) + '\n';
            e.target.value = val.substring(0, start) + '\n' + val.substring(end, val.length);
            e.target.focus();
            e.target.setSelectionRange(curs.length, curs.length);
            changeText(e)
        } else if (!e.ctrlKey && e.keyCode == 13) {
            sendMessageHandler()
            e.target.value = ``
            changeText(e)
            e.preventDefault()
        }
    }

    const sendMessageHandler = () => {
        if (!activeChat) return
        if (!text.trim().length && !messageFileList.length) return

        sendMessage(text,messageFileList,activeChat)
        setText(``)
        setMessageFileList([])
    }

    if (location.pathname.includes(`/task-list`) && activeTask && activeTask.status === 5) return null
    if (user.role === 'manager') return null
    return (
        <div className={css(s.sendMessageBox)}>
            <div className={css(s.fileList)}>
                {messageFileList.map((file) =>
                    <div key={file.data.name + file.id}
                         className={css(s.minFileItem)}
                         onClick={() => deleteFileHandler(file.id)}>
                        <span>{file.data.name}</span>
                        <div className={css(s.deleteFile)}>
                            &#x2715;
                        </div>
                    </div>)}
            </div>
            <div className={css(s.newMessage, (contentEditable || !!text.length) && s.contentEditable)}>
                <div className={css(s.addFileBtn)}>
                    <label>
                        <input type="file" onChange={addFileHandler} accept={`.png,.jpg,.jpeg,.zip`}/>
                        <svg width="20" height="18" viewBox="0 0 16 15" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.9452 6.39879L8.44107 12.9045C6.83805 14.508 4.92052 14.2986 4.82123 14.3169C3.53353 14.3169 2.32292 13.8155 1.41235 12.905C0.50181 11.9944 0.000392452 10.7838 0.000392452 9.49609C0.0153587 9.41729 -0.17436 7.46155 1.41963 5.87437L5.11065 2.19895C5.3552 1.95545 5.75082 1.9562 5.99438 2.20082C6.2379 2.44537 6.23709 2.84103 5.9925 3.08455L2.30152 6.75998C1.09728 7.95909 1.26444 9.41917 1.25022 9.49609C1.25022 10.4499 1.62169 11.3467 2.29617 12.0212C2.97062 12.6957 3.86738 13.0671 4.82126 13.0671C4.91109 13.0509 6.35026 13.2282 7.55725 12.0209L14.0613 5.51515C14.977 4.59921 14.9783 3.12117 14.0724 2.20282L13.9833 2.11255C13.048 1.16449 11.5444 1.19864 10.6419 2.10124L3.9708 8.77234C3.61143 9.13171 3.62955 9.7035 3.97193 10.0447L4.27585 10.3476C4.63104 10.7016 5.19923 10.6919 5.54452 10.3452L9.99691 5.87384C10.2404 5.62926 10.6361 5.62844 10.8806 5.87197C11.1252 6.11549 11.126 6.51114 10.8825 6.7557L6.43012 11.227C5.60338 12.0573 4.24348 12.0797 3.39362 11.2328L3.08967 10.9299C2.26418 10.1072 2.23325 8.74247 3.08707 7.88857L9.75817 1.21748C11.1751 -0.199348 13.4742 -0.183101 14.873 1.23485L14.9621 1.32512C16.3498 2.7318 16.3478 4.99571 14.9452 6.39879Z"
                                fill="#00AB55"/>
                        </svg>
                    </label>
                </div>
                <div className={css(s.textAreaBox)} onClick={() => setContentEditable(true)}
                     onBlur={() => setContentEditable(false)}>
                                <textarea autoFocus={contentEditable} rows={1}
                                          value={text}
                                          onChange={changeText}
                                          placeholder="Сообщение"
                                          onKeyDown={keyDownHandler}

                                />
                </div>
            </div>
            <div className={css(s.sendBtn)} id={`sendNewMessageBtn`}>
                <StandardButton onClick={sendMessageHandler} text={`Отправить`}
                                style={{maxWidth: 250, width: `100%`}}/>
            </div>
        </div>
    )
}
export const Message = ({message, type, profile ,companionProfile={}}:any) => {

    const {downloadMessageFile} = useThunks(MessengerAPI)
    const downloadFile = (fileName:string) => {
        downloadMessageFile(message.chatId,fileName)
    }
    switch (type) {
        case 'owner': return (
            <div className={css(s.ownerMessage)}>
                <div className={css(s.avatar)}>
                    {profile.image !== null &&
                        <img src={profile.image} alt="png"/>}
                    {profile.image === null && <SvgMyProfile/>}
                </div>
                <div className={css(s.main)}>
                    <div className={css(s.text)}>
                        {message.text}
                    </div>
                    {!!message.link.length && <div className={css(s.fileList)}>
                        {JSON.parse(message.link).map((fileName:string, index:number)=>
                            <div key={index} className={css(s.fileItem)} onClick={()=>downloadFile(fileName)}>
                                <svg width="20" height="18" viewBox="0 0 16 15" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.9452 6.39879L8.44107 12.9045C6.83805 14.508 4.92052 14.2986 4.82123 14.3169C3.53353 14.3169 2.32292 13.8155 1.41235 12.905C0.50181 11.9944 0.000392452 10.7838 0.000392452 9.49609C0.0153587 9.41729 -0.17436 7.46155 1.41963 5.87437L5.11065 2.19895C5.3552 1.95545 5.75082 1.9562 5.99438 2.20082C6.2379 2.44537 6.23709 2.84103 5.9925 3.08455L2.30152 6.75998C1.09728 7.95909 1.26444 9.41917 1.25022 9.49609C1.25022 10.4499 1.62169 11.3467 2.29617 12.0212C2.97062 12.6957 3.86738 13.0671 4.82126 13.0671C4.91109 13.0509 6.35026 13.2282 7.55725 12.0209L14.0613 5.51515C14.977 4.59921 14.9783 3.12117 14.0724 2.20282L13.9833 2.11255C13.048 1.16449 11.5444 1.19864 10.6419 2.10124L3.9708 8.77234C3.61143 9.13171 3.62955 9.7035 3.97193 10.0447L4.27585 10.3476C4.63104 10.7016 5.19923 10.6919 5.54452 10.3452L9.99691 5.87384C10.2404 5.62926 10.6361 5.62844 10.8806 5.87197C11.1252 6.11549 11.126 6.51114 10.8825 6.7557L6.43012 11.227C5.60338 12.0573 4.24348 12.0797 3.39362 11.2328L3.08967 10.9299C2.26418 10.1072 2.23325 8.74247 3.08707 7.88857L9.75817 1.21748C11.1751 -0.199348 13.4742 -0.183101 14.873 1.23485L14.9621 1.32512C16.3498 2.7318 16.3478 4.99571 14.9452 6.39879Z"
                                        fill="#00AB55"/>
                                </svg>
                                <span>{fileName}</span>
                            </div>
                        )}
                    </div>}
                </div>
                <div className={css(s.createDate)}>{new Date(message.createdAt).toLocaleTimeString()}</div>
            </div>
        )
        case 'companion': return (
            <div className={css(s.companionMessage)}>
                <div className={css(s.avatar)}>
                    {companionProfile.src !== null && !companionProfile.loading &&
                        <img src={companionProfile.src} alt="png"/>}
                    {companionProfile.src === null && !companionProfile.loading && <SvgMyProfile/>}
                    {companionProfile.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                </div>
                <div className={css(s.main)}>
                    <div className={css(s.text)}>
                        {message.text}
                    </div>
                    {!!message.link.length && <div className={css(s.fileList)}>
                        {JSON.parse(message.link).map((fileName:string, index:number)=>
                            <div key={index} className={css(s.fileItem)} onClick={()=>downloadFile(fileName)}>
                                <svg width="20" height="18" viewBox="0 0 16 15" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.9452 6.39879L8.44107 12.9045C6.83805 14.508 4.92052 14.2986 4.82123 14.3169C3.53353 14.3169 2.32292 13.8155 1.41235 12.905C0.50181 11.9944 0.000392452 10.7838 0.000392452 9.49609C0.0153587 9.41729 -0.17436 7.46155 1.41963 5.87437L5.11065 2.19895C5.3552 1.95545 5.75082 1.9562 5.99438 2.20082C6.2379 2.44537 6.23709 2.84103 5.9925 3.08455L2.30152 6.75998C1.09728 7.95909 1.26444 9.41917 1.25022 9.49609C1.25022 10.4499 1.62169 11.3467 2.29617 12.0212C2.97062 12.6957 3.86738 13.0671 4.82126 13.0671C4.91109 13.0509 6.35026 13.2282 7.55725 12.0209L14.0613 5.51515C14.977 4.59921 14.9783 3.12117 14.0724 2.20282L13.9833 2.11255C13.048 1.16449 11.5444 1.19864 10.6419 2.10124L3.9708 8.77234C3.61143 9.13171 3.62955 9.7035 3.97193 10.0447L4.27585 10.3476C4.63104 10.7016 5.19923 10.6919 5.54452 10.3452L9.99691 5.87384C10.2404 5.62926 10.6361 5.62844 10.8806 5.87197C11.1252 6.11549 11.126 6.51114 10.8825 6.7557L6.43012 11.227C5.60338 12.0573 4.24348 12.0797 3.39362 11.2328L3.08967 10.9299C2.26418 10.1072 2.23325 8.74247 3.08707 7.88857L9.75817 1.21748C11.1751 -0.199348 13.4742 -0.183101 14.873 1.23485L14.9621 1.32512C16.3498 2.7318 16.3478 4.99571 14.9452 6.39879Z"
                                        fill="#00AB55"/>
                                </svg>
                                <span>{fileName}</span>
                            </div>
                        )}
                    </div>}
                </div>
                <div className={css(s.createDate)}>{new Date(message.createdAt).toLocaleTimeString()}</div>
            </div>
        )
        default: return null
    }
    // return (
    //     <div className={css(s.Message, myMessage && s.myMessage)}>
    //         {!myMessage && <div>{companionProfile?.user?.name}</div>}
    //         <div>text: {message.text}</div>
    //         <div>ownerId: {message.ownerId}</div>
    //     </div>
    // )
}
export const TaskMessage = ({message}:any) => {

    return (
        <div className={css(s.TaskMessage)}>
            <div>{message.text}</div>
            <div className={css(s.taskMessageDate)}>Дата: {`${new Date(Date.parse(message.createdAt)).toLocaleDateString()}, ${new Date(Date.parse(message.createdAt)).toLocaleTimeString().slice(0,5)}`}</div>
        </div>
    )
}
export const ArbitrageMessage = ({message}:any) => {

    return (
        <div className={css(s.ArbitrageMessage)}>
            <div>{message.text}</div>
            <div className={css(s.arbitrageMessageDate)}>Дата: {`${new Date(Date.parse(message.createdAt)).toLocaleDateString()}, ${new Date(Date.parse(message.createdAt)).toLocaleTimeString().slice(0,5)}`}</div>
        </div>
    )
}

