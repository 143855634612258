import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../../helpers/css";
import {useAppState} from "../../../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../../../store/reducers/task";
import loadingGif from "../../../../../../assets/images/LoadingGif.gif";
import {useParams} from "react-router-dom";
import {useActions, useThunks} from "../../../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../../../API/TaskAPI";
import {OfferItem} from "./OfferItem";

export const OfferList = ({activeTask}:any) => {
    const params = useParams()

    const {offerList} = useAppState(TaskState)

    const {getUserOfferList} = useThunks(TaskAPI)

    const {setUserOfferListAction} = useActions(TaskActions)

    useEffect(()=>{
        if (offerList === null)
            getUserOfferList(Number(params.id))
    },[])

    useEffect(()=>{
        if (offerList !== null){
            getUserOfferList(Number(params.id))
        }
    },[activeTask.status])

    useEffect(()=>{
        return () => {
            setUserOfferListAction(null)
        }
    },[])

    return (
        <div className={css(s.OfferList)}>
            <div className={css(css(s.offerTitle))}>
                <span>Отклики</span>
                {offerList === null &&<img src={loadingGif} alt="loading"/>}
            </div>
            {/*{offerList === null && <div className={css(s.loadingList)}></div>}*/}
            {offerList !== null && offerList.length === 0 &&
                <div className={css(s.emptyList)}>
                    У данной задачи в данный момент нет откликов
                </div>}
            {offerList !== null && offerList.length > 0 &&
                <div>
                    {offerList.map((offer:any) => <OfferItem key={offer.id} offer={offer} taskId={activeTask.id} minPrice={activeTask.data.minPrice} infinityDeadline={activeTask.data.infinityDeadline}/>)}
                </div>}
        </div>
    )
}
