import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

export const TextInputField = ({text, setText, contentEditable, maxLength}:any) => {

    const setTextHandler = (e:any) => {
        if (e.target.value.length > maxLength) return
        setText(e.target.value)
    }

    return (
        <div className={css(s.TextInputField)}>
            <div className={css(s.main)}>
                {!contentEditable
                    ? <div className={css(s.text)}>{text}</div>
                    : <input type="text" value={text} onChange={setTextHandler}/>
                }
            </div>
            {contentEditable && <div className={css(s.maxLength)}>{text.length}/{maxLength}</div>}
        </div>
    )
}
