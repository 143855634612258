import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AdminAPI} from "../../../../API/useAdmin";
import loadingGif from "../../../assets/images/LoadingGif.gif";
import {useThunks} from "../../../../helpers/useActions";

export const AdminUser = ({user}:any) => {

    const {setManagerRole,deleteUser} = useThunks(AdminAPI)
    const [isFetch,setIsFetch] = useState({
        changeRole: false,
        delete: false
    })

    const changeToManagerRoleHandler = () => {
        if (isFetch.changeRole || isFetch.delete) return;
        if (!window.confirm(`Вы уверены что хотите назначить пользователя ${user.email} - Менеджером`)) return
        setIsFetch(prev => ({...prev, changeRole: true}))
        setManagerRole(user.id)
    }
    const deleteUserHandler = () => {
        if (isFetch.changeRole || isFetch.delete) return;
        if (!window.confirm(`Вы уверены что хотите удалить пользователя ${user.email}`)) return
        setIsFetch(prev => ({...prev, delete: true}))
        deleteUser(user.id)
    }

    return (
        <div className={css(s.User)}>
            <div className={css(s.main)}>
                <div className={css(s.email)}>
                    {user.email}
                </div>
                <div className={css(s.name)}>
                    {user.name}
                </div>
                <div className={css(s.role)}>
                    {user.role === 'regular' && 'пользователь'}
                    {user.role === 'verifyRegular' && 'участник'}
                    {user.role === 'manager' && 'менеджер'}
                </div>
            </div>
            <div className={css(s.control)}>
                {user.role === 'manager'
                    ? <div className={css(s.managerBtn, s.disable)}>является менеджером</div>
                    : <div className={css(s.managerBtn)} onClick={changeToManagerRoleHandler}>
                        {isFetch.changeRole ? <img src={loadingGif} alt="loading"/> : "назначить менеджером" }
                    </div>
                }
                <div className={css(s.deleteBtn)} onClick={deleteUserHandler}>
                    {isFetch.delete ? <img src={loadingGif} alt="loading"/> : "удалить" }
                </div>
            </div>
        </div>
    )
}
