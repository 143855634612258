import React from 'react';

export const SvgProfile = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.57141 12C9.41918 12 10.2479 11.7486 10.9528 11.2776C11.6577 10.8066 12.2071 10.1371 12.5315 9.35385C12.8558 8.57059 12.9407 7.70873 12.7752 6.87726C12.6098 6.04579 12.2015 5.28205 11.602 4.68264C11.0025 4.08323 10.2387 3.67506 9.40715 3.50976C8.57565 3.34446 7.71381 3.42945 6.93061 3.75398C6.14741 4.07851 5.47804 4.62801 5.00715 5.33297C4.53627 6.03794 4.28501 6.86672 4.28516 7.71449C4.28635 8.85084 4.73834 9.9403 5.54193 10.7437C6.34552 11.5472 7.43506 11.999 8.57141 12Z" fill="#212B36"/>
            <path d="M14.1788 15.5408C13.0926 14.4267 11.699 13.6617 10.176 13.3435C8.65303 13.0252 7.06973 13.1682 5.62839 13.7542C4.18705 14.3402 2.95309 15.3425 2.08414 16.6331C1.21519 17.9237 0.750703 19.4441 0.75 21C0.75 21.1989 0.829018 21.3897 0.96967 21.5303C1.11032 21.671 1.30109 21.75 1.5 21.75H15.645C15.8439 21.75 16.0347 21.671 16.1753 21.5303C16.316 21.3897 16.395 21.1989 16.395 21C16.3979 20.2047 16.2751 19.414 16.0312 18.657C15.6681 17.4867 15.0333 16.4189 14.1788 15.5408Z" fill="#212B36"/>
            <path d="M17.625 12.375C19.3854 12.375 20.8125 10.9479 20.8125 9.1875C20.8125 7.42709 19.3854 6 17.625 6C15.8646 6 14.4375 7.42709 14.4375 9.1875C14.4375 10.9479 15.8646 12.375 17.625 12.375Z" fill="#212B36"/>
            <path d="M17.624 13.2525C16.6187 13.2563 15.6328 13.5301 14.7695 14.0453C14.9323 14.1908 15.0965 14.334 15.2503 14.4915C16.2715 15.5412 17.0301 16.8174 17.4643 18.216C17.613 18.6765 17.7226 19.1486 17.792 19.6275H22.499C22.6979 19.6275 22.8887 19.5485 23.0294 19.4078C23.17 19.2672 23.249 19.0764 23.249 18.8775C23.2474 17.3861 22.6543 15.9563 21.5998 14.9018C20.5452 13.8472 19.1154 13.2541 17.624 13.2525Z" fill="#212B36"/>
        </svg>
    );
};
