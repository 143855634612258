import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Link} from "react-router-dom";
import {AuthAPI} from "../../../../API/AuthAPI";
import {SvgSearch} from "../../../assets/images/svg/SvgSearch";
import {SvgDoll} from "../../../assets/images/svg/SvgDoll";
import {SvgProfile} from "../../../assets/images/svg/SvgProfile";
import {useAppState} from "../../../../store/reduxStore";
import {UserState} from "../../../../store/reducers/auth";
import {useClickOutside} from "../../../../helpers/useClickOutside";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {showPopupActions} from "../../../../store/reducers/showPopup";
import {ProfileState} from "../../../../store/reducers/profile";
import {NoticeState} from "../../../../store/reducers/notice";
import {NoticeAPI} from "../../../../API/NoticeAPI";
import {NoticeItem} from "../NoticeItem";
import {SvgMyProfile} from "../../../assets/images/svg/SvgMyProfile";

export const RegularHeader = () => {

    const {user} = useAppState(UserState)
    const {profile} = useAppState(ProfileState)
    const {noticeList, messagesCount} = useAppState(NoticeState)
    const {setIsShowPopupAction} = useActions(showPopupActions)
    const {readAllNotice,readNotice} = useThunks(NoticeAPI)
    const {logout} = useThunks(AuthAPI)

    const [isShowMobileMenu,setIsShowMobileMenu] = useState(false)
    useClickOutside(()=>setIsShowMobileMenu(false), [`headerBurgerBtn`, `headerMobileMain`])

    const [isShowProfilePopup,setIsShowProfilePopup] = useState(false)
    useClickOutside(()=>setIsShowProfilePopup(false), [`headerProfileBtn`])

    const [isShowNoticePopup,setIsShowNoticePopup] = useState(false)
    useClickOutside(()=>setIsShowNoticePopup(false), [`headerNoticeBtn`])

    const logoutHandler = () => {
        logout()
    }
    const showPopupChangePasswordHandler = () => {
        setIsShowProfilePopup(false)
        setIsShowPopupAction({type: `changePassword`, isShow: true})
    }


    return (
        <div className={css(s.RegularHeader)}>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    <div id={`headerBurgerBtn`} className={css(s.burgerBtn)} onClick={()=>setIsShowMobileMenu(prev => !prev)}>
                        <svg className={css(s.showBtn, !isShowMobileMenu && s.active)} viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9748 3.5H1.70893C0.848169 3.5 0.189941 2.85 0.189941 2C0.189941 1.15 0.848169 0.5 1.70893 0.5H22.9748C23.8355 0.5 24.4937 1.15 24.4937 2C24.4937 2.85 23.8355 3.5 22.9748 3.5Z" fill="#212B36"/>
                            <path d="M22.9748 10.5H1.70893C0.848169 10.5 0.189941 9.85 0.189941 9C0.189941 8.15 0.848169 7.5 1.70893 7.5H22.9748C23.8355 7.5 24.4937 8.15 24.4937 9C24.4937 9.85 23.8355 10.5 22.9748 10.5Z" fill="#212B36"/>
                            <path d="M22.9748 17.5H1.70893C0.848169 17.5 0.189941 16.85 0.189941 16C0.189941 15.15 0.848169 14.5 1.70893 14.5H22.9748C23.8355 14.5 24.4937 15.15 24.4937 16C24.4937 16.85 23.8355 17.5 22.9748 17.5Z" fill="#212B36"/>
                        </svg>
                        <svg className={css(s.hideBtn, isShowMobileMenu && s.active)} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.578 14.9057C13.9458 14.9271 14.3074 14.8048 14.5868 14.5647C15.1377 14.0105 15.1377 13.1155 14.5868 12.5613L2.52411 0.498628C1.95114 -0.0375224 1.05205 -0.00771814 0.515901 0.565255C0.0310774 1.08339 0.00281715 1.87978 0.449741 2.43095L12.5835 14.5647C12.8592 14.8013 13.2151 14.9234 13.578 14.9057Z" fill="#212B36"/>
                            <path d="M1.52868 14.9057C1.9014 14.9041 2.25863 14.7562 2.52327 14.4937L14.586 2.43093C15.0963 1.83494 15.027 0.938004 14.431 0.427588C13.899 -0.0279459 13.1145 -0.0279459 12.5826 0.427588L0.448895 12.4903C-0.123939 13.0266 -0.153556 13.9257 0.382735 14.4986C0.404071 14.5213 0.426108 14.5434 0.448895 14.5647C0.746002 14.8231 1.13701 14.9466 1.52868 14.9057Z" fill="#212B36"/>
                        </svg>
                    </div>
                    <Link to={'/'} className={css(s.logo)}>
                        <div className={css(s.title)}>zemsite</div>
                    </Link>

                    <div className={css(s.navigation)}>
                        <Link to={'/about'}>О компании</Link>
                        <Link to={'/contact'}>Контакты</Link>
                        <Link to={'/executor-list'}>Каталог исполнителей</Link>
                        <Link to={'/faq'}>FAQ</Link>
                    </div>
                    <div className={css(s.messageNav)}>
                        {messagesCount > 0
                            ?<Link to={'/chat-list'} className={css(s.newMessage)}>
                                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.14418 0.861816H13.8714C14.2229 0.861816 14.5078 1.14674 14.5078 1.49818V10.8315C14.5078 11.183 14.2229 11.4679 13.8714 11.4679H1.14418C0.792735 11.4679 0.507812 11.183 0.507812 10.8315V1.49818C0.507812 1.14674 0.792735 0.861816 1.14418 0.861816ZM13.2351 2.89177L9.53976 6.09446L13.2351 9.40657V2.89177ZM7.50781 6.17121L12.1655 2.13454H2.85014L7.50781 6.17121ZM8.57024 6.93463L7.92459 7.49421C7.805 7.59785 7.65642 7.64969 7.50781 7.64969C7.3592 7.64969 7.21063 7.59785 7.09104 7.49421L6.44534 6.93463L2.80759 10.1951H12.208L8.57024 6.93463ZM5.4759 6.09446L1.78054 2.89182V9.40661L5.4759 6.09446Z" fill="white" stroke="#FF8D00" stroke-width="0.5"/>
                                </svg>
                                {messagesCount > 0 && <span className={css(s.count)}>{messagesCount >= 100 ? `99+`: messagesCount}</span>}
                                <span>{messagesCount === 1 ? `новое сообщение`:`новых сообщений`}</span>
                            </Link>
                            :<Link to={'/chat-list'}>Сообщения</Link>
                        }
                    </div>
                    {/*<div className={css(s.searchBtn)}>*/}
                    {/*    <SvgSearch />*/}
                    {/*</div>*/}
                    <div id={`headerNoticeBtn`} className={css(s.hintBtn)}>
                        <div className={css(s.btn,!noticeList.length && s.empty)} onClick={e=>setIsShowNoticePopup(prev=>!prev)}>
                            <SvgDoll />
                            {!!noticeList.length && <span>{noticeList.length >= 100 ? `99+`: noticeList.length}</span>}
                        </div>
                        <div className={css(s.popup, isShowNoticePopup && s.show)}>
                            {!!noticeList.length && <div className={css(s.closeAllNoticeBtn)} onClick={readAllNotice}>
                                <div className={s.btn}>
                                    <SvgDoll />
                                </div>
                                <span>Отметить все как <br/> прочитанные</span>
                            </div>}
                            <div className={css(s.noticeList)}>
                                {!noticeList.length && <div className={css(s.emptyNotice)}>Нет уведомлений</div>}
                                {!!noticeList.length && noticeList.map(notice => <NoticeItem key={notice.id} closeNoticeBox={()=>setIsShowNoticePopup(false)} notice={notice} readNotice={readNotice}/>)}
                            </div>
                        </div>
                    </div>
                    <div id={`headerProfileBtn`} className={css(s.profileBtn)}>
                        <div className={css(s.btn)} onClick={e=>setIsShowProfilePopup(prev=>!prev)}><SvgProfile /></div>
                        <div className={css(s.popup, isShowProfilePopup && s.show)}>
                            <div className={css(s.profileInfo)}>
                                <div className={css(s.avatar)}>
                                    {profile?.image !== null &&
                                        <img src={profile?.image} alt="png"/>}
                                    {(!profile || profile.image === null) && <SvgMyProfile/>}
                                </div>
                                <div className={css(s.profile)}>
                                    <div className={css(s.name)}>
                                        {profile?.name}
                                    </div>
                                    <div className={css(s.email)}>
                                        {user.email}
                                    </div>
                                    <Link className={css(s.messengerBtn)} to={`/chat-list`} onClick={e=>setIsShowProfilePopup(false)}>Сообщения</Link>
                                    <div className={css(s.showPopupChangePassword)} onClick={showPopupChangePasswordHandler}>
                                        Сменить пароль
                                    </div>
                                    <Link to={`/setting`} onClick={e=>setIsShowProfilePopup(false)}>Настройки профиля</Link>
                                    <div className={css(s.logout)} onClick={logoutHandler}>
                                        Выход
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div id={`headerMobileMain`} onClick={e=>setIsShowMobileMenu(false)} className={css(s.mobileMain, isShowMobileMenu && s.show)} >
                <div className={css(s.navigation)}>
                    <Link to={'/about'} onClick={e=>setIsShowProfilePopup(false)}>О компании</Link>
                    <Link to={'/executor-list'} onClick={e=>setIsShowProfilePopup(false)}>Каталог исполнителей</Link>
                    <Link to={'/contact'} onClick={e=>setIsShowProfilePopup(false)}>Контакты</Link>
                    <Link to={'/faq'} onClick={e=>setIsShowProfilePopup(false)}>FAQ</Link>
                </div>
            </div>
        </div>
    )
}

