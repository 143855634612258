import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useActions} from "../../../../../helpers/useActions";
import {showPopupActions} from "../../../../../store/reducers/showPopup";

export const SettingMainSecurity = () => {
    const {setIsShowPopupAction} = useActions(showPopupActions)
    const showPopupChangePasswordHandler = () => {
        setIsShowPopupAction({type: `changePassword`, isShow: true})
    }

    return (
        <div className={css(s.SettingMainSecurity)}>
            <div className={css(s.securityTitle)}>
                Безопасность
            </div>
            <div className={css(s.securityMain)}>
                <div className={css(s.securityPassword)}>
                    <div className={css(s.securityImg)}>
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8935 5.29541H10.4661V3.96606C10.4661 1.77917 8.68689 0 6.5 0C4.31311 0 2.53394 1.77917 2.53394 3.96606V5.29541H2.10652C1.36689 5.29541 0.765137 5.89717 0.765137 6.63683V13.6586C0.765137 14.3982 1.36689 15 2.10652 15H10.8935C11.6331 15 12.2349 14.3982 12.2349 13.6586V6.63683C12.2349 5.89717 11.6331 5.29541 10.8935 5.29541ZM3.41284 3.96606C3.41284 2.2638 4.79773 0.878906 6.5 0.878906C8.20227 0.878906 9.58716 2.2638 9.58716 3.96606V5.29541H8.70825V3.75979C8.70825 2.65588 7.81018 1.75781 6.70628 1.75781H6.29372C5.18981 1.75781 4.29175 2.65588 4.29175 3.75979V5.29541H3.41284V3.96606ZM5.17065 5.29541V3.75979C5.17065 3.14054 5.67444 2.63672 6.29372 2.63672H6.70628C7.32556 2.63672 7.82935 3.14054 7.82935 3.75979V5.29541H5.17065ZM11.356 13.6586C11.356 13.9136 11.1485 14.1211 10.8935 14.1211H2.10652C1.85152 14.1211 1.64404 13.9136 1.64404 13.6586V6.63683C1.64404 6.3818 1.85152 6.17432 2.10652 6.17432H10.8935C11.1485 6.17432 11.356 6.3818 11.356 6.63683V13.6586Z" fill="#212B36"/>
                            <path d="M6.49976 8.01953C5.77079 8.01953 5.17773 8.61259 5.17773 9.34155C5.17773 9.91647 5.54676 10.4066 6.0603 10.5882V11.9893C6.0603 12.232 6.25706 12.4287 6.49976 12.4287C6.74245 12.4287 6.93921 12.232 6.93921 11.9893V10.5882C7.45275 10.4066 7.82178 9.9165 7.82178 9.34155C7.82178 8.61259 7.22872 8.01953 6.49976 8.01953ZM6.49976 9.78467C6.25542 9.78467 6.05664 9.58589 6.05664 9.34155C6.05664 9.09722 6.25542 8.89844 6.49976 8.89844C6.74409 8.89844 6.94287 9.09722 6.94287 9.34155C6.94287 9.58589 6.74409 9.78467 6.49976 9.78467Z" fill="#212B36"/>
                        </svg>
                    </div>
                    <div className={css(s.securityPasswordValue)}>
                        <div className={css(s.securityPasswordTitle)}>Пароль*:</div>
                        <div className={css(s.hiddenPassword)}>***********</div>
                    </div>
                </div>
                <div className={css(s.changePasswordBtn)} onClick={showPopupChangePasswordHandler}>
                    Изменить пароль
                </div>
            </div>
        </div>
    )
}
