import React from 'react'

export const SvgDoll = () => {

    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g >
                <path d="M18.4373 9.82968C18.0923 9.82968 17.8123 9.54968 17.8123 9.20468C17.8123 6.81134 16.8807 4.56219 15.189 2.86969C14.9448 2.62555 14.9448 2.22974 15.189 1.9856C15.4331 1.74146 15.8289 1.74146 16.0732 1.9856C18.0007 3.91385 19.0623 6.47809 19.0623 9.20468C19.0623 9.54968 18.7823 9.82968 18.4373 9.82968Z" fill="white"/>
                <path d="M1.35352 9.82965C1.00851 9.82965 0.728516 9.54965 0.728516 9.20465C0.728516 6.47806 1.79022 3.91382 3.71848 1.98633C3.96262 1.74219 4.35858 1.74219 4.60272 1.98633C4.84686 2.23047 4.84686 2.62643 4.60272 2.87057C2.91022 4.56216 1.97852 6.81131 1.97852 9.20465C1.97852 9.54965 1.69852 9.82965 1.35352 9.82965Z" fill="white"/>
                <path d="M9.89453 20.8713C8.1712 20.8713 6.76953 19.4697 6.76953 17.7463C6.76953 17.4013 7.04953 17.1213 7.39453 17.1213C7.73953 17.1213 8.01953 17.4013 8.01953 17.7463C8.01953 18.7806 8.86029 19.6213 9.89453 19.6213C10.9286 19.6213 11.7695 18.7806 11.7695 17.7463C11.7695 17.4013 12.0495 17.1213 12.3945 17.1213C12.7395 17.1213 13.0195 17.4013 13.0195 17.7463C13.0195 19.4697 11.6179 20.8713 9.89453 20.8713Z" fill="white"/>
                <path d="M16.7709 18.3713H3.02094C2.21664 18.3713 1.5625 17.7172 1.5625 16.9131C1.5625 16.4863 1.74835 16.0822 2.0726 15.8047C3.34 14.7338 4.0625 13.1772 4.0625 11.5281V9.20462C4.0625 5.98807 6.67923 3.37134 9.89594 3.37134C13.1125 3.37134 15.7292 5.98807 15.7292 9.20462V11.5281C15.7292 13.1772 16.4517 14.7338 17.7109 15.7989C18.0434 16.0822 18.2292 16.4863 18.2292 16.9131C18.2292 17.7172 17.5751 18.3713 16.7709 18.3713ZM9.89594 4.62134C7.36832 4.62134 5.3125 6.67715 5.3125 9.20462V11.5281C5.3125 13.5454 4.42841 15.4505 2.88757 16.753C2.85843 16.778 2.8125 16.8297 2.8125 16.9131C2.8125 17.0263 2.90756 17.1213 3.02094 17.1213H16.7709C16.8842 17.1213 16.9792 17.0263 16.9792 16.9131C16.9792 16.8297 16.9334 16.778 16.9058 16.7547C15.3633 15.4505 14.4792 13.5454 14.4792 11.5281V9.20462C14.4792 6.67715 12.4234 4.62134 9.89594 4.62134Z" fill="white"/>
                <path d="M9.89453 4.62134C9.54953 4.62134 9.26953 4.34134 9.26953 3.99634V1.49634C9.26953 1.15134 9.54953 0.871338 9.89453 0.871338C10.2395 0.871338 10.5195 1.15134 10.5195 1.49634V3.99634C10.5195 4.34134 10.2395 4.62134 9.89453 4.62134Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_392_15933">
                    <rect width="20" height="20" fill="white" transform="translate(0.728516 0.871338)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
