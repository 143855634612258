import React, {HTMLAttributes, useEffect, useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

export const AnswerTextField = ({ contentEditable, onBlur, maxHeight='auto', maxLength, text, setText}:any) => {

    const changeText = (e: any) => {
        e.preventDefault()
        // управление отображением
        if (Number.parseInt(e.target.style.height) < 185) {
            e.target.style.overflowY = 'hidden'
        }
        e.target.style.height = 'auto';
        e.target.style.height = (e.target.scrollHeight < 52 ? 31: e.target.scrollHeight) + 'px';//////console.log(this.scrollHeight);
        if (Number.parseInt(e.target.style.height) > 185) {
            e.target.style.overflowY = 'scroll'
        } else {
            e.target.style.overflowY = 'hidden'
        }
        // управление обновлением текста
        if (e.target.value.length > maxLength) return
        setText(e.target.value)
    }


    return (
        <div className={css(s.AnswerTextField)}>
            {(contentEditable || !!text.length) &&
                <textarea
                    autoFocus={contentEditable}
                    onBlur={onBlur}
                      rows={1}
                      value={text}
                      onChange={changeText}
                      // onKeyDown={keyDownHandler}
            />}
            {!contentEditable && !text.length && <div className={s.placeHolder}>
                <div><span/>Другие исполнители не увидят ваш отклик и ответы на него</div>
                <div><span/>Опишите суть предложения и что входит в стоимость работы</div>
                <div><span/>Если мало информации, напишите здесь вопросы, стоимость согласуете потом</div>
            </div>}
            {contentEditable && <div className={css(s.maxLength)}>{text.length}/{maxLength}</div>}
        </div>
    )
}
