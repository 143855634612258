import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/view/assets/scss/clear.module.scss'
import '../src/view/assets/scss/font.scss'
import '../src/view/assets/scss/scroll.scss'
import '../src/view/assets/scss/variables.scss'
import {Auth} from "./view/components/Auth";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store/reduxStore";
import {LoadingApp} from "./view/components/LoadingApp";
import {Hint} from "./view/components/Hint";
import {Navigation} from "./view/components/Navigation";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Auth />
            <Hint />
            <Navigation />
        </BrowserRouter>
        <LoadingApp />
    </Provider>
);
