import React, {useEffect} from 'react'
import {useAppState} from "../../../store/reduxStore";
import {NavigationActions, NavigationState} from "../../../store/reducers/navigation";
import {useLocation, useNavigate} from "react-router-dom";
import {useActions} from "../../../helpers/useActions";

export const Navigation = () => {
    const navigate = useNavigate()
    const {path} = useAppState(NavigationState)
    const location = useLocation()
    const {setPathAction} = useActions(NavigationActions)
    useEffect(()=>{
        if (path !== null) {
            navigate(path)
        }
    },[path])
    useEffect(()=>{
        if (path !== null) {
            setPathAction(null)
        }
    },[location.pathname])
    return null
}
