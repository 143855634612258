import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {IProfile} from "../../../../../../types/profile";
import {notice_server, store_server, timeCheckOnline} from "../../../../../../helpers/config";
import {customFetch} from "../../../../../../helpers/customFetch";
import {css} from "../../../../../../helpers/css";
import {SvgMyProfile} from "../../../../../assets/images/svg/SvgMyProfile";
import loadingGif from '../../../../../assets/images/LoadingGif.gif'
import {useInterval} from "../../../../../../helpers/useInterval";
import {NoticeAPI} from "../../../../../../API/NoticeAPI";
import {useThunks} from "../../../../../../helpers/useActions";
import {useParams} from "react-router-dom";

export const Head = ({verify, profile,}: { verify: boolean, profile: IProfile}) => {

    const {updateOnline} = useThunks(NoticeAPI)
    const params = useParams()

    const [img, setImg] = useState<{ src: string | null, loading: boolean }>({
        src: null,
        loading: true,
    })

    useEffect(() => {
        if (!!profile.avatarLink.length) {
            customFetch(`${store_server}/${profile.avatarLink}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'image/png',
                },
            })
                .then(data => {
                    if (data.status === 200) return data.blob()
                    else throw Error()
                })
                .then(blobData => {
                    const urlCreator = window.URL || window.webkitURL;
                    const image = urlCreator.createObjectURL(blobData);
                    setImg(prev => ({loading: false, src: image}))
                })
                .catch((err) => {
                    setImg(prev => ({loading: false, src: null}))
                })
        } else setImg(prev => ({loading: false, src: null}))
    }, [profile])

    // онлайн
    const [online, setOnline] = useState<boolean | null>(null)
    useEffect(()=>{
        updateOnline(Number(params.id), (data:boolean) => setOnline(data))
    },[])
    useInterval(()=>{
        updateOnline(Number(params.id), (data:boolean) => setOnline(data))
    }, timeCheckOnline)

    return (
        <div className={css(s.Head)}>
            <div className={css(s.firstRow)}>
                <div className={css(s.name)}>
                    <div className={css(s.avatar)}>
                        <div className={css(s.imgBox)}>
                            {img.src !== null && !img.loading && <img src={img.src} alt="png"/>}
                            {img.src === null && !img.loading && <SvgMyProfile/>}
                            {img.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                        </div>
                    </div>
                    <div className={css(s.nameValue)}>
                        <div className={css(s.nameTitle)}>
                            Имя
                        </div>
                        <div className={css(s.nameText)}>{profile.name}</div>
                    </div>
                </div>
                {verify && <div className={css(s.cro)}>
                    <div className={css(s.croName)}>
                        <div className={css(s.croNameTitle)}>
                            Наименование СРО*:
                        </div>
                        <div className={css(s.croNameText)}>
                            {!!profile.croName.trim().length ? profile.croName : 'Не установленно'}
                            {/*Ассоциация «Союз кадастровых инженеров»*/}
                        </div>
                    </div>
                    <div className={css(s.croNumber)}>
                        <div className={css(s.croNumberTitle)}>
                            Номер в реестре членов СРО*:
                        </div>
                        <div className={css(s.croNumberText)}>
                            {!!profile.croNumber.trim().length ? profile.croNumber : 'Не установленно'}
                            {/*125-126-456*/}
                        </div>
                    </div>
                </div>}
                <div className={css(s.status)}>
                    <div className={css(s.online)}>
                        <div className={css(s.onlineTitle)}>
                            статус:
                        </div>
                        <div className={css(s.onlineText)}>
                            {online === null && <img className={css(s.onlineLoading)} src={loadingGif} alt="loading"/>}
                            {online !== null && online && <div className={css(s.onlineStatus)}><div className={css(s.on)}/>В сети</div>}
                            {online !== null && !online && <div className={css(s.onlineStatus)}><div className={css(s.off)}/>Не в сети</div>}
                        </div>
                    </div>
                    <div className={css(s.registeredDate)}>
                        <div className={css(s.registeredDateTitle)}>
                            На сайте:
                        </div>
                        <div className={css(s.registeredDateText)}>
                            {new Date(Date.parse(profile.registrationDate)).toLocaleDateString()}
                            {/*с 20 октября 2022*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css(s.secondRow)}>
                <div className={css(s.phone)}>
                    <div className={css(s.phoneMain)}>
                        <div className={css(s.phoneTitle)}>
                            Телефон:
                        </div>
                        {!profile.private.phone && <div className={css(s.emptyText)}>Скрыто пользователем</div>}
                        {profile.private.phone && <div className={css(s.phoneText)}>{`${profile.phone?.length ? profile.phone : 'не указан'}`}</div>}
                    </div>
                </div>
                <div className={css(s.email)}>
                    <div className={css(s.emailMain)}>
                        <div className={css(s.emailTitle)}>
                            E-mail:
                        </div>
                        {!profile.private.email && <div className={css(s.emptyText)}>Скрыто пользователем</div>}
                        {profile.private.email && <div className={css(s.emailText)}>{`${profile.email?.length ? profile.email : 'не указан'}`}</div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
