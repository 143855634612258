import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {TextField} from "../../../../TextField";
import {TextInputField} from "../../../../TextInputField";
import {PriceInputField} from "../../../../PriceInputField";
import {useClickOutside} from "../../../../../../helpers/useClickOutside";
import {DatePicker} from "../../../../DatePicker";

const endDateTime = (dateTime: number) => {
    let newDate = new Date(dateTime)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate.getTime() + 8.64e+7
}

export const Deadline = ({newTask, setNewTask}: any) => {

    const [endTimeDay] = useState(endDateTime(Date.now()))

    useEffect(() => {
        if (!newTask.deadline || new Date(endDateTime(Date.now())) < new Date(endTimeDay + 8.64e+7)) {
            setNewTask((prev:any)=>({...prev, deadline: endDateTime(Date.now()) + 8.64e+7}))
        }
    }, [])

    const onBlurHandler = () => {
        if (!newTask.deadline || new Date(endTimeDay) < new Date(endTimeDay + 8.64e+7)) {
            setNewTask((prev:any)=>({...prev, deadline: endTimeDay + 8.64e+7}))
        }
    }

    const changeInfinityDeadline = () => {
        setNewTask((prev: any) => ({...prev, infinityDeadline: !prev.infinityDeadline}))
    }

    const [showDatePicker, setShowDatePicker] = useState(false)
    const showDatePickerHandler = () => {
        setNewTask((prev:any)=>({...prev, infinityDeadline: false}))
        setShowDatePicker(prev => !prev)
    }
    useClickOutside(() => {
        setShowDatePicker(false)
    }, [`pickerBox`, `deadlineTextTime`])

    const setNewDateHandler = (deadlineTime: number) => {
        if (endTimeDay + 8.64e+7 > deadlineTime) {
            return
        }
        setShowDatePicker(false)
        onBlurHandler()
        setNewTask((prev: any) => ({...prev, deadline: deadlineTime}))
    }

    return (
        <div className={css(s.Deadline)}>
            <div className={css(s.iconBox)}>
                <svg width="14" height="14" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6032 14.6986H15.821V12.9163C15.821 12.5883 15.555 12.3223 15.2269 12.3223C14.8988 12.3223 14.6328 12.5883 14.6328 12.9163V15.2926C14.6328 15.6207 14.8988 15.8867 15.2269 15.8867H17.6032C17.9313 15.8867 18.1972 15.6207 18.1972 15.2926C18.1972 14.9645 17.9313 14.6986 17.6032 14.6986Z"
                          fill="white"/>
                    <path d="M18.1963 10.8489V3.33169C18.1963 2.34897 17.3968 1.54947 16.4141 1.54947H15.2259V0.9554C15.2259 0.627314 14.9599 0.361328 14.6319 0.361328C14.3038 0.361328 14.0378 0.627314 14.0378 0.9554V1.54947H11.6615V0.9554C11.6615 0.627314 11.3955 0.361328 11.0674 0.361328C10.7393 0.361328 10.4734 0.627314 10.4734 0.9554V1.54947H8.01786V0.9554C8.01786 0.627314 7.75187 0.361328 7.42379 0.361328C7.0957 0.361328 6.82971 0.627314 6.82971 0.9554V1.54947H4.45343V0.9554C4.45343 0.627314 4.18744 0.361328 3.85935 0.361328C3.53127 0.361328 3.26528 0.627314 3.26528 0.9554V1.54947H2.07714C1.09442 1.54947 0.294922 2.34897 0.294922 3.33169V17.6686C0.294922 17.9967 0.560908 18.2627 0.888994 18.2627H10.7825C11.7428 19.6946 13.3761 20.639 15.2259 20.639C18.1741 20.639 20.5726 18.2405 20.5726 15.2923C20.5726 13.4425 19.6282 11.8092 18.1963 10.8489ZM1.48307 3.33169C1.48307 3.00412 1.74957 2.73762 2.07714 2.73762H3.26528V3.33169C3.26528 3.65977 3.53127 3.92576 3.85935 3.92576C4.18744 3.92576 4.45343 3.65977 4.45343 3.33169V2.73762H6.82971V3.33169C6.82971 3.65977 7.0957 3.92576 7.42379 3.92576C7.75187 3.92576 8.01786 3.65977 8.01786 3.33169V2.73762H10.4734V3.33169C10.4734 3.65977 10.7393 3.92576 11.0674 3.92576C11.3955 3.92576 11.6615 3.65977 11.6615 3.33169V2.73762H14.0378V3.33169C14.0378 3.65977 14.3038 3.92576 14.6319 3.92576C14.9599 3.92576 15.2259 3.65977 15.2259 3.33169V2.73762H16.4141C16.7416 2.73762 17.0081 3.00412 17.0081 3.33169V5.19311H1.48307V3.33169ZM10.1851 17.0746H1.48307V6.38126H17.0081V10.2515C16.4504 10.0537 15.8506 9.94569 15.2259 9.94569C12.2778 9.94569 9.87928 12.3442 9.87928 15.2923C9.87928 15.917 9.98733 16.5168 10.1851 17.0746ZM15.2259 19.4508C12.9329 19.4508 11.0674 17.5853 11.0674 15.2923C11.0674 12.9993 12.9329 11.1338 15.2259 11.1338C17.5189 11.1338 19.3844 12.9993 19.3844 15.2923C19.3844 17.5853 17.5189 19.4508 15.2259 19.4508Z"
                          fill="white"/>
                    <defs>
                        <rect width="20.2777" height="20.2777" fill="white" transform="translate(0.294922 0.361328)"/>
                    </defs>
                </svg>
            </div>
            <div className={css(s.main)} onBlur={onBlurHandler}>
                <div className={css(s.title)}>Выполнить до:</div>
                {!newTask.infinityDeadline &&
                    <div id={`deadlineTextTime`} className={css(s.deadlineTextTime)} onClick={showDatePickerHandler}>
                        <div className={css(s.deadlineDate)}>{new Date(newTask.deadline).toLocaleDateString()}</div>
                        <div className={css(s.pickerBox, showDatePicker && s.show)} onClick={e => e.stopPropagation()}>
                            <DatePicker setNewDate={setNewDateHandler} deadline={+newTask.deadline}/>
                        </div>
                    </div>}
                {newTask.infinityDeadline &&
                    <div className={css(s.deadlineTextTime)} onClick={showDatePickerHandler}>Заказ с открытой датой
                        завершения</div>}
                <div className={css(s.minPrice)}>
                    Мин. 1 день
                </div>
            </div>
            <div className={css(s.setInfinityDeadline)}>
                <label>
                    <input type="checkbox" checked={newTask.infinityDeadline} onChange={changeInfinityDeadline}/>
                    <span>Заказ с открытой датой завершения. Жду предложений от фрилансеров</span>
                </label>
            </div>
        </div>
    )
}
