import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import {Link, useNavigate} from "react-router-dom";
import {SvgDevSite} from "../../assets/images/svg/SvgDevSite";
import {useAppState} from "../../../store/reduxStore";
import {UserState} from "../../../store/reducers/auth";
import {useActions} from "../../../helpers/useActions";
import {showPopupActions} from "../../../store/reducers/showPopup";
import {ProfileState} from "../../../store/reducers/profile";

export const Footer = () => {
    const {user} = useAppState(UserState)
    const {profile} = useAppState(ProfileState)
    const navigate = useNavigate()

    const {setIsShowPopupAction} = useActions(showPopupActions)

    const showLoginPopupHandler = () => {
        setIsShowPopupAction({type: 'login', isShow: true})
    }

    const navigateHandler = (path:string) => {
        navigate(path)
    }

    return (
        <div className={css(s.Footer)}>
            <div className={css(s.container)}>
                <div className={css(s.mainRow)}>
                    <Link to={'/'} className={css(s.logo)}>
                        <div className={css(s.title)}>zemsite</div>
                    </Link>
                    <div className={css(s.navigation)}>
                        <Link to={'/'}><span>Главная</span></Link>
                        {profile?.verify && <Link to={'/task-list'}>Каталог задач</Link>}
                        <Link to={'/executor-list'}>Список профессиональных исполнителей</Link>
                    </div>
                    {user.role === 'guest' && <div className={css(s.auth)} onClick={showLoginPopupHandler}>Вход</div>}
                    {user.role !== 'guest' &&  <Link to={`/setting`} >Личный кабинет</Link>}
                    <div className={css(s.about)}>
                        <Link to={'/about'}>О компании</Link>
                        <Link to={'/contact'}>Контакты</Link>
                        <Link to={'/faq'}>FAQ</Link>
                    </div>
                </div>
                <div className={css(s.infoRow)}>
                    <div className={css(s.copyright)} onClick={()=>navigateHandler('/copyright')}>
                        ©2022. Все права защищены.
                    </div>
                    <div className={css(s.regulations)}>
                        <div className={css(s.link)} onClick={()=>navigateHandler('/agreement')}>Пользовательское соглашение</div>
                        <div className={css(s.link)} onClick={()=>navigateHandler('/policy')}>Политика конфиденциальности</div>
                        {/*<a href={'/policy'} target={'_blank'} className={css(s.link)} >Политика конфиденциальности</a>*/}
                        {/*<div className={css(s.link)} onClick={()=>navigateHandler('/service-rules')}>Правила размещения услуг</div>*/}
                    </div>
                    <a href={`https://google.com`} target={`_blank`} className={css(s.devSite)}>
                        <div className={css(s.img)}>
                            <SvgDevSite />
                        </div>
                        <div className={css(s.text)}>
                            <span>Разработка сайта </span>
                            <span> — Digital-студия «Акцепт»</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
