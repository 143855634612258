import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {ChangeMode} from "../ChangeMode";
import {VerifyBtn} from "../VerifyBtn";
import {SettingMainEmailNotice} from "../SettingMainEmailNotice";
import {SettingMainSecurity} from "../SettingMainSecurity";
import {useAppState} from "../../../../../store/reduxStore";
import {UserState} from "../../../../../store/reducers/auth";
import {ProfileState} from "../../../../../store/reducers/profile";
import {IProfile} from "../../../../../types/profile";
import {ProfileAPI} from "../../../../../API/useProfile";
import {LoadingPage} from "../../../LoadingPage";

export const OutletInfoTwo = () => {
    const {user} = useAppState(UserState)

    return (
        <div className={css(s.InfoTwoPage)}>
            <ChangeMode />
            {!!user.verifyStatus && user.role === 'regular' && <VerifyBtn/>}
            <div className={css(s.mainBox)}>
                <SettingMainEmailNotice />
                <SettingMainSecurity />
            </div>
        </div>
    )
}
