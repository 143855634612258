import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useNavigate} from "react-router-dom";

export const NoticeItem = ({notice,closeNoticeBox,readNotice}:any) => {

    const navigate = useNavigate()
    const readNoticeHandler = () => {
        readNotice(notice.id)
    }
    const linkHandler = () => {
        if (!notice.linkText.trim().length) return
        if (!notice.srcLink.trim().length) return
        navigate(notice.srcLink)
        closeNoticeBox()
        readNotice(notice.id)
    }
    return (
        <div key={notice.id} className={css(s.NoticeItem)}>
            <div className={css(s.date)}>{new Date(notice.createdAt).toLocaleDateString()}, {new Date(notice.createdAt).toLocaleTimeString().slice(0,5)}</div>
            <div className={css(s.text)}>{notice.text}</div>
            <div className={css(s.linkText)} onClick={linkHandler}>{notice.linkText}</div>
            <div className={css(s.closeBtn)} onClick={readNoticeHandler}>
                <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.12577 7.48771L0.285067 13.3286C-0.0950365 13.7085 -0.0950365 14.3228 0.285067 14.7027C0.664991 15.0827 1.27928 15.0827 1.6592 14.7027L7.50008 8.86185L13.3408 14.7027C13.7209 15.0827 14.335 15.0827 14.7149 14.7027C15.095 14.3228 15.095 13.7085 14.7149 13.3286L8.87422 7.48771L14.7149 1.64683C15.095 1.2669 15.095 0.652618 14.7149 0.272693C14.5256 0.0831757 14.2766 -0.0120287 14.0279 -0.0120287C13.7791 -0.0120287 13.5303 0.0831757 13.3408 0.272693L7.50008 6.11357L1.6592 0.272693C1.46969 0.0831757 1.22091 -0.0120287 0.972135 -0.0120287C0.723359 -0.0120287 0.474585 0.0831757 0.285067 0.272693C-0.0950365 0.652618 -0.0950365 1.2669 0.285067 1.64683L6.12577 7.48771Z"/>
                </svg>
            </div>
        </div>
    )
}
