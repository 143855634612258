import React, {useState} from 'react'
import s from './index.module.scss'
import {TextField} from "../../../../TextField";
import {IProfile} from "../../../../../../types/profile";
import {css} from "../../../../../../helpers/css";

export const MyServices = ({profile}: {profile: IProfile}) => {

    return (
        <div className={css(s.MyServices)}>
            <div className={css(s.iconBox)}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.485 11.4545H3.39409V13.3636C3.39409 13.7151 3.67901 14 4.03045 14H12.5153C12.8667 14 13.1517 13.7151 13.1517 13.3636V3.18182C13.1517 2.83038 12.8667 2.54545 12.5153 2.54545H10.6062V0.636364C10.6062 0.284921 10.3213 0 9.96984 0H1.485C1.13355 0 0.848633 0.284921 0.848633 0.636364V10.8182C0.848633 11.1696 1.13355 11.4545 1.485 11.4545ZM11.8789 12.7273H4.66681V3.81818H11.8789V12.7273ZM2.12136 1.27273H9.33348V2.54545H4.03045C3.67901 2.54545 3.39409 2.83038 3.39409 3.18182V10.1818H2.12136V1.27273Z" fill="white" stroke="#475178" strokeWidth="0.5"/>
                    <path d="M6.57582 5.09082C6.22437 5.09082 5.93945 5.37574 5.93945 5.72718C5.93945 6.07863 6.22437 6.36355 6.57582 6.36355H9.96976C10.3212 6.36355 10.6061 6.07863 10.6061 5.72718C10.6061 5.37574 10.3212 5.09082 9.96976 5.09082H6.57582Z" fill="white" stroke="#475178" strokeWidth="0.5"/>
                    <path d="M9.96976 7.63647H6.57582C6.22437 7.63647 5.93945 7.9214 5.93945 8.27284C5.93945 8.62428 6.22437 8.9092 6.57582 8.9092H9.96976C10.3212 8.9092 10.6061 8.62428 10.6061 8.27284C10.6061 7.9214 10.3212 7.63647 9.96976 7.63647Z" fill="white" stroke="#475178" strokeWidth="0.5"/>
                    <path d="M9.96976 10.1819H6.57582C6.22437 10.1819 5.93945 10.4668 5.93945 10.8182C5.93945 11.1697 6.22437 11.4546 6.57582 11.4546H9.96976C10.3212 11.4546 10.6061 11.1697 10.6061 10.8182C10.6061 10.4668 10.3212 10.1819 9.96976 10.1819Z" fill="white" stroke="#475178" strokeWidth="0.5"/>
                </svg>
            </div>
            <div className={css(s.main)}>
                <div className={css(s.title)}>Мои услуги:</div>
                {!profile.private.myServices && <div className={css(s.emptyText)}>Скрыто пользователем</div>}
                {profile.private.myServices && !profile.myServices.trim().length && <div className={css(s.emptyText)}>Не заполнено...</div>}
                {profile.private.myServices && <div dangerouslySetInnerHTML={{__html: profile.myServices}}/>}
            </div>
        </div>
    )
}
