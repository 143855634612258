
import {useActions} from "../helpers/useActions";
import {AppDispatch, RootState, useAppDispatch, useAppState} from "../store/reduxStore";
import {UserState} from "../store/reducers/auth";
import {useCallback} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {user_server, store_server} from "../helpers/config";
import {customFetchFile, customFetchJson} from "../helpers/customFetch";
import {IResponse} from "../types/api";
import {VerificationActions, VerificationState} from "../store/reducers/verification";
import {IMinFile} from "../types/file";
import {HintActions} from "../store/reducers/hint";
import {AuthAPI} from "./AuthAPI";

export const VerificationAPI = {

    // статус верификации
    getVerificationStatus: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {

        try {
            const oldVerifyStatus = getState().VerificationReducer.verificationStatus
            const response = await customFetchJson(`${user_server}/api/verification/status`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                dispatch(VerificationActions.setVerificationStatusAction(null))

                console.log(response.payload)
                return
            }
            dispatch(VerificationActions.setVerificationStatusAction(response.payload))
            if (oldVerifyStatus !== response.payload && response.payload === 2) {
                dispatch(AuthAPI.checkAuth())
            }
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    // info
    getVerificationData: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${user_server}/api/verification/getMyVerifyData`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                return
            }
            dispatch(VerificationActions.setVerificationDataAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    sendVerificationData: (data: any) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchJson(`${user_server}/api/verification/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })

            if (!response.error) {
                dispatch(HintActions.setHintApp({isShow: true, text: 'Данные успешно отправлены', type: 'ok'}))

            }
            dispatch(VerificationActions.setVerificationStatusAction(response.payload.status))
            dispatch(VerificationActions.setVerificationDataAction(response.payload))
        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },

    // files
    getAddedVerificationFile: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<Array<IMinFile>> = await customFetchJson(`${store_server}/api/store/verification/getAddedFileList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload)
                window.location.pathname = `/error`
                // navigate(`/error`)
                return
            }
            dispatch(VerificationActions.setAddedFileListAction(response.payload))

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
    addVerificationFile: (file: File) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        const liteId = Date.now()
        const fakeFile = {
            id: liteId,
            userId: 0,
            fileName: file.name.toLowerCase(),
            fileSize: file.size
        }
        const addedFiles = getState().VerificationReducer.addedFiles
        try {
            const fullSize = addedFiles ? addedFiles.reduce((sum, file)=> {
                return sum + Number(file.fileSize)
            }, 0): 0
            if (((fullSize + file.size) > 20000000) || (addedFiles && addedFiles.length > 4)) {
                alert('Превышен лимит')
                return
            }
            if (file.size > 20000000) {
                alert('Слишком большой файл')
                return
            }
            dispatch(VerificationActions.addFileAction(fakeFile))

            const formData = new FormData();
            formData.append('file', file)

            const response: IResponse<IMinFile> = await customFetchJson(`${store_server}/api/store/verification/addFile`, {
                method: 'POST',
                body: formData
            })

            if (response.error) {
                dispatch(VerificationActions.deleteFileAction(fakeFile))
                const error: any = response.payload
                if (error.errorCode === 1.1) {
                    console.log(error.errorCode)
                    alert(error.message)
                    return
                }
                console.log(response.payload)
                window.location.pathname = `/error`
                // navigate(`/error`)
                return
            }
            dispatch(VerificationActions.deleteFileAction(fakeFile))
            dispatch(VerificationActions.addFileAction(response.payload))
        } catch (error) {
            dispatch(VerificationActions.deleteFileAction(fakeFile))
            console.log('error client', error)
        } finally {
        }
    },

    deleteVerificationFile: (file: IMinFile) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(VerificationActions.deleteFileAction(file))
            const response: IResponse<IMinFile> = await customFetchJson(`${store_server}/api/store/verification/deleteFile/${file.id}`, {
                method: 'DELETE',
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(VerificationActions.addFileAction(file))
                // navigate(`/error`)
                return
            }

        } catch (error) {
            console.log('error client', error)
        } finally {
        }
    },
}
