import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IMinFile} from "../../types/file";
import {IVerifyData, IVerifyItem} from "../../types/profile";

interface stateType {
    verificationStatus: number | null
    verificationData: IVerifyItem | null
    addedFiles: Array<IMinFile> | null

}

const initialState: stateType = {
    verificationData: null,
    addedFiles: null,
    verificationStatus: null,


}

export const slice = createSlice({
    name: 'verificationReducer',
    initialState,
    reducers: {
        setVerificationStatusAction: (state, action: PayloadAction<number | null>) : void => {
            state.verificationStatus = action.payload
        },
        setVerificationDataAction: (state, action: PayloadAction<IVerifyItem | null>) : void => {
            state.verificationData = action.payload
        },
        setAddedFileListAction: (state, action: PayloadAction<Array<IMinFile> | null>) : void => {
            state.addedFiles = action.payload
        },
        addFileAction: (state, action: PayloadAction<IMinFile>) : void => {
            state.addedFiles?.push(action.payload)
        },
        deleteFileAction: (state, action: PayloadAction<IMinFile>) : void => {
            if (state.addedFiles)
                state.addedFiles = state.addedFiles?.filter(file => file.id !== action.payload.id)
        },
    }
})

export const VerificationReducer = slice.reducer
export const VerificationActions = slice.actions
export const VerificationState = (state: RootState) => state.VerificationReducer

