import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AuthAPI} from "../../../../API/AuthAPI";
import {CloseBtn} from "../CloseBtn";
import {useLocation, useNavigate} from "react-router-dom";
import loadingGif from "../../../assets/images/LoadingGif.gif";
import {useThunks} from "../../../../helpers/useActions";


export const RecoverPassword = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [superLink, setSuperLink] = useState<{link: string, email: string} | null>({link: '', email: ''})
    useEffect(()=>{
        if (location.pathname.split('/')[1] === `resetpswd`) {
            let link = location.pathname.split('/')[2].split(`email:`)[0]
            let email = location.pathname.split('/')[2].split(`email:`)[1]

            if ((!!link && !!email) && (!!link.length && !!email.length) ) {
                setSuperLink({
                    link,
                    email
                })
                navigate('/resetpswd/send')
            } else {
                setSuperLink(null)
            }
        }
    },[])

    useEffect(()=>{
        if (superLink === null) navigate('/')
    },[superLink])

    const {recoverPassword: recoverPasswordAPI} = useThunks(AuthAPI)

    const [isFetch, setIsFetch] = useState(false)
    const [errorField, setErrorField] = useState({
        password: false,
        passwordSecond: false,
        text: '',
    })
    const [password, setPassword] = useState(``)
    const [passwordSecond, setPasswordSecond] = useState(``)

    useEffect(()=>{
        return ()=>{
            clearErrorHandler()
            setPassword('')
            setPasswordSecond('')
        }
    },[])

    const resetPasswordHandler = (e:any) => {
        e.preventDefault()

        if (superLink === null)
            return setErrorField(prev => ({...prev, text: 'Ошибка'}))

        if (password.length < 6 || password.length > 32)
            return setErrorField(prev => ({...prev, password: true, text: 'Пароль должен содержать от 6 до 32 символов'}))

        if (password !== passwordSecond)
            return setErrorField(prev => ({...prev, passwordSecond: true, text: 'Пароли не совпадают'}))

        setIsFetch(true)
        recoverPasswordAPI(superLink.email,superLink.link,password)
    }

    const clearErrorHandler = () => {
        setErrorField({password: false, passwordSecond: false, text: '',})
    }

    const onCloseHandler = () => {
        navigate('/')
    }
    return (
        <div className={css(s.ChangePasswordWrapper)}>
            <div className={css(s.ChangePassword)}>
                <div className={css(s.title)}>
                    Установить пароль
                </div>
                <div className={css(s.errorField)}>{errorField.text}</div>
                <form className={css(s.form)} onSubmit={resetPasswordHandler}>
                    <div className={css(s.field, errorField.password && s.error)}>
                        <input type="text" placeholder={`Новый пароль*`} value={password} onChange={e=> {
                            clearErrorHandler()
                            setPassword(e.target.value)
                        }}/>
                    </div>
                    <div className={css(s.field, errorField.passwordSecond && s.error)}>
                        <input type="text" placeholder={`Повторите пароль*`} value={passwordSecond} onChange={e=> {
                            clearErrorHandler()
                            setPasswordSecond(e.target.value)
                        }}/>
                    </div>
                    <button>
                        {!isFetch ? 'Сохранить' : <img src={loadingGif} alt="loading"/>}
                    </button>
                </form>
                <CloseBtn onClose={onCloseHandler}/>
            </div>
        </div>
    )
}
