import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {ProfileState} from "../../../../store/reducers/profile";
import {LoadingPage} from "../../LoadingPage";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export const SettingPage = () => {
    const {profile} = useAppState(ProfileState)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if (location.pathname === `/setting/info`) return
        if (location.pathname === `/setting/info-two`) return
        if (location.pathname === `/setting/verify`) return
        navigate('/setting/info')
    },[location])

    return (
        <div className={css(s.SettingRegularPage)}>
            <div className={css(s.container)}>
                <div className={css(s.title)}>
                    Личные данные
                </div>
                {!!profile ? <Outlet /> : <LoadingPage />}
            </div>
        </div>
    )
}
