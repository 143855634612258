import React from 'react'

export const SvgJpgIcon = () => {

    return (
        <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 0C7.11562 0 6.1875 0.928125 6.1875 2.0625V30.9375C6.1875 32.0719 7.11562 33 8.25 33H28.875C30.0094 33 30.9375 32.0719 30.9375 30.9375V8.25L22.6875 0H8.25Z" fill="#E2E5E7"/>
            <path d="M24.75 8.25H30.9375L22.6875 0V6.1875C22.6875 7.32188 23.6156 8.25 24.75 8.25Z" fill="#B0B7BD"/>
            <path d="M30.9375 14.4375L24.75 8.25H30.9375V14.4375Z" fill="#CAD1D8"/>
            <path d="M26.8125 26.8125C26.8125 27.3797 26.3484 27.8438 25.7812 27.8438H3.09375C2.52656 27.8438 2.0625 27.3797 2.0625 26.8125V16.5C2.0625 15.9328 2.52656 15.4688 3.09375 15.4688H25.7812C26.3484 15.4688 26.8125 15.9328 26.8125 16.5V26.8125Z" fill="#779BF6"/>
            <path d="M9.15031 19.539C9.15031 18.846 10.2393 18.846 10.2393 19.539V22.7957C10.2393 24.091 9.62056 24.8747 8.19434 24.8747C7.49309 24.8747 6.90837 24.6839 6.39687 24.025C5.97612 23.5217 6.76812 22.7968 7.19712 23.3237C7.54362 23.7527 7.91487 23.8517 8.27684 23.8352C8.73781 23.8187 9.14309 23.6114 9.15134 22.7957V19.539H9.15031Z" fill="white"/>
            <path d="M11.6875 19.5392C11.6875 19.267 11.902 18.97 12.2485 18.97H14.1532C15.2257 18.97 16.191 19.6877 16.191 21.0634C16.191 22.3669 15.2257 23.0929 14.1532 23.0929H12.7765V24.1819C12.7765 24.5449 12.5455 24.7501 12.2485 24.7501C11.9763 24.7501 11.6875 24.5449 11.6875 24.1819V19.5392ZM12.7765 20.0084V22.0627H14.1532C14.706 22.0627 15.1432 21.5749 15.1432 21.0634C15.1432 20.4869 14.706 20.0084 14.1532 20.0084H12.7765Z" fill="white"/>
            <path d="M22.081 24.1158C21.4952 24.6026 20.7775 24.8088 20.0525 24.8088C18.32 24.8088 17.0918 23.8199 17.0918 21.8564C17.0918 20.1919 18.387 18.8977 20.1257 18.8977C20.7775 18.8977 21.4942 19.1194 22.0057 19.6227C22.5079 20.1166 21.783 20.8509 21.289 20.4147C20.9837 20.1177 20.5547 19.8949 20.1257 19.8949C19.1285 19.8949 18.1643 20.6941 18.1643 21.8564C18.1643 23.0774 18.9718 23.8199 20.0525 23.8199C20.5547 23.8199 20.9837 23.6703 21.289 23.4486V22.4349H20.0525C19.3183 22.4349 19.3925 21.4284 20.0525 21.4284H21.7015C22.0067 21.4284 22.2862 21.6594 22.2862 21.9151V23.6714C22.2862 23.8199 22.2212 23.958 22.081 24.1158Z" fill="white"/>
            <path d="M25.7812 27.8438H6.1875V28.875H25.7812C26.3484 28.875 26.8125 28.4109 26.8125 27.8438V26.8125C26.8125 27.3797 26.3484 27.8438 25.7812 27.8438Z" fill="#CAD1D8"/>
        </svg>
    )
}
