import React from 'react'

export const SvgLogin = () => {

    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_392_16734)">
                <path d="M7.3707 10.8714C8.07718 10.8714 8.76779 10.6619 9.3552 10.2694C9.9426 9.87689 10.4004 9.319 10.6707 8.66629C10.9411 8.01358 11.0118 7.29536 10.8739 6.60247C10.736 5.90958 10.3958 5.27313 9.89618 4.77362C9.39658 4.27411 8.76008 3.93397 8.06716 3.79622C7.37424 3.65847 6.65604 3.72929 6.00337 3.99973C5.35071 4.27018 4.7929 4.72809 4.40049 5.31557C4.00809 5.90304 3.7987 6.59369 3.79883 7.30016C3.79982 8.24712 4.17648 9.155 4.84614 9.82454C5.5158 10.4941 6.42374 10.8706 7.3707 10.8714Z" fill="white"/>
                <path d="M12.0441 13.8221C11.139 12.8937 9.97772 12.2562 8.70855 11.991C7.43937 11.7258 6.11996 11.845 4.91884 12.3333C3.71772 12.8216 2.68942 13.6568 1.9653 14.7324C1.24118 15.8079 0.854101 17.0749 0.853516 18.3715C0.853516 18.5372 0.919364 18.6962 1.03657 18.8134C1.15378 18.9306 1.31276 18.9965 1.47852 18.9965H13.266C13.4318 18.9965 13.5907 18.9306 13.708 18.8134C13.8252 18.6962 13.891 18.5372 13.891 18.3715C13.8934 17.7087 13.7911 17.0498 13.5879 16.419C13.2853 15.4437 12.7563 14.5539 12.0441 13.8221Z" fill="white"/>
                <path d="M14.916 11.184C16.383 11.184 17.5723 9.99472 17.5723 8.52771C17.5723 7.0607 16.383 5.87146 14.916 5.87146C13.449 5.87146 12.2598 7.0607 12.2598 8.52771C12.2598 9.99472 13.449 11.184 14.916 11.184Z" fill="white"/>
                <path d="M14.9161 11.9152C14.0783 11.9184 13.2568 12.1465 12.5374 12.5758C12.673 12.6971 12.8099 12.8165 12.938 12.9477C13.789 13.8224 14.4212 14.8859 14.783 16.0515C14.9069 16.4352 14.9983 16.8287 15.0561 17.2277H18.9786C19.1444 17.2277 19.3033 17.1619 19.4205 17.0447C19.5378 16.9275 19.6036 16.7685 19.6036 16.6027C19.6023 15.3599 19.108 14.1684 18.2292 13.2896C17.3504 12.4108 16.1589 11.9165 14.9161 11.9152Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_392_16734">
                    <rect width="20" height="20" fill="white" transform="translate(0.228516 0.87146)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
