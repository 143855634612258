import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    profile: IProfile | null
    totalExecutorList: number | null
    executorList: Array<any> | null
    activeExecutor: any | null

    // searchExecutorList
    search: string
    // filterExecutorList
    place: {
        region: string,
        city: string,
    }

}
const initialState: stateType = {
    profile: null,
    totalExecutorList: null,
    executorList: null,
    activeExecutor: null,

    // searchExecutorList
    search: '',
    // filterExecutorList
    place: {
        region: '',
        city: '',
    },
}

export const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfileAction: (state, action: PayloadAction<IProfile>) : void => {
            state.profile = action.payload
        },
        setAvatarLinkAction: (state, action: PayloadAction<string>) : void => {
           if (state.profile) state.profile.avatarLink = action.payload
        },
        setExecutorListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.executorList = action.payload
        },
        addExecutorListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.executorList)
                state.executorList = [...state.executorList,...action.payload]
        },
        setTotalExecutorListAction: (state, action: PayloadAction<number | null>) : void => {
            state.totalExecutorList = action.payload
        },
        setActiveExecutorAction: (state, action: PayloadAction<any | null>) : void => {
            state.activeExecutor = action.payload
        },
        setMyProfileImageAction: (state, action: PayloadAction<any | null>) : void => {
            if (state.profile === null) return
            state.profile.image = action.payload
        },

        // searchExecutorList
        setSearchExecutorListAction: (state, action: PayloadAction<string>) : void => {
            state.search = action.payload
        },
        // filterExecutorList
        setFilterPlaceExecutorListAction: (state, action: PayloadAction<{region: string, city: string}>) : void => {
            state.place = action.payload
        },
    }
})

export const ProfileReducer = slice.reducer
export const ProfileActions = slice.actions
export const ProfileState = (state: RootState) => state.ProfileReducer


