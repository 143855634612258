import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

const getMonthText = (number: number):string =>{
    return [`Январь`,`Февраль`,`Март`,`Апрель`,`Май`,`Июнь`,`Июль`,`Август`,`Сентябрь`,`Октябрь`,`Ноябрь`,`Декабрь`,][number]
}
const getNameDaysText = ():Array<string> =>{
    return [`Пн`,`Вт`,`Ср`,`Чт`,`Пт`,`Сб`,`Вс`]
}
const startDateTime = (dateTime: number): number => {
    let newDate = new Date(dateTime)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate.getTime()
}
const endDateTime = (dateTime: number) => {
    let newDate = new Date(dateTime)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate.getTime() + 8.64e+7
}

export const DatePicker = ({setNewDate, deadline}:{setNewDate: Function, deadline: number}) => {

    const [newDateTime, setNewDateTime] = useState(startDateTime(Date.now()))

    useEffect(()=>{
        setNewDateTime(startDateTime(deadline))
    },[deadline])


    const prevMonthHandler = () => {
        const currentMont = new Date(newDateTime).getMonth()
        const date = new Date(newDateTime).setMonth(currentMont -1)
        setNewDateTime(date)
    }
    const nextMonthHandler = () => {
        const currentMont = new Date(newDateTime).getMonth()
        const date = new Date(newDateTime).setMonth(currentMont +1)
        setNewDateTime(date)
    }

    // заполнение дней в датапикере
    const [days, setDays] = useState<any>(null)
    useEffect(()=>{
        // текущий месяц
        const currentDate = new Date(newDateTime)
        // дней в текущем месяце
        const countDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 0).getDate();
        // дней в предыдущем месяце
        const countDayPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
        // день недели начала текущего месяца
        const startWeekDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDay()+1;

        // дни предыдущего месяца
        let prevMonthDays = []
        for (let i = 1; i < startWeekDayCurrentMonth; i++) {
            prevMonthDays.push({
                type: 'prev',
                value: countDayPrevMonth - (startWeekDayCurrentMonth-1) + i,
                current: false,
                active: false
            })
        }
        // дни текущего месяца
        let currentMonthDays = []
        for (let i = 1; i <= countDayCurrentMonth; i++) {
            currentMonthDays.push({
                type: 'current',
                value: i,
                current: new Date().getDate() === i && new Date(newDateTime).getMonth() === new Date().getMonth(),
                active: new Date(deadline).getDate() === i && new Date(newDateTime).getMonth() === new Date(deadline).getMonth(),
            })
        }
        // дни следущего месяца
        let nextMonthDays = []
        for (let i = 1; i <= 42 - (prevMonthDays.length + currentMonthDays.length); i++) {
            nextMonthDays.push({
                type: 'next',
                value: i,
                current: false,
                active: false
            })
        }
        setDays([...prevMonthDays, ...currentMonthDays, ...nextMonthDays])
    },[newDateTime])

    // установка даты deadline
    const setDateDeadline = (day: any) => {
        const year = new Date(newDateTime).getFullYear()
        const month = new Date(newDateTime).getMonth()
        let deadline = null

        switch (day.type) {
            case 'prev':
                deadline = startDateTime(new Date(year,month-1,day.value).getTime())
                break
            case 'current':
                deadline = startDateTime(new Date(year,month,day.value).getTime())
                break
            case 'next':
                deadline = startDateTime(new Date(year,month+1,day.value).getTime())
                break
        }
        setNewDate(deadline)
    }
    return (
        <div className={css(s.DatePicker)}>
            <div className={css(s.head)}>
                <div className={css(s.activeMonthAndYear)}>
                    <div className={css(s.month)}>{getMonthText(new Date(newDateTime).getMonth())}</div>
                    <div className={css(s.year)}>{new Date(newDateTime).getFullYear()}</div>
                </div>
                <div className={css(s.changeMonth)}>
                    <div className={css(s.prev)} onClick={prevMonthHandler}>&#x2039;</div>
                    <div className={css(s.next)} onClick={nextMonthHandler}>&#x203A;</div>
                </div>
            </div>
            <div className={css(s.nameDaysBox)}>
                {getNameDaysText().map(nameDay => <div key={nameDay} className={css(s.nameDay)}>{nameDay}</div>)}
            </div>
            <div className={css(s.calendar)}>
                {days?.map((day: any) => {
                    switch (day.type) {
                        case 'prev': return <PrevDay key={day.type+day.value} day={day} setDateDeadline={setDateDeadline}/>
                        case 'current': return <CurrentDay key={day.type+day.value} day={day} setDateDeadline={setDateDeadline}/>
                        case 'next': return <NextDay key={day.type+day.value} day={day} setDateDeadline={setDateDeadline}/>
                    }
                })}
            </div>
        </div>
    )
}
export const PrevDay = ({day, setDateDeadline}:any) => {
    const setDateDeadlineHandler = () => {
        setDateDeadline(day)
    }
    return (
        <div className={css(s.PrevDay)} onClick={setDateDeadlineHandler}>
            {day.value}
        </div>
    )
}
export const CurrentDay = ({day, setDateDeadline}:any) => {
    const setDateDeadlineHandler = () => {
        setDateDeadline(day)
    }
    return (
        <div className={css(s.CurrentDay, day.current && s.current, day.active && s.active)} onClick={setDateDeadlineHandler}>
            {day.value}
        </div>
    )
}
export const NextDay = ({day, setDateDeadline}:any) => {
    const setDateDeadlineHandler = () => {
        setDateDeadline(day)
    }
    return (
        <div className={css(s.NextDay)} onClick={setDateDeadlineHandler}>
            {day.value}
        </div>
    )
}

