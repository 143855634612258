import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AuthAPI} from "../../../../API/AuthAPI";
import {SvgSocialVk} from "../../../assets/images/svg/SvgSocialVk";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {showPopupActions, ShowPopupType} from "../../../../store/reducers/showPopup";
import {CloseBtn} from "../CloseBtn";
import {useAppState} from "../../../../store/reduxStore";
import {UserActions, UserState} from "../../../../store/reducers/auth";

type PropsType = {
    id:string,
    type: keyof ShowPopupType
}

export const ChangePassword = ({id, type} : PropsType) => {

    const {changePassword} = useThunks(AuthAPI)

    const {setIsShowPopupAction} = useActions(showPopupActions)
    const {errorChangePasswordAuth} = useAppState(UserState)
    const {setErrorChangePasswordAction} = useActions(UserActions)

    const [isFetch, setIsFetch] = useState(false)
    const [errorField, setErrorField] = useState({
        newPassword: false,
        repeatPassword: false,
        text: '',
    })

    const [lastPassword, setLastPassword] = useState(``)
    const [newPassword, setNewPassword] = useState(``)
    const [repeatPassword, setRepeatPassword] = useState(``)

    useEffect(()=>{
        return ()=>{
            clearErrorHandler()
            setLastPassword('')
            setNewPassword('')
            setRepeatPassword('')
        }
    },[])
    useEffect(()=>{
        if (!!errorChangePasswordAuth) {
            setIsFetch(false)
            setErrorField(prevState => ({...prevState, text: errorChangePasswordAuth}))
        }
    },[errorChangePasswordAuth])

    const changePasswordHandler = (e:any) => {
        e.preventDefault()
        if (newPassword.length < 6 || newPassword.length > 32)
            return setErrorField(prev => ({...prev, newPassword: true, text: 'Пароль должен содержать от 6 до 32 символов'}))
        if (repeatPassword.length < 6 || repeatPassword.length > 32)
            return setErrorField(prev => ({...prev, repeatPassword: true, text: 'Пароль должен содержать от 6 до 32 символов'}))
        if (newPassword !== repeatPassword)
            return setErrorField(prev => ({...prev, repeatPassword: true, text: 'Пароли не совпадают'}))

        changePassword(newPassword)
    }

    const clearErrorHandler = () => {
        setErrorChangePasswordAction(null)
        setErrorField({newPassword: false, repeatPassword: false, text: '',})
    }

    const onCloseHandler = () => {
        setIsShowPopupAction({type: "changePassword", isShow: false})
    }
    return (
        <div id={id} className={css(s.ChangePassword)}>
            <div className={css(s.title)}>
                Изменение пароля
            </div>
            <div className={css(s.errorField)}>{errorField.text}</div>
            <form className={css(s.form)} onSubmit={changePasswordHandler}>
                <div className={css(s.field)}>
                    <input type="password" placeholder={`Ваш старый пароль*`} value={lastPassword} onChange={e=>setLastPassword(e.target.value)}/>
                </div>
                <div className={css(s.field, errorField.newPassword && s.error)}>
                    <input type="password" placeholder={`Новый пароль*`} value={newPassword} onChange={e=>setNewPassword(e.target.value)}/>
                </div>
                <div className={css(s.field, errorField.repeatPassword && s.error)}>
                    <input type="password" placeholder={`Повторите пароль*`} value={repeatPassword} onChange={e=>setRepeatPassword(e.target.value)}/>
                </div>
                <button>
                    Сохранить изменения
                </button>
            </form>
            <CloseBtn onClose={onCloseHandler}/>
        </div>
    )
}
