import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {useNavigate} from "react-router-dom";
import {css} from "../../../../../../helpers/css";
import loadingGif from '../../../../../assets/images/LoadingGif.gif'
import {customFetch} from "../../../../../../helpers/customFetch";
import {user_server} from "../../../../../../helpers/config";
import {useActions} from "../../../../../../helpers/useActions";
import {TaskActions} from "../../../../../../store/reducers/task";
import {useAppState} from "../../../../../../store/reduxStore";
import {UserState} from "../../../../../../store/reducers/auth";

export const TaskItem = ({item}:any) => {
    const {user} = useAppState(UserState)
    const navigate = useNavigate();

    const {setActiveTaskAction} = useActions(TaskActions)

    const [name, setName] = useState<string | null>(null)

    useEffect(()=>{
        customFetch(`${user_server}/api/profile/getName/${item.ownerId}`,{})
            .then(data => data.json())
            .then(name => setName(name))
            .catch(err => setName('недоступно'))
    },[])

    const setActiveExecutorHandler = async () => {
        setActiveTaskAction(item)
        navigate(`/task-list/${item.id}`)
    }
    return (
        <div className={css(s.TaskItem)} onClick={setActiveExecutorHandler}>
            <div className={css(s.main)}>
                <div className={css(s.createDate)}>
                    {`${new Date(item.createDate).toLocaleDateString()}, ${new Date(item.createDate).toLocaleTimeString().slice(0, 5)}`}
                </div>
                <div className={css(s.doMake)}>
                    {!!item.data.doMake.trim().length ? item.data.doMake : `Не заполнено`}
                </div>
                <div className={css(s.description)}>
                    {!!item.data.description.trim().length ? item.data.description : `Не заполнено`}
                </div>
                <div className={css(s.borderLine)}/>
                <div className={css(s.price)}>
                    <div className={css(s.icon)}></div>
                    <div className={css(s.priceMain)}>
                        <div className={css(s.priceTitle)}>
                            {!item.data.minPrice && `${user.id === item.ownerId ? item.data.price : Math.floor(Number(item.data.price / 100 * (100 - item.percentTask)))} руб.`}
                            {item.data.minPrice && `Договорная цена`}
                        </div>
                    </div>
                </div>
                <div className={css(s.deadline)}>
                    <div className={css(s.icon)}></div>
                    <div className={css(s.deadlineMain)}>
                        <div className={css(s.deadlineTitle)}>
                            Выполнить до:
                        </div>
                        <div className={css(s.deadlineValue)}>
                            {!item.data.infinityDeadline && `${new Date(item.data.deadline).toLocaleDateString()}`}
                            {item.data.infinityDeadline && `Открытая дата`}
                        </div>
                    </div>
                </div>

            </div>
            <div className={css(s.profile)}>
                <div className={css(s.name)}>
                    {name === null && <img src={loadingGif} alt="loading"/>}
                    {name !== null && name}
                </div>
                <div className={css(s.rating)}>

                </div>
            </div>
        </div>
    )
}