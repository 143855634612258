import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export const Arbitrage = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if (location.pathname === `/arbitrage`)
            navigate('/arbitrage/list')
    },[location])

    return (
        <div className={css(s.Arbitrage)}>
            <Outlet />
        </div>
    )
}
