import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";

export const CloseBtn = ({onClose}:any) => {

    const onCloseHandler = () => {
        onClose && onClose()
    }
    return (
        <div className={css(s.CloseBtn)} onClick={onCloseHandler}/>
    )
}
