import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import loadingGif from '../../assets/images/LoadingGif.gif'

export const LoadingPage = () => {

    return (
        <div className={css(s.LoadingPage)}>
            <div className={css(s.main)}>
                <div className={css(s.text)}>Загрузка...</div>
                <div className={css(s.loader)}>
                    <img src={loadingGif} alt="loading"/>
                </div>
            </div>
        </div>
    )
}
