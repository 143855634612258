import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {IProfile} from "../../../../../../types/profile";

export const WorkEmail = ({profile}:{profile: IProfile}) => {

    return (
        <div className={css(s.WorkEmail)}>
            <div className={css(s.iconBox)}>
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.545455 0.314941H11.4545C11.7558 0.314941 12 0.55916 12 0.860396V8.8604C12 9.16163 11.7558 9.40585 11.4545 9.40585H0.545455C0.244219 9.40585 0 9.16163 0 8.8604V0.860396C0 0.55916 0.244219 0.314941 0.545455 0.314941ZM10.9091 2.0549L7.74167 4.80007L10.9091 7.63901V2.0549ZM6 4.86585L9.99229 1.40585H2.00771L6 4.86585ZM6.91065 5.52021L6.35724 5.99985C6.25473 6.08869 6.12738 6.13312 6 6.13312C5.87262 6.13312 5.74527 6.08869 5.64276 5.99985L5.08931 5.52021L1.97124 8.31494H10.0288L6.91065 5.52021ZM4.25836 4.80007L1.09091 2.05494V7.63905L4.25836 4.80007Z" fill="white" stroke="#475178" strokeWidth="0.5"/>
                </svg>
            </div>
            <div className={css(s.main)}>
                <div className={css(s.title)}>Рабочий E-mail:</div>
                {!profile.private.workEmail && <div className={css(s.emptyText)}>Скрыто пользователем</div>}
                {profile.private.workEmail && !profile.workEmail.length && <div className={css(s.emptyWorkEmailList)}>Нет добавленного e-mail</div>}
                {profile.private.workEmail && <div>{profile.workEmail}</div>}
            </div>
        </div>
    )
}
