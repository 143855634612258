import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import contactsFrame from "../../../assets/images/contactsFrame.png";
import {useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import loadingGif from '../../../assets/images/LoadingGif.gif'

export const ContactsPage = () => {

    const {sendAppeal} = useThunks(AppAPI)

    const [data, setData] = useState({
        topic: ``,
        description: ``,
        email: ``,
    })
    const [sendingLoad, setSendingLoad] = useState(false)

    const inputTopicHandler = (e: any) => {
        setData(prev => ({...prev, topic: e.target.value}))
    }
    const inputDescriptionHandler = (e: any) => {
        setData(prev => ({...prev, description: e.target.value}))
    }
    const inputEmailHandler = (e: any) => {
        setData(prev => ({...prev, email: e.target.value}))
    }

    const sendHandler = (e: any) => {
        e.preventDefault()
        setSendingLoad(true)
        sendAppeal(data.topic, data.description, data.email,
            () => {
                setData({
                    topic: ``,
                    description: ``,
                    email: ``,
                })
                setSendingLoad(false)
            },
            () => {
                setSendingLoad(false)
            })
    }

    return (
        <div className={css(s.ContactsPage)}>
            <div className={css(s.container)}>
                <div className={css(s.leftBlock)}>
                    <div className={css(s.title)}>
                        Контакты
                    </div>
                    <div className={css(s.address)}>
                        <div className={css(s.subTitle)}>Адрес:</div>
                        <div className={css(s.addressText)}>г. Санкт-Петербург, вн.тер.г. муниципальный округ Финляндский округ, ул. Минеральная, д. 13, литера Ч, офис 302</div>
                    </div>
                    <div className={css(s.map)}>
                        <iframe
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3A3cfa413315af69c7df3b542c633ec0990bd506b6c91c913ebdc986a229d7945a&amp;source=constructor"
                            width="100%" height="300" frameBorder="0"></iframe>
                    </div>
                    <div className={css(s.contacts)}>
                        <div className={css(s.phone)}>
                            <div className={css(s.phoneTitle)}>Телефон:</div>
                            <div className={css(s.phoneText)}>
                                <a href="tel::+79119593246">+7 (911) 959-32-46</a>
                            </div>
                        </div>
                        <div className={css(s.email)}>
                            <div className={css(s.emailTitle)}>Почта:</div>
                            <div className={css(s.emailText)}>
                                <a href="mailto::info@zemsite.ru">info@zemsite.ru</a>
                            </div>
                        </div>
                    </div>
                    <div className={css(s.companyData)}>
                        <div className={css(s.data)}>
                            <div className={css(s.inn)}>
                                <div className={css(s.dataTitle)}>ИНН:</div>
                                <div className={css(s.dataValue)}>7804681585</div>
                            </div>
                            <div className={css(s.ogrn)}>
                                <div className={css(s.dataTitle)}>ОГРН:</div>
                                <div className={css(s.dataValue)}>1217800051578</div>
                            </div>
                            <div className={css(s.kpp)}>
                                <div className={css(s.dataTitle)}>КПП:</div>
                                <div className={css(s.dataValue)}>780401001</div>
                            </div>
                            <div className={css(s.okpo)}>
                                <div className={css(s.dataTitle)}>ОКПО:</div>
                                <div className={css(s.dataValue)}>1217800051578</div>
                            </div>
                        </div>
                        <div className={css(s.bank)}>
                            <div className={css(s.check)}>
                                <div className={css(s.bankTitle)}>Расчетный счет:</div>
                                <div className={css(s.bankValue)}>7804681585</div>
                            </div>
                            <div className={css(s.bankName)}>
                                <div className={css(s.bankTitle)}>Банк:</div>
                                <div className={css(s.bankValue)}>СТ-ПЕТЕРБУРГСКИЙ Ф-Л ПАО "ПРОМСВЯЗЬБАНК"</div>
                            </div>
                            <div className={css(s.bik)}>
                                <div className={css(s.bankTitle)}>БИК:</div>
                                <div className={css(s.bankValue)}>044030920</div>
                            </div>
                            <div className={css(s.corpCheck)}>
                                <div className={css(s.bankTitle)}>Корр. счет:</div>
                                <div className={css(s.bankValue)}>30101810000000000920</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css(s.rightBlock)}>
                    <div className={css(s.firstBlock)}>
                        <div className={css(s.firstBlockMain)}>
                            <div className={css(s.firstBlockMainTitle)}>Нужна помощь?</div>
                            <div className={css(s.firstBlockMainSubTitle)}>Мы на связи 24/7 и готовы ответить на все ваши
                                вопросы. Для этого оставьте заявку в форме ниже
                            </div>
                            <form className={css(s.firstBlockMainForm)} onSubmit={sendHandler}>
                                <input type="text" placeholder={`Тема обращения*`} value={data.topic}
                                       onChange={inputTopicHandler}/>
                                <textarea placeholder={`Описание`} value={data.description}
                                          onChange={inputDescriptionHandler}/>
                                <input type="text" placeholder={`E-mail*`} value={data.email} onChange={inputEmailHandler}/>
                                <button>{
                                    !sendingLoad
                                        ? `Отправить форму`
                                        : <img src={loadingGif} alt="loading"/>
                                } </button>
                            </form>
                        </div>
                        <div className={css(s.firstBlockImg)}>
                            <div className={css(s.imgBox)}>
                                <img src={contactsFrame} alt="contactsFrame"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
