import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

export const ToggleBtn = ({checked, toggle}:{checked: boolean, toggle: Function}) => {

    return (
        <div className={css(s.ToggleBtn)} onClick={()=>toggle()}>
            <div className={css(s.toggle, !checked && s.checked)}/>
            <div className={css(s.text)}>
                Показать
            </div>
        </div>
    )
}
