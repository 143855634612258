import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import {IsLoadingState} from "../../../store/reducers/isLoading";
import {useAppState} from "../../../store/reduxStore";
import loadingGif from '../../assets/images/LoadingGif.gif'

export const LoadingApp = () => {

    const {startApp} = useAppState(IsLoadingState)

    return (
       <div className={css(s.LoadingApp, startApp && s.active)}>
           <div className={css(s.main)}>
               <div className={css(s.text)}>Загрузка...</div>
               <div className={css(s.loader)}>
                   <img src={loadingGif} alt="loading"/>
               </div>
           </div>
       </div>
    )
}
