import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../../../helpers/css";
import {customFetch, customFetchJson} from "../../../../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../../../../helpers/config";
import {IResponse} from "../../../../../../../../types/api";
import {SvgMyProfile} from "../../../../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../../../../assets/images/LoadingGif.gif";
import {useNavigate} from "react-router-dom";
import {useActions, useThunks} from "../../../../../../../../helpers/useActions";
import {MessengerActions} from "../../../../../../../../store/reducers/messenger";
import {TaskAPI} from "../../../../../../../../API/TaskAPI";
import {useAppState} from "../../../../../../../../store/reduxStore";
import {IsLoadingState} from "../../../../../../../../store/reducers/isLoading";

export const OfferItem = ({taskId, offer, minPrice, infinityDeadline}: any) => {
    const navigate = useNavigate();

    const {setCompanionIdAction} = useActions(MessengerActions)
    const {selectOffer, deselectOffer} = useThunks(TaskAPI)
    const [load, setLoad] = useState({selectBtn: false, deselectBtn: false})
    const {offerItemBtn: loadOfferItemBtn} = useAppState(IsLoadingState)

    const [respondProfile, setRespondProfile] = useState<any>({
        loading: true,
        user: null,
        src: null
    })

    useEffect(() => {
        (async () => {
            try {
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${offer.ownerRespondId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                    return
                }

                let image: any = null
                if (!!response.payload.avatarLink.length) {
                    const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })

                    const blobData = await src.blob()
                    const urlCreator = window.URL || window.webkitURL;
                    image = urlCreator.createObjectURL(blobData);
                }

                setRespondProfile((prev: any) => ({
                    ...prev,
                    user: {
                        id: response.payload.ownerId,
                        name: response.payload.name,
                        place: response.payload.place,
                    },
                    src: image,
                    loading: false
                }))
            } catch (error) {
                setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                console.log('error client', error)
            } finally {
            }
        })()
    }, [])

    useEffect(() => {
        return () => {
            setRespondProfile({
                loading: true,
                user: null,
                src: null
            })
        }
    }, [])
    useEffect(() => {
        setLoad({selectBtn: false, deselectBtn: false})
    }, [offer])
    useEffect(() => {
        if (!loadOfferItemBtn)
            setLoad({selectBtn: false, deselectBtn: false})
    }, [loadOfferItemBtn])

    const sendCompanionMessageHandler = async () => {
        if (!respondProfile.user) return
        await setCompanionIdAction(respondProfile.user.id)
        navigate(`/chat-list`)
    }

    const selectOfferHandler = () => {
        if (!respondProfile.user) return
        if (loadOfferItemBtn) return
        setLoad(prev => ({...prev, selectBtn: true}))
        selectOffer(taskId, offer.id)
    }
    const deselectOfferHandler = () => {
        if (!respondProfile.user) return
        if (loadOfferItemBtn) return
        setLoad(prev => ({...prev, deselectBtn: true}))
        deselectOffer(taskId, offer.id)
    }

    return (
        <div className={css(s.OfferItem)}>
            <div className={css(s.respondProfile)}>
                <div className={css(s.avatar)}>
                    {respondProfile.src !== null && !respondProfile.loading &&
                        <img src={respondProfile.src} alt="png"/>}
                    {respondProfile.src === null && !respondProfile.loading && <SvgMyProfile/>}
                    {respondProfile.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                </div>
                <div className={css(s.profileMain, respondProfile.loading && s.fetch)}>
                    {!!respondProfile?.user?.name && <div className={css(s.name)}>{respondProfile.user.name}</div>}
                    {!!respondProfile?.user?.place && <div className={css(s.place)}>{`${respondProfile.user.place.region}${!!respondProfile.user.place.region.trim().length && !!respondProfile.user.place.region.trim().length ? ', ':''}${respondProfile.user.place.city}`}</div>}
                    {/*<div className={css(s.rating)}></div>*/}
                </div>
            </div>
            <div className={css(s.respondMain)}>
                {!offer.answer.trim().length ? `Комментарий отсутствует` : `Комментарий: ` + offer.answer}
            </div>
            {<div className={css(s.respondPrice)}>{`${minPrice ? 'Предложенная цена: ' : 'Цена задачи: '}`} <span>{offer.price} руб.</span></div>}
            {<div className={css(s.respondDeadline)}>Исполню до: <span>{new Date(offer.deadline).toLocaleDateString()}</span></div>}
            <div className={css(s.respondBtnBox, respondProfile.loading && s.fetch)}>
                <div className={css(s.sendMessageBtn)} onClick={sendCompanionMessageHandler}>Обсудить детали</div>
                {offer.status === 0 &&
                    <div className={css(s.selectBtn)} onClick={selectOfferHandler}>
                        {load.selectBtn ? <img src={loadingGif} alt="loading"/> : <span>Выбрать исполнителем</span>}
                    </div>}
                {offer.status === 1 &&
                    <div className={css(s.deselectBtn)} onClick={deselectOfferHandler}>
                        {load.deselectBtn ? <img src={loadingGif} alt="loading"/> : <span>Отменить выбор</span>}
                    </div>}
            </div>
        </div>
    )
}
