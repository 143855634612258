import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../../helpers/css";
import {AnswerTextField} from "../../../../../AnswerTextField";
import {StandardButton} from "../../../../../Button/StandardButton";
import {DatePicker} from "../../../../../DatePicker";
import {useClickOutside} from "../../../../../../../helpers/useClickOutside";
import {PriceInputField} from "../../../../../PriceInputField";
import {useAppState} from "../../../../../../../store/reduxStore";
import {TaskState} from "../../../../../../../store/reducers/task";
import {useActions, useThunks} from "../../../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../../../API/TaskAPI";
import loadGif from '../../../../../../assets/images/LoadingGif.gif'
import {HintActions} from "../../../../../../../store/reducers/hint";

const endDateTime = (dateTime: number) => {
    let newDate = new Date(dateTime)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    return newDate.getTime() + 8.64e+7
}

export const Respond = ({percentTask, taskId, minPrice,infinityDeadline, price, deadline}:any) => {

    const {myRespond} = useAppState(TaskState)
    const {getMyRespond,respondOffer,deleteRespond,takeWorkTask} = useThunks(TaskAPI)
    const {setHintApp} = useActions(HintActions)

    const [sendRespondLoading, setSendRespondLoading] = useState(false)
    const [deleteRespondLoading, setDeleteRespondLoading] = useState(false)
    useEffect(()=>{
        setSendRespondLoading(false)
        setDeleteRespondLoading(false)
    },[myRespond])

    useEffect(()=>{
        getMyRespond(taskId)
    },[])

    const [offerPrice,setOfferPrice] = useState<number>(0)
    const [offerDeadline,setOfferDeadline] = useState<number | null>(null)
    const [answer,setAnswer] = useState('')
    const [change, setChange] = useState(false)

    // datapiker
    const [showDatePicker, setShowDatePicker] = useState(false)
    const showDatePickerHandler = () => {
        setShowDatePicker(prev => !prev)
    }
    useClickOutside(() => {
        setShowDatePicker(false)
    }, [`pickerBox`, `offerDeadlineTime`])
    const setNewDateHandler = (deadlineTime: number) => {
        if (new Date(deadlineTime).getTime() < endDateTime(Date.now()) + 8.64e+7) {
            setOfferDeadline(endDateTime(Date.now()) + 8.64e+7)
            return
        } else {
            setOfferDeadline(deadlineTime)
        }
        setShowDatePicker(false)
    }

    // price
    const [showPrice, setShowPrice] = useState(false)
    const changePriceHandler = (valueHtml: string) => {
        const pattern = /^\d+(\.?)\d*$/g;
        if (!valueHtml.match(pattern)) {
            valueHtml = valueHtml.slice(0, -1)
        }
        setOfferPrice(Math.abs(Number(valueHtml)))
    }
    const showPriceHandler = () => {
        setShowPrice(true)
    }
    const onBlurHandler = () => {
        if (!minPrice) return
        setOfferPrice(Math.abs(Number(offerPrice)))
        setShowPrice(false)
    }

    // send respond
    const sendRespondHandler = () => {
        if (infinityDeadline && offerDeadline === null) {
            setHintApp({
                isShow: true,
                text: `Укажите скрок выполнения`,
                type: `warning`
            })
            return;
        }
        if (minPrice && offerPrice < Number( 100 / 100 * (100 - percentTask)) ) {
            setHintApp({
                isShow: true,
                text: `Минимальная стоимость работ ${Number( 100 / 100 * (100 - percentTask))} руб.`,
                type: `warning`
            })
            return
        }
        if (minPrice && offerPrice > Number( 1000000 / 100 * (100 - percentTask))) {
            setHintApp({
                isShow: true,
                text: `Максимальная стоимость работ ${Number( 1000000 / 100 * (100 - percentTask))} руб.`,
                type: `warning`
            })
            return
        }
        setSendRespondLoading(true)
        respondOffer(
            answer,
            infinityDeadline ? offerDeadline : deadline,
            minPrice ? Math.ceil(Number( +offerPrice / (100 - percentTask)) * percentTask  + offerPrice) : +price,
            taskId ,
        )
    }

    const deleteRespondHandler = () => {
        if (!myRespond) return
        setDeleteRespondLoading(true)
        deleteRespond(myRespond[0].id)
        setAnswer(``)
    }
    const agreeWorkHandler = () => {
        takeWorkTask(taskId)
    }

    return (
        <div className={css(s.Respond, deleteRespondLoading && s.disable)}>
            <div className={css(s.title)}>
                Ваш отклик
                {myRespond === null && <img src={loadGif} alt="load"/>}
                {myRespond !== null && !!myRespond.length && <div className={css(s.deleteRespond)}>
                    { deleteRespondLoading
                        ? <img src={loadGif} alt="load"/>
                        : <span onClick={deleteRespondHandler}>Удалить</span>
                    }
                </div>}
            </div>
            {myRespond !== null && myRespond.length === 0 &&
                <div>
                    <div className={css(s.answer)} onClick={()=>setChange(true)}>
                        <AnswerTextField onBlur={()=>setChange(false)} contentEditable={change} maxLength={5000} text={answer} setText={(value:any)=>setAnswer(value)}/>
                        {!change && <div className={css(s.maxLength)}>{answer.length}/5000</div>}
                    </div>
                    <div className={css(s.offerData)}>
                        <div className={css(s.offerDeadline)}>
                            <div className={css(s.offerDeadlineTitle)}>Срок выполнения до:</div>
                            <div id={`offerDeadlineTime`} className={css(s.offerDeadlineMain, !infinityDeadline && s.disable)} onClick={showDatePickerHandler}>
                                {infinityDeadline && <span>{offerDeadline !== null ? `До: ${new Date(+offerDeadline).toLocaleDateString()}`: 'Укажите срок'}</span>}
                                {!infinityDeadline && <span>{`До: ${new Date(+deadline).toLocaleDateString()}`}</span>}
                                <div className={css(s.icon)}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.6032 14.6986H15.821V12.9163C15.821 12.5883 15.555 12.3223 15.2269 12.3223C14.8988 12.3223 14.6328 12.5883 14.6328 12.9163V15.2926C14.6328 15.6207 14.8988 15.8867 15.2269 15.8867H17.6032C17.9313 15.8867 18.1972 15.6207 18.1972 15.2926C18.1972 14.9645 17.9313 14.6986 17.6032 14.6986Z"
                                              fill="#212B36"/>
                                        <path d="M18.1963 10.8489V3.33169C18.1963 2.34897 17.3968 1.54947 16.4141 1.54947H15.2259V0.9554C15.2259 0.627314 14.9599 0.361328 14.6319 0.361328C14.3038 0.361328 14.0378 0.627314 14.0378 0.9554V1.54947H11.6615V0.9554C11.6615 0.627314 11.3955 0.361328 11.0674 0.361328C10.7393 0.361328 10.4734 0.627314 10.4734 0.9554V1.54947H8.01786V0.9554C8.01786 0.627314 7.75187 0.361328 7.42379 0.361328C7.0957 0.361328 6.82971 0.627314 6.82971 0.9554V1.54947H4.45343V0.9554C4.45343 0.627314 4.18744 0.361328 3.85935 0.361328C3.53127 0.361328 3.26528 0.627314 3.26528 0.9554V1.54947H2.07714C1.09442 1.54947 0.294922 2.34897 0.294922 3.33169V17.6686C0.294922 17.9967 0.560908 18.2627 0.888994 18.2627H10.7825C11.7428 19.6946 13.3761 20.639 15.2259 20.639C18.1741 20.639 20.5726 18.2405 20.5726 15.2923C20.5726 13.4425 19.6282 11.8092 18.1963 10.8489ZM1.48307 3.33169C1.48307 3.00412 1.74957 2.73762 2.07714 2.73762H3.26528V3.33169C3.26528 3.65977 3.53127 3.92576 3.85935 3.92576C4.18744 3.92576 4.45343 3.65977 4.45343 3.33169V2.73762H6.82971V3.33169C6.82971 3.65977 7.0957 3.92576 7.42379 3.92576C7.75187 3.92576 8.01786 3.65977 8.01786 3.33169V2.73762H10.4734V3.33169C10.4734 3.65977 10.7393 3.92576 11.0674 3.92576C11.3955 3.92576 11.6615 3.65977 11.6615 3.33169V2.73762H14.0378V3.33169C14.0378 3.65977 14.3038 3.92576 14.6319 3.92576C14.9599 3.92576 15.2259 3.65977 15.2259 3.33169V2.73762H16.4141C16.7416 2.73762 17.0081 3.00412 17.0081 3.33169V5.19311H1.48307V3.33169ZM10.1851 17.0746H1.48307V6.38126H17.0081V10.2515C16.4504 10.0537 15.8506 9.94569 15.2259 9.94569C12.2778 9.94569 9.87928 12.3442 9.87928 15.2923C9.87928 15.917 9.98733 16.5168 10.1851 17.0746ZM15.2259 19.4508C12.9329 19.4508 11.0674 17.5853 11.0674 15.2923C11.0674 12.9993 12.9329 11.1338 15.2259 11.1338C17.5189 11.1338 19.3844 12.9993 19.3844 15.2923C19.3844 17.5853 17.5189 19.4508 15.2259 19.4508Z"
                                              fill="#212B36"/>
                                        <defs>
                                            <rect width="20.2777" height="20.2777" fill="white" transform="translate(0.294922 0.361328)"/>
                                        </defs>
                                    </svg>
                                </div>
                                {infinityDeadline && <div className={css(s.pickerBox, showDatePicker && s.show)} onClick={e => e.stopPropagation()}>
                                    <DatePicker setNewDate={setNewDateHandler} deadline={offerDeadline === null ? Date.now() : +offerDeadline}/>
                                </div>}
                            </div>
                        </div>
                        <div className={css(s.offerPrice)}>
                            <div className={css(s.offerPriceTitle)}>Стоимость работ:</div>
                            <div className={css(s.offerPriceMain, !minPrice && s.disable)} onClick={showPriceHandler} onBlur={onBlurHandler}>
                                {minPrice && showPrice && <PriceInputField contentEditable={true} notBorder={true} maxLength={16} subText={` руб.`} text={`${offerPrice}`} setText={changePriceHandler}/>}
                                {minPrice && !showPrice && <span>{!!offerPrice ? `${offerPrice} руб.`: 'Укажите сумму'}</span>}
                                {!minPrice && <span>{`${Math.floor(Number( price / 100 * (100 - percentTask)))} руб.`}</span>}
                                <div className={css(s.icon)}>
                                    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.42773 14.3536H3.92773V11.9786H8.42773V10.3953H3.92773V8.81193H7.30273C9.37048 8.81193 11.0527 7.03622 11.0527 4.8536C11.0527 2.67097 9.37048 0.895264 7.30273 0.895264H3.17773C2.97882 0.895264 2.78806 0.978674 2.6474 1.12713C2.50675 1.2756 2.42773 1.47696 2.42773 1.68693V7.2286H0.177734V8.81193H2.42773V10.3953H0.177734V11.9786H2.42773V14.3536ZM3.92773 2.4786H7.30273C8.54323 2.4786 9.55273 3.54418 9.55273 4.8536C9.55273 6.16301 8.54323 7.2286 7.30273 7.2286H3.92773V2.4786Z" fill="#212B36"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css(s.btnBox)}>
                        <StandardButton text={"Откликнуться"} fetching={sendRespondLoading} onClick={sendRespondHandler}/>
                    </div>
                </div>
            }
            {myRespond !== null && myRespond.length > 0 &&
                <div className={css(s.myAnswerRespond)}>
                    <div className={css(s.myAnswer)} >
                        <div className={css(s.text)}>{!!myRespond[0].answer.trim().length ? myRespond[0].answer : 'Комментарий отсутствует'}</div>
                    </div>
                    <div className={css(s.myOfferData)}>
                        <div className={css(s.offerDeadline)}>
                            <div className={css(s.offerDeadlineTitle)}>Срок выполнения до:</div>
                            <div id={`offerDeadlineTime`} className={css(s.offerDeadlineMain, s.disable)}>
                                <span>{`До: ${new Date(+myRespond[0].deadline).toLocaleDateString()}`}</span>
                                <div className={css(s.icon)}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.6032 14.6986H15.821V12.9163C15.821 12.5883 15.555 12.3223 15.2269 12.3223C14.8988 12.3223 14.6328 12.5883 14.6328 12.9163V15.2926C14.6328 15.6207 14.8988 15.8867 15.2269 15.8867H17.6032C17.9313 15.8867 18.1972 15.6207 18.1972 15.2926C18.1972 14.9645 17.9313 14.6986 17.6032 14.6986Z"
                                              fill="#212B36"/>
                                        <path d="M18.1963 10.8489V3.33169C18.1963 2.34897 17.3968 1.54947 16.4141 1.54947H15.2259V0.9554C15.2259 0.627314 14.9599 0.361328 14.6319 0.361328C14.3038 0.361328 14.0378 0.627314 14.0378 0.9554V1.54947H11.6615V0.9554C11.6615 0.627314 11.3955 0.361328 11.0674 0.361328C10.7393 0.361328 10.4734 0.627314 10.4734 0.9554V1.54947H8.01786V0.9554C8.01786 0.627314 7.75187 0.361328 7.42379 0.361328C7.0957 0.361328 6.82971 0.627314 6.82971 0.9554V1.54947H4.45343V0.9554C4.45343 0.627314 4.18744 0.361328 3.85935 0.361328C3.53127 0.361328 3.26528 0.627314 3.26528 0.9554V1.54947H2.07714C1.09442 1.54947 0.294922 2.34897 0.294922 3.33169V17.6686C0.294922 17.9967 0.560908 18.2627 0.888994 18.2627H10.7825C11.7428 19.6946 13.3761 20.639 15.2259 20.639C18.1741 20.639 20.5726 18.2405 20.5726 15.2923C20.5726 13.4425 19.6282 11.8092 18.1963 10.8489ZM1.48307 3.33169C1.48307 3.00412 1.74957 2.73762 2.07714 2.73762H3.26528V3.33169C3.26528 3.65977 3.53127 3.92576 3.85935 3.92576C4.18744 3.92576 4.45343 3.65977 4.45343 3.33169V2.73762H6.82971V3.33169C6.82971 3.65977 7.0957 3.92576 7.42379 3.92576C7.75187 3.92576 8.01786 3.65977 8.01786 3.33169V2.73762H10.4734V3.33169C10.4734 3.65977 10.7393 3.92576 11.0674 3.92576C11.3955 3.92576 11.6615 3.65977 11.6615 3.33169V2.73762H14.0378V3.33169C14.0378 3.65977 14.3038 3.92576 14.6319 3.92576C14.9599 3.92576 15.2259 3.65977 15.2259 3.33169V2.73762H16.4141C16.7416 2.73762 17.0081 3.00412 17.0081 3.33169V5.19311H1.48307V3.33169ZM10.1851 17.0746H1.48307V6.38126H17.0081V10.2515C16.4504 10.0537 15.8506 9.94569 15.2259 9.94569C12.2778 9.94569 9.87928 12.3442 9.87928 15.2923C9.87928 15.917 9.98733 16.5168 10.1851 17.0746ZM15.2259 19.4508C12.9329 19.4508 11.0674 17.5853 11.0674 15.2923C11.0674 12.9993 12.9329 11.1338 15.2259 11.1338C17.5189 11.1338 19.3844 12.9993 19.3844 15.2923C19.3844 17.5853 17.5189 19.4508 15.2259 19.4508Z"
                                              fill="#212B36"/>
                                        <defs>
                                            <rect width="20.2777" height="20.2777" fill="white" transform="translate(0.294922 0.361328)"/>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className={css(s.offerPrice)}>
                            <div className={css(s.offerPriceTitle)}>Стоимость работ:</div>
                            <div className={css(s.offerPriceMain, s.disable)}>
                                <span>{`${Math.floor(Number( myRespond[0].price / 100 * (100 - percentTask)))} руб.`}</span>
                                <div className={css(s.icon)}>
                                    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.42773 14.3536H3.92773V11.9786H8.42773V10.3953H3.92773V8.81193H7.30273C9.37048 8.81193 11.0527 7.03622 11.0527 4.8536C11.0527 2.67097 9.37048 0.895264 7.30273 0.895264H3.17773C2.97882 0.895264 2.78806 0.978674 2.6474 1.12713C2.50675 1.2756 2.42773 1.47696 2.42773 1.68693V7.2286H0.177734V8.81193H2.42773V10.3953H0.177734V11.9786H2.42773V14.3536ZM3.92773 2.4786H7.30273C8.54323 2.4786 9.55273 3.54418 9.55273 4.8536C9.55273 6.16301 8.54323 7.2286 7.30273 7.2286H3.92773V2.4786Z" fill="#212B36"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css(s.agreeBtn, myRespond[0].status !== 1 && s.disable)}>
                        <StandardButton text={`Взять в работу`} onClick={agreeWorkHandler}/>
                        {/*<div className={css(s.agreeBtn, true && s.disable)}>Взять в работу</div>*/}
                    </div>
                </div>
            }
        </div>
    )
}
