import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {IProfile} from "../../../../../../types/profile";
import {useAppState} from "../../../../../../store/reduxStore";
import {UserState} from "../../../../../../store/reducers/auth";
import {useParams} from "react-router-dom";
import loadingGif from "../../../../../assets/images/LoadingGif.gif";
import {customFetch} from "../../../../../../helpers/customFetch";
import {task_server} from "../../../../../../helpers/config";

export const VerifyMessageBlock = ({profile, sendCompanionMessageHandler}:{profile:IProfile, sendCompanionMessageHandler:Function}) => {
    const {user} = useAppState(UserState)
    const params = useParams()
    const sendMessageHandler = () => {
        sendCompanionMessageHandler()
    }

    // count active task
    const [countActiveTask,setCountActiveTask] = useState<number | null>(null)
    useEffect(()=>{
        customFetch(`${task_server}/api/task/getWorkerActiveTask?workerId=${profile.ownerId}`, {
            method: 'GET',
        })
            .then(data => data.json())
            .then(data => {
                setCountActiveTask(data)
            })
            .catch((err) => {
                setCountActiveTask(0)
            })
        return ()=>{
            setCountActiveTask(null)
        }
    },[])

    // успешно выполненых задач
    const [accessWorkedTask,setAccessWorkedTask] = useState<number | null>(null)
    useEffect(()=>{
        customFetch(`${task_server}/api/task/accessWorkedTask?workerId=${profile.ownerId}`, {
            method: 'GET',
        })
            .then(data => data.json())
            .then(data => {
                setAccessWorkedTask(data)
            })
            .catch((err) => {
                setAccessWorkedTask(0)
            })
        return ()=>{
            setAccessWorkedTask(null)
        }
    },[])

    return (
        <div className={css(s.VerifyMessageBlock)}>
            {/*<div className={css(s.rating)}>*/}
            {/*    <div className={css(s.title)}>Рейтинг:</div>*/}
            {/*    <div className={css(s.value)}>?</div>*/}
            {/*</div>*/}
            <div className={css(s.info)}>
                <div className={css(s.taskCount)}>
                    <div className={css(s.title)}>В работе задач:</div>
                    <div className={css(s.value)}>{countActiveTask !== null ? countActiveTask:
                        <img src={loadingGif} alt="loading"/>}</div>
                </div>
                <div className={css(s.successTask)}>
                    <div className={css(s.title)}>Успешно выполненных задач:</div>
                    <div className={css(s.value)}>{accessWorkedTask !== null ? accessWorkedTask:
                        <img src={loadingGif} alt="loading"/>}</div>
                </div>
            </div>
            {Number(params.id) !== Number(user.id) && <div className={css(s.btnBox)}>
                <div className={css(s.sendMessage)} onClick={sendMessageHandler}>Написать сообщение</div>
            </div>}
        </div>
    )
}
