import React from 'react'

export const SvgZipIcon = () => {

    return (
        <svg viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.31484 0.400391C4.67547 0.400391 4.15234 0.923515 4.15234 1.56289V17.8379C4.15234 18.4773 4.67547 19.0004 5.31484 19.0004H16.9398C17.5792 19.0004 18.1023 18.4773 18.1023 17.8379V5.05039L13.4523 0.400391H5.31484Z" fill="#E2E5E7"/>
                <path d="M14.6156 5.05039H18.1031L13.4531 0.400391V3.88789C13.4531 4.52727 13.9762 5.05039 14.6156 5.05039Z" fill="#B0B7BD"/>
                <path d="M18.1027 8.53779L14.6152 5.05029H18.1027V8.53779Z" fill="#CAD1D8"/>
                <path d="M15.7781 15.5129C15.7781 15.8326 15.5166 16.0941 15.1969 16.0941H2.40937C2.08969 16.0941 1.82812 15.8326 1.82812 15.5129V9.70039C1.82812 9.3807 2.08969 9.11914 2.40937 9.11914H15.1969C15.5166 9.11914 15.7781 9.3807 15.7781 9.70039V15.5129Z" fill="#00AB55"/>
                <path d="M5.48296 14.3502C5.19001 14.3502 5.07376 14.0672 5.24116 13.8672L6.89598 11.6689H5.52423C5.12026 11.6689 5.15281 11.0981 5.52423 11.0981H7.3976C7.75042 11.0981 7.85272 11.4318 7.6022 11.7021L6.01713 13.7603H7.5278C7.8998 13.7603 7.9463 14.3508 7.49118 14.3508H5.48296V14.3502Z" fill="white"/>
                <path d="M8.36719 11.4133C8.36719 11.032 8.98099 11.0181 8.98099 11.4133V14.0667C8.98099 14.4521 8.36719 14.462 8.36719 14.0667V11.4133Z" fill="white"/>
                <path d="M9.80469 11.4131C9.80469 11.2597 9.92559 11.0923 10.1209 11.0923H11.1945C11.799 11.0923 12.343 11.4968 12.343 12.2722C12.343 13.0069 11.799 13.4161 11.1945 13.4161H10.4185V14.0299C10.4185 14.2345 10.2883 14.3502 10.1209 14.3502C9.96744 14.3502 9.80469 14.2345 9.80469 14.0299V11.4131ZM10.4179 11.6776V12.8355H11.1939C11.5054 12.8355 11.7519 12.5605 11.7519 12.2722C11.7519 11.9473 11.5054 11.6776 11.1939 11.6776H10.4179Z" fill="white"/>
                <path d="M15.1961 16.0939H4.15234V16.6752H15.1961C15.5158 16.6752 15.7773 16.4136 15.7773 16.0939V15.5127C15.7773 15.8324 15.5158 16.0939 15.1961 16.0939Z" fill="#CAD1D8"/>
            <defs>
                    <rect width="18.6" height="18.6" fill="white" transform="translate(0.666016 0.400391)"/>
            </defs>
        </svg>
    )
}
