import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {SvgSearch} from "../../../../assets/images/svg/SvgSearch";
import {SvgPlace} from "../../../../assets/images/svg/SvgPlace";
import {SvgClose} from "../../../../assets/images/svg/SvgClose";
import {SvgArrowTop} from "../../../../assets/images/svg/SvgArrowTop";
import {ProfileAPI} from "../../../../../API/useProfile";
import {useAppState} from "../../../../../store/reduxStore";
import {ProfileActions, ProfileState} from "../../../../../store/reducers/profile";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import loadingGif from '../../../../assets/images/LoadingGif.gif'
import {ExecutorItem} from "../ExecutorItem";
import {SetPlace} from "../../../SetPlace";
import {HintActions} from "../../../../../store/reducers/hint";
import {cityList, regionList} from "../../../../../helpers/locationList";
import {useSearchParams} from "react-router-dom";
import {useUpdateEffect} from "../../../../../helpers/useUpdateEffect";

export const ExecutorList = () => {
    const {setHintApp} = useActions(HintActions)

    const [params] = useSearchParams()
    const [addLoad, setAddLoad] = useState(false)
    const [showFilter, setShowFilter] = useState(true)
    const {getExecutorList,addExecutorList} = useThunks(ProfileAPI)
    const {executorList, search: searchExecutorList, place, totalExecutorList} = useAppState(ProfileState)
    const {setExecutorListAction, setSearchExecutorListAction, setFilterPlaceExecutorListAction} = useActions(ProfileActions)

    useUpdateEffect(() => {
        getExecutorList()
    }, [searchExecutorList, place])

    useEffect(() => {
        return () => {
            setSearchExecutorListAction('')
            setFilterPlaceExecutorListAction({
                region: ``,
                city: ``
            })
        }
    }, [])
    useEffect(() => {
        setAddLoad(false)
    }, [executorList])

    useEffect(() => {
        const region = params.get("region") ?? ''
        const city = params.get("city") ?? ''
        if (!!region.length) {
            setRegionHandler(region)
            setCityHandler(city)
            setFilterPlaceExecutorListAction({
                region: region,
                city: city
            })
        } else getExecutorList()

        return () => {
            setExecutorListAction(null)
        }
    }, [])

    const addExecutorListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addExecutorList()
    }

    // search
    const [search, setSearch] = useState('')
    const changeSearchHandler = (e:any) => {
        setSearch(e.target.value)
        if (e.target.value === `` && !!searchExecutorList.length) {
            setSearchExecutorListAction(``)
        }
    }
    const searchHandler = (e:any) => {
        e.preventDefault()
        setSearchExecutorListAction(search)
    }

    // местоположение
    const getPlace = async () => {
        // Если функциональность геолокации доступна,
        // пытаемся определить координаты посетителя
        if (navigator.geolocation) {
            // Передаем две функции
            navigator.geolocation.getCurrentPosition(
                async function (position:any) {
                    const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}&zoom=10`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.15673317516059}&lon=${61.30059957504273}&zoom=18`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.668291353993226}&lon=${38.10882568359376}&zoom=18`)

                    const data = await res.json()
                    let responseRegion = data.address.state ? data.address.state : ''
                    let responseCity = data.address.city ? data.address.city : data.address.town ? data.address.town : ''

                    // соответствие региона
                    if (responseRegion === `Москва` || responseRegion === `Московская область`) {
                        responseRegion = regionList[0]
                    } else if (responseRegion === `Санкт-Петербург` || responseRegion === `Ленинградская область`) {
                        responseRegion = regionList[1]
                    } else {
                        const compliance = regionList.find(reg => responseRegion.length && reg.startsWith(responseRegion))
                        responseRegion = !compliance ? '' : compliance
                    }
                    // соответствие города
                    const compliance = cityList.find(c => responseCity.length && c.startsWith(responseCity))
                    responseCity = !compliance ? '' : compliance

                    setHintApp({
                        isShow: true,
                        type: 'location',
                        text: `Регион: ${responseRegion.length ? responseRegion : 'не определенно'}, Город: ${responseCity.length ? responseCity : 'не определенно'}`
                    })
                    setInputFields({
                        region: responseRegion,
                        city: responseCity
                    })
                },
                function (positionError:any) {
                    if(positionError.code === 1) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Не удалось определить местоположение, пожалуйста удостоверьтесь что получение Геоданных не заблокировано.`
                        })
                    }
                    else if(positionError.code === 2) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Проблемы с сетью или нельзя связаться со службой определения местоположения по каким-либо другим причинам.`
                        })
                    }
                    else if(positionError.code === 3) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `He удалось определить местоположение в течение установленного времени.`
                        })
                    }
                },
                {
                    enableHighAccuracy: true, // высокая точность поиска
                    timeout: 10000, // время запроса
                    maximumAge: 60000 // кешировать данные полученные 1 мин назад
                }
            )
            // Выводим результат
            // alert("Поиск начался")
        } else {
            // Выводим результат
            setHintApp({
                isShow: true,
                type: 'warning',
                text: `Ваш браузер не поддерживает геолокацию.`
            })
            return
        }
    }
    const [inputFields,setInputFields] = useState({
        region: ``,
        city: ``
    })
    const setRegionHandler = (region: string) => {
        setInputFields(prev=>({...prev, region: region}))
    }
    const setCityHandler = (city: string) => {
        setInputFields(prev=>({...prev, city: city}))
    }
    const clearInputField = () => {
        setRegionHandler('')
        setCityHandler('')
        setFilterPlaceExecutorListAction({
            region: ``,
            city: ``
        })
    }
    const applyFilterHandler = () => {
        setFilterPlaceExecutorListAction({
            region: inputFields.region,
            city: inputFields.city
        })
    }

    return (
        <div className={css(s.ExecutorList)}>
            <div className={css(s.container)}>
                <div className={css(s.titlePage)}>
                    Каталог исполнителей
                </div>
                <div className={css(s.search)}>
                    <form className={css(s.inputBox)} onSubmit={searchHandler}>
                        <input type="text" value={search} onChange={changeSearchHandler} placeholder={`Поиск по имени`}/>
                        <div className={css(s.searchBtn)} onClick={searchHandler}>
                            <SvgSearch/>
                        </div>
                    </form>
                </div>
                <div className={css(s.main)}>
                    <div className={css(s.filterBlock, showFilter && s.show)} onClick={() => setShowFilter(true)}>
                        <div className={css(s.title)}>
                            <span>фильтр</span>
                            <SvgArrowTop/>
                        </div>
                        <div className={css(s.filterMain)}
                             onClick={e => e.stopPropagation()}>
                            <div className={css(s.getPlaceBtn)}>
                                <div className={css(s.placeTitle)}>По моему местоположению</div>
                                <div className={css(s.placeBtnBox)} onClick={getPlace}>
                                    <div className={css(s.icon)}>
                                        <SvgPlace/>
                                    </div>
                                    <div className={css(s.text)}>
                                        <span>Определить местоположение</span>
                                    </div>
                                </div>
                            </div>
                            <div className={css(s.inputPlaceBox)}>
                                <div className={css(s.inputPlaceTitle)}>Местоположение</div>
                                <SetPlace region={inputFields.region} city={inputFields.city} setRegion={setRegionHandler} setCity={setCityHandler}/>
                            </div>
                        </div>
                        <div className={css(s.sendBtn)} onClick={applyFilterHandler}>
                            Применить фильтр
                        </div>
                        <div className={css(s.clearFilterBtnBox)}>
                            <div className={css(s.clearFilterBtn)} onClick={clearInputField}>
                                <SvgClose/>
                                <div className={css(s.clearFilterText)}>
                                    Сбросить фильтр
                                </div>
                            </div>
                        </div>
                        <div className={css(s.hiddenFilterBtnBox)}>
                            <div className={css(s.clearFilterBtn)} onClick={(e) => {
                                e.stopPropagation()
                                setShowFilter(false)
                            }}>
                                <SvgArrowTop/>
                                <div className={css(s.clearFilterText)}>
                                    Свернуть
                                </div>
                            </div>
                        </div>
                    </div>
                    {!!executorList && <div className={css(s.executorList)}>
                        {executorList?.map(item => <ExecutorItem key={item.ownerId} item={item}/>)}
                        {!!executorList?.length && totalExecutorList !== null && totalExecutorList > 0 && totalExecutorList > executorList.length && <div className={css(s.showMore)}>
                            <span onClick={addExecutorListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                        </div>}
                    </div>}
                    {!!executorList && !executorList.length && <div className={css(s.emptyList)}>Список исполнителей пуст.</div>}
                    {!executorList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                </div>
            </div>
        </div>
    )
}
