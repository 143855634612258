import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {Head} from "../Head";
import {ChangeMode} from "../ChangeMode";
import {VerifyBtn} from "../VerifyBtn";
import {SettingMainPlace} from "../SettingMainPlace";
import {SettingMainSocialNetwork} from "../SettingMainSocialNetwork";
import {useAppState} from "../../../../../store/reduxStore";
import {ProfileState} from "../../../../../store/reducers/profile";
import {UserState} from "../../../../../store/reducers/auth";
import {SaveChangeBox} from "../SaveChangeBox";
import {IProfile} from "../../../../../types/profile";
import {LoadingPage} from "../../../LoadingPage";
import {ProfileAPI} from "../../../../../API/useProfile";
import {About} from "../About";
import {MyServices} from "../MyServices";
import {Address} from "../Address";
import {Sites} from "../Sites";
import {WorkEmail} from "../WorkEmail";
import {IsLoadingState} from "../../../../../store/reducers/isLoading";
import {useThunks} from "../../../../../helpers/useActions";

export const OutletInfo = () => {
    const {profile} = useAppState(ProfileState)
    const {user} = useAppState(UserState)
    const [changeMode,setChangeMode] = useState(false)
    const [newProfile,setNewProfile] = useState<IProfile>()
    const {updateMyProfile} = useThunks(ProfileAPI)
    const {updateProfile} = useAppState(IsLoadingState)

    useEffect(()=>{
        if (!!profile)
            setNewProfile(profile)
    },[profile])

    useEffect(()=>{
        if (!updateProfile)
            setChangeMode(false)
    },[updateProfile])

    const saveChangedHandler = () => {
        if (!newProfile) return
        updateMyProfile(newProfile)
    }
    const cancelChangedHandler = () => {
        setChangeMode(false)
        if (!profile) return
        setNewProfile(profile)
    }

    if (!newProfile) return <LoadingPage />
    return (
        <div className={css(s.InfoPage)}>
            <Head verify={user.role === 'verifyRegular'} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode} setChangeMode={setChangeMode}/>
            <ChangeMode />
            {!!user.verifyStatus && user.role === 'regular' && <VerifyBtn />}
            {user.role === 'regular' && <div className={css(s.mainBox)}>
                <SettingMainPlace profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <SettingMainSocialNetwork profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                {changeMode && <SaveChangeBox saveChangedHandler={saveChangedHandler} cancelChangedHandler={cancelChangedHandler}/>}
            </div>}
            {user.role === 'verifyRegular' && <div className={css(s.mainBox)}>
                <About maxLength={500} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <MyServices maxLength={500} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <WorkEmail maxLength={100} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <Address maxLength={100} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                {/*<iframe*/}
                {/*    src="https://yandex.ru/map-widget/v1/?um=constructor%3A7b0dc0e15857c394b3b994be60b8d90727c281d9c600efa4c591919b4cd53b75&amp;source=constructor"*/}
                {/*    width="100%" height="400" frameBorder="0"></iframe>*/}
                <Sites maxLength={100} profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <SettingMainPlace profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                <SettingMainSocialNetwork profile={newProfile as IProfile} setNewProfile={setNewProfile} changeMode={changeMode}/>
                {changeMode && <SaveChangeBox saveChangedHandler={saveChangedHandler} cancelChangedHandler={cancelChangedHandler}/>}
            </div>}
        </div>
    )
}
