import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {ManagerAPI} from "../../../../API/ManagerAPI";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {ManagerUserList} from "../ManagerUserList";
import {useThunks} from "../../../../helpers/useActions";

export const ManagerUser = ({user}:any) => {

    const {changeVerifyStatus} = useThunks(ManagerAPI)
    const [isFetch,setIsFetch] = useState(false)

    useEffect(()=>{
        setIsFetch(false)
    },[user])

    const changeVerifyStatusHandler = () => {
        if (isFetch) return
        setIsFetch(true)
        changeVerifyStatus(user.id, user.verifyStatus === 0 ? 1 : 0)
    }

    return (
        <div className={css(s.User)}>
            <div className={css(s.main)}>
                <div className={css(s.email)}>
                    {user.email}
                </div>
                <div className={css(s.name)}>
                    {user.name}
                </div>
            </div>
            <div className={css(s.control)}>
                {user.role === 'verifyRegular' && <div className={css(s.managerBtn, s.disable)}>верификация пройдена</div>}
                {user.role === 'regular' && user.verifyStatus === 0 &&
                    <div className={css(s.managerBtn, s.access)} onClick={changeVerifyStatusHandler}>
                        {isFetch ? <img src={loadingGif} alt="loading"/> : "разрешить верификацию" }
                    </div>}
                {user.role === 'regular' && user.verifyStatus === 1 &&
                    <div className={css(s.managerBtn, s.stop)} onClick={changeVerifyStatusHandler}>
                        {isFetch ? <img src={loadingGif} alt="loading"/> : "запретить верификацию" }
                    </div>}
            </div>
        </div>
    )
}
