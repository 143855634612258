import React, {StyleHTMLAttributes} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import loadingGit from '../../../assets/images/LoadingGif.gif'

type PropsType = {
    text: string
    svgComponent?: any
    style?: any
    onClick?: any
    fetching?: boolean
}
export const StandardButton = ({text, svgComponent, style, onClick, fetching = false}:PropsType) => {

    const onClickHandler = () => {
        onClick && onClick()
    }
    return (
        <div className={css(s.StandardButton, fetching && s.fetching)} style={style} onClick={onClickHandler}>
            <div className={css(s.main)}>
                {!fetching && svgComponent && <div className={css(s.svgBox)}>
                    {svgComponent}
                </div>}
                {!fetching && <div className={css(s.textBox)}>
                    {text}
                </div>}
                {fetching && <img src={loadingGit} alt="loading"/>}
            </div>
        </div>
    )
}
