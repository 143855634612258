import {AppDispatch, RootState} from "../store/reduxStore";
import {application_server, bill_server} from "../helpers/config";
import {customFetchJson} from "../helpers/customFetch";
import {IResponse} from "../types/api";
import {HintActions} from "../store/reducers/hint";
import {AppActions} from "../store/reducers/app";

export const AppAPI = {

    sendAppeal: (topic: string, description: string, email: string, accessCallback: Function, errorCallback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/appeal/add`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    topic,
                    description,
                    email,
                })
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `${response.payload.message}`,
                    type: `warning`
                }))
                errorCallback()
                return
            }

            accessCallback()
            dispatch(HintActions.setHintApp({
                isShow: true,
                text: `Обращение успешно отправленно`,
                type: `ok`
            }))

        }
        catch (err) {
            console.log(err)
        }
        finally {

        }
    },

    getFaqList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/faq/list`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload.message)
                return
            }
            dispatch(AppActions.setFaqListAction(response.payload))

        }
        catch (err) {
            console.log(err)
        }
        finally {

        }
    },

    getAboutInfo: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/about/getInfo`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload.message)
                return
            }
            dispatch(AppActions.setAboutInfoAction(response.payload))

        }
        catch (err) {
            console.log(err)
        }
        finally {

        }
    },

    getContent: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/app/content`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                console.log(response.payload.message)
                return
            }
            dispatch(AppActions.setContentAction(response.payload))

        }
        catch (err) {
            console.log(err)
            dispatch(AppActions.setContentAction({}))
        }
        finally {

        }
    },


}
