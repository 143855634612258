import React, {ReactNode, useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import {useClickOutside} from "../../../helpers/useClickOutside";
import {useAppState} from "../../../store/reduxStore";
import {showPopupActions, showPopupState, ShowPopupType} from "../../../store/reducers/showPopup";
import {useActions} from "../../../helpers/useActions";
import bg_blur from '../../assets/images/bg_blur.png'

type PropsType = {
    children: any
}
export const PopupWrapper = ({children}:PropsType) => {

    const activePopup:any = useAppState(showPopupState)
    const {setIsShowPopupAction} = useActions(showPopupActions)

    const [show,setShow] = useState(false)

    useClickOutside(()=>{
        if (!children.props.type) return
        setShow(false)
        setIsShowPopupAction({type: children.props.type as keyof ShowPopupType, isShow: false})
    }, [`${children.props.id}`],[activePopup])

    useEffect(()=>{
        if (!children.props.type) {
            setShow(true)
            return
        }
        if (!!activePopup[children.props.type]) {
            setShow(true)
        } else {
            setShow(false)
        }
        return () => {
            setShow(false)
        }
    },[activePopup])

    return (
        <div className={css(s.PopupWrapper, show && s.show)} onScroll={e=>e.stopPropagation()}>
            <div className={css(s.main)}>
                {children}
            </div>
        </div>
    )
}
