import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {TextField} from "../../../../TextField";

export const Description = ({newTask, setNewTask}:any) => {

    const changeDescriptionHandler = (valueHtml: string) => {
        setNewTask({...newTask, description: valueHtml})
    }

    return (
        <div className={css(s.Description)}>
            <div className={css(s.title)}>Подробное описание задачи</div>
            <TextField contentEditable={true} maxLength={5000} text={newTask.description} setText={changeDescriptionHandler}/>
        </div>
    )
}
