import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {TextField} from "../../../TextField";
import {DoMake} from "./DoMake";
import {Description} from "./Description";
import {Price} from "./Price";
import {Deadline} from "./Deadline";
import {AddFiles} from "./AddFiles";
import {useAppState} from "../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";
import {LoadingPage} from "../../../LoadingPage";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {StandardButton} from "../../../Button/StandardButton";
import {useNavigate} from "react-router-dom";
import {IsLoadingState} from "../../../../../store/reducers/isLoading";

export const AddTask = () => {

    const navigate = useNavigate();
    const {getNewTask, publishNewTask} = useThunks(TaskAPI)
    const {publishNewTask: publishNewTaskLoading} = useAppState(IsLoadingState)

    const {setNewTaskAction,setAddedFileListAction} = useActions(TaskActions)

    const {newTask: newTaskState} = useAppState(TaskState)
    const [taskData, setTaskData] = useState<any>(null)

    useEffect(()=>{
        getNewTask()
    },[])

    useEffect(()=>{
        if (newTaskState !== null) {
            setTaskData(newTaskState.data)
        }
    },[newTaskState])

    useEffect(()=>{
        return ()=>{
            setNewTaskAction(null)
            setAddedFileListAction(null)
        }
    },[])

    const publishHandler = () => {
        publishNewTask({
            ...newTaskState,
            data: taskData
        })
    }

    if (taskData === null || newTaskState === null) return <LoadingPage />
    return (
        <div className={css(s.AddTask)}>
            <div className={css(s.container)}>
                <div className={css(s.title)}>
                    Разместить заказ
                </div>
                <div className={css(s.main)}>
                    <DoMake newTask={taskData} setNewTask={setTaskData} />
                    <Description newTask={taskData} setNewTask={setTaskData} />
                    <Price newTask={taskData} setNewTask={setTaskData} />
                    <Deadline newTask={taskData} setNewTask={setTaskData} />
                    <AddFiles taskId={newTaskState.id}/>
                </div>
                <div className={css(s.publishBtnBox)}>
                    <StandardButton text={`Опубликовать заказ`} fetching={publishNewTaskLoading} onClick={publishHandler} style={{maxWidth: 280}}/>
                </div>
            </div>
        </div>
    )
}

