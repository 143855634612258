import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {ToggleBtn} from "../../../ToggleBtn";
import {IProfile} from "../../../../../types/profile";
import {useClickOutside} from "../../../../../helpers/useClickOutside";
import {SetPlace} from "../../../SetPlace";
import {useActions} from "../../../../../helpers/useActions";
import {HintActions} from "../../../../../store/reducers/hint";
import {cityList, regionList} from "../../../../../helpers/locationList";

export const SettingMainPlace = ({changeMode,profile,setNewProfile}:{changeMode:boolean, profile: IProfile, setNewProfile: Function}) => {

    // const setPlaceHandler = (field: string, value: string) => {
    //     setNewProfile({...profile, place: {...profile.place, [`${field}`]: value}})
    //     setFocus({
    //         region: false,
    //         city: false
    //     })
    // }

    const {setHintApp} = useActions(HintActions)

    const getPlace = async () => {
        // Если функциональность геолокации доступна,
        // пытаемся определить координаты посетителя
        if (navigator.geolocation) {
            // Передаем две функции
            navigator.geolocation.getCurrentPosition(
                async function (position:any) {
                    const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}&zoom=10`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.15673317516059}&lon=${61.30059957504273}&zoom=18`)
                    // const res = await fetch(`https://nominatim.openstreetmap.org/reverse?accept-language=ru&format=jsonv2&lat=${55.668291353993226}&lon=${38.10882568359376}&zoom=18`)

                    const data = await res.json()
                    let responseRegion = data.address.state ? data.address.state : ''
                    let responseCity = data.address.city ? data.address.city : data.address.town ? data.address.town : ''

                    // соответствие региона
                    if (responseRegion === `Москва` || responseRegion === `Московская область`) {
                        responseRegion = regionList[0]
                    } else if (responseRegion === `Санкт-Петербург` || responseRegion === `Ленинградская область`) {
                        responseRegion = regionList[1]
                    } else {
                        const compliance = regionList.find(reg => responseRegion.length && reg.startsWith(responseRegion))
                        responseRegion = !compliance ? '' : compliance
                    }
                    // соответствие города
                    const compliance = cityList.find(c => responseCity.length && c.startsWith(responseCity))
                    responseCity = !compliance ? '' : compliance

                    setHintApp({
                        isShow: true,
                        type: 'location',
                        text: `Регион: ${responseRegion.length ? responseRegion : 'не определенно'}, Город: ${responseCity.length ? responseCity : 'не определенно'}`
                    })
                    setInputFields({
                        region: responseRegion,
                        city: responseCity
                    })
                },
                function (positionError:any) {
                    if(positionError.code === 1) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Не удалось определить местоположение, пожалуйста удостоверьтесь что получение Геоданных не заблокировано.`
                        })
                    }
                    else if(positionError.code === 2) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `Проблемы с сетью или нельзя связаться со службой определения местоположения по каким-либо другим причинам.`
                        })
                    }
                    else if(positionError.code === 3) {
                        setHintApp({
                            isShow: true,
                            type: 'warning',
                            text: `He удалось определить местоположение в течение установленного времени.`
                        })
                    }
                },
                {
                    enableHighAccuracy: true, // высокая точность поиска
                    timeout: 10000, // время запроса
                    maximumAge: 60000 // кешировать данные полученные 1 мин назад
                }
            )
            // Выводим результат
            // alert("Поиск начался")
        } else {
            // Выводим результат
            setHintApp({
                isShow: true,
                type: 'warning',
                text: `Ваш браузер не поддерживает геолокацию.`
            })
            return
        }
    }
    const [inputFields,setInputFields] = useState({
        region: profile.place.region,
        city: profile.place.city,
    })
    const setRegionHandler = (region: string) => {
        // setNewProfile({...profile, place: {...profile.place, region: region}})
        setInputFields(prev=>({...prev, region: region}))
    }
    const setCityHandler = (city: string) => {
        // setNewProfile({...profile, place: {...profile.place, city: city}})
        setInputFields(prev=>({...prev, city: city}))
    }
    useEffect(()=>{
        setNewProfile({...profile, place: {region: inputFields.region, city: inputFields.city}})
    },[inputFields])

    return (
        <div className={css(s.SettingMainPlace)}>
            <div className={css(s.headPlace)}>
                <div className={css(s.placeTitle)}>Местоположение:</div>
                <div className={css(s.showToggleBox)}>
                    {/*{changeMode && <ToggleBtn checked={profile.private.place}*/}
                    {/*                          toggle={() => changeToggleShowHandler('place')}/>}*/}
                </div>
            </div>
            <div className={css(s.mainPlace)}>
                {!changeMode && <div className={css(s.regionInputBox)}>{profile.place.region.length ? profile.place.region : `Не указанно`}</div>}
                {!changeMode && <div className={css(s.cityInputBox)}>{profile.place.city ? profile.place.city : `Не указанно`}</div>}
                {changeMode && <SetPlace region={inputFields.region} city={inputFields.city} setRegion={setRegionHandler} setCity={setCityHandler} />}
            </div>
        </div>
    )
}
