import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {ManagerActions, ManagerState} from "../../../../store/reducers/manager";
import {ManagerAPI} from "../../../../API/ManagerAPI";

export const Appeal = () => {

    const {appealList, totalAppealList} = useAppState(ManagerState)

    const {getAppealList, addAppealList} = useThunks(ManagerAPI)

    const {setAppealListAction,setTotalAppealListAction} = useActions(ManagerActions)

    useEffect(() => {
        getAppealList()
        return () => {
            setAppealListAction(null)
            setTotalAppealListAction(0)
        }
    }, [])

    const [addLoad, setAddLoad] = useState(false)
    const addAppealListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addAppealList(()=>{setAddLoad(false)})
    }

    return (
        <div className={css(s.Appeal)}>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    {appealList !== null && !appealList.length &&
                        <div className={css(s.emptyList)}>Список обращений пуст.</div>}
                    {appealList === null && <img className={css(s.loadingList)} src={loadingGif} alt="loading"/>}
                    {appealList?.map(appeal => <AppealItem key={appeal.id} appeal={appeal}/>)}
                    {!!appealList?.length && totalAppealList > 0 && totalAppealList > appealList.length &&
                        <div className={css(s.showMore)}>
                            <span onClick={addAppealListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                        </div>}
                </div>
            </div>
        </div>
    )
}
export const AppealItem = ({appeal}: any) => {

    const [show, setShow] = useState(false)

    return (
        <div className={css(s.AppealItem)}>
            <div className={css(s.headItem)}>
                <div className={css(s.topic)}>Тема: {appeal.topic}</div>
                <div className={css(s.showBtn)} onClick={()=>setShow(prev=>!prev)}>
                    <span>{show ? `-` : `+`}</span>
                </div>
            </div>
            {show && <div className={css(s.mainItem)}>
                <div className={css(s.description)}>Описание: {appeal.description}</div>
                <div className={css(s.email)}>E-mail: {appeal.email}</div>
            </div>}
        </div>
    )
}

