import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    path: null | string
}
const initialState: stateType = {
    path: null,
}

export const slice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setPathAction: (state, action: PayloadAction<string | null>) : void => {
            state.path = action.payload
        },
    }
})

export const NavigationReducer = slice.reducer
export const NavigationActions = slice.actions
export const NavigationState = (state: RootState) => state.NavigationReducer


