import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {BillState} from "../../../../../store/reducers/bill";
import {useThunks} from "../../../../../helpers/useActions";
import {BillAPI} from "../../../../../API/BillAPI";
import {IsLoadingState} from "../../../../../store/reducers/isLoading";
import {LoadingPage} from "../../../LoadingPage";
import loadingGif from "../../../../assets/images/LoadingGif.gif";

export const History = () => {

    const {getBillHistoryList: loading} = useAppState(IsLoadingState)
    const {historyList, totalHistoryList} = useAppState(BillState)
    const {getBillHistory,addBillHistory} = useThunks(BillAPI)

    const [addLoad, setAddLoad] = useState(false)

    useEffect(() => {
        getBillHistory()
    }, [])

    useEffect(() => {
        setAddLoad(false)
    }, [historyList])

    const addHistoryListHandler = () => {
        setAddLoad(true)
        addBillHistory()
    }

    return (
        <div className={css(s.History)}>
            <div className={css(s.header)}>
                <div className={css(s.type)}>Назначение платежа</div>
                <div className={css(s.count)}>Сумма, руб</div>
                <div className={css(s.status)}>Статус</div>
                <div className={css(s.date)}>Дата</div>
            </div>
            <div className={css(s.list)}>
                {!loading && historyList?.map((el: any) => <BillHistoryItem key={el.id} item={el}/>)}
                {!loading && !historyList?.length && <div className={css(s.emptyList)}>Список транзакций пуст</div>}
                {!loading && historyList !== null && totalHistoryList !== null && totalHistoryList > historyList.length &&
                    <div className={css(s.showMore)}>
                            <span onClick={addHistoryListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                    </div>}
                {loading && <LoadingPage/>}
            </div>
        </div>
    )
}

const BillHistoryItem = ({item}: any) => {

    switch (item.type) {
        case 'add':
            return (
                <div className={css(s.BillHistoryItem)}>
                    <div className={css(s.type)}>Пополнение</div>
                    <div className={css(s.count)}>+{item.addedMoney}</div>
                    <div className={css(s.status)}>
                        {(()=>{
                            switch (item.status){
                                case 'succeeded': return <span className={css(s.success)}>Выполнен</span>
                                case 'pending': return <span className={css(s.pending)}>В процессе</span>
                                case 'canceled': return <span className={css(s.cancel)}>Отменен</span>
                                default: return null
                            }
                        })()}
                    </div>
                    <div className={css(s.date)}>{`${new Date(item.date).toLocaleDateString()} ${new Date(item.date).toLocaleTimeString()}`}</div>
                </div>
            )
        case 'withdraw':
            return (
                <div className={css(s.BillHistoryItem)}>
                    <div className={css(s.type)}>Вывод</div>
                    <div className={css(s.count)}>- {item.withdrawMoney}</div>
                    <div className={css(s.status)}>
                        {(()=>{
                            switch (item.status){
                                case 'succeeded': return <span className={css(s.success)}>Выполнен</span>
                                case 'pending': return <span className={css(s.pending)}>В обработке</span>
                                case 'cancel': return <span className={css(s.cancel)}>Отменен</span>
                                default: return null
                            }
                        })()}
                    </div>
                    <div className={css(s.date)}>{`${new Date(item.date).toLocaleDateString()} ${new Date(item.date).toLocaleTimeString()}`}</div>
                </div>
            )
        default:
            return null
    }
};

