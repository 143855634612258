import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";

interface stateType {
    hint: {
        isShow: boolean,
        type: string,
        text: string
    }
}
const initialState: stateType = {
    hint: {
        isShow: false,
        type: 'ok',
        text: ''
    },

}

export const slice = createSlice({
    name: 'hint',
    initialState,
    reducers: {
        setHintApp: (state, action: PayloadAction<{isShow:boolean, text: string, type: string}>) : void => {
            state.hint = {
                isShow: action.payload.isShow,
                type: action.payload.type,
                text: action.payload.text
            }
        },
        closeHintApp: (state) : void => {
            state.hint = {
                isShow: false,
                type: '',
                text: ''
            }
        },
    }
})

export const HintReducer = slice.reducer
export const HintActions = slice.actions
export const HintState = (state: RootState) => state.HintReducer


