import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {useAppState} from "../../../../store/reduxStore";
import {useNavigate} from "react-router-dom";
import {SvgSearch} from "../../../assets/images/svg/SvgSearch";
import loadingGif from "../../../assets/images/LoadingGif.gif";
import {ManagerAPI} from "../../../../API/ManagerAPI";
import {ManagerActions, ManagerState} from "../../../../store/reducers/manager";
import {verify} from "crypto";
import {IResponse} from "../../../../types/api";
import {customFetch, customFetchJson} from "../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../helpers/config";

export const VerificationList = () => {
    const navigate = useNavigate();

    const {getVerificationList, addVerificationList} = useThunks(ManagerAPI)
    const {setVerificationListAction,setTotalVerificationListAction} = useActions(ManagerActions)
    const {verificationList,totalVerificationList} = useAppState(ManagerState)

    const [search, serSearch] = useState('')

    useEffect(() => {
        getVerificationList()
        return () => {
            setVerificationListAction(null)
            setTotalVerificationListAction(0)
        }
    }, [])

    const [addLoad, setAddLoad] = useState(false)
    const addAppealListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addVerificationList(()=>{setAddLoad(false)})
    }

    return (
        <div className={css(s.VerificationList)}>
            <div className={css(s.container)}>
                {verificationList && <div className={css(s.list)}>
                    {verificationList.map(verify => <VerifyItem key={verify.id} verify={verify}/>)}
                </div>}
                {!verificationList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                {!!verificationList && !verificationList.length &&
                    <div className={css(s.emptyList)}>Нет пользователей ожидающих верификацию</div>}
                {!!verificationList?.length && totalVerificationList > 0 && totalVerificationList > verificationList.length &&
                    <div className={css(s.showMore)}>
                            <span onClick={addAppealListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                    </div>}
            </div>
        </div>
    )
}
export const VerifyItem = ({verify}:any) => {

    const { setActiveVerificationItemAction} = useActions(ManagerActions)

    const openVerificationItem = async (verify: any) => {
        await setActiveVerificationItemAction({
            ...verify,
            verifyData: JSON.parse(verify.verifyData)
        })
    }
    const [profile,setProfile] = useState<any>(null)
    useEffect(()=>{
        (async () => {
            try {
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${verify.userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setProfile({
                        name: '',
                        email: '',
                    })
                    return
                }

                setProfile(response.payload)
            } catch (error) {
                console.log('error client', error)
            } finally {
            }
        })()
    },[])

    return (
        <div className={css(s.VerifyItem)} onClick={() => openVerificationItem(verify)}>
            <div className={css(s.name)}>
                <div className={css(s.title)}>Email: </div>
                <div className={css(s.value)}>{`${profile ? profile.email : `loading...`}`}</div>
            </div>
            <div className={css(s.status)}>
                <div className={css(s.title)}>Статус:</div>
                {verify.status === 1 && <div className={css(s.value)}>Ожидает верификации</div>}
                {verify.status === 2 && <div className={css(s.value)}>Верификация пройдена</div>}
                {verify.status === 3 && <div className={css(s.value)}>Отклонён</div>}
                {verify.status === 4 && <div className={css(s.value)}>Ожидает повторной верификации</div>}
            </div>
            <div>
                <div className={css(s.title)}>Последнее изменение:</div>
                <div className={css(s.value)}>{`${new Date(verify.updatedAt).toLocaleDateString()} ${new Date(verify.updatedAt).toLocaleTimeString().slice(0,5)}`}</div>
            </div>
        </div>
    )
}


