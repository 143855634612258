import React, {useEffect} from 'react'
import {useAppState} from "../../../store/reduxStore";
import {UserState} from "../../../store/reducers/auth";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {GuestLayout} from "./GuestLayout";
import {HomePage} from "../Pages/HomePage/HomePage";
import {AboutPage} from "../Pages/AboutPage";
import {ContactsPage} from "../Pages/ContactsPage";
import {ExecutorList} from "../Pages/ExecutorPage/ExecutorList";
import {ActiveExecutor} from "../Pages/ExecutorPage/ActiveExecutor";
import {FaqPage} from "../Pages/FaqPage";
import {RecoverPassword} from "../PopupWrapper/RecoverPassword";
import {ErrorServer} from "../Pages/ErrorServer";
import {ManagerLayout} from "./ManagerLayout";
import {ManagerUserList} from "../PagesManager/ManagerUserList";
import {VerificationList} from "../PagesManager/VerificationList";
import {VerificationItem} from "../PagesManager/VerificationItem";
import {ProfileAPI} from "../../../API/useProfile";
import {useThunks} from "../../../helpers/useActions";
import {NoticeAPI} from "../../../API/NoticeAPI";
import {VerifyRegularLayout} from "./VerifyRegularLayout";
import {Messenger} from "../Pages/ChatPage/Messenger";
import {ActiveChat} from "../Pages/ChatPage/ActiveChat";
import {AdminLayout} from "./AdminLayout";
import {AdminUserList} from "../PagesAdmin/AdminUserList";
import {SettingPage} from "../Pages/SettingPage";
import {RegularLayout} from "./RegularLayout";
import {VerifyBlock} from "../Pages/SettingPage/VerifyBlock";
import {OutletInfoTwo} from "../Pages/SettingPage/OutletInfoTwo";
import {OutletInfo} from "../Pages/SettingPage/OutletInfo";
import {Verification} from "../PagesManager/Verification";
import {AddTask} from "../Pages/TaskPage/AddTask";
import {TaskList} from "../Pages/TaskPage/TaskList";
import {ActiveTask} from "../Pages/TaskPage/ActiveTask";
import {MyWork} from "../Pages/MyWork";
import {MyWorkTask} from "../Pages/MyWork/MyWorkTask";
import {MyWorkOffer} from "../Pages/MyWork/MyWorkOffer";
import {MobilChatList} from "../Pages/ChatPage/MobilChatList";
import {MyBill} from "../Pages/MyBill";
import {TopUp} from "../Pages/MyBill/TopUp";
import {Withdraw} from "../Pages/MyBill/Withdraw";
import {History} from "../Pages/MyBill/History";
import {Arbitrage} from "../PagesManager/Arbitrage";
import {ArbitrageItem} from "../PagesManager/Arbitrage/ArbitrageItem";
import {ArbitrageList} from "../PagesManager/Arbitrage/ArbitrageList";
import {Appeal} from "../PagesManager/Appeal";
import {AddFaq} from "../PagesAdmin/addFaq";
import {Content} from "../PagesAdmin/Content";
import {Billing} from "../PagesAdmin/Billing";
import Policy from "../Pages/Policy";
import { Agreement } from '../Pages/Agreement';
import { ManagerWithdraw } from '../PagesManager/Withdraw';

export const Routing = () => {
    const {user} = useAppState(UserState)

    switch (user.role) {
        case 'guest':
            return <GuestRouting/>
        case 'regular':
            return <RegularRouting/>
        case 'verifyRegular':
            return <VerifyRegularRouting/>
        case 'admin':
            return <AdminRouting/>
        case 'manager':
            return <ManagerRouting/>
        default:
            return <ErrorServer/>
    }
}

export const GuestRouting = () => {

    return (
        <Routes>
            <Route path="/" element={<GuestLayout/>}>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/contact" element={<ContactsPage/>}/>
                <Route path="/executor-list">
                    <Route path="/executor-list" element={<ExecutorList/>}/>
                    <Route path="/executor-list/:id" element={<ActiveExecutor/>}/>
                </Route>
                <Route path="/faq" element={<FaqPage/>}/>
            </Route>
            <Route path="/resetpswd/:link" element={<RecoverPassword/>}/>
            <Route path="/copyright" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "copyright"</div>}/>
            <Route path="/agreement" element={<Agreement />}/>
            <Route path="/policy" element={<Policy />}/>
            <Route path="/service-rules" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "Правила размещения услуг"</div>}/>
            <Route path="*" element={<ErrorServer/>}/>
        </Routes>
    )
}
export const RegularRouting = () => {

    const {getMyProfile} = useThunks(ProfileAPI)
    const {noticeConnect, closeNoticeConnect} = useThunks(NoticeAPI)

    useEffect(() => {
        getMyProfile()
        noticeConnect()
        return () => {
            closeNoticeConnect()
        }
    }, [])

    return (
        <Routes>
            <Route path="/" element={<RegularLayout/>}>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/contact" element={<ContactsPage/>}/>
                <Route path="/faq" element={<FaqPage/>}/>
                <Route path="/executor-list">
                    <Route path="/executor-list" element={<ExecutorList/>}/>
                    <Route path="/executor-list/:id" element={<ActiveExecutor/>}/>
                </Route>
                <Route path="/chat-list" element={<Messenger/>}>
                    <Route path="/chat-list" element={<MobilChatList/>}/>
                    <Route path="/chat-list/:chatId" element={<ActiveChat/>}/>
                </Route>
                <Route path="/setting" element={<SettingPage/>}>
                    <Route path="/setting/info" element={<OutletInfo/>}/>
                    <Route path="/setting/info-two" element={<OutletInfoTwo/>}/>
                    <Route path="/setting/verify" element={<VerifyBlock/>}/>
                </Route>
                <Route path="/copyright" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "copyright"</div>}/>
                <Route path="/agreement" element={<Agreement />}/>
                <Route path="/policy" element={<Policy />}/>
                <Route path="/service-rules" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "Правила размещения услуг"</div>}/>
            </Route>
            <Route path="*" element={<ErrorServer/>}/>
        </Routes>
    )
}
export const VerifyRegularRouting = () => {

    const {getMyProfile} = useThunks(ProfileAPI)
    const {noticeConnect, closeNoticeConnect} = useThunks(NoticeAPI)

    useEffect(() => {
        getMyProfile()
        noticeConnect()
        return () => {
            closeNoticeConnect()
        }
    }, [])

    return (
        <Routes>
            <Route path="/" element={<VerifyRegularLayout/>}>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/contact" element={<ContactsPage/>}/>
                <Route path="/faq" element={<FaqPage/>}/>
                <Route path="/executor-list">
                    <Route path="/executor-list" element={<ExecutorList/>}/>
                    <Route path="/executor-list/:id" element={<ActiveExecutor/>}/>
                </Route>
                <Route path="/chat-list" element={<Messenger/>}>
                    <Route path="/chat-list" element={<MobilChatList/>}/>
                    <Route path="/chat-list/:chatId" element={<ActiveChat/>}/>
                </Route>

                <Route path="/setting" element={<SettingPage/>}>
                    <Route path="/setting/info" element={<OutletInfo/>}/>
                    <Route path="/setting/info-two" element={<OutletInfoTwo/>}/>
                    <Route path="/setting/verify" element={<VerifyBlock/>}/>
                </Route>

                <Route path="/add-task" element={<AddTask/>}/>
                <Route path="/task-list" element={<TaskList/>}/>
                <Route path="/task-list/:id" element={<ActiveTask/>}/>

                <Route path="/my-work" element={<MyWork />}>
                    <Route path="/my-work/task" element={<MyWorkTask/>}/>
                    <Route path="/my-work/offer" element={<MyWorkOffer/>}/>
                </Route>
                <Route path="/my-bill" element={<MyBill />}>
                    <Route path="/my-bill/top-up" element={<TopUp/>}/>
                    <Route path="/my-bill/withdraw" element={<Withdraw/>}/>
                    <Route path="/my-bill/history" element={<History/>}/>
                </Route>
                <Route path="/copyright" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "copyright"</div>}/>
                <Route path="/agreement" element={<Agreement />}/>
                <Route path="/policy" element={<Policy />}/>
                <Route path="/service-rules" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>тело "Правила размещения услуг"</div>}/>
            </Route>

            <Route path="*" element={<ErrorServer/>}/>
        </Routes>
    )
}
export const AdminRouting = () => {
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === `/`)
            navigate(`/user-list`)
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<AdminLayout/>}>
                <Route path="/user-list" element={<AdminUserList/>}/>
                <Route path="/faq" element={<AddFaq />}/>
                <Route path="/content" element={<Content />}/>
                <Route path="/billing" element={<Billing />}/>
            </Route>
            <Route path="*" element={<ErrorServer/>}/>
        </Routes>
    )
}
export const ManagerRouting = () => {
    const {noticeConnect, closeNoticeConnect} = useThunks(NoticeAPI)

    useEffect(() => {
        noticeConnect()
        return () => {
            closeNoticeConnect()
        }
    }, [])

    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (location.pathname === `/`)
            navigate(`/user-list`)
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<ManagerLayout/>}>
                <Route path="/user-list" element={<ManagerUserList/>}/>
                <Route element={<Verification/>}>
                    <Route path="/verify" element={<VerificationList/>}/>
                    <Route path="/verifyItem/:id" element={<VerificationItem/>}/>
                </Route>
                <Route path="/arbitrage" element={<Arbitrage />}>
                    <Route path="/arbitrage/list" element={<ArbitrageList />}/>
                    <Route path="/arbitrage/:id" element={<ArbitrageItem />}/>
                </Route>
                <Route path="/appeal" element={<Appeal />}/>
                <Route path="/withdraw" element={<ManagerWithdraw />}/>
            </Route>
            <Route path="*" element={<ErrorServer/>}/>
        </Routes>
    )
}

