import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import {useAppState} from "../../../../store/reduxStore";
import {AppState} from "../../../../store/reducers/app";
import {AdminAPI} from "../../../../API/useAdmin";
import loadingGif from "../../../assets/images/LoadingGif.gif";

export const Content = () => {

    const {getAboutInfo, getContent} = useThunks(AppAPI)

    useEffect(()=>{
        getAboutInfo()
        getContent()
    },[])

    return (
        <div className={css(s.Content)}>
            <div className={css(s.container)}>
                <Home />
                <About />
            </div>
        </div>
    )
}

const Home = () => {

    const {content} = useAppState(AppState)
    const {updateHeadline} = useThunks(AdminAPI)
    const [newContentTitle, setNewContentTitle] = useState<any>(null)

    useEffect(()=>{
        if (content)
            setNewContentTitle(content.headline)
    },[content])

    const [addLoad, setAddLoad] = useState(false)
    const updateHandler = (e:any) =>{
        e.preventDefault()
        if (addLoad) return
        setAddLoad(true)
        updateHeadline(newContentTitle, ()=>{
            setAddLoad(false)
        })
    }

    if (newContentTitle === null) return null
    return (
        <form className={css(s.Home)} onSubmit={updateHandler}>
            <div className={css(s.title)}>
                Заголовок на главной
            </div>
            <div className={css(s.text)}>
                <textarea value={newContentTitle} onChange={(e)=>setNewContentTitle(e.target.value)}/>
            </div>
            <button>
                {addLoad ? <img src={loadingGif} alt="loading"/> : `обновить`}
            </button>
        </form>
    )
}

const About = () => {

    const {about} = useAppState(AppState)
    const {updateAbout} = useThunks(AdminAPI)

    const [newAboutInfo, setNewAboutInfo] = useState<any>(null)

    useEffect(()=>{
        setNewAboutInfo(about)
    },[about])

    const [addLoad, setAddLoad] = useState(false)
    const updateHandler = (e:any) =>{
        e.preventDefault()
        if (addLoad) return
        setAddLoad(true)
        updateAbout(newAboutInfo, ()=>{
            setAddLoad(false)
        })
    }

    if (!newAboutInfo) return null
    return (
        <form className={css(s.About)} onSubmit={updateHandler}>
            <div className={css(s.aboutTitle)}>About</div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 1:</div>
                <input type="text" value={newAboutInfo.oneValue} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,oneValue: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 2:</div>
                <input type="text" value={newAboutInfo.oneTitle} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,oneTitle: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 3:</div>
                <input type="text" value={newAboutInfo.twoValue} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,twoValue: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 4:</div>
                <input type="text" value={newAboutInfo.twoTitle} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,twoTitle: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 5:</div>
                <input type="text" value={newAboutInfo.threeValue} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,threeValue: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Поле 6:</div>
                <input type="text" value={newAboutInfo.threeTitle} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,threeTitle: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Подзаголовок:</div>
                <textarea value={newAboutInfo.title} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,title: e.target.value}))}/>
            </div>
            <div className={css(s.row)}>
                <div className={css(s.subTitle)}>Текст:</div>
                <textarea value={newAboutInfo.text} onChange={(e)=>setNewAboutInfo((prev:any)=>({...prev,text: e.target.value}))}/>
            </div>
            <button>
                {addLoad ? <img src={loadingGif} alt="loading"/> : `обновить`}
            </button>
        </form>
    )
}




