import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {BillAPI} from "../../../../../API/BillAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {IsLoadingState} from "../../../../../store/reducers/isLoading";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {HintActions} from "../../../../../store/reducers/hint";

export const Withdraw = () => {

    const {subtractBalance} = useThunks(BillAPI)
    const {subtractBalance: subtractBalanceLoading} = useAppState(IsLoadingState)
    const {setHintApp} = useActions(HintActions)

    const [limits] = useState({
        min: 100,
        max: 500000
    })

    const [price,setPrice] = useState(0)
    const [value,setValue] = useState('')

    const changePriceHandler = (valueHtml: string) => {
        const pattern = /^\d+(\.?)\d*$/g;
        if (!valueHtml.match(pattern)) {
            valueHtml = valueHtml.slice(0, -1)
        }
        const price = Math.abs(Number.parseInt(valueHtml))
        setPrice(isNaN(price) ? 0 : price)
    }
    const changeValueHandler = (valueHtml: string) => {
        valueHtml = valueHtml.replace(/[^\d]/g, '').substring(0,16)

        const match = valueHtml.match(/.{1,4}/g)

        valueHtml = valueHtml !== '' ? (match ? match.join(' '): '') : ''

        setValue(valueHtml)
    }

    const subtractBalanceHandler = (e:any) =>{
        e.preventDefault()
        const checkedValue = value.split(" ").join("")
        if (checkedValue.length < 16) {
            setHintApp({
                isShow: true,
                type: 'warning',
                text: `Неверный формат банковской карты.`
            })
            return
        }
        if (Number(price) < Number(limits.min) || Number(price) > Number(limits.max)) {
            setHintApp({
                isShow: true,
                type: 'warning',
                text: `Ограничения на вывод: мин 100 руб. / макс 500 000 руб.`
            })
            return
        }
        setPrice(0)
        setValue(``)
        subtractBalance(price, {type: `card`, value: checkedValue})
    }

    return (
        <form className={css(s.Withdraw)} onSubmit={subtractBalanceHandler}>
            <div className={css(s.title)}>Банковская карта</div>
            <div>Ограничения на вывод: мин {`${limits.min}`} руб. / макс {`${String(limits.max).replace(/(\d{1,3})(?=((\d{3})*([^\d]|$)))/g, " $1")}`} руб.</div>
            {/*<div>Максимальное количество выплат за сутки — 20 </div>*/}
            {/*<div>Максимальная сумма выплат за месяц — 1 500 000 руб.</div>*/}
            <div className={css(s.inputBox)}>
                <input type="text" placeholder={`Номер карты`} value={parseInt(value) > 0 ? value : ''} onChange={e=>{changeValueHandler(e.target.value)}}/>
                <input type="text" placeholder={`Сумма пополнения`} value={price > 0 ? price : ''} onChange={e=>{changePriceHandler(e.target.value)}}/>
            </div>
            <button>
                {subtractBalanceLoading ? <img src={loadingGif} alt="loading"/> : `Вывести`}
            </button>
        </form>
    )
}
