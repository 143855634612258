// export const originalFetch: any= window.fetch

import {user_server} from "./config";

export const customFetch = async (url:string, options:any) : Promise<any> => {
    const start = Date.now()
    try {
        const response = await fetch(url, {...options,
            // credentials: 'include',
            headers: {...options.headers,'Authorization': `Bearer ${localStorage.getItem('accessToken')}`}
        })
        if (response.status === 403) {

            const responseRefresh = await fetch(`${user_server}/api/auth/refresh`,{
                method: 'POST',
                // credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken: localStorage.getItem('refreshToken')
                })
            })
            if (responseRefresh.status === 200) {
                const refreshData = await responseRefresh.json()
                await localStorage.setItem('accessToken',refreshData.accessToken)
                await localStorage.setItem('refreshToken',refreshData.refreshToken)

                return await customFetch(url, options)
            } else {
                await localStorage.removeItem(`accessToken`)
                await localStorage.removeItem(`refreshToken`)
                // window.location.reload()
            }

        }
        if (response.status === 401) {
            await localStorage.removeItem(`accessToken`)
            await localStorage.removeItem(`refreshToken`)
            // window.location.reload()
        }

        await new Promise((resolve,reject) => {
            if (!!options.signal) {
                options.signal.addEventListener('abort', ()=>{
                    reject()
                });
            }
            setTimeout(()=> {
                resolve(undefined)
                if (!!options.signal) {
                    options.signal.removeEventListener('abort', reject)
                }
            }, 1000 - (Date.now() - start))
        })

        return response
    } catch (err) {
        throw err
    }
}
export const customFetchJson = async (url:string, options:any) : Promise<any> => {
    const start = Date.now()
    try {
        const tokenHeaderOption = localStorage.getItem('accessToken') ? {'Authorization':`Bearer ${localStorage.getItem('accessToken')}`} : {}
        const response = await fetch(url, {...options,
            headers: {...options.headers,...tokenHeaderOption}
        })
        if (response.status === 403) {
            const responseRefresh = await fetch(`${user_server}/api/auth/refresh`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken: localStorage.getItem('refreshToken')
                })
            })
            if (responseRefresh.status === 200) {
                const refreshData = await responseRefresh.json()
                await localStorage.setItem('accessToken',refreshData.accessToken)
                await localStorage.setItem('refreshToken',refreshData.refreshToken)

                return await customFetchJson(url, options)
            } else {
                await localStorage.removeItem(`accessToken`)
                await localStorage.removeItem(`refreshToken`)
                // window.location.reload()
            }

        }
        if (response.status === 401) {
            await localStorage.removeItem(`accessToken`)
            await localStorage.removeItem(`refreshToken`)
            // window.location.reload()
        }


        await new Promise((resolve,reject) => {
            // if (!!options.signal) {
            //     options.signal.addEventListener('abort', ()=>{
            //         reject()
            //     });
            // }
            setTimeout(()=> {
                resolve(undefined)
                // if (!!options.signal) {
                //     options.signal.removeEventListener('abort', reject)
                // }
            }, 1000 - (Date.now() - start))
        })

        const payload = await response.json()
        if (response.ok) {
            return {
                error: false,
                payload: payload
            }
        }
        return {
            error: true,
            payload: payload
        }

    } catch (err) {
        throw err
    }
}
export const customFetchFile = async (url:string, options:any) : Promise<any> => {
    const start = Date.now()
    try {
        const tokenHeaderOption = localStorage.getItem('accessToken') ? {'Authorization':`Bearer ${localStorage.getItem('accessToken')}`} : {}
        const response = await fetch(url, {...options,
            headers: {...options.headers,...tokenHeaderOption}
        })
        if (response.status === 403) {
            const responseRefresh = await fetch(`${user_server}/api/auth/refresh`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    refreshToken: localStorage.getItem('refreshToken')
                })
            })
            if (responseRefresh.status === 200) {
                const refreshData = await responseRefresh.json()
                await localStorage.setItem('accessToken',refreshData.accessToken)
                await localStorage.setItem('refreshToken',refreshData.refreshToken)

                return await customFetchFile(url, options)
            } else {
                await localStorage.removeItem(`accessToken`)
                await localStorage.removeItem(`refreshToken`)
                // window.location.reload()
            }

        }
        if (response.status === 401) {
            await localStorage.removeItem(`accessToken`)
            await localStorage.removeItem(`refreshToken`)
            // window.location.reload()
        }


        await new Promise((resolve,reject) => {
            // if (!!options.signal) {
            //     options.signal.addEventListener('abort', ()=>{
            //         reject()
            //     });
            // }
            setTimeout(()=> {
                resolve(undefined)
                // if (!!options.signal) {
                //     options.signal.removeEventListener('abort', reject)
                // }
            }, 1000 - (Date.now() - start))
        })


        if (response.ok) {
            const payload = await response.blob()
            return {
                error: false,
                payload: payload
            }
        }
        return {
            error: true,
            payload: null
        }

    } catch (err) {
        throw err
    }
}