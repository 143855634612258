import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {useAppState} from "../../../../store/reduxStore";
import {useNavigate, useParams} from "react-router-dom";
import {VerificationAPI} from "../../../../API/VerificationAPI";
import {ManagerAPI} from "../../../../API/ManagerAPI";
import {ManagerActions, ManagerState} from "../../../../store/reducers/manager";
import {SvgZipIcon} from "../../../assets/images/svg/SvgZipIcon";
import {SvgJpgIcon} from "../../../assets/images/svg/SvgJpgIcon";
import {SvgPdfIcon} from "../../../assets/images/svg/SvgPdfIcon";
import loadingGif from "../../../assets/images/LoadingGif.gif";

export const VerificationItem = () => {
    const navigate = useNavigate();
    const params = useParams();

    const {getVerificationFileList, changeStatusVerification} = useThunks(ManagerAPI)
    const {setActiveVerificationItemAction, setActiveVerificationFilesAction} = useActions(ManagerActions)
    const {activeVerificationItem} = useAppState(ManagerState)

    const [fetching, setFetching] = useState({
        access: false,
        reject: false,
    })

    useEffect(() => {
        if (!params.id) return
        getVerificationFileList(+params.id)
        return () => {
            setActiveVerificationItemAction(null)
            setActiveVerificationFilesAction(null)
            setFetching({
                access: false,
                reject: false,
            })
        }
    }, [])

    const backBtnHandler = () => {
        navigate(`/verify`)
    }

    const accessHandler = () => {
        if (!params.id) return
        setFetching({
            access: true,
            reject: false,
        })
        changeStatusVerification('access', +params.id)
    }
    const rejectedHandler = () => {
        if (!params.id) return
        setFetching({
            access: false,
            reject: true,
        })
        changeStatusVerification('reject', +params.id)
    }

    if (!activeVerificationItem) return null

    return (
        <div className={css(s.VerificationItem)}>
            <div className={css(s.container)}>
                <div className={css(s.backBtn)}><span onClick={backBtnHandler}>&#8592; назад</span></div>
                <div className={css(s.title)}>
                    {activeVerificationItem.status === 1 && <div>Ожидает верификации</div>}
                    {activeVerificationItem.status === 2 && <div>Верификация пройдена</div>}
                    {activeVerificationItem.status === 3 && <div>Отклонён</div>}
                    {activeVerificationItem.status === 4 && <div>Ожидает повторной верификации</div>}
                </div>
                <div className={css(s.main)}>
                    <FieldList activeVerificationItem={activeVerificationItem.verifyData}/>
                    <FileList/>
                </div>
                <div className={css(s.answerBtnBox)}>
                    <div className={css(s.access, fetching.access && s.fetching)} onClick={accessHandler}>
                        {!fetching.access ? 'одобрить' : <img src={loadingGif} alt="loadingGif"/>}
                    </div>
                    <div className={css(s.rejected, fetching.reject && s.fetching)} onClick={rejectedHandler}>
                        {!fetching.reject ? 'отклонить' : <img src={loadingGif} alt="loadingGif"/>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export const FieldList = ({activeVerificationItem}:any) => {

    return (
        <div className={css(s.FieldList)}>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Фамилия*</div>
                    <div className={css(s.fieldMain)}>
                        <div>{activeVerificationItem.surName}</div>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Имя*</div>
                    <div className={css(s.fieldMain)}>
                        <div>{activeVerificationItem.firstName}</div>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Отчество*</div>
                    <div className={css(s.fieldMain)}>
                        <div>{activeVerificationItem.patronymic}</div>
                    </div>
                </div>
            </div>
            {/*<div className={css(s.twoItemBox)}>*/}
            {/*    <div className={css(s.field)}>*/}
            {/*        <div className={css(s.fieldTitle)}>Регион*</div>*/}
            {/*        <div className={css(s.fieldMain)}>*/}
            {/*            <div>{activeVerificationItem.region}</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={css(s.field)}>*/}
            {/*        <div className={css(s.fieldTitle)}>Город*</div>*/}
            {/*        <div className={css(s.fieldMain)}>*/}
            {/*            <div>{activeVerificationItem.city}</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>E-mail*</div>
                    <div className={css(s.fieldMain)}>
                        <div>{activeVerificationItem.email}</div>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Телефон*</div>
                    <div className={css(s.fieldMain)}>
                        <div>{activeVerificationItem.phone}</div>
                    </div>
                </div>
            </div>
            {activeVerificationItem.croName === 'Кадастровый инженер' &&
            <div className={css(s.prof)}>
                <div className={css(s.profTitle)}>Кадастровый инженер</div>
            </div>}
            {activeVerificationItem.croName === 'Кадастровый инженер' &&
                <div className={css(s.oneItemBox)}>
                    <div className={css(s.field)}>
                        <div className={css(s.fieldTitle)}>Наименование СРО*</div>
                        <div className={css(s.fieldMain)}>
                            <div>{activeVerificationItem.croNumber}</div>
                        </div>
                    </div>
                </div>}
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Комментарий</div>
                    <div className={css(s.fieldMain)}>
                        <div className={css(s.comment)}>
                            {activeVerificationItem.comment}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const FileList = () => {

    const {downloadFile} = useThunks(ManagerAPI)

    const {activeVerificationFiles} = useAppState(ManagerState)

    const downloadFileHandler = (minFile: any) => {
        downloadFile(minFile)
    }

    return (
        <div className={css(s.FileList)}>
            <div className={css(s.title)}>
                Прикреплённые документы
            </div>
            {!activeVerificationFiles && <div className={css(s.loadingFileList)}>Загрузка...</div>}
            {!!activeVerificationFiles && !activeVerificationFiles.length &&
                <div className={css(s.emptyFileList)}>Нет прикреплённых файлов</div>}
            {activeVerificationFiles !== null &&
            <div className={css(s.fileList)}>
                {activeVerificationFiles?.map((minFile: any) =>
                    <div className={css(s.addedFileItem)} key={minFile.id} title={minFile.fileName}
                         onClick={() => downloadFileHandler(minFile)}>
                        <div className={css(s.fileImgBox)}>
                            {(() => {
                                switch (minFile.fileName.slice(minFile.fileName.lastIndexOf(`.`))) {
                                    case `.zip`:
                                        return <SvgZipIcon/>
                                    case `.jpeg`:
                                        return <SvgJpgIcon/>
                                    case `.jpg`:
                                        return <SvgPdfIcon/>
                                    case `.png`:
                                        return <SvgPdfIcon/>
                                    case `.pdf`:
                                        return <SvgPdfIcon/>
                                    default:
                                        return <SvgZipIcon/>
                                }
                            })()}
                        </div>
                        <div className={css(s.fileSizeText)}>{`${Math.ceil(minFile.fileSize / 1000 / 1000)}mb`}</div>
                        <div>{minFile.fileName}</div>
                        <div className={css(s.downloadFile)}>
                            &#x2913;
                        </div>
                    </div>)}
            </div>}
        </div>

    )
}
