import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useNavigate, useParams} from "react-router-dom";
import {useAppState} from "../../../../../store/reduxStore";
import {useActions} from "../../../../../helpers/useActions";
import {MessengerActions, MessengerState} from "../../../../../store/reducers/messenger";
import {SvgMyProfile} from "../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {IResponse} from "../../../../../types/api";
import {customFetch, customFetchJson} from "../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../helpers/config";
import {TaskState} from "../../../../../store/reducers/task";
import {UserState} from "../../../../../store/reducers/auth";

export const ChatItem = ({chat}:any) => {
    const params = useParams();
    const {setActiveChatAction,setActiveMessageListAction} = useActions(MessengerActions)
    const { activeChat } = useAppState(MessengerState)
    const { activeTask } = useAppState(TaskState)
    const { user } = useAppState(UserState)

    const setActiveChatHandler = async () => {
        if (chat.id === Number(params.chatId)) return
        // if (respondProfile.loading) return
        setActiveMessageListAction(null)
        setActiveChatAction({...chat, companionProfile: respondProfile})
        // navigate(`/chat-list/${chat.id}`)
    }

    const [respondProfile, setRespondProfile] = useState<any>({
        loading: true,
        user: null,
        src: null
    })
    useEffect(() => {
        (async () => {
            try {

                const companionId = chat.companionIdList.filter((id:number) =>id !== activeTask?.id).filter((id:number) => id !== user.id)[0]
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${companionId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                    return
                }

                let image: any = null
                if (!!response.payload.avatarLink.length) {
                    const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })

                    const blobData = await src.blob()
                    const urlCreator = window.URL || window.webkitURL;
                    image = urlCreator.createObjectURL(blobData);
                }

                setRespondProfile((prev: any) => ({
                    ...prev,
                    user: {
                        id: response.payload.id,
                        name: response.payload.name,
                        place: response.payload.place,
                    },
                    src: image,
                    loading: false
                }))
            } catch (error) {
                setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                console.log('error client', error)
            } finally {
            }
        })()
    }, [])
    useEffect(() => {
        return () => {
            setRespondProfile({
                loading: true,
                user: null,
                src: null
            })
        }
    }, [])

    return (
        <div key={chat.id} className={css(s.ChatItem, chat.id === activeChat?.id && s.active)} onClick={setActiveChatHandler}>
            <div className={css(s.avatar)}>
                {respondProfile.src !== null && !respondProfile.loading &&
                    <img src={respondProfile?.src} alt="png"/>}
                {respondProfile.src === null && !respondProfile.loading && <SvgMyProfile/>}
                {respondProfile.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
            </div>
            <div className={css(s.main)}>
                <div className={css(s.name)}>{!!respondProfile?.user?.name && respondProfile.user?.name}</div>
                <div className={css(s.lastMessage)}>{chat.lastMessage}</div>
            </div>
            <div className={css(s.update)}>
                <div className={css(s.updateTime)}>{new Date(chat.updateDate).toLocaleDateString()}</div>
                {!!chat.newMessages && <div className={css(s.notReadMessageCount)}>
                   <span>{chat.newMessages}</span>
                </div>}
            </div>
        </div>
    )
}
