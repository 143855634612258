import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {ManagerAPI} from "../../../../../API/ManagerAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {ManagerState} from "../../../../../store/reducers/manager";
import {VerificationAPI} from "../../../../../API/VerificationAPI";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {VerificationActions, VerificationState} from "../../../../../store/reducers/verification";
import {IVerifyData} from "../../../../../types/profile";
import {StandardButton} from "../../../Button/StandardButton";
import loadingGif  from '../../../../assets/images/LoadingGif.gif'
import {SvgZipIcon} from "../../../../assets/images/svg/SvgZipIcon";
import {SvgPdfIcon} from "../../../../assets/images/svg/SvgPdfIcon";
import {SvgJpgIcon} from "../../../../assets/images/svg/SvgJpgIcon";
import {UserState} from "../../../../../store/reducers/auth";
import {Outlet, useNavigate} from "react-router-dom";
import {LoadingPage} from "../../../LoadingPage";
import {ProfileState} from "../../../../../store/reducers/profile";
import {regionList} from "../../../../../helpers/locationList";
import {useClickOutside} from "../../../../../helpers/useClickOutside";
import {SetPlace} from "../../../SetPlace";

export const VerifyBlock = () => {
    const {user} = useAppState(UserState)
    const {profile} = useAppState(ProfileState)
    const navigate = useNavigate();
    const {getVerificationData, sendVerificationData} = useThunks(VerificationAPI)
    const {setVerificationDataAction} = useActions(VerificationActions)
    const {verificationData} = useAppState(VerificationState)

    useEffect(()=>{
        if (user.verifyStatus === 0)
            navigate(`/setting/info`)
    },[user])
    useEffect(()=>{
        if (user.role === 'verifyRegular')
            navigate(`/setting/info`)
    },[user.role])

    const [fetching, setFetching] = useState(false)
    const [verifyItem, setVerifyItem] = useState<IVerifyData>({
        surName: '',
        firstName: '',
        patronymic: '',
        region: '',
        city: '',
        email: '',
        phone: '',
        croName: '',
        croNumber: '',
        comment: ''
    })

    useEffect(() => {
        getVerificationData()
        return () => {
            setVerificationDataAction(null)
        }
    }, [])

    useEffect(() => {
        setFetching(false)
        if (!verificationData) return
        setVerifyItem({
            ...verificationData.verifyData,
            region: profile ? profile.place.region : '',
            city: profile ? profile.place.city : '',
            email: profile ? profile.email : '',
            phone: profile ? profile.phone : '',
        })
    }, [verificationData])

    const changeVerifyItemField = (field: string, value: any) => {
        setVerifyItem(prev => ({
            ...prev,
            [field]: value
        }))
    }
    const sendVerificationDataHandler = () => {
        setFetching(true)
        sendVerificationData(verifyItem)
    }

    if (verificationData === null) return <LoadingPage />
    return (
        <div className={css(s.VerifyBlock, fetching && s.disable)}>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    <FieldList verifyItem={verifyItem} changeVerifyItemField={changeVerifyItemField}/>
                    <FileList/>
                    <StandardButton fetching={fetching} onClick={sendVerificationDataHandler} text={`Отправить данные на проверку`}
                                    style={{maxWidth: `900px`}}/>
                </div>
            </div>
        </div>
    )
}

const FieldList = ({verifyItem, changeVerifyItemField}: any) => {

    //  value={verifyItem.region}
    // onChange={e => changeVerifyItemField('region', e.target.value)}
    return (
        <div className={css(s.FieldList)}>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Фамилия*</div>
                    <div className={css(s.fieldMain)}>
                        <input type="text" value={verifyItem.surName}
                               onChange={e => changeVerifyItemField('surName', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Имя*</div>
                    <div className={css(s.fieldMain)}>
                        <input type="text" value={verifyItem.firstName}
                               onChange={e => changeVerifyItemField('firstName', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Отчество*</div>
                    <div className={css(s.fieldMain)}>
                        <input type="text" value={verifyItem.patronymic}
                               onChange={e => changeVerifyItemField('patronymic', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>E-mail*</div>
                    <div className={css(s.fieldMain)}>
                        <input type="text" value={verifyItem.email}
                               onChange={e => changeVerifyItemField('email', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Телефон*</div>
                    <div className={css(s.fieldMain)}>
                        <input type="text" value={verifyItem.phone}
                               onChange={e => changeVerifyItemField('phone', e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className={css(s.prof)}>
                <div className={css(s.profBtn)}
                     onClick={e => changeVerifyItemField('croName', !verifyItem.croName.length ? 'Кадастровый инженер' : "")}>
                    <input type="checkbox" checked={verifyItem.croName === `Кадастровый инженер`} onChange={() => {
                    }}/>
                    <span>Кадастровый инженер</span>
                </div>
            </div>
            {verifyItem.croName === `Кадастровый инженер` &&
                <div className={css(s.oneItemBox)}>
                    <div className={css(s.field)}>
                        <div className={css(s.fieldTitle)}>Наименование СРО*</div>
                        <div className={css(s.fieldMain)}>
                            <input type="text" value={verifyItem.croNumber}
                                   onChange={e => changeVerifyItemField('croNumber', e.target.value)}/>
                        </div>
                    </div>
                </div>}
            <div className={css(s.oneItemBox)}>
                <div className={css(s.field)}>
                    <div className={css(s.fieldTitle)}>Комментарий</div>
                    <div className={css(s.fieldMain)}>
                        <textarea className={css(s.comment)} value={verifyItem.comment}
                                  onChange={e => changeVerifyItemField('comment', e.target.value)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FileList = () => {
    const {getAddedVerificationFile, addVerificationFile, deleteVerificationFile} = useThunks(VerificationAPI)
    const {addedFiles} = useAppState(VerificationState)
    const {setAddedFileListAction} = useActions(VerificationActions)

    const deleteFileHandler = (minFile:any) => {
        if (minFile.userId === 0) return
        deleteVerificationFile(minFile)
    }

    useEffect(() => {
        getAddedVerificationFile()
        return () => {
            setAddedFileListAction(null)
        }
    }, [])

    const addFileHandler = (e: any) => {
        const file = e.target.files[0]
        addVerificationFile(file)
        e.target.value = ''
    }

    return (
        <div className={css(s.FileList)}>
            <div className={css(s.title)}>Пожалуйста, прикрепите докуметы</div>
            <div className={css(s.subTitle)}>Загрузите до 5 файлов общим объемом до 20МБ</div>
            <div className={css(s.addedFileListMain)}>
                <label className={css(s.addFileBtn)}>
                    <input type="file" accept={`.png,.jpeg,.jpg,.zip,.pdf`} onInput={addFileHandler}/>
                    +
                </label>
                {addedFiles === null &&
                    <div className={css(s.loadingFileList)}>
                        loading...
                    </div>
                }
                {addedFiles !== null &&
                    <div className={css(s.fileList)}>
                    {addedFiles?.map((minFile: any) =>
                        <div className={css(s.addedFileItem)} key={minFile.id} title={minFile.fileName} onClick={()=>deleteFileHandler(minFile)}>
                            <div className={css(s.fileImgBox)}>
                                {(()=>{
                                    switch (minFile.fileName.slice(minFile.fileName.lastIndexOf(`.`))){
                                        case `.zip`: return <SvgZipIcon />
                                        case `.jpeg`: return <SvgJpgIcon />
                                        case `.jpg`: return <SvgPdfIcon />
                                        case `.png`: return <SvgPdfIcon />
                                        case `.pdf`: return <SvgPdfIcon />
                                        default: return <SvgZipIcon />
                                    }
                                })()}
                            </div>
                            <div className={css(s.fileSizeText)}>{`${Math.ceil(minFile.fileSize / 1000 / 1000)}mb`}</div>
                            <div >{minFile.fileName}</div>
                            {minFile.userId === 0 && <div className={css(s.upload)}>
                                <img src={loadingGif} alt="loading"/>
                            </div>}
                            <div className={css(s.deleteFile)}>
                                &#x2715;
                            </div>
                        </div>)}
                </div>}
            </div>
        </div>
    )
}

