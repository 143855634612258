import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {AdminActions, AdminState} from "../../../../store/reducers/admin";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {AdminAPI} from "../../../../API/useAdmin";
import {SvgSearch} from "../../../assets/images/svg/SvgSearch";
import {AdminUser} from "../AdminUser";
import {useActions, useThunks} from "../../../../helpers/useActions";

export const AdminUserList = () => {

    const {setSearchUserAction, setUserListAction, setTotalUserListAction} = useActions(AdminActions)
    const {userList,totalUserList,searchUser} = useAppState(AdminState)
    const {getUserList,addUserList} = useThunks(AdminAPI)

    useEffect(() => {
        getUserList()
        return () =>{
            setUserListAction(null)
            setTotalUserListAction(0)
        }
    }, [searchUser])

    // подгрузка пользователей
    const [addLoad, setAddLoad] = useState(false)
    const addUserListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addUserList(()=>{setAddLoad(false)})
    }
    
    // search
    const [search, setSearch] = useState('')
    const inputSearchHandler = (e:any) => {
        if (e.target.value === ``) {
            setSearchUserAction(null)
        }
        setSearch(e.target.value)
    }
    const searchHandler = (e:any) => {
        e.preventDefault()
        setSearchUserAction(search)
    }

    return (
        <div className={css(s.UserList)}>
            <div className={css(s.container)}>
                <form className={css(s.search)} onSubmit={searchHandler}>
                    <div className={css(s.inputBox)}>
                        <input type="text" placeholder={`Поиск по e-mail`}
                               value={search}
                               onChange={inputSearchHandler}/>
                        <button className={css(s.searchBtn)} >
                            <SvgSearch/>
                        </button>
                    </div>
                </form>
                {userList &&
                    <div className={css(s.list)}>
                        {userList.map(user => <AdminUser key={user.id} user={user}/>)}
                    </div>
                }
                {!userList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                {!!userList && !userList.length && <div className={css(s.emptyList)}>Нет пользователей</div>}
                {!!userList?.length && totalUserList > 0 && totalUserList > userList.length &&
                    <div className={css(s.showMore)}>
                            <span onClick={addUserListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                    </div>}
            </div>
        </div>
    )
}
