import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {ChatItem} from "../ChatItem";
import {useAppState} from "../../../../../store/reduxStore";
import {MessengerState} from "../../../../../store/reducers/messenger";

export const MobilChatList = () => {

    const { chatList } = useAppState(MessengerState)
    return (
        <div className={css(s.MobilChatList)}>
            <div className={css(s.notActiveChat)}>
                Выберите чат.
            </div>
            <div className={css(s.chatList)}>
                {chatList?.map(chat => <ChatItem  key={chat.id} chat={chat}/>)}
                {!chatList?.length && <div className={css(s.emptyChatList)}>нет чатов</div>}
            </div>
        </div>
    )
}
