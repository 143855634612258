import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Outlet} from "react-router-dom";
import {AdminHeader} from "../../Header/AdminHeader";

export const AdminLayout = () => {

    return (
        <div className={css(s.ManagerLayout)}>
            <AdminHeader />
            <Outlet />
        </div>
    )
}
