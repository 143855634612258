import React, {useEffect, useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";
import loading from '../../../../assets/images/LoadingGif.gif'
import {useNavigate} from "react-router-dom";
import loadingGit from "../../../../assets/images/LoadingGif.gif";
import loadingGif from "../../../../assets/images/LoadingGif.gif";

export const MyWorkTask = () => {
    const navigate = useNavigate()
    const {getWorkTaskList,addWorkTaskList} = useThunks(TaskAPI)
    const {workTaskList, filterWorkTask, totalWorkTaskList} = useAppState(TaskState)
    const {setStatisticWorkTaskAction, setWorkTaskListAction, setFilterWorkTaskAction, setTotalWorkTaskAction} = useActions(TaskActions)

    // const [activeFilter, setActiveFilter] = useState<null | number>(null)
    useEffect(() => {
        if (filterWorkTask === null) setFilterWorkTaskAction(0)
        if (filterWorkTask !== null) {
            getWorkTaskList(filterWorkTask)
        }
    }, [filterWorkTask])
    useEffect(() => {
        return ()=>{
            setStatisticWorkTaskAction(null)
            setWorkTaskListAction(null)
        }
    }, [])
    const openTask = (id:number) => {
        navigate(`/task-list/${id}`)
    }

    // добавление элементов
    const [addLoad, setAddLoad] = useState(false)
    const addListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addWorkTaskList(filterWorkTask)
    }
    useEffect(() => {
        setAddLoad(false)
    }, [workTaskList])
    useEffect(() => {
        return ()=>{
            setTotalWorkTaskAction(null)
        }
    }, [])

    return (
        <div className={css(s.MyWorkTask)}>
            <div className={css(s.main)} >
                {workTaskList === null && <div className={css(s.loadList)}><img src={loading} alt="loading"/></div>}
                {workTaskList !== null && !workTaskList.length && <div className={css(s.emptyList)}>Список пуст</div>}
                {workTaskList !== null && !!workTaskList.length && workTaskList.map((item: any) =>
                    <div className={css(s.mitTask)} key={item.id} onClick={()=>openTask(item.id)}>
                        <div className={css(s.head)}>
                            <div className={css(s.status)}>
                                <span />
                                {item.workStatus === null && item.status === 1 && `Поиск исполнителей`}
                                {item.workStatus === null && item.status === 2 && `Отправлено предложение`}
                                {item.workStatus === null && item.status === 3 && `Не опубликована`}
                                {item.workStatus === 1 && `Проверка`}
                                {item.workStatus === 2 && `Проверка`}
                                {item.workStatus === 0 && `В работе`}
                                {item.workStatus === 4 && `В арбитраже`}
                                {item.status === 5 && `Завершена`}
                            </div>
                            <div className={css(s.createDate)}>
                                Создано: {new Date(item.createDate).toLocaleDateString()}
                                {item.workStatus}
                            </div>
                        </div>
                        <div className={css(s.minTaskDoMake)}>
                            {!!item.data.doMake.trim().length ? item.data.doMake : "Не заполнено"}
                        </div>
                    </div>
                )}
                {!!workTaskList && totalWorkTaskList !== null && totalWorkTaskList > workTaskList.length && <div className={css(s.showMore)}>
                            <span onClick={addListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                </div>}
            </div>
            <Filter activeFilter={filterWorkTask} setActiveFilter={(filter:number)=>setFilterWorkTaskAction(filter)}/>
        </div>
    )
}
const Filter = ({activeFilter, setActiveFilter}: any) => {
    const {statisticWorkTask} = useAppState(TaskState)

    return (
        <div className={css(s.Filter)}>
            <div className={css(s.filterItem, activeFilter === 0 && s.active)} onClick={() => setActiveFilter(0)}>
                <span className={css(s.filterItemTitle)}>все</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.all : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 1 && s.active)} onClick={() => setActiveFilter(1)}>
                <span className={css(s.filterItemTitle)}>В работе</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.worked : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 2 && s.active)} onClick={() => setActiveFilter(2)}>
                <span className={css(s.filterItemTitle)}>Поиск исполнителей</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.findExecutor : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 3 && s.active)} onClick={() => setActiveFilter(3)}>
                <span className={css(s.filterItemTitle)}>Неопубликованные</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.disable : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 4 && s.active)} onClick={() => setActiveFilter(4)}>
                <span className={css(s.filterItemTitle)}>На проверке</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.check : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 5 && s.active)} onClick={() => setActiveFilter(5)}>
                <span className={css(s.filterItemTitle)}>В арбитраже</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.arbitrage : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 6 && s.active)} onClick={() => setActiveFilter(6)}>
                <span className={css(s.filterItemTitle)}>Завершённые</span>
                <span className={css(s.filterItemValue)}>{statisticWorkTask ? statisticWorkTask.finish : <img src={loading} alt="loading"/>}</span>
            </div>
        </div>
    )
}

