import React from 'react'
import s from './index.module.scss'
import {IMinFile} from "../../../types/file";
import {css} from "../../../helpers/css";
import {SvgZipIcon} from "../../assets/images/svg/SvgZipIcon";
import {SvgJpgIcon} from "../../assets/images/svg/SvgJpgIcon";
import {SvgPdfIcon} from "../../assets/images/svg/SvgPdfIcon";

export const DownloadFileList = ({downloadFunction, fileList, fileItemStyle={}}:any) => {

    const downloadFunctionHandler = (minFile:IMinFile) => {
        downloadFunction(minFile)
    }

    return (
        <div className={css(s.FileList)}>
            {/*<div className={css(s.title)}>*/}
            {/*    Прикреплённые документы*/}
            {/*</div>*/}
            {!fileList && <div className={css(s.loadingFileList)}>Загрузка...</div>}
            {!!fileList && !fileList.length &&
                <div className={css(s.emptyFileList)}>Нет прикреплённых файлов</div>}
            {fileList !== null && !!fileList.length &&
                <div className={css(s.fileList)}>
                    {fileList?.map((minFile: any) =>
                        <div className={css(s.addedFileItem)} style={fileItemStyle} key={minFile.id} title={minFile.fileName}
                             onClick={() => downloadFunctionHandler(minFile)}>
                            <div className={css(s.fileImgBox)}>
                                {(() => {
                                    switch (minFile.fileName.slice(minFile.fileName.lastIndexOf(`.`))) {
                                        case `.zip`:
                                            return <SvgZipIcon/>
                                        case `.jpeg`:
                                            return <SvgJpgIcon/>
                                        case `.jpg`:
                                            return <SvgJpgIcon/>
                                        case `.png`:
                                            return <SvgJpgIcon/>
                                        case `.pdf`:
                                            return <SvgPdfIcon/>
                                        default:
                                            return <SvgJpgIcon/>
                                    }
                                })()}
                            </div>
                            <div className={css(s.fileSizeText)}>{`${Math.ceil(minFile.fileSize / 1000 / 1000)}mb`}</div>
                            <div>{minFile.fileName}</div>
                            <div className={css(s.downloadFile)}>
                                &#x2913;
                            </div>
                        </div>)}
                </div>}
        </div>

    )
}
