import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {OfferItem} from "../../view/components/Pages/TaskPage/ActiveTask/MyTask/OfferList/OfferItem";

interface stateType {
    startApp: boolean
    updateProfile: boolean
    updateProfileAvatarLink: boolean
    publishNewTask: boolean
    addBalance: boolean
    subtractBalance: boolean
    offerItemBtn: boolean
    getAppealList: boolean
    getBillHistoryList: boolean
}
const initialState: stateType = {
    startApp: true,
    updateProfile: false,
    updateProfileAvatarLink: false,
    publishNewTask: false,
    addBalance: false,
    subtractBalance: false,
    offerItemBtn: false,
    getAppealList: false,
    getBillHistoryList: false,
}

export const slice = createSlice({
    name: 'isLoading',
    initialState,
    reducers: {
        setLoadingStartApp: (state, action: PayloadAction<boolean>) : void => {
            state.startApp = action.payload
        },
        setUpdateProfileAction: (state, action: PayloadAction<boolean>) : void => {
            state.updateProfile = action.payload
        },
        setUpdateProfileAvatarLinkAction: (state, action: PayloadAction<boolean>) : void => {
            state.updateProfileAvatarLink = action.payload
        },
        setPublishNewTaskAction: (state, action: PayloadAction<boolean>) : void => {
            state.publishNewTask = action.payload
        },
        setAddBalanceAction: (state, action: PayloadAction<boolean>) : void => {
            state.addBalance = action.payload
        },
        setSubtractBalanceAction: (state, action: PayloadAction<boolean>) : void => {
            state.subtractBalance = action.payload
        },
        setOfferItemBtnAction: (state, action: PayloadAction<boolean>) : void => {
            state.offerItemBtn = action.payload
        },
        setAppealListAction: (state, action: PayloadAction<boolean>) : void => {
            state.getAppealList = action.payload
        },
        setBillHistoryListAction: (state, action: PayloadAction<boolean>) : void => {
            state.getBillHistoryList = action.payload
        },
    }
})

export const IsLoadingReducer = slice.reducer
export const IsLoadingActions = slice.actions
export const IsLoadingState = (state: RootState) => state.IsLoadingReducer


