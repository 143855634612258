import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {ManagerActions, ManagerState} from "../../../../../store/reducers/manager";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {ManagerAPI} from "../../../../../API/ManagerAPI";
import {useNavigate} from "react-router-dom";
import {LoadingPage} from "../../../LoadingPage";
import loadingGif from "../../../../assets/images/LoadingGif.gif";

export const ArbitrageList = () => {

    const navigate = useNavigate()
    const {arbitrageTaskList, totalArbitrageTaskList} = useAppState(ManagerState)
    const {getArbitrageTaskList,addArbitrageTaskList} = useThunks(ManagerAPI)
    const {setArbitrageTaskListAction, setTotalArbitrageTaskListAction} = useActions(ManagerActions)

    useEffect(() => {
        getArbitrageTaskList()
        return () => {
            setArbitrageTaskListAction(null)
            setTotalArbitrageTaskListAction(0)
        }
    }, [])

    const setActiveTaskHandler = (id: number) => {
        navigate(`/arbitrage/${id}`)
    }

    const [addLoad, setAddLoad] = useState(false)
    const addAppealListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addArbitrageTaskList(()=>{setAddLoad(false)})
    }

    // if (arbitrageTaskList === null) return <LoadingPage />
    return (
        <div className={css(s.ArbitrageList)}>
            {/*<div className={css(s.arbitrageListTitle)}>Задачи в арбитраже:</div>*/}

            <div className={css(s.arbitrageListMain)}>
                {arbitrageTaskList === null && <img className={css(s.loadingList)} src={loadingGif} alt="loading"/>}
                {arbitrageTaskList?.map(task =>
                    <div key={task.id} className={css(s.taskItem)} onClick={() => setActiveTaskHandler(task.id)}>
                        <div>{task.data.doMake}</div>
                    </div>)}
                {arbitrageTaskList !== null && !arbitrageTaskList.length &&
                    <div className={css(s.emptyList)}>Список арбитража пуст.</div>}
            </div>
            {!!arbitrageTaskList?.length && totalArbitrageTaskList > 0 && totalArbitrageTaskList > arbitrageTaskList.length &&
                <div className={css(s.showMore)}>
                            <span onClick={addAppealListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                </div>}
        </div>
    )
}
