import React from 'react'

export const SvgPdfIcon = () => {

    return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.62812 0.75293C4.99047 0.75293 4.46875 1.27465 4.46875 1.9123V18.1435C4.46875 18.7812 4.99047 19.3029 5.62812 19.3029H17.2219C17.8595 19.3029 18.3812 18.7812 18.3812 18.1435V5.39042L13.7437 0.75293H5.62812Z" fill="#E2E5E7"/>
                <path d="M14.9035 5.39042H18.3816L13.7441 0.75293V4.23105C13.7441 4.86871 14.2659 5.39042 14.9035 5.39042Z" fill="#B0B7BD"/>
                <path d="M18.3805 8.86875L14.9023 5.39062H18.3805V8.86875Z" fill="#CAD1D8"/>
                <path d="M16.0629 15.8248C16.0629 16.1436 15.802 16.4045 15.4832 16.4045H2.73008C2.41125 16.4045 2.15039 16.1436 2.15039 15.8248V10.0279C2.15039 9.7091 2.41125 9.44824 2.73008 9.44824H15.4832C15.802 9.44824 16.0629 9.7091 16.0629 10.0279V15.8248Z" fill="#FF4842"/>
                <path d="M4.67773 11.7365C4.67773 11.5835 4.79831 11.4165 4.9925 11.4165H6.06319C6.66606 11.4165 7.20865 11.82 7.20865 12.5933C7.20865 13.326 6.66606 13.7341 6.06319 13.7341H5.2893V14.3462C5.2893 14.5503 5.15945 14.6656 4.9925 14.6656C4.83947 14.6656 4.67773 14.5503 4.67773 14.3462V11.7365ZM5.2893 12.0002V13.155H6.06319C6.3739 13.155 6.61969 12.8808 6.61969 12.5933C6.61969 12.2692 6.3739 12.0002 6.06319 12.0002H5.2893Z" fill="white"/>
                <path d="M8.11686 14.6654C7.96382 14.6654 7.79688 14.5819 7.79688 14.3785V11.7455C7.79688 11.5792 7.96382 11.458 8.11686 11.458H9.17827C11.2964 11.458 11.2501 14.6654 9.22001 14.6654H8.11686ZM8.40902 12.0238V14.1002H9.17827C10.4298 14.1002 10.4855 12.0238 9.17827 12.0238H8.40902Z" fill="white"/>
                <path d="M12.0009 12.061V12.7978H13.1829C13.3498 12.7978 13.5168 12.9647 13.5168 13.1264C13.5168 13.2795 13.3498 13.4047 13.1829 13.4047H12.0009V14.378C12.0009 14.5403 11.8855 14.6649 11.7232 14.6649C11.5192 14.6649 11.3945 14.5403 11.3945 14.378V11.745C11.3945 11.5787 11.5197 11.4575 11.7232 11.4575H13.3504C13.5544 11.4575 13.675 11.5787 13.675 11.745C13.675 11.8934 13.5544 12.0604 13.3504 12.0604H12.0009V12.061Z" fill="white"/>
                <path d="M15.4828 16.4049H4.46875V16.9846H15.4828C15.8016 16.9846 16.0625 16.7237 16.0625 16.4049V15.8252C16.0625 16.144 15.8016 16.4049 15.4828 16.4049Z" fill="#CAD1D8"/>
            <defs>
                    <rect width="18.55" height="18.55" fill="white" transform="translate(0.990234 0.75293)"/>
            </defs>
        </svg>
    )
}
