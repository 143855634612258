import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {VerificationAPI} from "../../../../../API/VerificationAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {VerificationState} from "../../../../../store/reducers/verification";
import {useNavigate} from "react-router-dom";
import {useThunks} from "../../../../../helpers/useActions";

export const VerifyBtn = () => {
    const navigate = useNavigate();
    const {getVerificationStatus} = useThunks(VerificationAPI)
    const {verificationStatus} = useAppState(VerificationState)

    useEffect(() => {
        getVerificationStatus()
    }, [])

    if (verificationStatus === null) return null
    return (
        <div className={css(s.VerifyBtn)}>
            {verificationStatus === 0 &&
                <div onClick={() => navigate(`/setting/verify`)}>Пройти верификацию</div>}
            {verificationStatus === 1 && <div onClick={() => navigate(`/setting/verify`)}>Ожидает проверки</div>}
            {verificationStatus === 2 && <div>Верификация пройдена</div>}
            {verificationStatus === 3 &&
                <div onClick={() => navigate(`/setting/verify`)}>Верификация отклонена</div>}
            {verificationStatus === 4 && <div onClick={() => navigate(`/setting/verify`)}>Ожидает повторной проверки</div>}
        </div>
    )
}
