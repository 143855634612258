import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

export const MyWork = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if (location.pathname === `/my-work/task`) return
        if (location.pathname === `/my-work/offer`) return
        navigate('/my-work/task')
    },[location])

    return (
        <div className={css(s.MyWork)}>
            <div className={css(s.container)}>
                <div className={css(s.changePage)}>
                    <Link to={`/my-work/task`} className={css(s.taskBtn, location.pathname === `/my-work/task` && s.active)}>Заказы</Link>
                    <Link to={`/my-work/offer`} className={css(s.offerBtn, location.pathname === `/my-work/offer` && s.active)}>Заявки</Link>
                </div>
                <Outlet />
            </div>
        </div>
    )
}
