
import {AppDispatch, RootState} from "../store/reduxStore";
import {store_server, user_server, ws_messenger_server} from "../helpers/config";
import {customFetchFile, customFetchJson} from "../helpers/customFetch";
import {AuthAPI} from "./AuthAPI";
import {MessengerActions} from "../store/reducers/messenger";
import {IChat} from "../types/messenger";
import {HintActions} from "../store/reducers/hint";
import {NavigationActions} from "../store/reducers/navigation";

let client: any = null

export const MessengerAPI = {

    connectClient: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {

        try {
            const ws = new WebSocket(`${ws_messenger_server}`, `${localStorage.getItem('accessToken')}`)
            ws.onopen = () => {
                console.log(`opened`)
                client = ws
                dispatch(MessengerActions.setConnectAction(true))
            }
            ws.onmessage = async (event) => {
                const {type, payload} = JSON.parse(event.data)
                switch (type) {
                    case 'error': {
                        if (payload.status === 403) {
                            await dispatch(AuthAPI.checkAuth())
                            await dispatch(MessengerAPI.connectClient())
                        } else {
                            // dispatch(NavigationActions.setPathAction(`/error-server`))
                            console.log('appConnect неизвестная ошибка')
                        }
                        break
                    }
                    case 'getChatList': {
                        const companionId = getState().MessengerReducer.companionId
                        if (!!companionId && !!payload.list.length) {
                            await dispatch(MessengerActions.setActiveChatAction(payload.list[0]))
                            // console.log(payload[0].id)
                            // dispatch(NavigationActions.setPathAction(`/chat-list/${payload[0].id}`))
                        }
                        dispatch(MessengerActions.setChatListAction(payload.list))
                        dispatch(MessengerActions.setTotalChatListAction(payload.total))
                        break
                    }
                    case 'addChatList': {
                        dispatch(MessengerActions.addChatListAction(payload.list))
                        dispatch(MessengerActions.setTotalChatListAction(payload.total))
                        break
                    }
                    case 'getTaskChat': {
                        dispatch( MessengerActions.setActiveChatAction(payload))
                        break
                    }
                    case 'newMessage':  {
                        dispatch(MessengerActions.setNewMessageAction(payload))
                        break
                    }
                    case 'getMessageList':   {
                        dispatch(MessengerActions.setActiveMessageListAction(payload.list))
                        dispatch(MessengerActions.setTotalMessageListAction(payload.total))
                        break
                    }
                    case 'addMessageList':   {
                        dispatch(MessengerActions.addMessageListAction(payload.list))
                        dispatch(MessengerActions.setTotalMessageListAction(payload.total))
                        break
                    }
                    case 'sendMessage':   {
                        if (!!payload.newChat) {
                            dispatch(MessengerActions.updateActiveChatAction(payload.chatId))
                            // dispatch(NavigationActions.setPathAction(`/chat-list/${payload.chatId}`))
                        } else {
                            dispatch(MessengerActions.addActiveMessageListAction(payload))
                        }
                        break
                    }
                }
            }
            ws.onclose = (event) => {
                dispatch(MessengerActions.setConnectAction(false))
                console.log(`onclose`)
            }
            ws.onerror = (event) => {
                dispatch(MessengerActions.setConnectAction(false))
                console.log(`onerror`)
                dispatch(NavigationActions.setPathAction(`/`))
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Соединение блокируется, проверьте что у вас выключены блокировщики рекламы.`,
                    type: `error`
                }))
            }
        } catch (error: any) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    closeClient: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            client.close()
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    getChatList: (companionId: number | null) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return

            const offset = getState().MessengerReducer.chatList?.filter(el=>el.id !== -1).length

            client.send(JSON.stringify({
                type: 'getChatList',
                payload: {
                    companionId: companionId,
                    offset: offset ? offset : 0
                }
            }))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    addChatList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return

            const offset = getState().MessengerReducer.chatList?.filter(el=>el.id !== -1).length

            client.send(JSON.stringify({
                type: 'addChatList',
                payload: {
                    offset: offset
                }
            }))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    getTaskChat: (taskId: number ) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return

            client.send(JSON.stringify({
                type: 'getTaskChat',
                payload: {
                    taskId: taskId
                }
            }))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    getMessageList: (chatId:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            if (chatId === -1) {
                dispatch(MessengerActions.setActiveMessageListAction([]))
                return
            }

            client.send(JSON.stringify({
                type: 'getMessageList',
                payload: {
                    chatId: chatId
                }
            }))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    addMessageList: (chatId:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return

            const offset = getState().MessengerReducer.activeMessageList?.length

            client.send(JSON.stringify({
                type: 'addMessageList',
                payload: {
                    chatId: chatId,
                    offset: offset ? offset : 0
                }
            }))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    sendMessage: (message: string,messageFileList: Array<any>, activeChat: IChat) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            if (!activeChat) return

            let linkFileList = []

            // отправить файлы
            if (messageFileList.length > 0) {
                const formData = new FormData();
                formData.append('companionIdList',JSON.stringify(activeChat.companionIdList))
                for (let i = 0; i < messageFileList.length; i++) {
                    formData.append('file', messageFileList[i].data)
                }
                const response = await customFetchJson(`${store_server}/api/store/messenger/add?chatId=${activeChat.id}`, {
                    method: 'POST',
                    body: formData
                })

                if (response.error) {
                    dispatch(HintActions.setHintApp({
                        isShow: true,
                        text: `${response.payload.message}`,
                        type: 'error'
                    }))
                    return
                }

                linkFileList = response.payload
            }

            const companionId = getState().MessengerReducer.companionId
            if (activeChat.id === -1) {
                client.send(JSON.stringify({
                    type: 'sendMessage',
                    payload: {
                        chatId: activeChat.id,
                        message: message,
                        link: JSON.stringify(linkFileList.map((fileInfo:any) => fileInfo.fileName)),
                        companionId: companionId
                    }
                }))
                const total:any = getState().MessengerReducer.totalChatList
                dispatch(MessengerActions.setTotalChatListAction(total+1))
            } else {
                client.send(JSON.stringify({
                    type: 'sendMessage',
                    payload: {
                        chatId: activeChat.id,
                        message: message,
                        link: JSON.stringify(linkFileList.map((fileInfo:any) => fileInfo.fileName))
                    }
                }))
            }
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    downloadMessageFile: (chatId: number, fileName: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response = await customFetchFile(`${store_server}/api/store/messenger/download?chatId=${chatId}&fileName=${fileName}`, {
                method: 'GET',
                headers: {
                    // 'Content-Type': 'application/json',
                },
            })

            if (response.error) {
                // setActiveVerificationFilesAction(null)
                // navigate(`/verify`)
                return
            }

            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(response.payload);
            a.download = fileName;
            a.click();
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
    readChat: (chatId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!chatId || !client) return

            client.send(JSON.stringify({
                type: 'readChat',
                payload: {
                    chatId: chatId,
                }
            }))
            dispatch(MessengerActions.readChatAction(chatId))
        } catch (error: any) {
            console.log('error client', error)
            dispatch(NavigationActions.setPathAction(`/error-server`))
        } finally {}
    },
}
