import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {ManagerAPI} from "../../../../../API/ManagerAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {ManagerActions, ManagerState} from "../../../../../store/reducers/manager";
import {useParams} from "react-router-dom";
import {LoadingPage} from "../../../LoadingPage";
import {DownloadFileList} from "../../../DownloadFileList";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";
import {IMinFile} from "../../../../../types/file";
import {ChatActiveTask} from "../../../Pages/TaskPage/ActiveTask/ChatActiveTask";
import {IResponse} from "../../../../../types/api";
import {customFetch, customFetchJson} from "../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../helpers/config";
import {SvgMyProfile} from "../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {useClickOutside} from "../../../../../helpers/useClickOutside";

export const ArbitrageItem = () => {

    const { getArbitrageTask } = useThunks(ManagerAPI)
    const { activeArbitrageTask } = useAppState(ManagerState)
    const { setActiveArbitrageTaskAction } = useActions(ManagerActions)
    const params = useParams()

    useEffect(()=>{
        if (!params.id) return
        getArbitrageTask(+params.id)
    },[])

    useEffect(()=>{
       return () => {
           setActiveArbitrageTaskAction(null)
       }
    },[])

    // файлы
    const [showFileBox,setShowFileBox] = useState(false)
    const {getDownloadTaskFile,downloadFile} = useThunks(TaskAPI)
    const {setDownloadFileListAction} = useActions(TaskActions)
    useEffect(()=>{
        if (activeArbitrageTask)
            getDownloadTaskFile(activeArbitrageTask.id)
        return ()=>{
            setDownloadFileListAction(null)
        }
    },[activeArbitrageTask])
    const {downloadFiles} = useAppState(TaskState)
    const downloadFileHandler = (minFile: IMinFile) => {
        downloadFile(minFile)
    }

    if (activeArbitrageTask === null) return <LoadingPage />
    return (
        <div className={css(s.ArbitrageItem)}>
            <div className={css(s.container)}>
                <div className={css(s.main)}>
                    <Decision taskId={activeArbitrageTask.id}/>
                    <div className={css(s.lineDashed)} />
                    <div className={css(s.taskInfo)}>
                        <div className={css(s.DoMake)}>
                            {!!activeArbitrageTask.data.doMake.trim().length ? activeArbitrageTask.data.doMake : `Не заполнено`}
                        </div>
                        <div className={css(s.description)}>
                            {!!activeArbitrageTask.data.description.trim().length ? activeArbitrageTask.data.description : `Не заполнено`}
                        </div>
                        <div className={css(s.deadlineAndPrice)}>
                            <div className={css(s.price)}>
                                <div className={css(s.priceTitle)}>Стоимость:</div>
                                <div className={css(s.priceValue)}>
                                    {activeArbitrageTask.data.price} руб.
                                </div>
                            </div>
                            <div className={css(s.deadline)}>
                                <div className={css(s.deadlineTitle)}>Срок до:</div>
                                <div className={css(s.deadlineValue)}>
                                    {new Date(+activeArbitrageTask.data.deadline).toLocaleDateString()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChatActiveTask activeTask={activeArbitrageTask}/>
                    <div className={css(s.lineDashed)} />
                    <div className={css(s.arbitrageItemList)}>
                        <div className={css(s.arbitrageItemListTitle)}>Обращения</div>
                        {activeArbitrageTask.arbitrageList.map((arb:any) => <Appeal key={arb.id} item={arb}/> )}
                    </div>
                </div>
                <div className={css(s.info)}>
                    <div className={css(s.status)}>
                        <div className={css(s.statusTitle)}>Статус заказа</div>
                        <div className={css(s.statusValue)}>
                            {(()=>{
                                switch (activeArbitrageTask.workStatus){
                                    case 0: return <div className={css(s.statusWorked)}>В работе</div>
                                    case 1: return <div className={css(s.statusTest)}>На проверке</div>
                                    case 2: return <div className={css(s.statusWorked)}>Доработка</div>
                                    case 3: return <div className={css(s.statusFinish)}>Завершена</div>
                                    case 4: return <div className={css(s.statusArbitrage)}>Отправлено в арбитраж</div>
                                    default: return null
                                }
                            })()}
                        </div>
                    </div>
                    <div className={css(s.fileList)}>
                        <div className={css(s.showFileListBtn)} onClick={()=>setShowFileBox(prev=>!prev)}>
                            <div className={css(s.fileListTitle)}>Файлы заказа</div>
                            <svg className={css(s.fileListBtnIcon, showFileBox && s.show)} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99274 0.888582L0.207681 6.67375C0.0737717 6.80755 -5.82426e-08 6.98617 -4.99176e-08 7.17662C-4.15926e-08 7.36707 0.0737717 7.54569 0.207681 7.67949L0.633613 8.10553C0.911155 8.38276 1.36224 8.38276 1.63936 8.10553L6.4973 3.24759L11.3606 8.11092C11.4945 8.24473 11.6731 8.3186 11.8634 8.3186C12.054 8.3186 12.2325 8.24473 12.3665 8.11092L12.7923 7.68488C12.9262 7.55098 13 7.37246 13 7.18201C13 6.99156 12.9262 6.81294 12.7923 6.67914L7.00198 0.888582C6.86764 0.754462 6.68829 0.680796 6.49762 0.681219C6.30622 0.680796 6.12697 0.754462 5.99274 0.888582Z" fill="#212B36"/>
                            </svg>
                        </div>
                        <div className={css(s.fileBox, showFileBox && s.show)}>
                            <DownloadFileList fileItemStyle={{height: 80, width: 68}} downloadFunction={downloadFileHandler} fileList={downloadFiles}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Appeal = ({item}:any) => {

    const [profile, setProfile] = useState<any>({
        loading: true,
        user: null,
        src: null
    })
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        (async () => {
            try {
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${item.ownerArbitrageId}`, {
                    signal: signal,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setProfile((prev: any) => ({...prev, user: {}, loading: false}))
                    return
                }

                let image: any = null
                if (!!response.payload.avatarLink.length) {
                    const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                        signal: signal,
                        method: 'GET',
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })

                    const blobData = await src.blob()
                    const urlCreator = window.URL || window.webkitURL;
                    image = urlCreator.createObjectURL(blobData);
                }

                setProfile((prev: any) => ({
                    ...prev,
                    user: {
                        id: response.payload.id,
                        name: response.payload.name,
                        place: response.payload.place,
                    },
                    src: image,
                    loading: false
                }))
            } catch (error) {
                console.log('error client', error)
            } finally {
            }
        })()
        return () => {
            controller.abort()
            setProfile({
                loading: true,
                user: null,
                src: null
            })
        }
    }, [])

    return (
        <div className={css(s.Appeal)}>
            <div className={css(s.head)}>
                <div className={css(s.avatar)}>
                    {profile.src !== null && !profile.loading &&
                        <img src={profile.src} alt="png"/>}
                    {profile.src === null && !profile.loading && <SvgMyProfile/>}
                    {profile.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                </div>
                <div className={css(s.profileMain)}>
                    <div className={css(s.profileType)}>{item.ownerArbitrageId === item.ownerTaskId ? `Заказчик` : `Исполнитель`}</div>
                    {!!profile?.user?.name && <div className={css(s.name)}>{profile.user?.name}</div>}
                    {!!profile?.user?.place && <div className={css(s.place)}>{`${profile.user.place.region}${!!profile.user.place.region.trim().length && !!profile.user.place.region.trim().length ? ', ':''}${profile.user.place.city}`}</div>}
                </div>
            </div>
            <div className={css(s.appealMain)}>
                <div className={css(s.appealInner)}>
                    <div className={css(s.type)}>{item.data.general}</div>
                    <div className={css(s.percent)}>Считаю задача выполнена на <span>{item.data.percent}%</span></div>
                </div>
                <div className={css(s.comment)}><span>Комментарий:</span>{item.data.comment}</div>
            </div>
        </div>
    )
}

export const Decision = ({taskId}:any) => {
    const {sendDecisionArbitrageTask} = useThunks(ManagerAPI)

    const [answer, setAnswer] = useState('')

    const [showPercentBox, setShowPercentBox] = useState(false)
    useClickOutside(()=>{setShowPercentBox(false)},[`percentValueBtn`,`percentValueBox`])

    const [data, setData] = useState({
        percent: 0,
    })

    const sendHandler = () => {
        sendDecisionArbitrageTask(+taskId, answer, data.percent)
    }

    return (
        <div className={css(s.Decision)}>
            <div className={css(s.decisionTitle)}>Ваше решение по арбитражу</div>
            <div className={css(s.answerText)}>
                <textarea value={answer} onChange={e=>setAnswer(e.target.value)} placeholder={`Введите ответ участникам задчи.`}/>
            </div>
            <div className={css(s.btnBox)}>
                <div className={css(s.percent)}>
                    <div className={css(s.percentTitle)}>Выполнено на </div>
                    <div className={css(s.percentBtn)}>
                        <div id={`percentValueBtn`} className={css(s.percentValue)} onClick={()=>setShowPercentBox(prev=>!prev)}>
                            <span>{data.percent}%</span>
                            <svg className={css(s.arbitrageBtnIcon, showPercentBox && s.show)} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99274 0.888582L0.207681 6.67375C0.0737717 6.80755 -5.82426e-08 6.98617 -4.99176e-08 7.17662C-4.15926e-08 7.36707 0.0737717 7.54569 0.207681 7.67949L0.633613 8.10553C0.911155 8.38276 1.36224 8.38276 1.63936 8.10553L6.4973 3.24759L11.3606 8.11092C11.4945 8.24473 11.6731 8.3186 11.8634 8.3186C12.054 8.3186 12.2325 8.24473 12.3665 8.11092L12.7923 7.68488C12.9262 7.55098 13 7.37246 13 7.18201C13 6.99156 12.9262 6.81294 12.7923 6.67914L7.00198 0.888582C6.86764 0.754462 6.68829 0.680796 6.49762 0.681219C6.30622 0.680796 6.12697 0.754462 5.99274 0.888582Z" />
                            </svg>
                        </div>
                        <div id={`percentValueBox`} className={css(s.percentBox, showPercentBox && s.show)}>
                            <span  onClick={()=> {
                                setData(prev => ({...prev, percent: 0}))
                                setShowPercentBox(false)
                            }}>0%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 20}))
                                setShowPercentBox(false)
                            }}>20%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 40}))
                                setShowPercentBox(false)
                            }}>40%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 60}))
                                setShowPercentBox(false)
                            }}>60%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 80}))
                                setShowPercentBox(false)
                            }}>80%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 100}))
                                setShowPercentBox(false)
                            }}>100%</span>
                        </div>
                    </div>
                </div>
                <div className={css(s.sendBtn)} onClick={sendHandler}>
                    Обработать
                </div>
            </div>

        </div>
    )
}


