import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Outlet} from "react-router-dom";
import {Footer} from "../../Footer";
import {PopupWrapper} from "../../PopupWrapper";
import {ChangePassword} from "../../PopupWrapper/ChangePassword";
import {ManagerHeader} from "../../Header/ManagerHeader";

export const ManagerLayout = () => {

    return (
        <div className={css(s.ManagerLayout)}>
            <ManagerHeader />
            <Outlet />
            <PopupWrapper><ChangePassword id={`changePasswordPopupMain`} type={`changePassword`}/></PopupWrapper>
        </div>
    )
}
