import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {TextField} from "../../../../TextField";
import {TextInputField} from "../../../../TextInputField";
import {PriceInputField} from "../../../../PriceInputField";

export const Price = ({newTask, setNewTask}:any) => {

    useEffect(()=>{
        if (Math.abs(Number(newTask.price)) < 100 ) {
            setNewTask((prev:any)=>({...prev, price: 100}))
        }
    },[])

    const changePriceHandler = (valueHtml: string) => {
        const pattern = /^\d+(\.?)\d*$/g;
        if (!valueHtml.match(pattern)) {
            valueHtml = valueHtml.slice(0, -1)
        }
        const price = Math.abs(Number.parseInt(valueHtml))
        setNewTask((prev:any)=>({...prev, price: isNaN(price) ? 0 : price }))
    }

    const onBlurHandler = () => {
        if (Math.abs(Number(newTask.price)) < 100 ) {
            setNewTask((prev:any)=>({...prev, price: 100}))
        }
    }

    const changeMinPrice = () => {
        setNewTask((prev:any)=>({...prev, minPrice: !newTask.minPrice}))
    }

    return (
        <div className={css(s.Price)}>
            <div className={css(s.iconBox)}>
                <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5083 12.7297H3.81982V10.6531H7.75439V9.26872H3.81982V7.88433H6.77075C8.57869 7.88433 10.0496 6.33174 10.0496 4.42337C10.0496 2.51499 8.57869 0.962402 6.77075 0.962402H3.16406C2.99014 0.962402 2.82335 1.03533 2.70037 1.16514C2.57739 1.29495 2.5083 1.47101 2.5083 1.6546V6.49995H0.541016V7.88433H2.5083V9.26872H0.541016V10.6531H2.5083V12.7297ZM3.81982 2.34679H6.77075C7.85538 2.34679 8.73804 3.27848 8.73804 4.42337C8.73804 5.56825 7.85538 6.49995 6.77075 6.49995H3.81982V2.34679Z" fill="white"/>
                </svg>
            </div>
            <div className={css(s.main)} onBlur={onBlurHandler}>
                <div className={css(s.title)}>Бюджет:</div>
                {!newTask.minPrice && <PriceInputField contentEditable={true} maxLength={16} subText={` руб.`} text={`${newTask.price}`} setText={changePriceHandler}/>}
                {newTask.minPrice && <PriceInputField contentEditable={false} maxLength={16} subText={`договорная цена`} text={``} setText={()=>{}}/>}
                <div className={css(s.minPrice)}>
                    Мин. 100 руб.
                </div>
            </div>
            <div className={css(s.setMinPrice)}>
                <label >
                    <input type="checkbox" checked={newTask.minPrice} onChange={changeMinPrice}/>
                    <span>Не могу оценить стоимость заказа. Жду предложений от исполнителей</span>
                </label>
            </div>
        </div>
    )
}
