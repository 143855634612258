import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AuthAPI} from "../../../../API/AuthAPI";
import {SvgSocialVk} from "../../../assets/images/svg/SvgSocialVk";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {showPopupActions, showPopupState, ShowPopupType} from "../../../../store/reducers/showPopup";
import {CloseBtn} from "../CloseBtn";
import {useAppState} from "../../../../store/reduxStore";
import loadingGif from "../../../assets/images/LoadingGif.gif";

type PropsType = {
    id:string,
    type: keyof ShowPopupType
}

export const ResetPassword = ({id, type}:PropsType) => {

    const {resetPassword: resetPasswordAPI} = useThunks(AuthAPI)

    const {setIsShowPopupAction} = useActions(showPopupActions)
    const {resetPassword} = useAppState(showPopupState)

    const [isFetch, setIsFetch] = useState(false)
    const [errorField, setErrorField] = useState({
        email: false,
        text: '',
    })
    const [email, setEmail] = useState(``)

    useEffect(()=>{
        return ()=>{
            clearErrorHandler()
            setEmail('')
        }
    },[resetPassword])

    const clearErrorHandler = () => {
        // setErrorAuthAction(null)
        setErrorField({email: false, text: '',})
    }

    const resetPasswordHandler = (e:any) => {
        e.preventDefault()

        if (!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(String(email).toLowerCase()))
            return setErrorField(prev => ({...prev, email: true, text: 'Неверный формат e-mail'}))


        setIsFetch(true)
        resetPasswordAPI(email)
    }

    const setIsShowLoginPopupHandler = () => {
        setIsShowPopupAction({type: "resetPassword", isShow: false})
        setIsShowPopupAction({type: "login", isShow: true})
    }
    const onCloseHandler = () => {
        setIsShowPopupAction({type: "resetPassword", isShow: false})
    }

    return (
        <div id={id} className={css(s.ResetPassword)}>
            <div className={css(s.title)}>
                Востановление пароля
            </div>
            <div className={css(s.errorField)}>{errorField.text}</div>
            <form className={css(s.form)} onSubmit={resetPasswordHandler}>
                <div className={css(s.field)}>
                    <input type="text" placeholder={`E-mail*`} value={email} onChange={e=> {
                        clearErrorHandler()
                        setEmail(e.target.value)
                    }}/>
                </div>
                <button>
                    {!isFetch ? 'Отправить инструкцию' : <img src={loadingGif} alt="loading"/>}
                </button>
            </form>
            <div className={css(s.changeMode)}>
                <div className={s.modeItem} onClick={setIsShowLoginPopupHandler}>
                    Вход в кабинет
                </div>
                {/*<div className={s.modeItem} onClick={setIsShowResetPasswordPopupHandler}>*/}
                {/*    Восстановить пароль?*/}
                {/*</div>*/}
            </div>
            <CloseBtn onClose={onCloseHandler}/>
        </div>
    )
}
