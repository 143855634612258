import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useMatch, useNavigate} from "react-router-dom";

export const ChangeMode = () => {
    const navigate = useNavigate();

    return (
        <div className={css(s.ChangeMode)}>
            <div className={css(s.setInfoModeBtn, !!useMatch(`/setting/info`) && s.active)} onClick={() => navigate(`/setting/info`)}>
                <svg width="14" height="17" viewBox="0 0 14 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.85593 7.5146C7.59908 7.5146 8.32554 7.29422 8.94344 6.88133C9.56134 6.46845 10.0429 5.8816 10.3273 5.195C10.6116 4.50841 10.686 3.75291 10.541 3.02405C10.396 2.29518 10.038 1.6257 9.51251 1.10026C8.98698 0.574815 8.31743 0.217019 7.58854 0.072117C6.85965 -0.0727849 6.10416 0.00171571 5.41762 0.286198C4.73107 0.570679 4.14431 1.05236 3.73153 1.67034C3.31875 2.28831 3.0985 3.01481 3.09863 3.75796C3.09968 4.75407 3.49589 5.70908 4.20031 6.41338C4.90474 7.11768 5.85981 7.51373 6.85593 7.5146Z"/>
                    <path
                        d="M11.7716 10.618C10.8195 9.64144 9.59786 8.97084 8.2628 8.69189C6.92774 8.41294 5.53984 8.53829 4.27637 9.05193C3.01289 9.56558 1.93122 10.4442 1.1695 11.5756C0.407787 12.7069 0.000616279 14.0397 0 15.4036C0 15.5779 0.0692663 15.7451 0.192561 15.8684C0.315856 15.9917 0.48308 16.061 0.657445 16.061H13.0569C13.2312 16.061 13.3984 15.9917 13.5217 15.8684C13.645 15.7451 13.7143 15.5779 13.7143 15.4036C13.7169 14.7064 13.6092 14.0133 13.3954 13.3497C13.0771 12.3238 12.5207 11.3878 11.7716 10.618Z"/>
                </svg>
                <span>Личные данные</span>
            </div>
            <div className={css(s.setSettingModeBtn,  !!useMatch(`/setting/info-two`) && s.active)} onClick={() => navigate(`/setting/info-two`)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.6448 9.39206L13.8095 9.84341C13.6992 10.1913 13.5573 10.5287 13.3861 10.8514C13.6616 11.3101 14.2407 12.2755 14.2407 12.2755C14.3515 12.46 14.3222 12.6962 14.1702 12.8482L12.8445 14.1738C12.6926 14.3263 12.4568 14.3556 12.2719 14.2443L10.8455 13.3897C10.5246 13.5595 10.189 13.7001 9.84344 13.8095L9.39209 15.6447C9.33988 15.8535 9.15266 16 8.9375 16H7.0625C6.84734 16 6.65966 15.8535 6.60794 15.6448C6.60794 15.6448 6.29528 14.3638 6.15703 13.8099C5.78853 13.6932 5.43241 13.5417 5.09319 13.3568L3.61416 14.2443C3.42922 14.3542 3.19394 14.3258 3.0415 14.1738L1.71581 12.8482C1.56384 12.6962 1.53453 12.46 1.64531 12.2755L2.55994 10.7479C2.41253 10.4573 2.28894 10.1547 2.19053 9.84478L0.35525 9.39206C0.1465 9.33988 0 9.15266 0 8.9375V7.0625C0 6.84734 0.1465 6.65966 0.355219 6.60794L2.19097 6.15659C2.30222 5.80366 2.44684 5.46078 2.62219 5.13166L1.80372 3.76753C1.69294 3.58306 1.72225 3.34684 1.87422 3.19487L3.19991 1.86919C3.35144 1.71766 3.58763 1.68884 3.77256 1.79869C3.77256 1.79869 4.69038 2.34891 5.13991 2.61809C5.46537 2.44459 5.80594 2.30131 6.15706 2.19009L6.60797 0.35525C6.65966 0.146469 6.84734 0 7.0625 0H8.9375C9.15266 0 9.33987 0.146501 9.39206 0.355219L9.84297 2.19006C10.2042 2.3045 10.552 2.45237 10.883 2.63181C11.3449 2.35487 12.2718 1.79869 12.2718 1.79869C12.4563 1.68838 12.6925 1.71675 12.8445 1.86919L14.1702 3.19487C14.3222 3.34684 14.3515 3.58306 14.2407 3.76753L13.3979 5.1715C13.5641 5.48691 13.7019 5.81694 13.8095 6.15659L15.6447 6.60794C15.8535 6.66013 16 6.84734 16 7.0625V8.9375C16 9.15266 15.8535 9.33988 15.6448 9.39206ZM8 4.71875C6.19091 4.71875 4.71875 6.19091 4.71875 8C4.71875 9.80909 6.19091 11.2812 8 11.2812C9.80909 11.2812 11.2812 9.80909 11.2812 8C11.2812 6.19091 9.80909 4.71875 8 4.71875Z"/>
                </svg>
                <span>Настройки</span>
            </div>
        </div>
    )
}
