import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";
import {IChat} from "../../types/messenger";


interface stateType {
    companionId: number | null
    connect: boolean
    chatList: Array<any> | null
    totalChatList: number | null
    activeChat: IChat | null
    activeMessageList: Array<any> | null
    totalMessageList: number | null
}

const initialState: stateType = {
    companionId: null,
    connect: false,
    chatList: null,
    totalChatList: null,
    activeChat: null,
    activeMessageList: null,
    totalMessageList: null,
}

export const slice = createSlice({
    name: 'messenger',
    initialState,
    reducers: {
        setCompanionIdAction: (state, action: PayloadAction<number | null>): void => {
            state.companionId = action.payload
        },
        setConnectAction: (state, action: PayloadAction<boolean>): void => {
            state.connect = action.payload
        },
        setChatListAction: (state, action: PayloadAction<Array<any> | null>): void => {
            state.chatList = action.payload
        },
        addChatListAction: (state, action: PayloadAction<Array<any>>): void => {
            if (state.chatList)
                state.chatList = [...state.chatList,...action.payload]
        },
        setTotalChatListAction: (state, action: PayloadAction<number | null>): void => {
            state.totalChatList = action.payload
        },
        setActiveChatAction: (state, action: PayloadAction<IChat | null>): void => {
            state.activeChat = action.payload
        },
        updateActiveChatAction: (state, action: PayloadAction<number>): void => {
            if (!state.activeChat ) return
            if (!state.chatList ) return
            state.companionId = null
            state.activeChat.id = action.payload
            state.chatList = state.chatList.filter(chat=>chat.id !== action.payload).map(item => item.id === -1 ? {...item,id: action.payload} : item)
        },
        setActiveMessageListAction: (state, action: PayloadAction<Array<any> | null>): void => {
            state.activeMessageList = action.payload
        },
        setTotalMessageListAction: (state, action: PayloadAction<number | null>): void => {
            state.totalMessageList = action.payload
        },
        addMessageListAction: (state, action: PayloadAction<Array<any>>): void => {
            if (state.activeMessageList)
                state.activeMessageList = [...action.payload,...state.activeMessageList]
        },
        addActiveMessageListAction: (state, action: PayloadAction<any | null>): void => {
            if (state.activeMessageList) state.activeMessageList.push(action.payload)
            else state.activeMessageList = [action.payload]
            if (!!state.chatList) {
                const chat = state.chatList.find(el => el.id === action.payload.chatId)
                const newChatList = state.chatList.filter(el => el.id !== action.payload.chatId)
                newChatList.unshift(chat)
                state.chatList = newChatList
            }
        },
        setNewMessageAction: (state, action: PayloadAction<any>): void => {
            const chat = action.payload.chat
            const message = action.payload.message

            if (!!state.activeMessageList && !!state.activeChat && state.activeChat.id === chat.id) {
                state.activeMessageList.push(message)
            } else if (!!state.chatList && chat.chatType === 0) {
                const newChatList = state.chatList.filter(el => el.id !== chat.id)
                newChatList.unshift(chat)
                state.chatList = newChatList
            }
        },
        readChatAction: (state, action: PayloadAction<number>): void => {
            if (!state.chatList || !action.payload) return
            state.chatList.map(el => el.id === action.payload ? el.newMessages = 0 : false)
        },
    }
})

export const MessengerReducer = slice.reducer
export const MessengerActions = slice.actions
export const MessengerState = (state: RootState) => state.MessengerReducer


