import jwt_decode from "jwt-decode";
import {useActions} from "../helpers/useActions";
import {AppDispatch, RootState, useAppDispatch, useAppState} from "../store/reduxStore";
import {UserState} from "../store/reducers/auth";
import {useCallback, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AuthAPI} from "./AuthAPI";
import {MessengerActions, MessengerState} from "../store/reducers/messenger";
import {IResponse} from "../types/api";
import {customFetchJson} from "../helpers/customFetch";
import {user_server, ws_notice_server} from "../helpers/config";
import {NoticeActions, NoticeReducer} from "../store/reducers/notice";
import {VerificationActions} from "../store/reducers/verification";
import {VerificationAPI} from "./VerificationAPI";
import {TaskAPI} from "./TaskAPI";
import {BillAPI} from "./BillAPI";
import {HintActions} from "../store/reducers/hint";

let client: any = null

export const NoticeAPI = {

    updateOnlineMap: new Map(),

    noticeConnect: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {

        try {
            const ws = new WebSocket(`${ws_notice_server}`, `${localStorage.getItem('accessToken')}`)
            // const ws = new WebSocket(`${ws_notice_server}`)
            ws.onopen = () => {
                client = ws
                ws.send(JSON.stringify({
                    type: `getNotice`
                }))
                ws.send(JSON.stringify({
                    type: `getDemonNotice`,
                    payload: {
                        type: 'messagesCount'
                    }
                }))
            }
            ws.onmessage = async (event) => {
                const {type, payload} = JSON.parse(event.data)
                switch (type) {
                    case 'error': {
                        if (payload.status === 403) {
                            const refreshToken = await localStorage.getItem('refreshToken')
                            if (!refreshToken) {
                                return
                            }
                            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/checkAuth`,{
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    refreshToken
                                })
                            })

                            if (response.error) {
                                await localStorage.removeItem(`accessToken`)
                                await localStorage.removeItem(`refreshToken`)
                                return
                            }

                            await localStorage.setItem('accessToken',response.payload.accessToken)
                            dispatch(NoticeAPI.noticeConnect())
                        } else {
                            console.log('appConnect неизвестная ошибка')
                        }
                        break
                    }
                    case 'newNotice': {
                        if (window.location.pathname.includes(payload.srcLink)) {
                            dispatch(NoticeAPI.readNotice(payload.id))
                            return
                        }
                        dispatch(NoticeActions.pushNoticeAction(payload))
                        break
                    }
                    case 'getNotice': {
                        dispatch(NoticeActions.setNoticeAction(payload))
                        break
                    }
                    case 'newDemonNotice': {
                        const demonType = payload.type
                        switch (demonType){
                            case 'messagesCount': {
                                dispatch(NoticeActions.setDemonNoticeMessagesCountAction(payload.data))
                                break
                            }
                            case 'changeVerify': {
                                dispatch(VerificationAPI.getVerificationStatus())
                                break
                            }
                            case 'changeVerifyUserStatus': {
                                dispatch(AuthAPI.checkAuth())
                                break
                            }
                            case 'newRespond': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getUserOfferList(payload.data))
                                }
                                break
                            }
                            case 'taskUpdate': {
                                console.log(payload.data)
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'offerListUpdate': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getUserOfferList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'responseRespond': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getMyRespond(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'deselectOffer': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getMyRespond(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'takeWorkTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'sendTestTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'cancelTestTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'agreeTestTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'rejectTestTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'sendArbitrageTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'cancelArbitrageTask': {
                                if (window.location.pathname === `/task-list/${payload.data}`) {
                                    dispatch(TaskAPI.getActiveTaskList(payload.data))
                                }
                                if (window.location.pathname === `/my-work/task`) {
                                    dispatch(TaskAPI.updateWorkTaskItem(payload.data))
                                }
                                if (window.location.pathname === `/my-work/offer`) {
                                    dispatch(TaskAPI.updateWorkOfferItem(payload.data))
                                }
                                break
                            }
                            case 'updateBalance': {
                                dispatch(BillAPI.getMyBalance())
                                break
                            }
                            case 'updateBillHistory': {
                                dispatch(BillAPI.getBillHistory())
                                break
                            }
                        }
                        // dispatch(NoticeActions.setNoticeAction(payload))
                        break
                    }
                    case 'getDemonNotice': {
                        const demonType = payload.type
                        switch (demonType){
                            case 'messagesCount': {
                                if (window.location.pathname.includes(`/chat-list`)) {
                                    // dispatch(NoticeAPI.readDemonNotice(`messagesCount`))
                                } else {
                                    dispatch(NoticeActions.setDemonNoticeMessagesCountAction(payload.data))
                                }
                                break
                            }
                        }
                        // dispatch(NoticeActions.setNoticeAction(payload))
                        break
                    }
                    case 'checkOnline': {
                        const callback = NoticeAPI.updateOnlineMap.get(payload.userId)
                        callback(payload.isOnline)
                        NoticeAPI.updateOnlineMap.delete(payload.userId)
                        break
                    }
                    case '': {
                        console.log(payload)
                        // dispatch(NoticeActions.setNoticeAction(payload))
                        break
                    }
                }
            }
            ws.onclose = (event) => {
                console.log(event)
                console.log(`onclose`)
            }
            ws.onerror = (event) => {
                console.log(event)
                dispatch(HintActions.setHintApp({
                    isShow: true,
                    text: `Соединение блокируется, проверьте что у вас выключены блокировщики рекламы.`,
                    type: `error`
                }))
                console.log(`onerror`)
            }
        } catch (error: any) {

        } finally {}
    },
    closeNoticeConnect: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            client.close()
        } catch (error: any) {

        } finally {}
    },
    readNotice: (noticeId: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            client.send(JSON.stringify({
                type: `readNotice`,
                payload: {noticeId}
            }))
            dispatch(NoticeActions.deleteNoticeAction(+noticeId))
        } catch (error: any) {

        } finally {}
    },
    readAllNotice: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            client.send(JSON.stringify({
                type: `readAllNotice`
            }))
            dispatch(NoticeActions.clearNoticeAction())
        } catch (error: any) {

        } finally {}
    },
    readDemonNotice: (demonNoticeType: string) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return
            client.send(JSON.stringify({
                type: `readDemonNotice`,
                payload: {type: demonNoticeType}
            }))
            switch (demonNoticeType) {
                case "messagesCount": {
                    dispatch(NoticeActions.deleteDemonNoticeMessagesCountAction())
                    break
                }
            }

        } catch (error: any) {

        } finally {}
    },
    updateOnline: (userId: number, callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            if (!client) return

            NoticeAPI.updateOnlineMap.set(userId, callback)

            client.send(JSON.stringify({
                type: `checkOnline`,
                payload: {userId: userId}
            }))
        } catch (error: any) {

        } finally {}
    },
}
