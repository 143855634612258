import jwt_decode from "jwt-decode";
import {useActions} from "../helpers/useActions";
import {IsLoadingActions} from "../store/reducers/isLoading";
import {AppDispatch, RootState, useAppDispatch, useAppState} from "../store/reduxStore";
import {UserActions, UserState} from "../store/reducers/auth";
import {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {application_server, bill_server, user_server} from "../helpers/config";
import {customFetchJson} from "../helpers/customFetch";
import {IUser} from "../types/user";
import {showPopupActions} from "../store/reducers/showPopup";
import {IResponse} from "../types/api";
import {ProfileActions, ProfileState} from "../store/reducers/profile";
import {IProfile} from "../types/profile";
import {AdminActions, AdminState} from "../store/reducers/admin";
import {ManagerActions} from "../store/reducers/manager";
import {NavigationActions} from "../store/reducers/navigation";
import {AppActions} from "../store/reducers/app";

export const AdminAPI = {

    getUserList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            try {

                const offset = getState().AdminReducer.userList?.length
                const search = getState().AdminReducer.searchUser

                const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/userList?offset=${offset?offset:0}&search=${!!search?search:``}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    console.log(response.payload)
                    dispatch(NavigationActions.setPathAction(`/error`))
                    return
                }

                dispatch(AdminActions.setUserListAction(response.payload.list))
                dispatch(AdminActions.setTotalUserListAction(response.payload.total))
            }
            catch (error) {
                dispatch(NavigationActions.setPathAction(`/error-server`))
                console.log('error client', error)
            }
            finally { }
        } catch (error) {
            console.log('error client', error)
        } finally {

        }
    },

    addUserList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            try {

                const offset = getState().AdminReducer.userList?.length
                const search = getState().AdminReducer.searchUser

                const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/userList?offset=${offset?offset:0}&search=${!!search?search:``}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    console.log(response.payload)
                    dispatch(NavigationActions.setPathAction(`/error`))
                    return
                }

                dispatch(AdminActions.addUserListAction(response.payload.list))
                dispatch(AdminActions.setTotalUserListAction(response.payload.total))
            }
            catch (error) {
                dispatch(NavigationActions.setPathAction(`/error-server`))
                console.log('error client', error)
            }
            finally { }
        } catch (error) {
            console.log('error client', error)
        } finally {
            callback()
        }
    },

    setManagerRole: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/setManagerRole`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id
                })
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(AdminActions.changeRoleUserAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },

    deleteUser: (id: number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${user_server}/api/auth/deleteUser`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id
                })
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(AdminActions.deleteUserAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },

    addNewFaq: (data:{ask:string,answer:string},callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/faq/add`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ask: data.ask,
                    answer: data.answer,
                })
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AppActions.addNewFaqAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    deleteFaq: (id: number,callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/faq/${id}`,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AppActions.deleteFaqAction(id))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    updateAbout: (data:any, callback:Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/about/updateInfo`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data
                })
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AppActions.setAboutInfoAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    updateHeadline: (headline:string, callback:Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${application_server}/api/app/setHeadline`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    headline:headline
                })
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AppActions.setContentAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    getPercent: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getPercent`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AdminActions.setPercentAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {}
    },

    updatePercent: (newPercent:number, callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/updatePercent`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newPercent: newPercent
                })
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AdminActions.setPercentAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            callback()
        }
    },

    getAvailableFunds: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getAvailableFunds`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AdminActions.setAvailableFundsAction(response.payload))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {}
    },

    getWithdrawHistoryList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const countList = getState().AdminReducer.withdrawHistoryList?.length

            const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getWithdrawHistoryList?offset=${countList ? countList : 0}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(AdminActions.setWithdrawHistoryListAction(response.payload.list))
            dispatch(AdminActions.setTotalWithdrawHistoryListAction(response.payload.total))
        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {}
    },

    // addWithdrawHistoryList: (callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    //     try {
    //         const countList = getState().AdminReducer.withdrawHistoryList?.length
    //
    //         const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/getWithdrawHistoryList?offset=${countList ? countList : 0}`,{
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //         if (response.error) {
    //             console.log(response.payload)
    //             dispatch(NavigationActions.setPathAction(`/error`))
    //             return
    //         }
    //         dispatch(AdminActions.addWithdrawHistoryListAction(response.payload.list))
    //         dispatch(AdminActions.setTotalWithdrawHistoryListAction(response.payload.total))
    //     }
    //     catch (error) {
    //         dispatch(NavigationActions.setPathAction(`/error-server`))
    //         console.log('error client', error)
    //     }
    //     finally {callback()}
    // },

    // sendWithdrawMoney: (money: number, callback:Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    //     try {
    //         const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/withdrawMoney`,{
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 money: money
    //             })
    //         })
    //         if (response.error) {
    //             console.log(response.payload)
    //             dispatch(NavigationActions.setPathAction(`/error`))
    //             return
    //         }
    //         dispatch(AdminActions.setAvailableFundsAction(response.payload.availableFunds))
    //         dispatch(AdminActions.unshiftWithdrawHistoryListAction(response.payload.withdrawHistoryItem))
    //     }
    //     catch (error) {
    //         dispatch(NavigationActions.setPathAction(`/error-server`))
    //         console.log('error client', error)
    //     }
    //     finally {callback()}
    // },
    // cancelWithdraw: (historyId:number, callback: Function) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    //     try {
    //         const response: IResponse<any> = await customFetchJson(`${bill_server}/api/bill/cancelWithdraw?historyId=${historyId}`,{
    //             method: 'DELETE',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //         if (response.error) {
    //             console.log(response.payload)
    //             dispatch(NavigationActions.setPathAction(`/error`))
    //             return
    //         }
    //         dispatch(AdminActions.setAvailableFundsAction(response.payload.availableFunds))
    //         dispatch(AdminActions.deleteWithdrawHistoryItemAction(historyId))
    //     }
    //     catch (error) {
    //         dispatch(NavigationActions.setPathAction(`/error-server`))
    //         console.log('error client', error)
    //     }
    //     finally {callback()}
    // },

}
