import React from 'react';
import s from './index.module.scss'
import {css} from "../../../../helpers/css";

export const Agreement = () => {
    return (
        <div className={css(s.Agreement)} style={{border: 'solid 2px #eee', padding: '20px'}}>
            <p><strong>Настоящее Пользовательское соглашение сервиса Zemsite </strong>(далее также <strong>"Соглашение"</strong>) заключается между компанией <strong>ООО"НАДЕЖНЫЙ ПАРТНЕР"</strong>, зарегистрированной под номером 1167847327230, находящейся по адресу: 197341, город Санкт-Петербург, ул. 3-я линия 1-й половины, д. 2а литер б, офис 41, в лице Директора Семенова Александра Владимировича, действующей на основании Устава, и лицом, успешно прошедшим регистрацию на сайте Zemsite.ru и начавшим использование Сервиса Zemsite.</p>
            <p><strong>Пожалуйста, внимательно изучите текст настоящего Соглашения.</strong> Принятие настоящего Соглашения путём успешного прохождения регистрации на сайте Zemsite.ru и начала использования сервиса означает <strong>полное и безоговорочное принятие условий настоящего Соглашения</strong> и всех иных документов, ссылки на которые приведены в настоящем Соглашении (далее &ndash; <strong>"Связанные документы"</strong>).</p>
            <p>&nbsp;</p>
            <p><strong>Настоящее соглашение не является возмездным договором оказания услуг, передачей прав на использование Сервиса Zemsite, лицензионным договором или иным договором, предполагающим оплату услуг ООО"НАДЕЖНЫЙ ПАРТНЕР", или вознаграждения ООО"НАДЕЖНЫЙ ПАРТНЕР"</strong>, а представляет собой свод общих правил и требований, регулирующих использование Сервиса Пользователем.</p>
            <p>Принимая условия настоящего Соглашения и создавая Личный Кабинет в Сервисе Zemsite, Вы соглашаетесь выполнять и соблюдать все правила и требования по использованию Сервиса Zemsite, условия Связанных Документов, соглашаетесь с правилами и ценами оплаты услуг третьих лиц и правилами осуществления платежей в рамках Сервиса, а <strong>ООО"НАДЕЖНЫЙ ПАРТНЕР" </strong>предоставляет Вам доступ к Сервису на условиях настоящего Соглашения и Связанных Документов.</p>
            <p>&nbsp;</p>
            <p>Настоящее Пользовательское соглашение регулирует ваш доступ и использование сайта Zemsite.ru, продуктов, функций, услуг, технологий и программного обеспечения, опубликованных на нем, предоставляемых <strong>ООО"НАДЕЖНЫЙ ПАРТНЕР"</strong>. <strong>ООО"НАДЕЖНЫЙ ПАРТНЕР"</strong> именуется в документе &laquo;Zemsite&raquo;, &laquo;мы&raquo; или &laquo;нас&raquo;. А &laquo;вы&raquo; или &laquo;Пользователь&raquo; обозначает вас как пользователя Сайта.</p>
            <p>&nbsp;</p>
            <p>В соответствии с условиями, изложенными в настоящем документе, Zemsite может по своему усмотрению вносить изменения в настоящее Пользовательское соглашение и любые другие соглашения, входящие в состав Пользовательского соглашения, в любое время, опубликовав измененную версию на Сайте. Любые изменения Пользовательского соглашения вступают в силу с указанной даты последнего обновления (&laquo;Дата последнего обновления&raquo;).</p>
            <p>&nbsp;</p>
            <p>Вы должны прочитать, согласиться и принять все условия, содержащиеся в настоящем Пользовательском соглашении, а также в Связанных документах, чтобы использовать Сервис Zemsite. Использование вами Сервиса Zemsite подтверждает ваше согласие с Пользовательским соглашением и Связанными документами. Вы соглашаетесь не нарушать Правила и условия Связанных документов при использовании сервиса Zemsite. Если вы не принимаете настоящее Пользовательское соглашение или Связанные документы, вы не должны использовать Сервис Zemsite после даты последнего обновления документа. Для получения более подробной информации о правилах, касающихся использования Сайта, обратитесь к соответствующим разделам настоящего документа.</p>
            <p>&nbsp;</p>
            <p>Ни при каких условиях, Пользователю не предоставляются какие-либо имущественные интеллектуальные права в отношении Сервиса, его программного обеспечения.</p>
            <p>&nbsp;</p>
            <p>Дата последнего обновления: 1 января 2023 г.</p>
            <p>&nbsp;</p>
            <p>Вы можете связаться с нашей Службой поддержки 24/7, если у вас есть какие-либо вопросы относительно Сервиса Zemsite или Пользовательского соглашения. Вам ответят в порядке очереди. Вы можете связаться с нашей Службой поддержки по<a href="https://zemsite.ru/contact"> ссылке</a>.&nbsp;</p>
            <p>&nbsp;</p>
            <p>Zemsite гарантирует, что обладает всеми правами, необходимыми для предоставления Пользователю возможности использования Сервиса Zemsite по настоящему Соглашению.</p>
            <p>&nbsp;</p>
            <p>Настоящее Соглашение вступает в силу в момент принятия его условий Пользователем, и продолжает действовать в полной силе до момента прекращения использования Сервиса Zemsite Пользователем.</p>
            <p>Zemsite вправе прекратить действие настоящего Соглашения в одностороннем порядке в любой момент времени, по единоличному усмотрению Zemsite, в том числе в случае нарушения Пользователем любого положения Соглашения, либо любого положения любого из Связанных Документов.</p>
            <h2><strong>УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</strong></h2>
            <p>&nbsp;</p>
            <ul>
                <li>Пользователи должны быть совершеннолетними по закону государства, из которого они ведут бизнес, чтобы использовать Сервис Zemsite.&nbsp;</li>
                <li>Чтобы создать или заказать задачу, вы должны зарегистрироваться, используя действующий адрес электронной почты.</li>
                <li>Каждый Пользователь может создать только одну учетную запись. Zemsite оставляет за собой право удалять дублирующие учетные записи.</li>
                <li>Пользователь имеет право удалить свою Учетную запись в любое время только после того, как все заказы в его учетной записи будут выполнены и завершены. Если Пользователь решит удалить свою Учетную запись, он соглашается на окончательное удаление такой Учетной записи.</li>
                <li>Пользователи не имеют права продавать, покупать, дарить учетные записи Zemsite и передавать их данные третьим лицам.</li>
                <li>При регистрации Пользователи должны создать подходящий логин, который будет виден другим Пользователям. Логин может содержать латинские буквы, цифры, дефисы и символы подчеркивания. Запрещены логины, содержащие номера телефонов, слова, зарезервированные для сотрудников Zemsite, ненормативная лексика, а также оскорбительные выражения. Zemsite оставляет за собой право изменить любой логин, который нарушает эти условия.</li>
                <li>Пользователи несут ответственность за защиту своих регистрационных данных от злонамеренного использования Сервиса третьими лицами, и должны незамедлительно<a href="https://zemsite.ru/contact"> уведомить Zemsite</a> о злоупотреблении использования сервиса.</li>
                <li>Пользователи несут личную ответственность за весь контент, размещенный на Zemsite, включая, помимо прочего, задачи, изображения, видео, отзывы и комментарии. Создавая учетную запись на Zemsite, Пользователи подтверждают, что они понимают и согласны с этими условиями, а также соглашаются возместить любые расходы, понесенные Zemsite, в случае предъявления претензии в отношении авторских прав против Zemsite.</li>
                <li>Пользователь не является работником, сотрудником или агентом Zemsite, и не имеет права представлять себя таковым.</li>
                <li>Пользователь не вправе передавать свои права и обязанности по настоящему Соглашению полностью или частично. Zemsite может передать свои права и обязанности по настоящему Соглашению или любые свои права и обязанности по настоящему Соглашению без согласия Пользователя в любое время.</li>
                <li>Запрещены ненормативная лексика, грубое общение, пошлость, несанкционированная реклама, а также размещение информации и материалов, негативно влияющих на имидж бренда Zemsite.&nbsp;</li>
                <li>Zemsite оставляет за собой право удалять любые материалы, размещенные на Сервисе Zemsite, включая, помимо прочего, задачи, отзывы, комментарии и т. д., а также приостанавливать работу или удалять Учетные записи Пользователей без объяснения причин. В случае подозрения на несанкционированный доступ к Учетной записи, спам или другие подозрительные действия в вашей Учетной записи Zemsite может временно приостановить действие Учетной записи до тех пор, пока учетная запись снова не будет считаться безопасной. В случае блокировки Учетной записи, Пользователь может выполнить активные заказы, но не может начинать работу над новыми заказами. Пользователь может вывести деньги со своего баланса на кредитную/дебетовую карту или другим способом, доступным на странице<a href="https://zemsite.ru/setting/info"> Баланса</a>. Заблокированные Пользователи могут связываться только со Службой поддержки, а общение и чаты с другими пользователями будут недоступны.</li>
                <li>Спорные ситуации и другие проблемы, включая финансовые вопросы, можно решить, обратившись в Службу поддержки Zemsite по электронной почте или <a href="https://zemsite.ru/contact">отправив запрос</a> на Zemsite.ru. Служба поддержки рассмотрит обращение и примет меры для решения проблемы. Уникальные проблемы без применимых или регулирующих правил будут решаться по усмотрению Службы поддержки Zemsite.</li>
                <li>Вы не можете разглашать информацию, полученную во время работы на Zemsite, включая, помимо прочего, спецификации и условия заказов, а также личную переписку с другими пользователями и представителями Zemsite. Вы можете раскрывать информацию о своих задачах, доходе на Zemsite и другую информацию, связанную с вашей учетной записью, если эта информация не нарушает конфиденциальность других пользователей.</li>
                <li>Все продавцы, которые получают доход, предлагая услуги и выполняя заказы с использованием Сервиса Zemsite, несут единоличную ответственность за соблюдение ими местных правил и законов, включая, помимо прочего, регистрацию в качестве юридического лица, ИП или самозанятого, положения лицензировании и налоговом законодательстве.</li>
                <li>Пользователи не могут использовать неэтичные или недобросовестные рекламные методы для продвижения партнерских ссылок Zemsite, копировать Сервис Zemsite полностью или частично, а также использовать брендинг или дизайн Zemsite для введения других в заблуждение.&nbsp;</li>
                <li>По своему собственному усмотрению Zemsite имеет право в одностороннем порядке прекратить действие настоящего Пользовательского соглашения в любой момент, в том числе в случае нарушения Пользователем любого положения настоящего Соглашения или любого положения любого из Связанных документов. В случае блокировки пользователя за нарушение Соглашения, если это возможно, средства оставшиеся на балансе будут возвращены на карту, с которой было пополнение или будет осуществлено перечисление средств на предоставленные пользователем реквизиты. Если произвести перечисление будет невозможно, средства останутся на балансе пользователя.</li>
                <li>В этом документе под &laquo;Связанными документами&raquo; подразумеваются <a href="https://zemsite.ru/policy">Политика конфиденциальности</a></li>
            </ul>
            <p>&nbsp;</p>
            <h1><strong>ПОЛИТИКА ОБРАБОТКИ ДАННЫХ</strong></h1>
            <p>&nbsp;</p>
            <ul>
                <li>Регистрируясь на Zemsite, вы соглашаетесь на хранение и обработку введенных вами данных и на получение информации от Zemsite технического (оповещения, связанные с работой Сервиса) и маркетингового характера в виде&nbsp; рассылки на указанный вами при регистрации адрес&nbsp; электронной почты.</li>
                <li>Zemsite может собирать, хранить и обрабатывать такие данные, как файлы cookie, идентификаторы (логин и пароль), иную обезличенную информацию по мере необходимости для работы веб-сайта, эффективной реализации партнерской программы и в рекламных целях.</li>
                <li>Zemsite не собирает, не хранит и не обрабатывает персональные данные пользователей, кроме случаев-исключений. Такие случаи, цели обработки и объем обрабатываемых персональных данных в этих случаях будут указаны в<a href="https://zemsite.ru/policy"> Политике конфиденциальности</a>.</li>
                <li>Zemsite может контролировать личную переписку между Пользователями для обеспечения соблюдения Правил сервиса и Связанных документов. Это включает в себя право Zemsite просматривать содержание личной переписки.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h1><strong>ПРАВИЛА СЕРВИСА Zemsite</strong></h1>
            <p>&nbsp;</p>
            <h3><strong>ОБЩИЕ УСЛОВИЯ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Zemsite</strong> &mdash; это цифровой онлайн-маркетплейс, где компании и физические лица могут покупать и продавать фриланс-услуги.&nbsp;</li>
                <li><strong>Покупатели </strong>&mdash; это Пользователи, приобретающие фриланс-услуги на Zemsite.</li>
                <li><strong>Исполнитель </strong>&mdash; это Пользователи, которые продают фриланс-услуги на Zemsite.</li>
                <li>Каждый <strong>Пользователь</strong> может быть продавцом и покупателем одновременно.</li>
                <li><strong>Задача </strong>&mdash; это фриланс-услуга, предлагаемая в каталоге.&nbsp;</li>
                <li><strong>Учетная запись (Личный кабинет)</strong> &mdash; это личный раздел Zemsite, уникальный для каждого Пользователя. Пользователи могут получить доступ к своим учетным записям, введя данные аутентификации на странице входа Zemsite.</li>
                <li><strong>Портфолио</strong> &ndash; это коллекция примеров работ Исполнителя.</li>
                <li><strong>Каталог исполителей</strong> относится ко всем задачам, предлагаемым Исполнителями на Zemsite. Покупатели используют каталог, чтобы купить задачи у продавцов.</li>
                <li><strong>Проект</strong> - задание, размещенное Покупателем на Бирже.</li>
                <li><strong>Страница задачи </strong>&mdash; это специальная страница для каждой отдельной задачи. Покупатели могут приобрести, прочитать отзывы и узнать больше о задаче на странице задачи.</li>
                <li>На <strong>странице Заказа</strong> Покупатели и Продавцы общаются друг с другом о приобретенной задаче.</li>
                <li>Ваш <strong>Баланс</strong> включает в себя ваши пополнения и возмещенные платежи после отмены заказа (в качестве покупателя), а также доходы в качестве продавца. Вы можете просмотреть свой Баланс в разделе &laquo;Баланс&raquo; в вашем аккаунте.</li>
                <li><strong>Партнеры</strong> &mdash; это платежные системы или другие службы, которые осуществляют всю работу с платежами: принимают, передают, возвращают или удерживают платежи, а также удерживают комиссию по платежам в соответствии со своими условиями. Перечень партнеров, через которые осуществляется выплата/пополнение баланса, доступен на странице выплаты/пополнения<a href="https://zemsite.ru/setting/info"> баланса</a> или на страницах / формах, где эти сервисы оказывают свои услуги.&nbsp;</li>
                <li><strong>Доход</strong> &mdash; это деньги, которые Продавцы получают от выполненных заказов. Свой доход продавцы могут либо выводить, либо использовать для покупок на Zemsite в соответствии с настоящим Пользовательским соглашением.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>СОЗДАНИЕ ЗАКАЗА</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Покупатели вносят оплату в счет заказа заранее. Платежи надежно удерживаются Партнерами до тех пор, пока заказ не будет выполнен.</li>
                <li>Перед началом работы Пользователь (Продавец) обязан убедиться, что тематика заказа не нарушает закон. Если заказ нарушает настоящее Соглашение, Продавец обязан отменить его и<a href="https://zemsite.ru/contact"> обратиться</a> в Службу поддержки.</li>
                <li>Если задание Покупателя отличается по описанию и объему от услуги, описанной Продавцом, и Продавец принял заказ, приоритет отдается заданию Покупателя. Задания можно давать в переписке до оформления заказа, а также при заказе задачи.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ВЫПОЛНЕНИЕ ЗАКАЗА</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Заказ помечается как выполненный после подтверждения Покупателем или после завершения арбитража.&nbsp;</li>
                <li>По правилам сервиса Zemsite, описанным в настоящем Соглашении, результат работы продавца должен быть качественным. Если результат содержит чрезмерное количество объективно проверяемых ошибок, заказ считается невыполненным.</li>
                <li>Согласно Правилам Zemsite, описанным в настоящем Соглашении, если покупатель не может проверить работу продавца (например, если продавец не предоставил доказательство проделанной работы или конечный продукт), заказ считается невыполненным.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ПРОВЕРКА</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Заказы, удовлетворяющие критериям, изложенным в описании задачи и требованиям Покупателя, считаются успешно выполненными. Если задача не выполнена, явно не соответствует стандартам, указанным на странице задачи, или не соответствует требованиям Покупателя, Покупатель может вернуть заказ Продавцу на доработку. В этом случае Продавцы должны внести столько изменений, пока работа не будет соответствовать требованиям заказа без дополнительной оплаты.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ОТМЕНА ЗАКАЗА</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>И Продавцы, и Покупатели имеют право отменить любой заказ, указав причину отмены. После подтверждения отмены другой стороной платеж возвращается Покупателю. Если Покупатель решает отменить заказ из-за того, что Продавец просрочил заказ, средства будут немедленно возвращены Покупателю без необходимости подтверждения со стороны Продавца.</li>
                <li>Заказы не могут быть отменены на основании субъективной оценки Покупателем работы Продавца, если услуга была оказана в соответствии с информацией, представленной на странице задачи.</li>
                <li>Zemsite оставляет за собой право в любое время отменить любой заказ, нарушающий настоящее Пользовательское соглашение.</li>
                <li>Zemsite оставляет за собой право отменить любые выполненные заказы, если они нарушают настоящее Пользовательское соглашение, а также заказы, в которых просрочено время взятия в работу или выполнения. Распространенные причины отмены:</li>
                <ul>
                    <li>Продавец не взял заказ в работу в течение 24 часов или просрочил сдачу заказа и не отвечает более двух недель.</li>
                    <li>Пользователи демонстрируют агрессивное поведение, используют материалы заказа (например, личную информацию) друг против друга, или Покупатели угрожают Продавцам плохим отзывом.</li>
                    <li>Заказ создан на услуги, входящие в список запрещенных на Zemsite или заказ признан мошенническим и проч.</li>
                    <li>Пользователи опубликовали в заказе материалы, которые нарушают права интеллектуальной собственности третьих лиц.</li>
                    <li>Пользователи откладывают сдачу работы на проверку, предоставление файлов или информации, необходимых для завершения заказа, с намерением получить положительные отзывы, дополнительные опции или дополнительную оплату.</li>
                    <li>Продавец сдает заказ на проверку без полного результата с целью продления срока заказа.</li>
                    <li>Продавец использовал материалы, нарушающие чужую интеллектуальную собственность.</li>
                    <li>Результат работы Продавца содержит ошибки, неисправен или не работает.</li>
                    <li>Покупатель не приобрел прав на коммерческое использование результата работы и, как выяснилось, использовал результат работы в коммерческих целях.</li>
                </ul>
            </ul>
            <ul>
                <li>В таких случаях при отмене заказа сумма, уплаченная за заказ, будет возвращена на баланс Покупателя и вычтена из баланса Продавца. А при недостаточности средств на балансе Продавца - из будущего дохода Продавца. Средства за такой заказ будут списаны с баланса Продавца независимо от того, когда заказ был выполнен.</li>
                <li>Продавцы должны сдавать на проверку заказ, в котором приложен результат, отвечающий всем требованиям Покупателя и соответствующий описанию задачи. Несоблюдение этого требования позволит Покупателю отменить заказ, что негативно скажется на рейтинге Продавца.</li>
                <li>Если срок на выполнение заказа завершился, заказ помечается как просроченный. Частичная отмена заказа покупателем или продавцом невозможна. Заказ может быть отменен только полностью.</li>
                <li>Частичная отмена заказа возможна только в случае обращения в Арбитраж. Если заказ был завершен без оплаты в пользу Продавца, например, в случае срыва заказа по вине одной из сторон, по решению арбитража или в результате иных ситуаций, то Покупатель не имеет права использовать какие-либо материалы (результат работы или ее часть), предоставленные Продавцом. В случае нарушения возможны санкции вплоть до блокировки учетной записи Покупателя.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Все права интеллектуальной собственности, связанные с результатами работы над заказом, переходят к Покупателю после успешного выполнения и оплаты заказа, если между Покупателем и Продавцом не достигнуто альтернативное соглашение. Если интеллектуальная собственность содержит элементы, подлежащие лицензированию, Продавец обязан передать лицензию или, если применимо, предоставить Покупателю обоснованное предупреждение о необходимости дополнительного приобретения соответствующей лицензии.</li>
                <li>Любая передача и уступка интеллектуальной собственности Покупателю осуществляется при условии полной оплаты задачи, при этом результат работы не может быть использован, если оплата отменена по какой-либо причине. Нарушение этого правила может привести к блокировке аккаунта Покупателя.</li>
                <li>Если не указано иное, Продавцы не имеют права получать и не могут запрашивать у Покупателя какие-либо гонорары, полученные от продажи их работ.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ОБМЕН КОНТАКТНОЙ ИНФОРМАЦИЕЙ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Продавцы соглашаются с тем, что любая личная информация, предоставленная Покупателями, является конфиденциальной и не может быть передана или использована для каких-либо целей, кроме выполнения самого заказа.</li>
                <li>Все взаимодействия между Покупателями и Продавцами, включая, помимо прочего, размещение заказов, оплату, согласование условий и утверждение доставки, должны происходить в рамках Сервиса Zemsite.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>РЕЙТИНГ ПРОДАВЦА</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Рейтинг Продавца формируется автоматически по формуле сайта Zemsite</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>КОНТЕНТ, СОЗДАВАЕМЫЙ ПОЛЬЗОВАТЕЛЯМИ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Zemsite не несет ответственности за пользовательский контент. Однако, если вы обнаружите какой-либо контент, размещенный на Zemsite, который может нарушать настоящее Пользовательское соглашение, вы должны сообщить об этом Zemsite через форму обратной связи, расположенную<a href="https://zemsite.ru/contact"> здесь</a>. Все обращения рассматриваются Службой поддержки.</li>
                <li>Весь контент, загружаемый пользователями на Zemsite, считается контентом, созданным пользователями. Zemsite прилагает разумные усилия для отслеживания контента, загружаемого на Сервис Zemsite, но не может нести ответственность за контент, созданный пользователями. Пользователи несут личную ответственность за свой контент и последствия его использования, раскрытия, хранения и передачи.</li>
                <li>Пользователи несут полную ответственность за проверку всех файлов, передаваемых на Zemsite.ru, на наличие вирусов и вредоносных программ.</li>
                <li>Файлы, предоставленные в переписке и форме заказа, хранятся на Zemsite в течение двух лет, после чего удаляются.</li>
                <li>Загружая или создавая контент на Zemsite.ru, вы заявляете и гарантируете, что владеете или получили все права, лицензии, согласия, разрешения, полномочия, необходимые для использования и/или загрузки такого контента, и что такой контент или его использование на Zemsite не должны:</li>
                <ul>
                    <li>нарушать любые применимые местные, государственные, федеральные и международные законы, правила и конвенции;</li>
                    <li>нарушать интеллектуальные и иные права третьих лиц.</li>
                </ul>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li>Пользователи (как Покупатели, так и Продавцы) соглашаются с тем, что, если они прямо не укажут иное, контент, который они создают/загружают на Zemsite.ru, включая содержание задач, изображения, видео и любую другую информацию, в том числе отображение результата работы, может быть использован Zemsite по своему усмотрению в целях продвижения Сервиса.</li>
                <li>Если сообщается о нарушении, Zemsite имеет право удалить такой контент.</li>
                <li>Пользователи соглашаются возместить любые расходы, понесенные Zemsite, в случае подачи иска о нарушении авторских прав против Zemsite.</li>
            </ul>
            <h2><strong>ПЛАТЕЖИ И ФИНАНСЫ</strong></h2>
            <p>&nbsp;</p>
            <p>Принимая условия настоящего Соглашения, Пользователь соглашается с тем, что Zemsite не оказывает Пользователю никаких возмездных услуг и не осуществляет прием платежей, или платежи в пользу Пользователей.</p>
            <p>&nbsp;</p>
            <h3><strong>ГАРАНТИЯ ВОЗВРАТА СРЕДСТВ</strong></h3>
            <p>&nbsp;</p>
            <p>Пока Продавец работает над заказом Покупателя, платеж Покупателя надежно удерживается Партнерами. Все оплаты, выплаты и возвраты при использовании Zemsite совершаются Пользователем посредством сервисов Партнеров в соответствии с правилами, условиями и комиссиями, установленными Партнерами.&nbsp; С условиями предоставления услуг партнерами вы можете ознакомиться в их офертах, размещенных на соответствующих страницах сайта (Баланс, форма пополнения и проч.)</p>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ПАРТНЕРЫ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Zemsite сотрудничает с Партнерами для принятия платежей от Покупателей, перевода таких платежей от Покупателей Продавцам и хранения средств на Балансах Пользователей. Все услуги, связанные с выводом средств на Zemsite.ru, также выполняются Партнерами.</li>
                <li>В указанных здесь целях Пользователи обязуются принять оферту Партнера, пользовательское соглашение или другие документы Партнера. Ссылки на оферты и прочие документы Партнеров размещенных на соответствующих страницах сайта (Баланс, форма пополнения и проч.)</li>
                <li>Продавцы уполномочивают Партнеров получать и переводить платежи со счета Покупателя на счет Продавца в сервисе Партнера. Ваша учетная запись в сервисе Партнера может быть связана только с одной учетной записью Zemsite. Запрещается привязывать одну и ту же кредитную/дебетовую карту, один и тот же электронный кошелек или учетную запись для вывода средств к нескольким учетным записям Zemsite.</li>
                <li>Все средства, доступные для вывода, будут храниться от вашего имени на счете Партнера.</li>
                <li>Zemsite оставляет за собой право через любого из Партнеров заморозить перевод средств в случае обнаружения любых подозрительных или мошеннических действий на Сервисе Zemsite или в случае отмены заказов.</li>
                <li>Запрещено подавать диспут об отмене заказа или платежа, или отменять транзакции через вашего поставщика платежных услуг, ваш банк или Партнера. Это может привести к временной или постоянной блокировке вашей учетной записи, счета и баланса.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ОПЛАТА ЗАКАЗОВ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Совершая или получая платежи через Сервис Zemsite, вы принимаете и соглашаетесь соблюдать настоящее Пользовательское соглашение.</li>
                <li>Продавцы и Покупатели обязуются использовать платежные системы Партнеров для всех сделок. На Zemsite размещается платежная страница для осуществления Пользователем оплаты посредством Партнеров.</li>
                <li>Заказы можно оплачивать дебетовыми и кредитными картами. В некоторых местах могут быть доступны дополнительные способы оплаты. Покупатели также могут использовать средства, которые могут быть на их Балансе.</li>
                <li>Заказывая задачу, Покупатели соглашаются платить Продавцам за их услуги вместе со всеми сопутствующими комиссиями и сборами. При совершении покупки Покупатели видят общую сумму к оплате.</li>
                <li>Продавцы соглашаются с тем, что оплата посредством участия Партнера равносильна оплате Покупателем непосредственно Продавцу.</li>
                <li>Платежное обязательство Покупателя перед Продавцом будет выполнено после получения Партнером платежа. Партнер несет ответственность за перевод средств Продавцам. При этом, если Партнер не перечисляет Продавцу какие-либо суммы, Продавец должен связаться с Zemsite, и не должен общаться с Покупателем или Партнерами напрямую.</li>
                <li>Продавец соглашается с тем, что Zemsite может указывать платежные реквизиты в квитанциях, уведомлениях и других документах, предоставляемых Покупателям, если Zemsite сочтет это необходимым и разумным.</li>
                <li>За перевод средств может взиматься дополнительная комиссия в соответствии с условиями Партнера.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>БЕЗОПАСНОСТЬ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>В случае отмены заказа оплаченные средства возвращаются на баланс Покупателя.</li>
                <li>Возврат средств за отмененные заказы возвращается на баланс Покупателя и доступен для будущих покупок и снятия средств на Сервисе Zemsite.</li>
                <li>Вопросы, связанные с возвратом платежей, остаются на усмотрение Zemsite или Партнера.</li>
                <li>В целях защиты от мошенничества, несанкционированных транзакций (таких как отмывание денег), претензий или других обязательств вся платежная информация собирается либо Zemsite, либо Партнерами.</li>
                <li>Все заказы обрабатываются через безопасную сделку, обеспечиваемую сервисами Партнеров. Оплаченные средства размещаются в счет заказа и передаются Продавцу только после выполнения работы. Возможно также внесение средств на баланс заранее до заказа с целью расходования данных средств на будущие заказы. Размещение любого другого типа заказа на Zemsite невозможно. Запрещается выполнение неоплачиваемых (тестовых) заданий и проведение операций вне рамок заказа. Нарушения могут привести к временной или постоянной блокировке вашей учетной записи.</li>
                <li>Zemsite не имеет доступа к платежной информации, предоставляемой пользователями Партнерам.</li>
                <li>Совершая платежи и/или предоставляя реквизиты для оплаты на Сервисе Zemsite, вы заявляете и гарантируете, что:</li>
                <ul>
                    <li>вы законно уполномочены осуществлять платежи на Сервисе Zemsite, используя выбранный вами способ оплаты;</li>
                    <li>ваши действия не нарушают действующее законодательство.</li>
                </ul>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ВЫВОД СРЕДСТВ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Средства</strong> относятся к деньгам на Балансе Пользователя. Продавец получает средства за выполнение заказов. У Покупателя могут быть средства от пополнений баланса в счет будущих заказов или в случае возвраты средств на баланс после отмены заказа. Средства могут быть сняты или использованы для покупок на Сервисе Zemsite в соответствии с настоящим Пользовательским соглашением.</li>
                <li>Пользователи не могут выводить средства, полученные с помощью промокодов. Средства, полученные с помощью промокодов, можно использовать только для покупок на Zemsite.</li>
                <li>Пользователи могут создать запрос на вывод средств на странице<a href="https://zemsite.ru/setting/info"> Баланса</a>. Вывод средств возможен на карты и электронные кошельки, доступные на странице Баланса. Все выводы являются окончательными и не могут быть отменены.</li>
                <li>Комиссия за вывод средств определяется Партнерами. Она отображается на странице<a href="https://zemsite.ru/setting/info"> Баланса</a> перед созданием запроса на снятие средств. Размещая заявку на вывод средств, вы соглашаетесь на сопутствующие сборы и признаете, что Zemsite не имеет никакого влияния или контроля над сборами Партнера на вывод средств.</li>
                <li>Для осуществления выплат денежных средств Пользователю, а также для возврата денежных средств, внесенных Пользователем, Zemsite с помощью Партнера вправе проводить верификацию держателя карты во избежание осуществления мошеннических действий, а также, по требованию Партнера по осуществлению платежей. Ознакомиться с условиями Партнера по верификации, а также принять условия оферты, соглашения или иного документа и предоставить свое согласие Сервису Верификации на обработку персональных данных&nbsp; вы можете на форме запроса верификации.</li>
                <li>Zemsite оставляет за собой право временно приостановить возможность Пользователя выводить средства для предотвращения мошеннических или незаконных действий или по другим причинам безопасности.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>НАЛОГИ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Пользователи несут ответственность за уплату любых прямых или косвенных налогов, включая любой налог на товары и услуги, НДС или подоходный налог, которые могут применяться к ним в зависимости от их места жительства, местонахождения или иным образом, в соответствии с положениями их юрисдикции.</li>
                <li>Продавцы заявляют и гарантируют, что они всегда соблюдают свои обязательства в соответствии с положениями о подоходном налоге в своей юрисдикции. Цены, указанные на Zemsite, включают все налоги и сборы, которые могут применяться к Продавцам.</li>
                <li>Действующее законодательство может потребовать от Zemsite или Партнеров взимать с Пользователей косвенные налоги (такие как налог с продаж, НДС или налог на товары и услуги) или удерживать налоги.</li>
                <li>Любая сумма, которую Zemsite или Партнеры должны будут собрать, будет добавлена к сумме покупки и любым другим сборам, подлежащим уплате Покупателями, а любая сумма, которую Zemsite или Партнеры должны будут удержать, будет вычтена из дохода Продавцов в соответствии с требованиями действующего законодательства.</li>
                <li>Косвенные налоги добавляются к цене, указанной на Zemsite. Любые такие налоги всегда будут отображаться для Покупателей до оплаты заказа.</li>
            </ul>
            <p>&nbsp;</p>
            <h3><strong>РАЗРЕШЕНИЕ СПОРОВ (АРБИТРАЖ)</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Любые споры и разногласия между Продавцом и Покупателем решаются путем переговоров один на один или с привлечением специалиста Службы поддержки. Если переговоры не увенчались успехом, Покупатель или Продавец вправе обратиться в арбитраж.</li>
                <li>Чтобы подать заявку на арбитраж, Пользователь должен перейти по ссылке &ldquo;Арбитраж&rdquo; в самой задаче, указать соответствующую категорию и заполнить форму арбитража. Ссылка на арбитраж появляется после отправки заказа на проверку.&nbsp;</li>
                <li>Заявка будет направлена компетентному специалисту Службы поддержки Zemsite (арбитру) для оценки и разрешения спора. Решение будет принято в течение 3 рабочих дней; однако этот период времени может быть продлен в исключительно сложных обстоятельствах.</li>
                <li>Арбитры исследуют информацию и переписку в заказе, запрашивают (при необходимости) мнение обеих сторон. Осуществляют поиск объективной информации по подобным услугам для сравнения в открытых источниках.</li>
                <li>Арбитрами оценивается соответствие выполненной работы условиям заказа (описанию услуг и согласованным требованиям в переписке), результат работы с объективной точки зрения, а также соответствие правилам работы на сайте.</li>
                <li>Арбитры не оценивают творческие компоненты заказов, включая, помимо прочего, цвета, шрифты и стиль выполнения (за исключением результатов, которые явно не соответствуют стандартам, отображаемым в портфолио Продавца).</li>
                <li>При работе над заказом Продавцы никогда не должны предпринимать действий, которые могут нанести вред Покупателям. Например, Продавцы не должны вносить правки или загружать файлы, которые могут нанести вред сайту или пользователям сайта при работе с доступами к сайту. Если выяснится, что Покупатель пострадал от Продавца, арбитр отменит заказ, а к Продавцу могут быть применены санкции вплоть до блокировки учетной записи. Если арбитр обнаружит, что заказ серьезно нарушает Условия предоставления услуг Zemsite (например, тип услуги запрещен в Zemsite, заказ может быть мошенническим и т. д.), арбитр имеет право отменить заказ и применить санкции к обеим сторонам. Если заказ является мошенническим или явно нарушает закон, платеж Покупателя должен быть возвращен на ту карту или электронный кошелек, с которого осуществлялся платеж. Если возврат осуществить невозможно, денежные средства возвращаются на Баланс Покупателя.</li>
                <li>Если Продавец и Покупатель пришли к обоюдному согласию относительно частичной оплаты заказа, решение принимает арбитр Zemsite, исходя из пожеланий обеих сторон и Пользовательского соглашения.</li>
                <li>Покупатель имеет право оставить отзыв по заказу, решенному через арбитраж, во всех случаях, даже если арбитраж решен в пользу продавца. Отзыв по заказу, прошедшему через арбитраж, не может быть оставлен покупателем только в случае, когда требования покупателя превышают объем заказа.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ПРАВА СОБСТВЕННОСТИ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Пользователи не имеют права и обязуются не совершать следующих действий в отношении Сервиса Zemsite, его частей, компонентов или расширений:</li>
                <ul>
                    <li>копировать, передавать, адаптировать, модифицировать, распространять, передавать, отображать, создавать производные работы, публиковать или воспроизводить их любым способом;</li>
                    <li>производить обратную сборку, декомпиляцию, обратный инжиниринг или другие попытки получить исходный код, лежащие в его основе идеи, алгоритмы, структуру или организацию;</li>
                    <li>удалять любое уведомление об авторских правах, идентификацию или любые другие уведомления о правах собственности;</li>
                    <li>обходить, удалять, изменять, деактивировать, ухудшать или препятствовать любым технологическим мерам и средствам защиты содержимого Сервиса Zemsite;</li>
                    <li>пытаться получить несанкционированный доступ, вмешиваться, повредить или прервать работу Zemsite.ru или Сервиса Zemsite;</li>
                    <li>использовать программное обеспечение для автоматизации (боты), хаки, модификации (моды) или любое другое несанкционированное стороннее программное обеспечение, предназначенное для модификации Сервиса Zemsite;</li>
                    <li>внедрять в Сервис Zemsite любые вирусы, трояны, черви, логические бомбы или другие вредоносные или технологически опасные материалы;</li>
                    <li>использовать любого робота, паука, поисковые роботы или другое автоматическое устройство, процесс, программное обеспечение или запросы, которые перехватывают, &laquo;майнят&raquo;, очищают или иным образом получают доступ к Сервису Zemsite, чтобы отслеживать, извлекать, копировать или собирать информацию или данные из Сервиса Zemsite или через него, или участвовать в любом ручном процессе, чтобы сделать то же самое;</li>
                    <li>использовать Сервис Zemsite любым способом, который может повредить, отключить, перегрузить или ухудшить его работу или помешать любым другим пользователям пользоваться сайтом или мобильным приложением или получать доступ или использовать Сервис Zemsite любым способом, прямо не разрешенным настоящими Правилами сервиса.</li>
                </ul>
            </ul>
            <p>Пользователи также гарантируют, что не разрешат и не уполномочат кого-либо делать что-либо из вышеперечисленного.</p>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ОТКАЗ ОТ ГАРАНТИЙ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Вы используете Сервис Zemsite на свой страх и риск. Сервис Zemsite и любые услуги, полученные с его помощью, предоставляются на условиях &laquo;как есть&raquo; и &laquo;по мере доступности&raquo;, без каких-либо гарантий, явных или подразумеваемых. Ни Zemsite, ни любое другое лицо, связанное с Zemsite, не дает никаких гарантий или заявлений в отношении полноты, безопасности, надежности, качества, точности или доступности Сервиса Zemsite.</li>
                <li>Ни Продавцы, ни Zemsite не дают никаких гарантий, явных или подразумеваемых, в том числе в отношении любой задачи или пригодности результата работы для конкретной цели. Ни Продавцы, ни Zemsite не несут ответственности за любые претензии или случайные, косвенные или иные убытки, возникшие в результате использования результата работы.</li>
                <li>Вышеизложенное не влияет на какие-либо гарантии, которые не могут быть исключены или ограничены в соответствии с действующим законодательством.</li>
            </ul>
            <h3><strong>&nbsp;</strong></h3>
            <h3><strong>ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</strong></h3>
            <p>&nbsp;</p>
            <ul>
                <li>Ни при каких обстоятельствах Zemsite, его аффилированные лица* или их лицензиары, поставщики услуг, сотрудники, агенты, должностные лица или директора не несут ответственности за ущерб любого рода, возникший в результате или в связи с вашим использованием или невозможностью использования Zemsite.ru, Zemsite. Услуги или любые веб-сайты, связанные с ними, любой контент на Zemsite.ru или других веб-сайтах или любые услуги или предметы, полученные через Zemsite или его услуги или такие другие веб-сайты, включая любые прямые и косвенные убытки, включая, помимо прочего, моральный ущерб, потерю дохода, потерю прибыли, потерю бизнеса, потерю репутации, потерю данных, в том числе вызванные деликтом (включая халатность), нарушением соглашения или иным образом, даже если это можно было предвидеть.</li>
                <li>Zemsite, его аффилированные лица или их лицензиары, поставщики услуг, сотрудники, агенты, должностные лица или директора ни в коем случае не несут ответственности за действия или бездействие Пользователя.</li>
                <li>Вышеизложенное не влияет на какую-либо ответственность, которая не может быть исключена или ограничена в соответствии с действующим законодательством.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Ни при каких обстоятельствах Zemsite не несет ответственности за неисполнение или любую задержку в выполнении любого обязательства по настоящему Соглашению в течение разумного периода времени из-за стихийных бедствий, войн, терроризма, восстаний, эмбарго, действий гражданских или военных властей, пожаров, наводнений, несчастных случаев, забастовок или нехватки транспортных средств, топлива, энергии, рабочей силы или материалов или любых других обстоятельств, находящихся вне разумного контроля Zemsite. Кроме того, Zemsite не несет ответственности за сторонние DDoS-атаки и/или другие атаки на Сервис Zemsite, за последствия таких атак, за технические проблемы, влияющие на Сервис Zemsite, связанные с хостинг-провайдером Сервиса Zemsite, а также за недоступность Zemsite по любой другой причине, не связанной напрямую с Zemsite.</p>
            <p>&nbsp;</p>
            <p>*Термин &laquo;Аффилированное лицо&raquo;, упоминаемый в настоящем документе, представляет собой юридическое лицо, которое прямо или косвенно контролирует, находится под контролем или находится под общим контролем с Zemsite, где контроль означает владение более чем пятьюдесятью процентами (50%) голосующих акций или иной долей собственности или большинством прав голоса такого юридического лица.</p>
        </div>
    );
};
