import React, {useEffect, useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {MessengerAPI} from "../../../../../API/MessengerAPI";
import {useAppState} from "../../../../../store/reduxStore";
import {MessengerActions, MessengerState} from "../../../../../store/reducers/messenger";
import {ChatItem} from "../ChatItem";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {NoticeAPI} from "../../../../../API/NoticeAPI";
import loadingGit from '../../../../assets/images/LoadingGif.gif'

export const Messenger = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {connectClient, closeClient, getChatList, addChatList} = useThunks(MessengerAPI)
    const {setChatListAction, setCompanionIdAction} = useActions(MessengerActions)
    const {readDemonNotice} = useThunks(NoticeAPI)

    const { companionId, connect, chatList, activeChat, totalChatList } = useAppState(MessengerState)

    const [addLoading, setAddLoading] = useState(false)

    useEffect(() => {
        connectClient()
        readDemonNotice(`messagesCount`)
        return () => {
            closeClient()
            setChatListAction(null)
            setCompanionIdAction(null)
        }

    }, [])

    useEffect(()=>{
        if (connect) {
            getChatList(companionId)
        }
    },[connect])

    useEffect(()=>{
        setAddLoading(false)
    },[chatList])

    // const addChatListHandler = () => {
    //
    // }

    useEffect(()=>{
        if (!activeChat) {
            navigate(`/chat-list`)
        } else if (Number(params.id) !== +activeChat.id) {
            navigate(`/chat-list/${activeChat.id}`)
        }
    },[activeChat])

    // добавление элементов при скроле
    const debounce = useRef<any>(null)
    function onScrollListHandler(e: any) {
        if (addLoading) return
        clearTimeout(debounce.current)
        debounce.current = setTimeout(()=>{
            const scrollBottom = e.target.scrollTop +
                e.target.offsetHeight + 50 > e.target.scrollHeight;

            if ( scrollBottom && !!chatList?.length && totalChatList && totalChatList > chatList.length) {
                setAddLoading(true)
                addChatList()
            }
        },500)
    }

    return (
        <div className={css(s.Messenger)}>
            <div className={css(s.container)}>
                <Outlet />
                <div className={css(s.chatList)} onScroll={onScrollListHandler}>
                    {chatList?.map(chat => <ChatItem  key={chat.id} chat={chat}/>)}
                    {!!chatList?.length && !!totalChatList && totalChatList > chatList.length &&
                        <div className={css(s.addChatListBtn)}><img src={loadingGit} alt="loading"/></div>}
                    {!chatList?.length && <div className={css(s.emptyChatList)}>нет чатов</div>}
                </div>
            </div>
        </div>
    )
}
