import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

export const PriceInputField = ({text, notBorder=false, setText,subText="", maxLength}:any) => {

    const setTextHandler = (e:any) => {
        if (e.target.value.length > maxLength) return
        setText(e.target.value)
    }

    return (
        <div className={css(s.PriceInputField, notBorder && s.notBorder)}>
            <div className={css(s.main)}>
                <input autoFocus={notBorder} type="text" value={text} onChange={setTextHandler}/>
                <div className={css(s.opacityText)}><span>{text}</span><span>{subText}</span></div>
            </div>
        </div>
    )
}
