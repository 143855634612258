import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {ProfileActions, ProfileState} from "../../../../../store/reducers/profile";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {useNavigate, useParams} from "react-router-dom";
import {ProfileAPI} from "../../../../../API/useProfile";
import {MessengerActions} from "../../../../../store/reducers/messenger";
import {IProfile} from "../../../../../types/profile";
import {UserState} from "../../../../../store/reducers/auth";
import {Head} from "./Head";
import {SocialNetwork} from "./SocialNetwork";
import {Sites} from "./Sites";
import {WorkEmail} from "./WorkEmail";
import {Address} from "./Address";
import {MyServices} from "./MyServices";
import {About} from "./About";
import {LoadingPage} from "../../../LoadingPage";
import {StandardButton} from "../../../Button/StandardButton";
import {VerifyMessageBlock} from "./VerifyMessageBlock";
import {SetPlace} from "../../../SetPlace";
import {PlaceExecutor} from "./PlaceExecutor";

export const ActiveExecutor = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {getExecutor} = useThunks(ProfileAPI)
    const {user} = useAppState(UserState)
    const {activeExecutor} = useAppState(ProfileState)
    const {setActiveExecutorAction} = useActions(ProfileActions)
    const {setCompanionIdAction} = useActions(MessengerActions)

    useEffect(() => {
        getExecutor(Number(params.id))
        return () => {
            setActiveExecutorAction(null)
        }
    }, [])

    const sendCompanionMessageHandler = async () => {
        if (!activeExecutor) return
        await setCompanionIdAction(activeExecutor.ownerId)
        navigate(`/chat-list`)
    }
    if (!activeExecutor) return <LoadingPage />
    return (
        <div className={css(s.ActiveExecutor)}>
            <div className={css(s.container)}>
                <Head verify={user.role === 'verifyRegular'} profile={activeExecutor as IProfile}/>
                <div style={{gridArea: `changeModeBox`}}>
                    {activeExecutor.ownerId !== user.id && user.role === 'regular' && <StandardButton onClick={sendCompanionMessageHandler} text={"Написать сообщение"}/>}
                    {(user.role === 'verifyRegular' || user.role === 'manager') && <VerifyMessageBlock profile={activeExecutor} sendCompanionMessageHandler={sendCompanionMessageHandler}/>}
                </div>
                <div className={css(s.mainBox)}>
                    <About profile={activeExecutor as IProfile}/>
                    <MyServices profile={activeExecutor as IProfile}/>
                    <Address profile={activeExecutor as IProfile}/>
                    {activeExecutor.private.address && <iframe
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A7b0dc0e15857c394b3b994be60b8d90727c281d9c600efa4c591919b4cd53b75&amp;source=constructor"
                        width="100%" height="400" frameBorder="0"></iframe>}
                    <WorkEmail profile={activeExecutor as IProfile}/>
                    <Sites profile={activeExecutor as IProfile}/>
                    <PlaceExecutor region={activeExecutor.place.region} city={activeExecutor.place.className}/>
                    <SocialNetwork profile={activeExecutor as IProfile}/>
                </div>
            </div>
        </div>
    )
}
