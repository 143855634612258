import React, {useEffect, useRef} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {Outlet, useLocation} from 'react-router-dom';
import {GuestHeader} from "../../Header/GuestHeader";
import {Footer} from "../../Footer";
import {PopupWrapper} from "../../PopupWrapper";
import {Login} from "../../PopupWrapper/Login";
import {Registration} from "../../PopupWrapper/Registration";
import {ResetPassword} from "../../PopupWrapper/ResetPassword";

export const GuestLayout = () => {
    const location = useLocation()
    const ref = useRef<any>(null)
    useEffect(()=>{
        if (ref?.current) {
            ref?.current.scrollTo(0, 0)
        }
    },[location,ref])

    return (
        <>
            <div className={css(s.GuestLayout)} ref={ref}>
                <GuestHeader/>
                <Outlet/>
                <Footer/>
            </div>
            <PopupWrapper><Login id={`loginPopupMain`} type={`login`}/></PopupWrapper>
            <PopupWrapper><Registration id={`registrationPopupMain`} type={`registration`}/></PopupWrapper>
            <PopupWrapper><ResetPassword id={`resetPasswordPopupMain`} type={`resetPassword`}/></PopupWrapper>
        </>
    )
}
