import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {AppActions, AppState} from "../../../../store/reducers/app";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import loadingGif from "../../../assets/images/LoadingGif.gif";
import {UserState} from "../../../../store/reducers/auth";
import {AdminAPI} from "../../../../API/useAdmin";

export const FaqPage = () => {

    const {faqList} = useAppState(AppState)
    const {getFaqList} = useThunks(AppAPI)
    const {setFaqListAction} = useActions(AppActions)

    useEffect(()=>{
        getFaqList()
        return ()=>{
            setFaqListAction(null)
        }
    },[])

    return (
       <div className={css(s.FaqPage)}>
           <div className={css(s.container)}>
                <div className={css(s.title)}>Ответы на частые вопросы</div>
               <div className={css(s.faqList)}>
                   {faqList?.map((item, index) => <FaqItem key={index} item={item}/>)}
                   {!faqList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
               </div>
           </div>
       </div>
    )
}
export const FaqItem = ({item}:any) => {

    const {user} = useAppState(UserState)
    const [showAnswer, setShowAnswer] = useState(false)
    const {deleteFaq} = useThunks(AdminAPI)

    const [addLoad, setAddLoad] = useState(false)

    const deleteHandler = (e:any) =>{
        e.preventDefault()
        if (addLoad) return
        setAddLoad(true)
        deleteFaq(item.id, ()=>{
            setAddLoad(false)
        })
    }

    return (
        <div className={css(s.FaqItem)}>
            <div className={css(s.faqItemAsk, showAnswer && s.active)} onClick={()=>setShowAnswer(prev=>!prev)}>
                <span>{item.ask}</span>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99992 0.666673C5.80521 0.666292 5.61652 0.734105 5.46659 0.858339L0.46659 5.02501C0.112207 5.31956 0.0637046 5.84562 0.358257 6.20001C0.652808 6.55439 1.17887 6.60289 1.53326 6.30834L5.99992 2.57501L10.4666 6.17501C10.6388 6.31481 10.8595 6.38023 11.0801 6.35677C11.3006 6.33331 11.5027 6.22291 11.6416 6.05001C11.7959 5.87679 11.871 5.64711 11.8488 5.41621C11.8267 5.18531 11.7093 4.97408 11.5249 4.83334L6.52492 0.80834C6.37069 0.703743 6.18584 0.653864 5.99992 0.666673Z" fill="#212B36"/>
                </svg>
            </div>
            <div className={css(s.faqItemAnswer, showAnswer && s.show)}>
                {item.answer}
                {user.role === `admin` && <div className={css(s.deleteBtn)} onClick={deleteHandler}>
                    {!addLoad ? `Удалить` : <img src={loadingGif} alt="loading"/>}
                </div>}
            </div>
        </div>
    )
}

