import React from 'react'

export const SvgClose = () => {

    return (
        <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96757 7.84197C7.1563 7.85295 7.3419 7.79021 7.48524 7.66699C7.76794 7.38261 7.76794 6.92331 7.48524 6.63893L1.29504 0.448699C1.001 0.173563 0.539618 0.188857 0.264481 0.48289C0.0156841 0.748783 0.00118173 1.15747 0.23053 1.44031L6.45721 7.66699C6.5987 7.78843 6.78132 7.85105 6.96757 7.84197V7.84197Z" fill="#212B36"/>
            <path d="M0.784217 7.84189C0.975485 7.84107 1.15881 7.76515 1.29461 7.63045L7.48484 1.4402C7.74674 1.13435 7.71114 0.674072 7.40529 0.412141C7.13231 0.178375 6.72973 0.178375 6.45678 0.412141L0.230102 6.60237C-0.0638589 6.87758 -0.0790576 7.33899 0.196151 7.63295C0.2071 7.64464 0.218409 7.65595 0.230102 7.6669C0.382569 7.79949 0.583225 7.86285 0.784217 7.84189V7.84189Z" fill="#212B36"/>
        </svg>
    )
}
