import React, {useRef, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";

const cleanTags = (str: string) => {
    let newStr = str.replace(/(<([^>]+)>)/gi, "")
    return newStr
}

export const TextField = ({ contentEditable, maxLength, text, setText}:any) => {

    // const [lengthText,setLengthText] = useState(text
    //     .replaceAll(`<div>`, ``)
    //     .replaceAll(`</div>`, ``)
    //     .replaceAll(`<br>`, `  `)
    //     .length)
    //
    // const [lastText,setLastText] = useState(text)
    //
    // const changeAboutHandler = (e: any) => {
    //     setText(e.target.innerHTML)
    // }
    // const maxLengthTextHandler = (e: any) => {
    //     if (e.target.innerText.length > maxLength) {
    //         e.preventDefault()
    //         return
    //     }
    // }
    // const setLengthTextHandler = (e: any) => {
    //     if (e.target.innerHTML === `<br>` || e.target.innerHTML === `<div><br></div>`) {
    //         e.target.innerHTML = ``
    //     }
    //     if (e.target.innerHTML
    //         .replaceAll(`<div><br></div>`, `   `)
    //         .replaceAll(`<div>`, ``)
    //         .replaceAll(`</div>`, ``)
    //         .length >= maxLength) {
    //         // e.preventDefault()
    //         e.target.innerHTML = lastText
    //         return
    //     } else {
    //         setLengthText(e.target.innerText.length)
    //         setLastText(e.target.innerHTML)
    //     }
    // }
    // const pastHandler = (e:any) => {
    //     e.preventDefault();
    //     let text = e.clipboardData.getData("text/plain");
    //     document.execCommand("insertHTML", false, text);
    // }

    // field
    // const [text, setText] = useState('')
    // const [contentEditable, setContentEditable] = useState(false)
    const changeText = (e: any) => {
        e.preventDefault()
        // управление отображением
        if (Number.parseInt(e.target.style.height) < 300) {
            e.target.style.overflowY = 'hidden'
        }
        e.target.style.height = 'auto';
        e.target.style.height = (e.target.scrollHeight < 52 ? 31: e.target.scrollHeight) + 'px';//////console.log(this.scrollHeight);
        if (Number.parseInt(e.target.style.height) > 300) {
            e.target.style.overflowY = 'scroll'
        }
        else {
            e.target.style.overflowY = 'hidden'
        }
        // управление обновлением текста
        if (e.target.value.length > 1000) return
        setText(e.target.value)
    }
    const keyDownHandler = (e:any) => {
        // let val = e.target.value,
        //     start = e.target.selectionStart,
        //     end = e.target.selectionEnd,
        //     curs = val.substring(0, start) + '\n';
        // e.target.value = val.substring(0, start) + '\n' + val.substring(end, val.length);
        // e.target.focus();
        // e.target.setSelectionRange(curs.length, curs.length);
        // changeText(e)
    }

    return (
        <div className={css(s.TextField)}>
            {contentEditable && <textarea autoFocus={contentEditable} rows={1}
                       value={text}
                       onChange={changeText}
                       placeholder="Не заполнено..."
                       onKeyDown={keyDownHandler}

             />}
            {!contentEditable && <div className={s.textFieldText}>{text}</div>}
            {contentEditable && <div className={css(s.maxLength)}>{text.length}/{maxLength}</div>}
        </div>
    )
}
