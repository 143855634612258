import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useNavigate} from "react-router-dom";
import {useAppState} from "../../../../../store/reduxStore";
import {UserState} from "../../../../../store/reducers/auth";
import {SvgMyProfile} from "../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../assets/images/LoadingGif.gif";
import {customFetch} from "../../../../../helpers/customFetch";
import {notice_server, store_server, task_server, timeCheckOnline} from "../../../../../helpers/config";
import {useInterval} from "../../../../../helpers/useInterval";
import {useThunks} from "../../../../../helpers/useActions";
import {NoticeAPI} from "../../../../../API/NoticeAPI";

export const ExecutorItem = ({item}:any) => {
    const navigate = useNavigate();
    const {user} = useAppState(UserState)
    const {updateOnline} = useThunks(NoticeAPI)

    const setActiveExecutorHandler = async () => {
        if (user.role === 'guest') return
        navigate(`/executor-list/${item.ownerId}`)
    }

    // аватарка
    const [img, setImg] = useState<{ src: string | null, loading: boolean }>({
        src: null,
        loading: true,
    })
    useEffect(() => {
        if (!!item.avatarLink.length) {
            customFetch(`${store_server}/${item.avatarLink}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'image/png',
                },
            })
                .then(data => {
                    if (data.status === 200) return data.blob()
                    else throw Error()
                })
                .then(blobData => {
                    const urlCreator = window.URL || window.webkitURL;
                    const image = urlCreator.createObjectURL(blobData);
                    setImg(prev => ({loading: false, src: image}))
                })
                .catch((err) => {
                    setImg(prev => ({loading: false, src: null}))
                })
        } else setImg(prev => ({loading: false, src: null}))
    }, [item])

    // онлайн
    const [online, setOnline] = useState<boolean | null>(null)

    useEffect(()=>{
        if (user.role !== 'guest')
            updateOnline(Number(item.ownerId), (data:boolean) => setOnline(data))
    },[])
    useInterval(()=>{
        if (user.role !== 'guest')
            updateOnline(Number(item.ownerId), (data:boolean) => setOnline(data))
    }, timeCheckOnline)

    //count active task
    const [countActiveTask,setCountActiveTask] = useState<number | null>(null)
    useEffect(()=>{
        if (user.role === 'guest') return
        if (user.role === 'regular') return
        customFetch(`${task_server}/api/task/getWorkerActiveTask?workerId=${item.ownerId}`, {
            method: 'GET',
        })
            .then(data => data.json())
            .then(data => {
                setCountActiveTask(data)
            })
            .catch((err) => {
                setCountActiveTask(0)
            })
        return ()=>{
            setCountActiveTask(null)
        }
    },[])

    return (
        <div className={css(s.ExecutorItem, (user.role === 'guest') && s.disable)} onClick={setActiveExecutorHandler}>
            <div className={css(s.main)}>
                {user.role !== 'guest' && online === null && <img className={css(s.onlineLoading)} src={loadingGif} alt="loading"/>}
                {user.role !== 'guest' && online !== null && online && <div className={css(s.onlineStatus)}><div className={css(s.on)}/>В сети</div>}
                {user.role !== 'guest' && online !== null && !online && <div className={css(s.onlineStatus)}><div className={css(s.off)}/>Не в сети</div>}
                <div className={css(s.avatar)}>
                    {img.src !== null && !img.loading && <img src={img.src} alt="png"/>}
                    {img.src === null && !img.loading && <SvgMyProfile/>}
                    {img.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                </div>
                <div className={css(s.prof)}>
                    {!!item.croName?.trim().length ? item.croName : "Не установленно"}
                </div>
                <div className={css(s.place)}>
                    {item.private.place ? <span>{`${item.place.region}${!!item.place.region.trim().length && !!item.place.city.trim().length ? ', ':''}${item.place.city}`}</span> : 'Скрыто пользователем'}
                </div>
                <div className={css(s.text)} >
                    <span>О себе:  </span>
                    {item.private.about ? <span>{!!item.about.length ? item.about : 'Не заполненно'}</span> : 'Скрыто пользователем'}
                </div>
                {(user.role === 'guest' || user.role === 'regular') &&
                    <div className={css(s.info)}>
                        <div className={css(s.addressBox)}>
                            <div className={css(s.icon)}>
                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.64673 0.433594H15.6584C16.484 0.433594 17.1526 1.06678 17.1526 1.83984V10.2773C17.1526 11.055 16.4791 11.6836 15.6584 11.6836H1.64673C0.828106 11.6836 0.152588 11.0577 0.152588 10.2773V1.83984C0.152588 1.06816 0.818542 0.433594 1.64673 0.433594ZM1.85597 1.37109C2.16124 1.65688 7.41468 6.57525 7.59606 6.74506C7.87829 7.01069 8.25349 7.15694 8.65259 7.15694C9.05169 7.15694 9.42689 7.01066 9.71004 6.74419C9.83203 6.62997 15.0275 1.76591 15.4492 1.37109H1.85597ZM16.1565 10.0865V2.03125L11.8521 6.06109L16.1565 10.0865ZM15.4486 10.7461L11.1459 6.72225L10.4134 7.40797C9.94309 7.85066 9.31775 8.09444 8.65259 8.09444C7.98743 8.09444 7.36208 7.85066 6.89266 7.40884L6.1593 6.72225L1.85661 10.7461H15.4486ZM1.14868 10.0865L5.45307 6.06109L1.14868 2.03125V10.0865Z" fill="#212B36"/>
                                </svg>
                            </div>
                            <div className={css(s.email)}>
                                <div className={css(s.emailTitle)}>
                                    E-mail:
                                </div>
                                <div className={css(s.emailText)}>
                                    {item.private.email ? item.email : 'Скрыто пользователем'}
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {(user.role === 'verifyRegular' || user.role === 'manager') &&
                    <div className={css(s.info)}>
                        <div className={css(s.emailBox)}>
                            <div className={css(s.icon)}>

                            </div>
                            <div className={css(s.email)}>
                                <div className={css(s.emailTitle)}>
                                    E-mail:
                                </div>
                                <div className={css(s.emailText)}>
                                    {item.email}
                                </div>
                            </div>
                        </div>
                        <div className={css(s.activeTaskCountBox)}>
                            <div className={css(s.icon)}>

                            </div>
                            <div className={css(s.activeTaskCount)}>
                                <div className={css(s.activeTaskCountTitle)}>
                                    В работе задач:
                                </div>
                                <div className={css(s.activeTaskCountText)}>
                                    {countActiveTask !== null ? countActiveTask:
                                        <img src={loadingGif} alt="loading"/>}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className={css(s.name)}>
                <div className={css(s.text)}>
                    {item.name}
                </div>
                <div className={css(s.rating)}>

                </div>
            </div>
        </div>
    )
}