import React from 'react';

export const SvgMyProfile = () => {
    return (
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.40613 6.5937C8.40613 10.2295 11.364 13.1874 14.9999 13.1874C18.6359 13.1874 21.5937 10.2295 21.5937 6.5937C21.5937 2.95786 18.6359 0 14.9999 0C11.364 0 8.40613 2.95786 8.40613 6.5937Z"
                fill="#8A8A8A"/>
            <path
                d="M4.49208 30.0001H25.5078C26.3783 30.0001 27.084 29.2944 27.084 28.4239C27.084 21.7608 21.6631 16.3398 15 16.3398C8.3368 16.3398 2.91589 21.7608 2.91589 28.4239C2.91589 29.2944 3.6216 30.0001 4.49208 30.0001Z"
                fill="#8A8A8A"/>
        </svg>
    );
};
