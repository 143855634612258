import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    userList: Array<any> | null
    totalUserList: number

    verificationList: Array<any> | null
    totalVerificationList: number
    activeVerificationItem: any | null
    activeVerificationFiles: any | null

    arbitrageTaskList: Array<any> | null
    totalArbitrageTaskList: number
    activeArbitrageTask: any | null

    appealList: Array<any> | null
    totalAppealList: number

    withdrawList: Array<any> | null
    totalWithdrawList: number
}
const initialState: stateType = {
    userList: null,
    totalUserList: 0,
    // manager
    verificationList: null,
    totalVerificationList: 0,
    activeVerificationItem: null,
    activeVerificationFiles: null,

    arbitrageTaskList: null,
    totalArbitrageTaskList: 0,
    activeArbitrageTask: null,

    appealList: null,
    totalAppealList: 0,

    withdrawList: null,
    totalWithdrawList: 0
}

export const slice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        setUserListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.userList = action.payload
        },
        addUserListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.userList)
                state.userList = [...state.userList, ...action.payload]
        },
        setTotalUserListAction: (state, action: PayloadAction<number>) : void => {
            state.totalUserList = action.payload
        },
        updateUserAction: (state, action: PayloadAction<any>) : void => {
            if (state.userList)
                state.userList = state.userList.map(user => user.id === action.payload.id ? action.payload : user)
        },

        // manager
        setVerificationListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.verificationList = action.payload
        },
        addVerificationListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.verificationList)
                state.verificationList = [...state.verificationList,...action.payload]
        },
        setTotalVerificationListAction: (state, action: PayloadAction<number>) : void => {
            state.totalVerificationList = action.payload
        },
        setActiveVerificationItemAction: (state, action: PayloadAction<any | null>) : void => {
            state.activeVerificationItem = action.payload
        },
        setActiveVerificationFilesAction: (state, action: PayloadAction<any | null>) : void => {
            state.activeVerificationFiles = action.payload
        },

        // arbitrage
        setArbitrageTaskListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.arbitrageTaskList = action.payload
        },
        addArbitrageTaskListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.arbitrageTaskList)
                state.arbitrageTaskList = [...state.arbitrageTaskList,...action.payload]
        },
        setTotalArbitrageTaskListAction: (state, action: PayloadAction<number>) : void => {
            state.totalArbitrageTaskList = action.payload
        },
        setActiveArbitrageTaskAction: (state, action: PayloadAction<any | null>) : void => {
            state.activeArbitrageTask = action.payload
        },

        // appeal
        setAppealListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.appealList = action.payload
        },
        setTotalAppealListAction: (state, action: PayloadAction<number>) : void => {
            state.totalAppealList = action.payload
        },
        addAppealListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.appealList)
                state.appealList = [...state.appealList,...action.payload]
        },

        // withdrawList
        setWithdrawListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.withdrawList = action.payload
        },
        addWithdrawListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.withdrawList)
                state.withdrawList = [...state.withdrawList, ...action.payload]
        },
        setTotalWithdrawListAction: (state, action: PayloadAction<number>) : void => {
            state.totalWithdrawList = action.payload
        },
        changeWithdrawItemAction: (state, action: PayloadAction<any>) : void => {
            if (!!state.withdrawList)
            state.withdrawList = state.withdrawList?.map(el => {
                console.log(+el.id, +action.payload.id)
                if(+el.id === +action.payload.id) {
                    return action.payload
                }
                return el
            })
        },

    }
})

export const ManagerReducer = slice.reducer
export const ManagerActions = slice.actions
export const ManagerState = (state: RootState) => state.ManagerReducer


