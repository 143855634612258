import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";

export const PlaceExecutor = ({region='',city=''}:any) => {

    return (
        <div className={css(s.PlaceExecutor)}>
            <div className={css(s.headPlaceExecutor)}>
                <div className={css(s.placeExecutorTitle)}>Местоположение:</div>
            </div>
            <div className={css(s.mainPlaceExecutor)}>
                <div className={css(s.regionPlace)}>
                    <div className={css(s.regionPlaceMain)}>{!region.length ? `Регион не установлен` : `${region}`}</div>
                </div>
                <div className={css(s.cityPlace)}>
                    <div className={css(s.cityPlaceMain)}>{!city.length ? `Город не установлен` : `${city}`}</div>
                </div>
            </div>
        </div>
    )
}
