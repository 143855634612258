import React from 'react'

export const SvgPlace = () => {

    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4242 17.0754C10.2347 17.4114 8.66367 17.5965 7.00043 17.5965C5.33719 17.5965 3.76613 17.4114 2.57668 17.0754C1.12149 16.6643 0.383634 16.0693 0.383634 15.3068C0.383634 14.5444 1.12149 13.9494 2.57668 13.5383C3.24164 13.3505 4.02621 13.2101 4.87918 13.1225C3.20883 10.2715 1.55356 7.04336 1.55356 5.44684C1.55356 2.44344 3.99699 0 7.00043 0C10.0039 0 12.4473 2.44344 12.4473 5.44684C12.4473 7.04332 10.7921 10.2714 9.12172 13.1224C9.97469 13.21 10.7591 13.3504 11.4241 13.5383C12.8793 13.9494 13.6172 14.5444 13.6172 15.3068C13.6172 16.0693 12.8794 16.6643 11.4242 17.0754ZM7.00043 1.17188C4.64316 1.17188 2.72543 3.08961 2.72543 5.44684C2.72543 6.65762 4.22356 9.83152 6.73285 13.9371C6.81574 14.0727 6.94762 14.0873 7.00047 14.0873C7.05328 14.0873 7.1852 14.0727 7.26809 13.9371C9.77738 9.83152 11.2755 6.65766 11.2755 5.44684C11.2754 3.08961 9.3577 1.17188 7.00043 1.17188ZM10.8928 14.6089C10.1942 14.431 9.35711 14.3061 8.4548 14.2411C8.39219 14.3444 8.32988 14.4468 8.26797 14.5481C7.99586 14.9934 7.52203 15.2591 7.00047 15.2591C6.47887 15.2591 6.00508 14.9933 5.73297 14.5481C5.67106 14.4468 5.60871 14.3444 5.54609 14.2411C4.64379 14.3061 3.80676 14.431 3.10809 14.6089C2.03715 14.8817 1.6459 15.1888 1.56547 15.3068C1.64594 15.4249 2.03711 15.732 3.10805 16.0047C4.17106 16.2755 5.55344 16.4246 7.00047 16.4246C8.4475 16.4246 9.82988 16.2755 10.8929 16.0047C11.9638 15.732 12.355 15.4248 12.4354 15.3068C12.355 15.1888 11.9638 14.8817 10.8928 14.6089Z" fill="#00AB55"/>
            <path d="M4.81078 5.07305C4.81078 3.8659 5.79293 2.88379 7.00012 2.88379C8.2073 2.88379 9.18945 3.8659 9.18945 5.07305C9.18945 6.28023 8.2073 7.26234 7.00012 7.26234C5.79293 7.26234 4.81078 6.28027 4.81078 5.07305ZM8.01758 5.07305C8.01758 4.51207 7.56113 4.05566 7.00012 4.05566C6.4391 4.05566 5.98266 4.51207 5.98266 5.07305C5.98266 5.63402 6.4391 6.09047 7.00012 6.09047C7.56113 6.09047 8.01758 5.63406 8.01758 5.07305Z" fill="#00AB55"/>
        </svg>
    )
}
