import React from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useNavigate} from "react-router-dom";

export const ErrorServer = () => {

    const navigate = useNavigate()

    const refreshHandler = () => {
        navigate(`/`)
    }

    return (
        <div className={css(s.ErrorServer)}>
            <div className={css(s.main)}>
                <div className={css(s.title)}> Ошибка на сервере, попробуйте позже</div>
                <div className={css(s.refreshBtn)}
                     onClick={refreshHandler}
                >
                    На главную
                </div>
            </div>
        </div>
    )
}
