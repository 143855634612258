import React, {useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {FaqPage} from "../../Pages/FaqPage";
import {useThunks} from "../../../../helpers/useActions";
import {AppAPI} from "../../../../API/AppAPI";
import {AdminAPI} from "../../../../API/useAdmin";

export const AddFaq = () => {

    const { addNewFaq } = useThunks(AdminAPI)

    const [addLoad, setAddLoad] = useState(false)
    const [newFaq,setNewFaq] = useState({
        ask:``,
        answer:``,
    })

    const addNewFaqHandler = (e:any) => {
        e.preventDefault()
        if (addLoad) return
        setAddLoad(true)
        addNewFaq({ask: newFaq.ask, answer: newFaq.answer},()=>{setAddLoad(false)})
        setNewFaq({
            ask:``,
            answer:``,
        })
    }

    return (
        <div className={css(s.AddFaq)}>
            <div className={css(s.container)}>
                <form className={css(s.addBlock)} onSubmit={addNewFaqHandler}>
                    <div className={css(s.title)}>
                        Добавление faq
                    </div>
                    <div className={css(s.main)}>
                        <textarea value={newFaq.ask} onChange={(e)=>setNewFaq(prev=>({...prev, ask: e.target.value}))}/>
                        <textarea value={newFaq.answer} onChange={(e)=>setNewFaq(prev=>({...prev, answer: e.target.value}))}/>
                    </div>
                    <button>
                        {!addLoad ? `Создать` : <img src={loadingGif} alt="loading"/>}
                    </button>
                </form>
            </div>
            <FaqPage/>
        </div>
    )
}
