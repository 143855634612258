import React, {useEffect} from 'react'
import {AuthAPI} from "../../../API/AuthAPI";
import {UserState} from "../../../store/reducers/auth";
import {useAppState} from "../../../store/reduxStore";
import {Routing} from "../Routing";
import {useThunks} from "../../../helpers/useActions";
import {AppAPI} from "../../../API/AppAPI";
import {AppState} from "../../../store/reducers/app";

export const Auth = () => {
    const {checkAuth} = useThunks(AuthAPI)

    const {content} = useAppState(AppState)
    const {getContent} = useThunks(AppAPI)
    const {user} = useAppState(UserState)

    useEffect(() => {
        if (content !== null)
        checkAuth()
    }, [content])

    useEffect(()=>{
        getContent()
    },[])

    if (content === null) return null
    if (user.role === null) return null
    else return <Routing />
}
