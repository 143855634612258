import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {ToggleBtn} from "../../../ToggleBtn";
import {useAppState} from "../../../../../store/reduxStore";
import {UserState} from "../../../../../store/reducers/auth";
import {ProfileState} from "../../../../../store/reducers/profile";
import {IProfile} from "../../../../../types/profile";
import {ProfileAPI} from "../../../../../API/useProfile";
import {store_server} from "../../../../../helpers/config";
import {customFetch} from "../../../../../helpers/customFetch";
import {SvgMyProfile} from "../../../../assets/images/svg/SvgMyProfile";
import loadingGif from '../../../../assets/images/LoadingGif.gif'
import {IsLoadingState} from "../../../../../store/reducers/isLoading";
import {useThunks} from "../../../../../helpers/useActions";

export const Head = ({
                         verify,
                         profile,
                         setNewProfile,
                         changeMode,
                         setChangeMode
                     }: { verify: boolean, profile: IProfile, setNewProfile: Function, changeMode: boolean, setChangeMode: Function }) => {

    const {user} = useAppState(UserState)
    const {setAvatarFile} = useThunks(ProfileAPI)
    const {updateProfileAvatarLink} = useAppState(IsLoadingState)

    const [img, setImg] = useState<{ src: string | null, loading: boolean }>({
        src: null,
        loading: true,
    })

    useEffect(() => {
        if (!!profile.avatarLink.length) {
            customFetch(`${store_server}/${profile.avatarLink}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'image/png',
                },
            })
                .then(data => {
                    if (data.status === 200) return data.blob()
                    else throw Error()
                })
                .then(blobData => {
                    const urlCreator = window.URL || window.webkitURL;
                    const image = urlCreator.createObjectURL(blobData);
                    setImg(prev => ({loading: false, src: image}))
                })
                .catch((err) => {
                    setImg(prev => ({loading: false, src: null}))
                })
        } else setImg(prev => ({loading: false, src: null}))
    }, [profile, updateProfileAvatarLink])

    const changeNameHandler = (e: any) => {
        setNewProfile({...profile, name: e.target.value})
    }
    const changePhoneHandler = (e:any) => {
        setNewProfile({...profile, phone: e.target.value})
    }
    const changeToggleShowHandler = (field: any) => {
        // @ts-ignore
        setNewProfile({...profile, private: {...profile.private, [`${field}`]: !profile.private[`${field}`]}})
    }

    const addFileHandler = (e: any) => {
        setImg(prev => ({...prev, loading: true}))
        const file = e.target.files[0]
        setAvatarFile(file)
        e.target.value = ''
    }

    return (
        <div className={css(s.Head)}>
            <div className={css(s.changeBtnBox)}>
                <div className={css(s.changeBtn, changeMode && s.active)} onClick={() => setChangeMode(true)}>
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.587 12.9594L19.0546 13.5821C18.9024 14.0622 18.7066 14.5277 18.4704 14.973C18.8506 15.6059 19.6497 16.938 19.6497 16.938C19.8025 17.1925 19.7621 17.5185 19.5524 17.7282L17.7232 19.5574C17.5135 19.7677 17.1882 19.8081 16.933 19.6547L14.9648 18.4754C14.522 18.7097 14.059 18.9036 13.5822 19.0546L12.9594 21.587C12.8874 21.875 12.629 22.0771 12.3322 22.0771H9.74499C9.44811 22.0771 9.18914 21.875 9.11778 21.587C9.11778 21.587 8.68637 19.8195 8.49561 19.0553C7.98714 18.8942 7.49575 18.6851 7.02769 18.4299L4.98689 19.6547C4.73171 19.8063 4.40706 19.7671 4.19673 19.5574L2.36752 17.7282C2.15783 17.5185 2.11738 17.1925 2.27024 16.938L3.53226 14.8302C3.32886 14.4291 3.15833 14.0117 3.02254 13.584L0.490182 12.9594C0.202144 12.8874 0 12.629 0 12.3322V9.74499C0 9.44811 0.202144 9.18914 0.490139 9.11778L3.02315 8.495C3.17665 8.00801 3.37621 7.53491 3.61815 7.08077L2.48881 5.19852C2.33595 4.94399 2.3764 4.61805 2.58609 4.40836L4.4153 2.57915C4.62439 2.37006 4.95028 2.3303 5.20546 2.48187C5.20546 2.48187 6.47188 3.24107 7.09215 3.6125C7.54124 3.3731 8.01116 3.1754 8.49565 3.02194L9.11782 0.490183C9.18914 0.202101 9.44811 0 9.74499 0H12.3322C12.629 0 12.8874 0.202145 12.9594 0.490139L13.5815 3.0219C14.0799 3.1798 14.5599 3.38384 15.0166 3.63143C15.6539 3.24931 16.933 2.48187 16.933 2.48187C17.1875 2.32966 17.5134 2.36881 17.7231 2.57915L19.5523 4.40836C19.762 4.61805 19.8025 4.94399 19.6496 5.19852L18.4868 7.13575C18.716 7.57095 18.9062 8.02634 19.0546 8.495L21.587 9.11778C21.875 9.18979 22.0771 9.44811 22.0771 9.74499V12.3322C22.0771 12.629 21.875 12.8874 21.587 12.9594ZM11.0386 6.51103C8.54235 6.51103 6.51103 8.54235 6.51103 11.0386C6.51103 13.5348 8.54235 15.5661 11.0386 15.5661C13.5348 15.5661 15.5661 13.5348 15.5661 11.0386C15.5661 8.54235 13.5348 6.51103 11.0386 6.51103Z"/>
                    </svg>
                    <span>редактировать</span>
                </div>
            </div>

            <div className={css(s.firstRow)}>
                <div className={css(s.name)}>
                    <div className={css(s.avatar)}>
                        <div className={css(s.imgBox)}>
                            {img.src !== null && !img.loading && <img src={img.src} alt="png"/>}
                            {img.src === null && !img.loading && <SvgMyProfile/>}
                            {img.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                        </div>
                        {changeMode && <label className={css(s.changeAvatarBtn)}>
                            <input type="file" accept={`.png,.jpeg,.jpg`} onInput={addFileHandler}/>
                            <span>Редактировать фото</span>
                            <div className={css(s.mobilChangeAvatarBtn)}>+</div>
                        </label>}
                    </div>
                    <div className={css(s.nameValue)}>
                        <div className={css(s.nameTitle)}>
                            Имя
                        </div>
                        {!changeMode
                            ? <div className={css(s.nameText)}>{profile.name}</div>
                            : <input type="text" value={profile.name} onChange={changeNameHandler}/>
                        }
                    </div>
                </div>
                {verify && <div className={css(s.cro)}>
                    <div className={css(s.croName)}>
                        <div className={css(s.croNameTitle)}>
                            Наименование СРО*:
                        </div>
                        <div className={css(s.croNameText)}>
                            {!!profile.croName.trim().length ? profile.croName : 'Не установленно'}
                            {/*Ассоциация «Союз кадастровых инженеров»*/}
                        </div>
                    </div>
                    <div className={css(s.croNumber)}>
                        <div className={css(s.croNumberTitle)}>
                            Номер в реестре членов СРО*:
                        </div>
                        <div className={css(s.croNumberText)}>
                            {!!profile.croNumber.trim().length ? profile.croNumber : 'Не установленно'}
                            {/*125-126-456*/}
                        </div>
                    </div>
                </div>}
                <div className={css(s.status)}>
                    <div className={css(s.online)}>
                        <div className={css(s.onlineTitle)}>
                            статус:
                        </div>
                        <div className={css(s.onlineText)}>
                            <div className={css(s.onlineStatus)}><div className={css(s.on)}/>В сети</div>
                        </div>
                    </div>
                    <div className={css(s.registeredDate)}>
                        <div className={css(s.registeredDateTitle)}>
                            На сайте:
                        </div>
                        <div className={css(s.registeredDateText)}>
                            {new Date(Date.parse(profile.registrationDate)).toLocaleDateString()}
                            {/*с 20 октября 2022*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css(s.secondRow)}>
                <div className={css(s.phone)}>
                    <div className={css(s.phoneMain)}>
                        <div className={css(s.phoneTitle)}>
                            Телефон:
                        </div>
                        {!changeMode
                            ? <div className={css(s.phoneText)}>{`${profile.phone.length ? profile.phone : 'не указан'}`}</div>
                            : <input type="text" value={profile.phone} onChange={changePhoneHandler}/>
                        }
                    </div>
                    <div className={css(s.changeShowBtn)}>
                        {changeMode && <ToggleBtn checked={profile.private.phone}
                                                  toggle={() => changeToggleShowHandler('phone')}/>}
                    </div>
                </div>
                <div className={css(s.email)}>
                    <div className={css(s.emailMain)}>
                        <div className={css(s.emailTitle)}>
                            E-mail:
                        </div>
                        <div className={css(s.emailText)}>
                            {`${profile.email.length ? profile.email : 'не указан'}`}
                        </div>
                    </div>
                    <div className={css(s.changeShowBtn)}>
                        {changeMode && <ToggleBtn checked={profile.private.email}
                                                  toggle={() => changeToggleShowHandler('email')}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}
