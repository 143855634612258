
// production
// export const url = `https://zemsite.site`
export const user_server = `https://zemsite.site/auth`
export const store_server = `https://zemsite.site/store`
export const task_server = `https://zemsite.site/task`
// export const message_server = `http://45.84.225.170:7008`
export const notice_server = `https://zemsite.site/notice`
export const bill_server = `https://zemsite.site/bill`
export const application_server = `https://zemsite.site/app`

export const ws_messenger_server = `wss://zemsite.site/wsmessenger`
export const ws_notice_server = `wss://zemsite.site/wsnotice`

// developer
// export const url = `http://127.0.0.1`
// export const user_server = `http://127.0.0.1:7001`
// export const store_server = `http://127.0.0.1:7003`
// export const task_server = `http://127.0.0.1:7004`
// // // // // export const message_server = `http://127.0.0.1:7008`
// export const ws_messenger_server = `ws://127.0.0.1:7009`
// export const ws_notice_server = `ws://127.0.0.1:7007`
// export const notice_server = `http://localhost:7006`
// export const bill_server = `http://localhost:7010/bill`
// export const application_server = `http://localhost:7002`


// setting App
export const timeCheckOnline = 3000
