import React from 'react'
import s from './index.module.scss'
import {css} from "../../../helpers/css";
import {useAppState} from "../../../store/reduxStore";
import {HintActions, HintState} from "../../../store/reducers/hint";
import {useActions} from "../../../helpers/useActions";

export const Hint = () => {

    const {hint} = useAppState(HintState)
    const {closeHintApp} = useActions(HintActions)

    const closeHandler = () => {
        closeHintApp()
    }

    if (!hint.isShow) return null
    return (
        <div className={css(s.Hint)}>
            <div className={css(s.main)}>
                <div className={css(s.text)}>
                    {hint.type === 'ok' &&
                        <span className={css(s.iconHint, s.ok)}>
                             <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.5194 2.2535L10.0478 1.78192C9.85354 1.58766 9.5364 1.58766 9.34044 1.78192L4.39551 6.72853L1.80514 4.13817C1.61087 3.94388 1.29371 3.94388 1.09777 4.13817L0.626169 4.60975C0.431902 4.80404 0.431902 5.12118 0.626169 5.31712L4.04017 8.73108C4.13815 8.82906 4.26432 8.87888 4.39219 8.87888C4.52006 8.87888 4.64791 8.82906 4.74424 8.73108L10.5144 2.96089C10.7137 2.76331 10.7137 2.44781 10.5194 2.2535Z"
                                    fill="white"/>
                                <clipPath id="clip0_755_23339">
                                    <rect width="10.184" height="10.184" fill="white" transform="translate(0.482422 0.165527)"/>
                                </clipPath>
                            </svg>
                        </span>
                    }
                    {hint.type === 'warning' &&
                        <span className={css(s.iconHint, s.warning)}>!</span>
                    }
                    {hint.type === 'location' &&
                        <span className={css(s.iconHint)}>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 0C6.15694 0 2.625 3.53194 2.625 7.875C2.625 13.3337 9.7335 20.5052 10.0354 20.8084C10.164 20.9357 10.332 21 10.5 21C10.668 21 10.836 20.9357 10.9646 20.8084C11.2665 20.5052 18.375 13.3337 18.375 7.875C18.375 3.53194 14.8431 0 10.5 0ZM10.5 19.3948C8.93288 17.7174 3.9375 12.0448 3.9375 7.875C3.9375 4.25644 6.88144 1.3125 10.5 1.3125C14.1186 1.3125 17.0625 4.25644 17.0625 7.875C17.0625 12.0409 12.0671 17.7174 10.5 19.3948Z" fill="#00AB55"/>
                                    <path d="M10.5 3.9375C8.32912 3.9375 6.5625 5.70412 6.5625 7.875C6.5625 10.0459 8.32912 11.8125 10.5 11.8125C12.6709 11.8125 14.4375 10.0459 14.4375 7.875C14.4375 5.70412 12.6709 3.9375 10.5 3.9375ZM10.5 10.5C9.05231 10.5 7.875 9.32269 7.875 7.875C7.875 6.42731 9.05231 5.25 10.5 5.25C11.9477 5.25 13.125 6.42731 13.125 7.875C13.125 9.32269 11.9477 10.5 10.5 10.5Z" fill="#00AB55"/>
                                </svg>
                        </span>
                    }
                    <span>{hint.text}</span>
                </div>
                <div className={css(s.closeBtn)} onClick={closeHandler}>
                    Закрыть
                </div>
            </div>
        </div>
    )
}
