import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import loadingGif from '../../../../assets/images/LoadingGif.gif'
import {useActions} from "../../../../../helpers/useActions";
import {IsLoadingActions, IsLoadingState} from "../../../../../store/reducers/isLoading";
import {useAppState} from "../../../../../store/reduxStore";

export const SaveChangeBox = ({saveChangedHandler,cancelChangedHandler}:any) => {

    const {setUpdateProfileAction} = useActions(IsLoadingActions)
    const {updateProfile} = useAppState(IsLoadingState)

    useEffect(()=>{
        return ()=>{
            setUpdateProfileAction(false)
        }
    },[])

    const saveHandler = () => {
        if (updateProfile) return
        setUpdateProfileAction(true)
        saveChangedHandler()
    }
    const cancelHandler = () => {
        if (updateProfile) return
        cancelChangedHandler()
    }

    return (
        <div className={css(s.SaveChangeBox)}>
            <div className={css(s.saveBtn)} onClick={saveHandler}>
                {!updateProfile
                    ? 'Сохранить изменения'
                    : <img src={loadingGif} alt="loading"/>
                }
            </div>
            <div className={css(s.cancelBtn)} onClick={cancelHandler}>
                Отменить изменения
            </div>
        </div>
    )
}
