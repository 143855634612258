import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";
import {IProfile} from "../../types/profile";

interface stateType {
    userList: Array<any> | null
    totalUserList: number
    searchUser: string | null
    percent: number | null
    availableFunds: number | null
    withdrawHistoryList: Array<any> | null
    totalHistoryList: number
}

const initialState: stateType = {
    userList: null,
    totalUserList: 0,
    searchUser: null,
    percent: null,
    availableFunds: null,
    withdrawHistoryList: null,
    totalHistoryList: 0,
}

export const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setUserListAction: (state, action: PayloadAction<Array<any> | null>): void => {
            state.userList = action.payload
        },
        addUserListAction: (state, action: PayloadAction<Array<any>>): void => {
            if (state.userList)
                state.userList = [...state.userList, ...action.payload]
        },
        setTotalUserListAction: (state, action: PayloadAction<number>): void => {
            state.totalUserList = action.payload
        },
        setSearchUserAction: (state, action: PayloadAction<string | null>): void => {
            state.searchUser = action.payload
        },
        changeRoleUserAction: (state, action: PayloadAction<any>): void => {
            if (state.userList)
                state.userList = state.userList.map(user => user.id === action.payload.id ? action.payload : user)
        },
        deleteUserAction: (state, action: PayloadAction<any>): void => {
            if (state.userList)
                state.userList = state.userList.filter(user => user.id !== action.payload.id)
        },
        setPercentAction: (state, action: PayloadAction<any>): void => {
            state.percent = action.payload
        },
        setAvailableFundsAction: (state, action: PayloadAction<any>): void => {
            state.availableFunds = action.payload
        },
        setWithdrawHistoryListAction: (state, action: PayloadAction<any>): void => {
            state.withdrawHistoryList = action.payload
        },
        addWithdrawHistoryListAction: (state, action: PayloadAction<any>): void => {
            if (state.withdrawHistoryList)
                state.withdrawHistoryList = [...state.withdrawHistoryList, ...action.payload]
        },
        setTotalWithdrawHistoryListAction: (state, action: PayloadAction<any>): void => {
            state.totalHistoryList = action.payload
        },
        unshiftWithdrawHistoryListAction: (state, action: PayloadAction<any>): void => {
            if (state.withdrawHistoryList)
                state.withdrawHistoryList = [action.payload, ...state.withdrawHistoryList]
        },
        deleteWithdrawHistoryItemAction: (state, action: PayloadAction<number>): void => {
            if (state.withdrawHistoryList)
                state.withdrawHistoryList = state.withdrawHistoryList.filter(el => el.id !== action.payload)
            state.totalHistoryList = state.totalHistoryList - 1
        },
    }
})

export const AdminReducer = slice.reducer
export const AdminActions = slice.actions
export const AdminState = (state: RootState) => state.AdminReducer


