import React, {useEffect} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";
import {LoadingPage} from "../../../LoadingPage";
import {UserState} from "../../../../../store/reducers/auth";
import {MyTask} from "./MyTask";
import {GuestTask} from "./GuestTask";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {useParams} from "react-router-dom";
import {WorkerWorkTask} from "./WorkerWorkTask";
import {OwnerWorkTask} from "./OwnerWorkTask";

export const ActiveTask = () => {
    const params = useParams()

    const {activeTask} = useAppState(TaskState)
    const {user} = useAppState(UserState)

    const {getActiveTaskList} = useThunks(TaskAPI)

    const {setActiveTaskAction} = useActions(TaskActions)

    useEffect(()=>{
        if (activeTask === null)
            getActiveTaskList(Number(params.id))
    },[params,activeTask])

    useEffect(()=>{
        return () => {
            setActiveTaskAction(null)
        }
    },[])

    if (activeTask === null) return <LoadingPage />
    return (
        <div className={css(s.ActiveTask)}>
            {activeTask.status !== 4 && activeTask.status !== 5 && activeTask.ownerId === user.id && <MyTask activeTask={activeTask}/>}
            {activeTask.status !== 4 && activeTask.status !== 5 && activeTask.ownerId !== user.id && <GuestTask activeTask={activeTask}/>}
            {(activeTask.status === 4 || activeTask.status === 5) && activeTask.ownerId === user.id && <OwnerWorkTask activeTask={activeTask}/>}
            {(activeTask.status === 4 || activeTask.status === 5) && activeTask.ownerId !== user.id && activeTask.workerId === user.id && <WorkerWorkTask activeTask={activeTask}/>}
        </div>
    )
}


