import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {AdminActions, AdminState} from "../../../../store/reducers/admin";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {AdminAPI} from "../../../../API/useAdmin";
import loadingGif from '../../../assets/images/LoadingGif.gif'

export const Billing = () => {

    return (
        <div className={css(s.Billing)}>
            <div className={css(s.container)}>
                <SetPercent />
                <AvailableFunds />
            </div>
        </div>
    )
}

const AvailableFunds = () => {

    const {availableFunds} = useAppState(AdminState)
    const {getAvailableFunds} = useThunks(AdminAPI)
    const {setTotalWithdrawHistoryListAction, setWithdrawHistoryListAction} = useActions(AdminActions)

    const [withdrawMoney, setWithdrawMoney] = useState(0)

    useEffect(()=>{
        getAvailableFunds()
        // getWithdrawHistoryList()
        return ()=>{
            setWithdrawHistoryListAction(null)
            setTotalWithdrawHistoryListAction(0)
        }
    },[])

    // const inputPercentHandler = (e:any) => {
    //     if (addLoad) return;
    //     setWithdrawMoney(Number(e.target.value.replace(/[^\d]/g, '')))
    // }
    //
    // const [addLoad, setAddLoad] = useState(false)
    // const sendWithdrawMoneyHandler = (e:any) => {
    //     e.preventDefault()
    //     if (withdrawMoney < 1) return
    //     setAddLoad(true)
    //     sendWithdrawMoney(Number(withdrawMoney), ()=>{
    //         setAddLoad(false)
    //     })
    // }
    // const [canselLoad, setCanselLoad] = useState(false)
    // const [canselIdLoad, setCanselIdLoad] = useState<any>(null)
    // const cancelWithdrawHandler = (id:number)=>{
    //     if (canselLoad) return
    //     setCanselLoad(true)
    //     setCanselIdLoad(id)
    //     cancelWithdraw(id,()=>{
    //         setCanselLoad(false)
    //         setCanselIdLoad(null)
    //     })
    // }
    // const [addListLoad, setAddListLoad] = useState(false)
    // const addWithdrawHistoryListHandler = ()=>{
    //     if (addListLoad) return
    //     setAddListLoad(true)
    //     addWithdrawHistoryList(()=>{
    //         setAddListLoad(false)
    //     })
    // }

    return (
        <div className={css(s.AvailableFunds)}>
            <div className={css(s.balance)}>
                <div>Сумма необходимая системе (баланс для вывода участниками):</div>
                <div className={css(s.money)}>{availableFunds !== null ? availableFunds : `...`} руб.</div>
            </div>
            {/*<form className={css(s.withdraw)} onSubmit={sendWithdrawMoneyHandler}>*/}
            {/*    <div className={css(s.title)}>Выведено средств из кошелька, информация для обновления доступных средств</div>*/}
            {/*    <input type="text" value={withdrawMoney} onChange={inputPercentHandler}/>*/}
            {/*    <button>*/}
            {/*        {addLoad ? <img src={loadingGif} alt="loading"/> : `Вывел сумму`}*/}
            {/*    </button>*/}
            {/*</form>*/}
            {/*<div className={css(s.history)}>*/}
            {/*    <div className={css(s.title)}>История вывода средств:</div>*/}
            {/*    {withdrawHistoryList?.map((item, index) =>*/}
            {/*        <div key={item.id} className={css(s.historyItem)}>*/}
            {/*            <div className={css(s.index)}>{index + 1}.</div>*/}
            {/*            <div className={css(s.amount)}>*/}
            {/*                {item.withdrawMoney} руб.*/}
            {/*            </div>*/}
            {/*            <div className={css(s.backWithdraw)} onClick={()=>cancelWithdrawHandler(item.id)}>*/}
            {/*                {canselLoad && canselIdLoad === item.id ? <img src={loadingGif} alt="loading"/> : `отменить вывод`}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*    {!!withdrawHistoryList?.length && totalHistoryList > 0 && totalHistoryList > withdrawHistoryList.length &&*/}
            {/*        <div className={css(s.showMore)}>*/}
            {/*                <span onClick={addWithdrawHistoryListHandler}>*/}
            {/*                    {addListLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}*/}
            {/*                </span>*/}
            {/*        </div>}*/}
            {/*    {withdrawHistoryList === null && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}*/}
            {/*    {withdrawHistoryList !== null && withdrawHistoryList.length === 0 && <div className={css(s.historyListEmpty)}>Список пуст</div>}*/}
            {/*</div>*/}
        </div>
    )
}

const SetPercent = () => {

    const {percent} = useAppState(AdminState)
    const {getPercent, updatePercent} = useThunks(AdminAPI)
    const [newPercent,setNewPercent] = useState<any>(null)

    useEffect(()=>{
        getPercent()
    },[])

    useEffect(()=>{
        if (percent)
            setNewPercent(percent)
    },[percent])

    const inputPercentHandler = (e:any) => {
        if (addLoad) return;
        if (e.target.value > 100) return;
        if (!!e.target.value.length && !e.target.value.replace(/[^\d]/g, '').length) return
        setNewPercent(Number(e.target.value.replace(/[^\d]/g, '')))
    }

    const [addLoad, setAddLoad] = useState(false)
    const setNewPercentHandler = (e:any) => {
        e.preventDefault()
        setAddLoad(true)
        updatePercent(Number(newPercent), ()=>{
            setAddLoad(false)
        })
    }

    if (newPercent === null) return null
    return (
        <form className={css(s.SetPercent)} onSubmit={setNewPercentHandler}>
            <div className={css(s.title)}>Комиссия сервиса {percent !== null ? percent+`%` : '...'}. Будьте внимательны при обновлении, комиссия установится только к новым опубликованным задачам.</div>
            <input type="text" value={newPercent} onChange={inputPercentHandler}/>
            <span>%</span>
            <button>
                {addLoad ? <img src={loadingGif} alt="loading"/> : `Обновить комиссию`}
            </button>
        </form>
    )
}
