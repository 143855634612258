import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IMinFile} from "../../types/file";
import {IVerifyData, IVerifyItem} from "../../types/profile";
import {OfferList} from "../../view/components/Pages/TaskPage/ActiveTask/MyTask/OfferList";

interface stateType {
    newTask: any
    addedFiles: Array<IMinFile> | null
    downloadFiles: Array<IMinFile> | null

    totalTaskList: number | null
    taskList: any
    searchTaskList: string
    filterTaskList: any

    activeTask: any
    ownerProfile: any
    offerList: any

    myRespond: any
    myArbitrage: any

    // my Work
    filterWorkTask: any
    workTaskList: any
    totalWorkTaskList: any
    statisticWorkTask: any
    // my Offer
    filterWorkOffer: any
    workOfferList: any
    totalWorkOfferList: any
    statisticWorkOffer: any
}

const initialState: stateType = {
    newTask: null,
    addedFiles: null,
    downloadFiles: null,

    totalTaskList: null,
    taskList: null,
    searchTaskList: ``,
    filterTaskList: {
        createDate: null,
        deadline: null,
    },

    activeTask: null,
    ownerProfile: null,
    offerList: null,

    myRespond: null,
    myArbitrage: undefined,

    // my Work
    filterWorkTask: null,
    workTaskList: null,
    totalWorkTaskList: null,
    statisticWorkTask: null,
    // my Offer
    filterWorkOffer: null,
    workOfferList: null,
    totalWorkOfferList: null,
    statisticWorkOffer: null,
}

export const slice = createSlice({
    name: 'taskReducer',
    initialState,
    reducers: {
        setNewTaskAction: (state, action: PayloadAction<any>) : void => {
            state.newTask = action.payload
        },
        // files task
        setAddedFileListAction: (state, action: PayloadAction<Array<IMinFile> | null>) : void => {
            state.addedFiles = action.payload
        },
        setDownloadFileListAction: (state, action: PayloadAction<Array<IMinFile> | null>) : void => {
            state.downloadFiles = action.payload
        },
        addFileAction: (state, action: PayloadAction<IMinFile>) : void => {
            state.addedFiles?.push(action.payload)
        },
        deleteFileAction: (state, action: PayloadAction<IMinFile>) : void => {
            if (state.addedFiles)
                state.addedFiles = state.addedFiles?.filter(file => file.id !== action.payload.id)
        },

        // taskList
        setTaskListAction: (state, action: PayloadAction<Array<any> | null>) : void => {
            state.taskList = action.payload
        },
        addTaskListAction: (state, action: PayloadAction<Array<any>>) : void => {
            if (state.taskList)
                state.taskList = [...state.taskList,...action.payload]
        },
        setTotalTaskListAction: (state, action: PayloadAction<number | null>) : void => {
            state.totalTaskList = action.payload
        },
        // searchExecutorList
        setSearchTaskListAction: (state, action: PayloadAction<string>) : void => {
            state.searchTaskList = action.payload
        },
        // filterExecutorList
        setFilterTaskListAction: (state, action: PayloadAction<any>) : void => {
            state.filterTaskList = action.payload
        },

        // active task
        setActiveTaskAction: (state, action: PayloadAction<any>) : void => {
            state.activeTask = action.payload
        },
        // owner Profile
        setOwnerProfileAction: (state, action: PayloadAction<any>) : void => {
            state.ownerProfile = action.payload
        },
        setUserOfferListAction: (state, action: PayloadAction<any>) : void => {
            state.offerList = action.payload
        },

        setMyRespondAction: (state, action: PayloadAction<any>) : void => {
            if (action.payload !== null) state.myRespond = [action.payload]
            else state.myRespond = []
        },
        clearMyRespondAction: (state) : void => {
            state.myRespond = null
        },

        // my Arbitrage
        setArbitrageTaskAction: (state, action: PayloadAction<any>) : void => {
            state.myArbitrage = action.payload
        },

        // my Work
        setFilterWorkTaskAction: (state, action: PayloadAction<any>) : void => {
            state.filterWorkTask = action.payload
        },
        setWorkTaskListAction: (state, action: PayloadAction<any>) : void => {
            state.workTaskList = action.payload
        },
        addWorkTaskListAction: (state, action: PayloadAction<any>) : void => {
            state.workTaskList = [...state.workTaskList, ...action.payload]
        },
        setTotalWorkTaskAction: (state, action: PayloadAction<any>) : void => {
            state.totalWorkTaskList = action.payload
        },
        setStatisticWorkTaskAction: (state, action: PayloadAction<any>) : void => {
            state.statisticWorkTask = action.payload
        },
        updateWorkTaskAction: (state, action: PayloadAction<{updateId: number, task: any}>) : void => {
            state.workTaskList = state.workTaskList.filter((task:any) => task.id !== action.payload.updateId)
            if (!!action.payload.task) {
                state.workTaskList.unshift(action.payload.task)
            }
        },
        // my Offer
        setFilterWorkOfferAction: (state, action: PayloadAction<any>) : void => {
            state.filterWorkOffer = action.payload
        },
        setWorkOfferListAction: (state, action: PayloadAction<any>) : void => {
            state.workOfferList = action.payload
        },
        addWorkOfferListAction: (state, action: PayloadAction<any>) : void => {
            state.workOfferList = [...state.workOfferList, ...action.payload]
        },
        setTotalWorkOfferAction: (state, action: PayloadAction<any>) : void => {
            state.totalWorkOfferList = action.payload
        },
        setStatisticWorkOfferAction: (state, action: PayloadAction<any>) : void => {
            state.statisticWorkOffer = action.payload
        },
        updateWorkOfferAction: (state, action: PayloadAction<{updateId: number, task: any}>) : void => {
            state.workOfferList = state.workOfferList.filter((task:any) => task.id !== action.payload.updateId)
            if (!!action.payload.task) {
                state.workOfferList.unshift(action.payload.task)
            }
        },
    }
})

export const TaskReducer = slice.reducer
export const TaskActions = slice.actions
export const TaskState = (state: RootState) => state.TaskReducer

