import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {SvgSearch} from "../../../../assets/images/svg/SvgSearch";
import {SvgPlace} from "../../../../assets/images/svg/SvgPlace";
import {SvgClose} from "../../../../assets/images/svg/SvgClose";
import {SvgArrowTop} from "../../../../assets/images/svg/SvgArrowTop";
import {ProfileAPI} from "../../../../../API/useProfile";
import {useAppState} from "../../../../../store/reduxStore";
import {ProfileActions, ProfileState} from "../../../../../store/reducers/profile";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import loadingGif from '../../../../assets/images/LoadingGif.gif'
import {TaskItem} from "./TaskItem";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";

export const TaskList = () => {

    const [addLoad, setAddLoad] = useState(false)
    const [showFilter, setShowFilter] = useState(true)
    const {getTaskList,addExecutorList} = useThunks(TaskAPI)
    const {taskList, totalTaskList, searchTaskList, filterTaskList} = useAppState(TaskState)
    const {setTaskListAction, setSearchTaskListAction, setFilterTaskListAction} = useActions(TaskActions)

    useEffect(() => {
        getTaskList()
        return () => {
            setTaskListAction(null)
        }
    }, [searchTaskList, filterTaskList])
    useEffect(() => {
        return () => {
            setSearchTaskListAction('')
            setFilterTaskListAction({
                createDate: null,
                deadline: null,
            })
        }
    }, [])
    useEffect(() => {
        setAddLoad(false)
    }, [taskList])

    const addExecutorListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addExecutorList()
    }

    // search
    const [search, setSearch] = useState('')
    const changeSearchHandler = (e:any) => {
        setSearch(e.target.value)
        if (e.target.value === `` && !!searchTaskList.length) {
            setSearchTaskListAction(``)
        }
    }
    const searchHandler = (e:any) => {
        e.preventDefault()
        setSearchTaskListAction(search)
    }

    // filter
    const [filter, setFilter] = useState<any>({
        createDate: null,
        deadline: null,
    })
    const setFilterCreateDateHandler = (index: number) => {
        setFilter((prev:any) => ({...prev, createDate: prev.createDate !== index ? index : null}))
    }
    const setFilterDeadlineHandler = (index: number) => {
        setFilter((prev:any) => ({...prev, deadline: prev.deadline !== index ? index : null}))
    }
    const clearFilterHandler = () => {
        setFilter({
            createDate: null,
            deadline: null,
        })
        setFilterTaskListAction({
            createDate: null,
            deadline: null,
        })
    }
    const applyFilterHandler = () => {
        setFilterTaskListAction({
            createDate: filter.createDate,
            deadline: filter.deadline
        })
    }

    return (
        <div className={css(s.TaskList)}>
            <div className={css(s.container)}>
                <div className={css(s.titlePage)}>
                    Каталог задач
                </div>
                <div className={css(s.search)}>
                    <form className={css(s.inputBox)} onSubmit={searchHandler}>
                        <input type="text" onChange={changeSearchHandler} placeholder={`Поиск по названию`}/>
                        <div className={css(s.searchBtn)} onClick={searchHandler}>
                            <SvgSearch/>
                        </div>
                    </form>
                </div>
                <div className={css(s.main)}>
                    <div className={css(s.filterBlock, showFilter && s.show)} onClick={() => setShowFilter(true)}>
                        <div className={css(s.title)}>
                            <span>фильтр</span>
                            <SvgArrowTop/>
                        </div>
                        <div className={css(s.filterMain)}
                             onClick={e => e.stopPropagation()}>
                            <div className={css(s.filterCreateDate)}>
                                <div className={css(s.filterCreateDateTitle)}>
                                    По дате создания
                                </div>
                                <div className={css(s.filterCreateDateList)}>
                                    <div className={css(s.filterCreateDateListItem, filter.createDate === 0 && s.active)} onClick={()=>setFilterCreateDateHandler(0)}>
                                        <div className={css(s.filterCreateDateCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterCreateDateText)}>
                                            за 24 часа
                                        </div>
                                    </div>
                                    <div className={css(s.filterCreateDateListItem, filter.createDate === 1 && s.active)} onClick={()=>setFilterCreateDateHandler(1)}>
                                        <div className={css(s.filterCreateDateCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterCreateDateText)}>
                                            до 2 дней
                                        </div>
                                    </div>
                                    <div className={css(s.filterCreateDateListItem, filter.createDate === 2 && s.active)} onClick={()=>setFilterCreateDateHandler(2)}>
                                        <div className={css(s.filterCreateDateCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterCreateDateText)}>
                                            до 3 дней
                                        </div>
                                    </div>
                                    <div className={css(s.filterCreateDateListItem, filter.createDate === 3 && s.active)} onClick={()=>setFilterCreateDateHandler(3)}>
                                        <div className={css(s.filterCreateDateCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterCreateDateText)}>
                                            до 5 дней
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={css(s.filterDeadline)}>
                                <div className={css(s.filterDeadlineTitle)}>
                                    По срокам выполнения
                                </div>
                                <div className={css(s.filterDeadlineList)}>
                                    <div className={css(s.filterDeadlineListItem, filter.deadline === 0 && s.active)} onClick={()=>setFilterDeadlineHandler(0)}>
                                        <div className={css(s.filterDeadlineCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterDeadlineText)}>
                                            более 24 часов
                                        </div>
                                    </div>
                                    <div className={css(s.filterDeadlineListItem, filter.deadline === 1 && s.active)} onClick={()=>setFilterDeadlineHandler(1)}>
                                        <div className={css(s.filterDeadlineCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterDeadlineText)}>
                                            более 48 часов
                                        </div>
                                    </div>
                                    <div className={css(s.filterDeadlineListItem, filter.deadline === 2 && s.active)} onClick={()=>setFilterDeadlineHandler(2)}>
                                        <div className={css(s.filterDeadlineCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterDeadlineText)}>
                                            более 72 часов
                                        </div>
                                    </div>
                                    <div className={css(s.filterDeadlineListItem, filter.deadline === 3 && s.active)} onClick={()=>setFilterDeadlineHandler(3)}>
                                        <div className={css(s.filterDeadlineCheckBox)}>
                                            <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6874 1.16537L10.2158 0.693796C10.0215 0.499529 9.70437 0.499529 9.50841 0.693796L4.56348 5.64041L1.97311 3.05004C1.77884 2.85575 1.46168 2.85575 1.26574 3.05004L0.794138 3.52162C0.599871 3.71591 0.599871 4.03305 0.794138 4.22899L4.20813 7.64296C4.30611 7.74094 4.43229 7.79075 4.56016 7.79075C4.68803 7.79075 4.81587 7.74094 4.91221 7.64296L10.6824 1.87277C10.8816 1.67518 10.8816 1.35968 10.6874 1.16537Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={css(s.filterDeadlineText)}>
                                            более 96 часов
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css(s.sendBtn)} onClick={applyFilterHandler}>
                            Применить фильтр
                        </div>
                        <div className={css(s.clearFilterBtnBox)}>
                            <div className={css(s.clearFilterBtn)} onClick={clearFilterHandler}>
                                <SvgClose/>
                                <div className={css(s.clearFilterText)}>
                                    Сбросить фильтр
                                </div>
                            </div>
                        </div>
                        <div className={css(s.hiddenFilterBtnBox)}>
                            <div className={css(s.clearFilterBtn)} onClick={(e) => {
                                e.stopPropagation()
                                setShowFilter(false)
                            }}>
                                <SvgArrowTop/>
                                <div className={css(s.clearFilterText)}>
                                    Свернуть
                                </div>
                            </div>
                        </div>
                    </div>
                    {!!taskList && <div className={css(s.taskList)}>
                        {taskList?.map((item:any) => <TaskItem key={item.id} item={item}/>)}
                        {!!taskList && totalTaskList !== null && totalTaskList > taskList.length && <div className={css(s.showMore)}>
                            <span onClick={addExecutorListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                        </div>}
                    </div>}
                    {!!taskList && !taskList.length && <div className={css(s.emptyList)}>На данный момент нет задач</div>}
                    {!taskList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                </div>
            </div>
        </div>
    )
}
