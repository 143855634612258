import jwt_decode from "jwt-decode";
import {useActions} from "../helpers/useActions";
import {IsLoadingActions} from "../store/reducers/isLoading";
import {AppDispatch, RootState, useAppDispatch, useAppState} from "../store/reduxStore";
import {UserActions, UserState} from "../store/reducers/auth";
import {useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {store_server, user_server} from "../helpers/config";
import {customFetch, customFetchJson} from "../helpers/customFetch";
import {IUser} from "../types/user";
import {showPopupActions} from "../store/reducers/showPopup";
import {IResponse} from "../types/api";
import {ProfileActions, ProfileState} from "../store/reducers/profile";
import {IProfile} from "../types/profile";
import {IMinFile} from "../types/file";
import {HintActions} from "../store/reducers/hint";
import {NavigationActions} from "../store/reducers/navigation";

export const ProfileAPI = {
    // const dispatch = useAppDispatch()
    // const navigate = useNavigate();
    // const params = useParams();
    //
    // // const {setLoadingStartApp} = useActions(IsLoadingActions)
    // const {user} = useAppState(UserState)
    // const {setProfileAction, setMyProfileImageAction, setAvatarLinkAction, setExecutorListAction, setActiveExecutorAction} = useActions(ProfileActions)
    // const {setHintApp} = useActions(HintActions)
    // const {setUpdateProfileAction, setUpdateProfileAvatarLinkAction} = useActions(IsLoadingActions)

    getMyProfile: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const user = getState().UserReducer.user

            const response: IResponse<IProfile> = await customFetchJson(`${user_server}/api/profile/get/${user.id}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ProfileActions.setProfileAction(response.payload))


            let image: any = null
            if (!!response.payload.avatarLink.length) {
                const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'image/png',
                    },
                })

                const blobData = await src.blob()
                const urlCreator = window.URL || window.webkitURL;
                image = urlCreator.createObjectURL(blobData);
            }
            dispatch(ProfileActions.setMyProfileImageAction(image))

        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    setAvatarFile: (file: File) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            if (file.size > 20000000) {
                alert('Слишком большой файл')
                return
            }

            const formData = new FormData();
            formData.append('file', file)

            dispatch(IsLoadingActions.setUpdateProfileAvatarLinkAction(true))

            const response: IResponse<IMinFile> = await customFetchJson(`${store_server}/api/store/avatar/set`, {
                method: 'POST',
                body: formData
            })

            if (response.error) {
                dispatch(HintActions.setHintApp({
                    type: 'error',
                    text: 'Ошибка добавления изображения',
                    isShow: true
                }))
                return
            }
            dispatch(ProfileActions.setAvatarLinkAction(`${response.payload}`))
            dispatch(IsLoadingActions.setUpdateProfileAvatarLinkAction(false))
            dispatch(HintActions.setHintApp({
                type: 'ok',
                text: 'Изображение успешно изменено',
                isShow: true
            }))


        } catch (error) {
            dispatch(HintActions.setHintApp({
                type: 'error',
                text: 'Ошибка добавления изображения',
                isShow: true
            }))
            console.log('error client', error)
        } finally {
        }
    },

    updateMyProfile: (profile: IProfile) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            dispatch(IsLoadingActions.setUpdateProfileAction(true))

            const response: IResponse<IProfile> = await customFetchJson(`${user_server}/api/profile/updateProfile`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newProfile: {
                        ...profile,
                        address: profile.address.filter(el=> !!el.trim().length),
                        site: profile.site.filter(el=> !!el.trim().length),
                        socialNetwork: profile.socialNetwork.filter(el=> !!el.type.trim().length).filter(el=> !!el.value.trim().length)
                    }
                })
            })

            if (response.error) {
                console.log(response.payload)
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }

            dispatch(ProfileActions.setProfileAction(response.payload))

        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally {
            dispatch(IsLoadingActions.setUpdateProfileAction(false))
            window.location.reload()
        }
    },
    getExecutorList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const search = getState().ProfileReducer.search
            const place = getState().ProfileReducer.place
            const countList = getState().ProfileReducer.executorList !== null ? getState().ProfileReducer.executorList?.length : 0

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list?offset=${0}&search=${search}&region=${place.region}&city=${place.city}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(ProfileActions.setExecutorListAction(null))
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(ProfileActions.setExecutorListAction(response.payload.list))
            dispatch(ProfileActions.setTotalExecutorListAction(response.payload.total))

        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    addExecutorList: () => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {
            const search = getState().ProfileReducer.search
            const place = getState().ProfileReducer.place
            const countList = getState().ProfileReducer.executorList !== null ? getState().ProfileReducer.executorList?.length : 0

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list?offset=${countList}&search=${search}&region=${place.region}&city=${place.city}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(ProfileActions.setExecutorListAction(null))
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(ProfileActions.addExecutorListAction(response.payload.list))
            dispatch(ProfileActions.setTotalExecutorListAction(response.payload.total))

        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
    getExecutor: (id:number) => async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
        try {

            const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${id}`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })

            if (response.error) {
                dispatch(ProfileActions.setActiveExecutorAction(null))
                dispatch(NavigationActions.setPathAction(`/error`))
                return
            }
            dispatch(ProfileActions.setActiveExecutorAction(response.payload))

        }
        catch (error) {
            dispatch(NavigationActions.setPathAction(`/error-server`))
            console.log('error client', error)
        }
        finally { }
    },
}
