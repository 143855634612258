import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {IResponse} from "../../../../../../types/api";
import {customFetch, customFetchJson} from "../../../../../../helpers/customFetch";
import {store_server, user_server} from "../../../../../../helpers/config";
import {SvgMyProfile} from "../../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../../assets/images/LoadingGif.gif";
import {DownloadFileList} from "../../../../DownloadFileList";
import {ManagerAPI} from "../../../../../../API/ManagerAPI";
import {useAppState} from "../../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../../store/reducers/task";
import {IMinFile} from "../../../../../../types/file";
import {useActions, useThunks} from "../../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../../API/TaskAPI";
import {ChatActiveTask} from "../ChatActiveTask";
import {useClickOutside} from "../../../../../../helpers/useClickOutside";
import {NoticeAPI} from "../../../../../../API/NoticeAPI";
import {NoticeState} from "../../../../../../store/reducers/notice";
import {useLocation} from "react-router-dom";

export const OwnerWorkTask = ({activeTask}: any) => {
    const location = useLocation()
    const {readNotice} = useThunks(NoticeAPI)
    const {noticeList} = useAppState(NoticeState)

    const [respondProfile, setRespondProfile] = useState<any>({
        loading: true,
        user: null,
        src: null
    })
    useEffect(() => {
        (async () => {
            try {
                const response: IResponse<any> = await customFetchJson(`${user_server}/api/profile/executor-list/${activeTask.workerId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })

                if (response.error) {
                    setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                    return
                }

                let image: any = null
                if (!!response.payload.avatarLink.length) {
                    const src: any = await customFetch(`${store_server}/${response.payload.avatarLink}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'image/png',
                        },
                    })

                    const blobData = await src.blob()
                    const urlCreator = window.URL || window.webkitURL;
                    image = urlCreator.createObjectURL(blobData);
                }

                setRespondProfile((prev: any) => ({
                    ...prev,
                    user: {
                        id: response.payload.id,
                        name: response.payload.name,
                        place: response.payload.place,
                    },
                    src: image,
                    loading: false
                }))
            } catch (error) {
                setRespondProfile((prev: any) => ({...prev, user: {}, loading: false}))
                console.log('error client', error)
            } finally {
            }
        })()
    }, [])
    useEffect(() => {
        return () => {
            setRespondProfile({
                loading: true,
                user: null,
                src: null
            })
        }
    }, [])

    useEffect(()=>{
        noticeList.forEach((el) => {
            if (location.pathname.includes(el.srcLink)) {
                readNotice(el.id)
            }
        })
    },[])

    // арбитраж
    const [showArbitrage, setShowArbitrage] = useState(false)
    const {getArbitrageTask} = useThunks(TaskAPI)
    const {setArbitrageTaskAction} = useActions(TaskActions)
    const {myArbitrage} = useAppState(TaskState)
    useEffect(()=>{
        if (activeTask.status !== 5) getArbitrageTask(activeTask.id)
        return ()=>{
            setArbitrageTaskAction(undefined)
        }
    },[])
    useEffect(()=>{
        setShowArbitrage(false)
    },[myArbitrage])

    return (
        <div className={css(s.OwnerWorkTask)}>
            <div className={css(s.container)}>
                <div className={css(s.head)}>
                    <div className={css(s.avatar)}>
                        {respondProfile.src !== null && !respondProfile.loading &&
                            <img src={respondProfile.src} alt="png"/>}
                        {respondProfile.src === null && !respondProfile.loading && <SvgMyProfile/>}
                        {respondProfile.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                    </div>
                    <div className={css(s.profileMain)}>
                        {!!respondProfile?.user?.name && <div className={css(s.name)}>{respondProfile.user?.name}</div>}
                        {!!respondProfile?.user?.place && <div className={css(s.place)}>{`${respondProfile.user?.place.region}${!!respondProfile.user?.place.region.trim().length && !!respondProfile.user?.place.region.trim().length ? ', ':''}${respondProfile.user?.place.city}`}</div>}
                    </div>
                </div>
                <div className={css(s.main)}>
                    <div className={css(s.mainInner)}>
                        <div className={css(s.taskInfo)}>
                            <div className={css(s.DoMake)}>
                                {!!activeTask.data.doMake.trim().length ? activeTask.data.doMake : `Не заполнено`}
                            </div>
                            <div className={css(s.description)}>
                                {!!activeTask.data.description.trim().length ? activeTask.data.description : `Не заполнено`}
                            </div>
                            <div className={css(s.deadlineAndPrice)}>
                                <div className={css(s.price)}>
                                    <div className={css(s.priceTitle)}>Стоимость:</div>
                                    <div className={css(s.priceValue)}>
                                        {activeTask.data.price} руб.
                                    </div>
                                </div>
                                <div className={css(s.deadline)}>
                                    <div className={css(s.deadlineTitle)}>Срок до:</div>
                                    <div className={css(s.deadlineValue)}>
                                        {new Date(+activeTask.data.deadline).toLocaleDateString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css(s.mainInfoInner)}>
                            <InfoInner myArbitrage={myArbitrage} activeTask={activeTask} showArbitrage={()=>{setShowArbitrage(true)}}/>
                        </div>
                        <ChatActiveTask activeTask={activeTask}/>
                    </div>
                    <div className={css(s.mainInfo)}>
                        <InfoInner myArbitrage={myArbitrage} activeTask={activeTask} showArbitrage={()=>{setShowArbitrage(true)}}/>
                    </div>
                </div>
            </div>
            {myArbitrage !== undefined && showArbitrage && <OwnerArbitrage myArbitrage={myArbitrage} taskId={activeTask.id} close={()=>setShowArbitrage(false)}/>}
        </div>
    )
}

const InfoInner = ({activeTask,showArbitrage, myArbitrage}: any) => {
    const {agreeTestTask,rejectTestTask,cancelMyArbitrage} = useThunks(TaskAPI)
    const {setArbitrageTaskAction} = useActions(TaskActions)

    const [loadingBtn,setLoadingBtn] = useState({
        access: false,
        reject: false,
    })
    const [showFileBox,setShowFileBox] = useState(false)
    const [showArbitrageBox,setShowArbitrageBox] = useState(false)

    useEffect(()=>{
        setLoadingBtn({
            access: false,
            reject: false,
        })
    },[activeTask])

    // файлы
    const {getDownloadTaskFile,downloadFile} = useThunks(TaskAPI)
    const {setDownloadFileListAction} = useActions(TaskActions)
    useEffect(()=>{
        getDownloadTaskFile(activeTask.id)
        return ()=>{
            setDownloadFileListAction(null)
        }
    },[])
    const {downloadFiles} = useAppState(TaskState)
    const downloadFileHandler = (minFile: IMinFile) => {
        downloadFile(minFile)
    }

    const agreeTestTaskHandler = () => {
        setLoadingBtn((prev)=>({...prev, access: true}))
        agreeTestTask(activeTask.id)
    }
    const rejectTestTaskHandler = () => {
        setLoadingBtn((prev)=>({...prev, reject: true}))
        rejectTestTask(activeTask.id)
    }
    const cancelMyArbitrageHandler = () => {
        cancelMyArbitrage(activeTask.id)
    }

    return (
        <div className={css(s.InfoInner)}>
            <div className={css(s.status)}>
                <div className={css(s.statusTitle)}>Статус заказа</div>
                <div className={css(s.statusValue)}>
                    {(()=>{
                        switch (activeTask.workStatus){
                            case 0: return <div className={css(s.statusWorked)}>В работе</div>
                            case 1: return <div className={css(s.statusTest)}>На проверке</div>
                            case 2: return <div className={css(s.statusWorked)}>Доработка</div>
                            case 3: return <div className={css(s.statusFinish)}>Завершена</div>
                            case 4: return <div className={css(s.statusArbitrage)}>Отправлено в арбитраж</div>
                            default: return null
                        }
                    })()}
                </div>
            </div>
            {activeTask.status !== 5 && <div className={css(s.btnBox)}>
                <div className={css(s.accessBtn, activeTask.workStatus === 1 && s.active)} onClick={agreeTestTaskHandler}>
                    {loadingBtn.access ? <img src={loadingGif} alt="loading"/> : `Принять работу`}
                </div>
                <div className={css(s.rejectBtn, activeTask.workStatus === 1 && s.active)} onClick={rejectTestTaskHandler}>
                    {loadingBtn.reject ? <img src={loadingGif} alt="loading"/> : `Отклонить работу`}
                </div>
            </div>}
            <div className={css(s.fileList)}>
                <div className={css(s.showFileListBtn)} onClick={()=>setShowFileBox(prev=>!prev)}>
                    <div className={css(s.fileListTitle)}>Файлы заказа</div>
                    <svg className={css(s.fileListBtnIcon, showFileBox && s.show)} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99274 0.888582L0.207681 6.67375C0.0737717 6.80755 -5.82426e-08 6.98617 -4.99176e-08 7.17662C-4.15926e-08 7.36707 0.0737717 7.54569 0.207681 7.67949L0.633613 8.10553C0.911155 8.38276 1.36224 8.38276 1.63936 8.10553L6.4973 3.24759L11.3606 8.11092C11.4945 8.24473 11.6731 8.3186 11.8634 8.3186C12.054 8.3186 12.2325 8.24473 12.3665 8.11092L12.7923 7.68488C12.9262 7.55098 13 7.37246 13 7.18201C13 6.99156 12.9262 6.81294 12.7923 6.67914L7.00198 0.888582C6.86764 0.754462 6.68829 0.680796 6.49762 0.681219C6.30622 0.680796 6.12697 0.754462 5.99274 0.888582Z" fill="#212B36"/>
                    </svg>
                </div>
                <div className={css(s.fileBox, showFileBox && s.show)}>
                    <DownloadFileList fileItemStyle={{height: 80, width: 68}} downloadFunction={downloadFileHandler} fileList={downloadFiles}/>
                </div>
            </div>
            {activeTask.status !== 5 && <div className={css(s.arbitrageBtn)}>
                <div className={css(s.showArbitrageBoxBtn)} onClick={()=>setShowArbitrageBox(prev=>!prev)}>
                    <div className={css(s.arbitrageTitle)}>Помощь по заказу</div>
                    <svg className={css(s.arbitrageBtnIcon, showArbitrageBox && s.show)} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99274 0.888582L0.207681 6.67375C0.0737717 6.80755 -5.82426e-08 6.98617 -4.99176e-08 7.17662C-4.15926e-08 7.36707 0.0737717 7.54569 0.207681 7.67949L0.633613 8.10553C0.911155 8.38276 1.36224 8.38276 1.63936 8.10553L6.4973 3.24759L11.3606 8.11092C11.4945 8.24473 11.6731 8.3186 11.8634 8.3186C12.054 8.3186 12.2325 8.24473 12.3665 8.11092L12.7923 7.68488C12.9262 7.55098 13 7.37246 13 7.18201C13 6.99156 12.9262 6.81294 12.7923 6.67914L7.00198 0.888582C6.86764 0.754462 6.68829 0.680796 6.49762 0.681219C6.30622 0.680796 6.12697 0.754462 5.99274 0.888582Z" fill="#212B36"/>
                    </svg>
                </div>
                <div className={css(s.arbitrageBox, showArbitrageBox && s.show)}>
                    <div className={css(s.prompt)}>
                        {(myArbitrage !== undefined && myArbitrage !== null)
                            ? `Вы можете отменить обращение в систему арбитраж.`
                            : `В случае проблем с исполнителем или заказчиком, используйте систему арбитраж.`}
                    </div>
                    {myArbitrage === undefined && <div className={css(s.showArbitrageBtn)}>
                        <img src={loadingGif} alt="loading"/>
                    </div>}
                    {myArbitrage === null && <div className={css(s.showArbitrageBtn)} onClick={showArbitrage}>
                        Отправить в арбитраж
                    </div>}
                    {(myArbitrage !== undefined && myArbitrage !== null) && <div className={css(s.showArbitrageBtn)} onClick={cancelMyArbitrageHandler}>
                        Отменить обращение
                    </div>}
                </div>
            </div>}
        </div>
    )
}
const generalList = [
    `Исполнитель не выполняет работу`,
    `Исполнитель общается некорректно`,
    `Исполнитель не выходит на связь`,
    `Другое`,
]
const OwnerArbitrage = ({ myArbitrage, taskId, close}:any) => {

    const {sendArbitrageTask} = useThunks(TaskAPI)
    const [load, setLoad] = useState(true)

    const [showPercentBox, setShowPercentBox] = useState(false)
    useClickOutside(()=>{setShowPercentBox(false)},[`percentValueBtn`,`percentValueBox`])

    const [data, setData] = useState({
        comment: '',
        activeGeneral: 0,
        percent: 0,
    })

    const sendArbitrageTaskHandler = () => {
        sendArbitrageTask(taskId, {
            comment: data.comment,
            activeGeneral: data.activeGeneral,
            general: generalList[data.activeGeneral],
            percent: data.percent
        })
    }

    return (
        <div className={css(s.OwnerArbitrage)} onClick={close}>
            <div className={css(css(s.main))} onClick={e=>e.stopPropagation()}>
                <div className={css(s.title)}>
                    <div className={css(s.titleValue)}>Отправка заказа в арбитраж</div>
                    <div className={css(s.subTitleValue)}>Пожалуйста, четко и конкретно опишите обращение. На основании этих данных модераторами будет вынесено решение.</div>
                </div>
                <div className={css(s.general)}>
                    <div onClick={()=>setData(prev=>({...prev, activeGeneral: 0}))} className={css(s.generalItem, data.activeGeneral === 0 && s.active)}>{generalList[0]}</div>
                    <div onClick={()=>setData(prev=>({...prev, activeGeneral: 1}))} className={css(s.generalItem, data.activeGeneral === 1 && s.active)}>{generalList[1]}</div>
                    <div onClick={()=>setData(prev=>({...prev, activeGeneral: 2}))} className={css(s.generalItem, data.activeGeneral === 2 && s.active)}>{generalList[2]}</div>
                    <div onClick={()=>setData(prev=>({...prev, activeGeneral: 3}))} className={css(s.generalItem, data.activeGeneral === 3 && s.active)}>{generalList[3]}</div>
                </div>
                <div className={css(s.percent)}>
                    <div className={css(s.percentTitle)}>Выберите насколько процентов выполнена работа по вашему мнению</div>
                    <div className={css(s.percentBtn)}>
                        <div id={`percentValueBtn`} className={css(s.percentValue)} onClick={()=>setShowPercentBox(prev=>!prev)}>
                            <span>{data.percent}%</span>
                            <svg className={css(s.arbitrageBtnIcon, showPercentBox && s.show)} width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99274 0.888582L0.207681 6.67375C0.0737717 6.80755 -5.82426e-08 6.98617 -4.99176e-08 7.17662C-4.15926e-08 7.36707 0.0737717 7.54569 0.207681 7.67949L0.633613 8.10553C0.911155 8.38276 1.36224 8.38276 1.63936 8.10553L6.4973 3.24759L11.3606 8.11092C11.4945 8.24473 11.6731 8.3186 11.8634 8.3186C12.054 8.3186 12.2325 8.24473 12.3665 8.11092L12.7923 7.68488C12.9262 7.55098 13 7.37246 13 7.18201C13 6.99156 12.9262 6.81294 12.7923 6.67914L7.00198 0.888582C6.86764 0.754462 6.68829 0.680796 6.49762 0.681219C6.30622 0.680796 6.12697 0.754462 5.99274 0.888582Z" />
                            </svg>
                        </div>
                        <div id={`percentValueBox`} className={css(s.percentBox, showPercentBox && s.show)}>
                            <span  onClick={()=> {
                                setData(prev => ({...prev, percent: 0}))
                                setShowPercentBox(false)
                            }}>0%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 10}))
                                setShowPercentBox(false)
                            }}>10%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 20}))
                                setShowPercentBox(false)
                            }}>20%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 30}))
                                setShowPercentBox(false)
                            }}>30%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 40}))
                                setShowPercentBox(false)
                            }}>40%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 50}))
                                setShowPercentBox(false)
                            }}>50%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 60}))
                                setShowPercentBox(false)
                            }}>60%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 70}))
                                setShowPercentBox(false)
                            }}>70%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 80}))
                                setShowPercentBox(false)
                            }}>80%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 90}))
                                setShowPercentBox(false)
                            }}>90%</span>
                            <span onClick={()=> {
                                setData(prev => ({...prev, percent: 100}))
                                setShowPercentBox(false)
                            }}>100%</span>
                        </div>
                    </div>
                </div>
                <div className={css(s.comment)}>
                    <textarea value={data.comment} placeholder={`Комментарий`} onChange={e=>setData(prev=>({...prev, comment: e.target.value}))}/>
                </div>
                <div className={css(s.btnBox)}>
                    <div className={css(s.send)} onClick={sendArbitrageTaskHandler}>В арбитраж</div>
                    <div className={css(s.cancel)} onClick={close}>Отменить</div>
                </div>
                <div className={css(s.closeBtn)} onClick={close}>
                    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.12577 7.48771L0.285067 13.3286C-0.0950365 13.7085 -0.0950365 14.3228 0.285067 14.7027C0.664991 15.0827 1.27928 15.0827 1.6592 14.7027L7.50008 8.86185L13.3408 14.7027C13.7209 15.0827 14.335 15.0827 14.7149 14.7027C15.095 14.3228 15.095 13.7085 14.7149 13.3286L8.87422 7.48771L14.7149 1.64683C15.095 1.2669 15.095 0.652618 14.7149 0.272693C14.5256 0.0831757 14.2766 -0.0120287 14.0279 -0.0120287C13.7791 -0.0120287 13.5303 0.0831757 13.3408 0.272693L7.50008 6.11357L1.6592 0.272693C1.46969 0.0831757 1.22091 -0.0120287 0.972135 -0.0120287C0.723359 -0.0120287 0.474585 0.0831757 0.285067 0.272693C-0.0950365 0.652618 -0.0950365 1.2669 0.285067 1.64683L6.12577 7.48771Z"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

