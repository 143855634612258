import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {SvgSearch} from "../../../assets/images/svg/SvgSearch";
import {ManagerActions, ManagerState} from "../../../../store/reducers/manager";
import {ManagerAPI} from "../../../../API/ManagerAPI";
import {ManagerUser} from "../ManagerUser";
import {useActions, useThunks} from "../../../../helpers/useActions";

export const ManagerUserList = () => {

    const {userList, totalUserList} = useAppState(ManagerState)
    const {getUserList,addUserList} = useThunks(ManagerAPI)
    const {setUserListAction, setTotalUserListAction} = useActions(ManagerActions)
    const [search, serSearch] = useState('')

    useEffect(() => {
        getUserList()
        return () =>{
            setUserListAction(null)
            setTotalUserListAction(0)
        }
    }, [])

    const [addLoad, setAddLoad] = useState(false)
    const addAppealListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addUserList(()=>{setAddLoad(false)})
    }

    return (
        <div className={css(s.UserList)}>
            <div className={css(s.container)}>
                {userList &&
                    <div className={css(s.list)}>
                        {userList.map(user => <ManagerUser key={user.id} user={user}/>)}
                    </div>
                }
                {!userList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                {!!userList && !userList.length && <div className={css(s.emptyList)}>Нет пользователей</div>}
                {!!userList?.length && totalUserList > 0 && totalUserList > userList.length &&
                    <div className={css(s.showMore)}>
                            <span onClick={addAppealListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                    </div>}
            </div>
        </div>
    )
}
