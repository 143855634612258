import React, {useEffect} from 'react'
import s from './index.module.scss'
import {Outlet, useNavigate} from "react-router-dom";
import {css} from "../../../../helpers/css";
import {useAppState} from "../../../../store/reduxStore";
import {ManagerState} from "../../../../store/reducers/manager";

export const Verification = () => {
    const navigate = useNavigate();
    const {activeVerificationItem} = useAppState(ManagerState)

    useEffect(()=>{
        if (!activeVerificationItem) return
        navigate(`/verifyItem/${activeVerificationItem.userId}`)
    },[activeVerificationItem])

    return (
        <div className={css(s.Verification)}>
            <Outlet />
        </div>
    )
}
