import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../helpers/css";
import {useAppState} from "../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../store/reducers/task";
import loading from "../../../../assets/images/LoadingGif.gif";
import {useActions, useThunks} from "../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../API/TaskAPI";
import {useNavigate} from "react-router-dom";
import loadingGif from "../../../../assets/images/LoadingGif.gif";

export const MyWorkOffer = () => {
    const navigate = useNavigate()
    const {getWorkOfferList , addWorkOfferList} = useThunks(TaskAPI)
    const {workOfferList, filterWorkOffer, totalWorkOfferList} = useAppState(TaskState)
    const {setStatisticWorkOfferAction, setWorkOfferListAction, setFilterWorkOfferAction, setTotalWorkOfferAction} = useActions(TaskActions)

    // const [activeFilter, setActiveFilter] = useState<null | number>(null)
    useEffect(() => {
        if (filterWorkOffer === null) setFilterWorkOfferAction(0)
        if (filterWorkOffer !== null) {
            getWorkOfferList(filterWorkOffer)
        }
    }, [filterWorkOffer])
    useEffect(() => {
        // getStatisticWorkOfferList()
        return ()=>{
            setStatisticWorkOfferAction(null)
            setWorkOfferListAction(null)
        }
    }, [])

    //     worked,
    //     sendRespond,
    //     check,
    //     arbitrage,
    //     finish,

    const openTask = (id:number) => {
        navigate(`/task-list/${id}`)
    }

    // добавление элементов
    const [addLoad, setAddLoad] = useState(false)
    const addListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addWorkOfferList(filterWorkOffer)
    }
    useEffect(() => {
        setAddLoad(false)
    }, [workOfferList])
    useEffect(() => {
        return ()=>{
            setTotalWorkOfferAction(null)
        }
    }, [])

    return (
        <div className={css(s.MyWorkOffer)}>
            <div className={css(s.main)}>
                {workOfferList === null && <div className={css(s.loadList)}><img src={loading} alt="loading"/></div>}
                {workOfferList !== null && !workOfferList.length && <div className={css(s.emptyList)}>Список пуст</div>}
                {workOfferList !== null && !!workOfferList.length && workOfferList.map((item: any) =>
                    <div className={css(s.mitTask)} key={item.id} onClick={()=>openTask(item.id)}>
                        <div className={css(s.head)}>
                            <div className={css(s.status)}>
                                <span />
                                {item.status === 4 && item.workStatus === 0 && `В работе`}
                                {item.status === 4 && item.workStatus === 2 && `В работе`}
                                {item.status === 1 && item.workStatus === null && `Оставлена заявка`}
                                {item.status === 2 && item.workStatus === null && `Ожидает подтверждения`}
                                {item.workStatus === 1 && `Проверка`}
                                {item.workStatus === 4 && `В арбитраже`}
                                {item.status === 5 && `Завершена`}
                            </div>
                            <div className={css(s.createDate)}>
                                Создано: {new Date(item.createDate).toLocaleDateString()}
                                {item.workStatus}
                            </div>
                        </div>
                        <div className={css(s.minTaskDoMake)}>
                            {!!item.data.doMake.trim().length ? item.data.doMake : "Не заполнено"}
                        </div>
                    </div>
                )}
                {!!workOfferList && totalWorkOfferList !== null && totalWorkOfferList > workOfferList.length && <div className={css(s.showMore)}>
                            <span onClick={addListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                </div>}
            </div>
            <Filter activeFilter={filterWorkOffer} setActiveFilter={(status:number)=>setFilterWorkOfferAction(status)}/>
        </div>
    )
}

const Filter = ({activeFilter, setActiveFilter}: any) => {
    const {statisticWorkOffer} = useAppState(TaskState)

    return (
        <div className={css(s.Filter)}>
            <div className={css(s.filterItem, activeFilter === 0 && s.active)} onClick={() => setActiveFilter(0)}>
                <span className={css(s.filterItemTitle)}>все</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.all : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 1 && s.active)} onClick={() => setActiveFilter(1)}>
                <span className={css(s.filterItemTitle)}>В работе</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.worked : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 2 && s.active)} onClick={() => setActiveFilter(2)}>
                <span className={css(s.filterItemTitle)}>Оставлена заявка</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.sendRespond : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 3 && s.active)} onClick={() => setActiveFilter(3)}>
                <span className={css(s.filterItemTitle)}>На проверке</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.check : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 4 && s.active)} onClick={() => setActiveFilter(4)}>
                <span className={css(s.filterItemTitle)}>В арбитраже</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.arbitrage : <img src={loading} alt="loading"/>}</span>
            </div>
            <div className={css(s.filterItem, activeFilter === 5 && s.active)} onClick={() => setActiveFilter(5)}>
                <span className={css(s.filterItemTitle)}>Завершённые</span>
                <span className={css(s.filterItemValue)}>{statisticWorkOffer ? statisticWorkOffer.finish : <img src={loading} alt="loading"/>}</span>
            </div>
        </div>
    )
}
