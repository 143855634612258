import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AuthAPI} from "../../../../API/AuthAPI";
import {SvgSocialVk} from "../../../assets/images/svg/SvgSocialVk";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {showPopupActions, showPopupState, ShowPopupType} from "../../../../store/reducers/showPopup";
import {CloseBtn} from "../CloseBtn";
import {useAppState} from "../../../../store/reduxStore";
import loadingGif from '../../../assets/images/LoadingGif.gif'
import {UserActions, UserState} from "../../../../store/reducers/auth";

type PropsType = {
    id:string,
    type: keyof ShowPopupType
}

export const Registration = ({id, type} : PropsType) => {

    const {registration: registrationAPI} = useThunks(AuthAPI)

    const {errorAuth} = useAppState(UserState)
    const {setErrorAuthAction} = useActions(UserActions)
    const {registration} = useAppState(showPopupState)
    const {setIsShowPopupAction} = useActions(showPopupActions)

    const [isFetch, setIsFetch] = useState(false)
    const [errorField, setErrorField] = useState({
        name: false,
        email: false,
        password: false,
        agreement: false,
        text: '',
    })

    const [name, setName] = useState(``)
    const [email, setEmail] = useState(``)
    const [password, setPassword] = useState(``)
    const [agreement, setAgreement] = useState(false)

    useEffect(()=>{
        return ()=>{
            clearErrorHandler()
            setName('')
            setEmail('')
            setPassword('')
            setAgreement(false)
        }
    },[registration])

    useEffect(()=>{
        if (!!errorAuth) {
            setIsFetch(false)
            setErrorField(prevState => ({...prevState, text: errorAuth}))
        }
    },[errorAuth])

    const clearErrorHandler = () => {
        setErrorAuthAction(null)
        setErrorField({name: false, email: false, password: false, agreement: false, text: '',})
    }

    const registrationHandler = (e:any) => {
        e.preventDefault()
        if (isFetch) return

        if (name.length < 2 || name.length > 32)
            return setErrorField(prev => ({...prev, name: true, text: 'Имя должно содержать от 2 до 32 символов'}))

        if (!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(String(email).toLowerCase()))
            return setErrorField(prev => ({...prev, email: true, text: 'Неверный формат e-mail'}))

        if (password.length < 6 || password.length > 32)
            return setErrorField(prev => ({...prev, password: true, text: 'Пароль должен содержать от 6 до 32 символов'}))

        if (!agreement)
            return setErrorField(prev => ({...prev, agreement: true, text: 'Подтвердите соглашение'}))

        setErrorAuthAction(null)
        setIsFetch(true)
        registrationAPI(name, email, password)
    }
    const onCloseHandler = () => {
        setIsShowPopupAction({type: "registration", isShow: false})
    }

    const setIsShowLoginPopupHandler = () => {
        setIsShowPopupAction({type: "registration", isShow: false})
        setIsShowPopupAction({type: "login", isShow: true})
    }

    return (
        <div id={id} className={css(s.Registration)}>
            <div className={css(s.title)}>
                Регистрация
            </div>
            <div className={css(s.errorField)}>{errorField.text}</div>
            <form className={css(s.form)} onSubmit={registrationHandler}>
                <div className={css(s.field, errorField.name && s.error)}>
                    <input type="text" placeholder={`Имя*`} value={name} onChange={e=> {
                        clearErrorHandler()
                        setName(e.target.value)
                    }}/>
                </div>
                <div className={css(s.field, errorField.email && s.error)}>
                    <input type="text" placeholder={`E-mail*`} value={email} onChange={e=> {
                        clearErrorHandler()
                        setEmail(String(e.target.value).toLowerCase())
                    }}/>
                </div>
                <div className={css(s.field, errorField.password && s.error)}>
                    <input type="password" placeholder={`Пароль*`} value={password} onChange={e=> {
                        clearErrorHandler()
                        setPassword(e.target.value)
                    }}/>
                </div>
                <div className={css(s.agreement, errorField.agreement && s.error)}>
                    <input id={`agreementRegistration`} type="checkbox" checked={agreement} onChange={e=> {
                        clearErrorHandler()
                        setAgreement(e.target.checked)
                    }}/>
                    <label htmlFor={`agreementRegistration`} className={css(s.text)}>
                        Я согласен с
                        <span onClick={e=> {e.stopPropagation();e.preventDefault()}}> правилами сайта </span>
                        и
                        <span onClick={e=> {e.stopPropagation();e.preventDefault()}}> обработкой персональных данных </span>
                    </label>
                </div>
                <button>
                    {!isFetch ? 'Зарегистрироваться' : <img src={loadingGif} alt="loading"/>}
                </button>
            </form>
            <div className={css(s.loginBtn)}
                onClick={setIsShowLoginPopupHandler}
            >
                У вас уже есть аккаунт? <span>Войти</span>
            </div>
            {/*<div className={css(s.socialLogin)}>*/}
            {/*    <div className={css(s.main)}>*/}
            {/*        <div className={css(s.text)}>или войдите с помощью:</div>*/}
            {/*        <div className={css(s.imageButton)}>*/}
            {/*            <SvgSocialVk />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <CloseBtn onClose={onCloseHandler}/>
        </div>
    )
}
