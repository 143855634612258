import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../reduxStore";
import {IUser} from "../../types/user";

interface stateType {
    user: IUser
    errorAuth: string | null
    errorChangePasswordAuth: string | null
}
const initialState: stateType = {
    user: {
        id: null,
        email: null,
        role: null,
        name: '',
        verifyStatus: 0,
    },
    errorAuth: null,
    errorChangePasswordAuth: null
}

export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setGuestAction: (state) : void => {
            state.user = {
                role: 'guest',
                id: null,
                email: null,
                name: '',
                verifyStatus: 0,
            }
        },
        setUserAction: (state, action: PayloadAction<IUser>) : void => {
            state.user = action.payload
        },
        setErrorAuthAction: (state, action: PayloadAction<string | null>) : void => {
            state.errorAuth = action.payload
        },
        setErrorChangePasswordAction: (state, action: PayloadAction<string | null>) : void => {
            state.errorChangePasswordAuth = action.payload
        },
    }
})

export const UserReducer = slice.reducer
export const UserActions = slice.actions
export const UserState = (state: RootState) => state.UserReducer


