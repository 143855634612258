import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../helpers/css";
import {AuthAPI} from "../../../../API/AuthAPI";
import {useActions, useThunks} from "../../../../helpers/useActions";
import {showPopupActions, showPopupState, ShowPopupType} from "../../../../store/reducers/showPopup";
import {CloseBtn} from "../CloseBtn";
import {useAppState} from "../../../../store/reduxStore";
import {UserActions, UserState} from "../../../../store/reducers/auth";
import loadingGif from '../../../assets/images/LoadingGif.gif'

type PropsType = {
    id:string,
    type: keyof ShowPopupType
}

export const Login = ({id, type} : PropsType) => {

    const {login: loginAPI} = useThunks(AuthAPI)

    const {errorAuth} = useAppState(UserState)
    const {setErrorAuthAction} = useActions(UserActions)
    const {login} = useAppState(showPopupState)
    const {setIsShowPopupAction} = useActions(showPopupActions)

    const [isFetch, setIsFetch] = useState(false)
    const [errorField, setErrorField] = useState({
        email: false,
        password: false,
        text: '',
    })

    const [email, setEmail] = useState(``)
    const [password, setPassword] = useState(``)

    useEffect(()=>{
        return ()=>{
            clearErrorHandler()
            setEmail('')
            setPassword('')
        }
    },[login])

    useEffect(()=>{
        if (!!errorAuth) {
            setIsFetch(false)
            setErrorField(prevState => ({...prevState, text: errorAuth}))
        }
    },[errorAuth])

    const loginHandler = (e:any) => {
        e.preventDefault()

        if (!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(String(email).toLowerCase()))
            return setErrorField(prev => ({...prev, email: true, text: 'Неверный формат e-mail'}))

        if (password.length < 6 || password.length > 32)
            return setErrorField(prev => ({...prev, password: true, text: 'Пароль должен содержать от 6 до 32 символов'}))

        setErrorAuthAction(null)
        setIsFetch(true)
        loginAPI(email, password)
    }

    const clearErrorHandler = () => {
        setErrorAuthAction(null)
        setErrorField({email: false, password: false, text: '',})
    }

    const setIsShowRegPopupHandler = () => {
        setIsShowPopupAction({type: "login", isShow: false})
        setIsShowPopupAction({type: "registration", isShow: true})
    }
    const setIsShowResetPasswordPopupHandler = () => {
        setIsShowPopupAction({type: "login", isShow: false})
        setIsShowPopupAction({type: "resetPassword", isShow: true})
    }

    const onCloseHandler = () => {
        setIsShowPopupAction({type: "login", isShow: false})
    }

    return (
        <div id={id} className={css(s.Login)}>
            <div className={css(s.title)}>
                Вход в кабинет
            </div>
            <div className={css(s.errorField)}>{errorField.text}</div>
            <form className={css(s.form)} onSubmit={loginHandler}>
                <div className={css(s.field, errorField.email && s.error)}>
                    <input type="text" placeholder={`E-mail*`} value={email} onChange={e=> {
                        clearErrorHandler()
                        setEmail(String(e.target.value).toLowerCase())
                    }}/>
                </div>
                <div className={css(s.field, errorField.password && s.error)}>
                    <input type="password" placeholder={`Пароль*`} value={password} onChange={e=> {
                        clearErrorHandler()
                        setPassword(e.target.value)
                    }}/>
                </div>
                <button>
                    {!isFetch ? 'Вход' : <img src={loadingGif} alt="loading"/>}
                </button>
            </form>
            <div className={css(s.changeMode)}>
                <div className={css(s.modeItem, s.registrationBtn)} onClick={setIsShowRegPopupHandler}>
                    Зарегистрироваться
                </div>
                <div className={s.modeItem} onClick={setIsShowResetPasswordPopupHandler}>
                    Восстановить пароль?
                </div>
            </div>
            <CloseBtn onClose={onCloseHandler}/>
        </div>
    )
}
