import React, {useEffect, useState} from 'react'
import s from './index.module.scss'
import {css} from "../../../../../../helpers/css";
import {ManagerAPI} from "../../../../../../API/ManagerAPI";
import {useAppState} from "../../../../../../store/reduxStore";
import {TaskActions, TaskState} from "../../../../../../store/reducers/task";
import {IMinFile} from "../../../../../../types/file";
import {DownloadFileList} from "../../../../DownloadFileList";
import {useActions, useThunks} from "../../../../../../helpers/useActions";
import {TaskAPI} from "../../../../../../API/TaskAPI";
import {Respond} from "./Respond";
import {customFetch} from "../../../../../../helpers/customFetch";
import {store_server, task_server} from "../../../../../../helpers/config";
import {SvgMyProfile} from "../../../../../assets/images/svg/SvgMyProfile";
import loadingGif from "../../../../../assets/images/LoadingGif.gif";
import {useNavigate} from "react-router-dom";
import {MessengerActions} from "../../../../../../store/reducers/messenger";

export const GuestTask = ({activeTask}:any) => {
    const navigate = useNavigate();

    const {setCompanionIdAction} = useActions(MessengerActions)
    const {getOwnerProfile} = useThunks(TaskAPI)
    const {ownerProfile} = useAppState(TaskState)
    const {setOwnerProfileAction, clearMyRespondAction} = useActions(TaskActions)

    useEffect(()=>{
        getOwnerProfile(activeTask.ownerId)
        return ()=> {
            setOwnerProfileAction(null)
            clearMyRespondAction()
        }
    },[])

    //avatar
    const [img, setImg] = useState<{ src: string | null, loading: boolean }>({
        src: null,
        loading: true,
    })
    useEffect(() => {
        if (!ownerProfile) return
        if (!!ownerProfile?.avatarLink.length) {
            customFetch(`${store_server}/${ownerProfile.avatarLink}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'image/png',
                },
            })
                .then(data => {
                    if (data.status === 200) return data.blob()
                    else throw Error()
                })
                .then(blobData => {
                    const urlCreator = window.URL || window.webkitURL;
                    const image = urlCreator.createObjectURL(blobData);
                    setImg(prev => ({loading: false, src: image}))
                })
                .catch((err) => {
                    setImg(prev => ({loading: false, src: null}))
                })
        } else setImg(prev => ({loading: false, src: null}))
    }, [ownerProfile])

    useEffect(()=>{
        return ()=>{
            setImg({
                src: null,
                loading: true,
            })
        }
    },[])

    // файлы
    const {getDownloadTaskFile,downloadFile} = useThunks(TaskAPI)
    const {setDownloadFileListAction} = useActions(TaskActions)
    useEffect(()=>{
        getDownloadTaskFile(activeTask.id)
        return ()=>{
            setDownloadFileListAction(null)
        }
    },[])
    const {downloadFiles} = useAppState(TaskState)
    const downloadFileHandler = (minFile: IMinFile) => {
        downloadFile(minFile)
    }

    const sendCompanionMessageHandler = async () => {
        if (!ownerProfile) return
        await setCompanionIdAction(ownerProfile.ownerId)
        navigate(`/chat-list`)
    }

    //countRespond   getRespondTask
    const {myRespond} = useAppState(TaskState)
    const [countRespond,setCountRespond] = useState<number | null>(null)
    useEffect(()=>{
        customFetch(`${task_server}/api/task/getRespondTask?taskId=${activeTask.id}`, {
            method: 'GET',
        })
            .then(data => data.json())
            .then(data => {
                setCountRespond(data)
            })
            .catch((err) => {
                setCountRespond(0)
            })
        return ()=>{
            setCountRespond(null)
        }
    },[myRespond])

    //count active task
    const [countActiveTask,setCountActiveTask] = useState<number | null>(null)
    useEffect(()=>{
        customFetch(`${task_server}/api/task/getOwnerActiveTask?ownerId=${activeTask.ownerId}`, {
            method: 'GET',
        })
            .then(data => data.json())
            .then(data => {
                setCountActiveTask(data)
            })
            .catch((err) => {
                setCountActiveTask(0)
            })
        return ()=>{
            setCountActiveTask(null)
        }
    },[])

    return (
        <div className={css(s.GuestTask)}>
            <div className={css(s.container)}>
                <div className={css(s.task)}>
                    <div className={css(s.taskMain)}>
                        <div className={css(s.DoMake)}>
                            {!!activeTask.data.doMake.trim().length ? activeTask.data.doMake : `Не заполнено`}
                        </div>
                        <div className={css(s.description)}>
                            <div className={css(s.descriptionTitle)}>Описание: </div>
                            {!!activeTask.data.description.trim().length ? activeTask.data.description : `Не заполнено`}
                        </div>
                        <div className={css(s.downloadFileListTitle)}>
                            Прикреплённые документы
                        </div>
                        <DownloadFileList downloadFunction={downloadFileHandler} fileList={downloadFiles}/>
                        <Respond taskId={activeTask.id}
                                 percentTask={activeTask.percentTask}
                                 minPrice={activeTask.data.minPrice}
                                 infinityDeadline={activeTask.data.infinityDeadline}
                                 price={+activeTask.data.price}
                                 deadline={+activeTask.data.deadline}
                        />
                    </div>
                    <div className={css(s.taskInfo)}>
                        <div className={css(s.ownerProfile)}>
                            <div className={css(s.profileMain)}>
                                <div className={css(s.profile)}>
                                    <div className={css(s.avatar)}>
                                        {img.src !== null && !img.loading && <img src={img.src} alt="png"/>}
                                        {img.src === null && !img.loading && <SvgMyProfile/>}
                                        {img.loading && <img style={{width: `50%`, height: `50%`}} src={loadingGif} alt="load"/>}
                                    </div>
                                    <div className={css(s.nameBox)}>
                                        <div className={css(s.name)}>{ownerProfile?.name}</div>
                                        <div className={css(s.place)}>{ownerProfile && `${ownerProfile?.place.region}${!!ownerProfile?.place.region.trim().length && !!ownerProfile?.place.city.trim().length ? `, `:``}${ownerProfile?.place.city}`}</div>
                                    </div>
                                </div>
                                {/*<div className={css(s.ownerRating)}>*/}
                                {/*    <div className={css(s.ownerRatingTitle)}>Рейтинг:</div>*/}
                                {/*    <div className={css(s.ownerRatingValue)}>?</div>*/}
                                {/*</div>*/}
                                <div className={css(s.countWorkList)}>
                                    <div className={css(s.countWorkListTitle)}>Отданых в работу задач:</div>
                                    <div className={css(s.countWorkListValue)}>{countActiveTask !== null ? countActiveTask:
                                        <img src={loadingGif} alt="loading"/>}</div>
                                </div>
                            </div>
                            <div className={css(s.sendMessageBtn)} onClick={sendCompanionMessageHandler}>Написать сообщение</div>
                        </div>
                        <div className={css(s.countRespond)}>
                            <div className={css(s.icon)}>
                                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9516 3.33569H17.1777L14.048 0.206012C13.8672 0.0251816 13.5832 -0.000623539 13.3725 0.144506L11.3371 1.54819L9.99492 0.205973C9.81405 0.0251429 9.52988 -0.000701126 9.31945 0.144467L4.71007 3.32335C4.70444 3.32723 4.69947 3.33161 4.69408 3.33565H2.66646C1.40294 3.33569 0.375 4.36363 0.375 5.62711V16.4088C0.375 17.6723 1.40294 18.7003 2.66646 18.7003H17.9516C19.2151 18.7003 20.243 17.6723 20.243 16.4088V5.62711C20.243 4.36363 19.2151 3.33569 17.9516 3.33569ZM13.6107 1.2674L15.6791 3.33569H10.6118L13.6107 1.2674ZM9.55767 1.2674L10.4502 2.15991L8.7631 3.32343C8.75748 3.32731 8.75251 3.33169 8.74712 3.33573H6.55861L9.55767 1.2674ZM19.1834 13.1372H14.627C14.2326 13.1372 13.9118 12.8164 13.9118 12.422V9.61394C13.9118 9.21953 14.2326 8.89869 14.627 8.89869H19.1834V13.1372H19.1834ZM19.1834 7.83909H14.627C13.6483 7.83909 12.8521 8.63529 12.8521 9.61398V12.422C12.8521 13.4007 13.6483 14.1969 14.627 14.1969H19.1834V16.4089C19.1834 17.0881 18.6308 17.6407 17.9516 17.6407H2.66646C1.98722 17.6407 1.43464 17.0881 1.43464 16.4089V5.62711C1.43464 4.94787 1.98722 4.39529 2.66646 4.39529H17.9516C18.6308 4.39529 19.1834 4.94787 19.1834 5.62711V7.83909H19.1834Z" fill="#212B36"/>
                                    <path d="M16.5504 12.6071C17.4268 12.6071 18.1398 11.8941 18.1398 11.0177C18.1398 10.1413 17.4268 9.42822 16.5504 9.42822C15.674 9.42822 14.9609 10.1413 14.9609 11.0177C14.9609 11.8941 15.674 12.6071 16.5504 12.6071ZM16.5504 10.4879C16.8425 10.4879 17.0802 10.7255 17.0802 11.0177C17.0802 11.3098 16.8425 11.5475 16.5504 11.5475C16.2583 11.5475 16.0206 11.3098 16.0206 11.0177C16.0206 10.7255 16.2583 10.4879 16.5504 10.4879Z" fill="#212B36"/>
                                </svg>
                            </div>
                            <div className={css(s.countRespondValue)}><span>Поданных заявок: </span>{countRespond !== null ? countRespond:
                                <img src={loadingGif} alt="loading"/>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
