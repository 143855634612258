import React, {useEffect, useState} from 'react'
import {ManagerAPI} from '../../../../API/ManagerAPI'
import {css} from '../../../../helpers/css'
import {useActions, useThunks} from '../../../../helpers/useActions'
import {ManagerActions, ManagerState} from '../../../../store/reducers/manager'
import {useAppState} from '../../../../store/reduxStore'
import s from './index.module.scss'
import loadingGif from "../../../assets/images/LoadingGif.gif";

export const ManagerWithdraw = () => {
    const {withdrawList, totalWithdrawList} = useAppState(ManagerState)

    const {getWithdrawList, addWithdrawList} = useThunks(ManagerAPI)

    const {setWithdrawListAction, setTotalWithdrawListAction} = useActions(ManagerActions)

    useEffect(() => {
        getWithdrawList()
        return () => {
            setWithdrawListAction(null)
            setTotalWithdrawListAction(0)
        }
    }, [])

    const [addLoad, setAddLoad] = useState(false)
    const addWithdrawListHandler = () => {
        if (addLoad) return
        setAddLoad(true)
        addWithdrawList(() => {
            setAddLoad(false)
        })
    }

    return (
        <div className={css(s.ManagerWithdraw)}>
            <div className={css(s.container)}>
                {withdrawList &&
                    <div className={css(s.list)}>
                        {withdrawList.map(withdraw => <WithdrawItem key={withdraw.id} withdraw={withdraw}/>)}
                    </div>
                }
                {!withdrawList && <div className={css(s.loadingList)}><img src={loadingGif} alt="loading"/></div>}
                {!!withdrawList && !withdrawList.length && <div className={css(s.emptyList)}>Список выплат пуст</div>}
                {!!withdrawList?.length && totalWithdrawList > 0 && totalWithdrawList > withdrawList.length &&
                    <div className={css(s.showMore)}>
                            <span onClick={addWithdrawListHandler}>
                                {addLoad ? <img src={loadingGif} alt="loading"/> : `показать еще`}
                            </span>
                    </div>}
            </div>
        </div>
    )
}
const WithdrawItem = ({withdraw}: any) => {

    const [show, setShow] = useState(false)

    const {changeStatusWithdraw} = useThunks(ManagerAPI)

    // if (withdraw.status !== `pending`) return null

    const accessHandler = () => {
        if (!window.confirm('Подтвердить выплату?')) return
        changeStatusWithdraw(withdraw.id, `preAccess`)
    }
    const cancelHandler = () => {
        if (!window.confirm('Отменить выплату?')) return
        changeStatusWithdraw(withdraw.id, `cancel`)
    }

    const transformCardValue = (value: string) => {
        value = value.replace(/[^\d]/g, '').substring(0,16)

        const match = value.match(/.{1,4}/g)

        value = value !== '' ? (match ? match.join(' '): '') : ''

        return value
    }

    return (
        <div className={css(s.WithdrawItem)}>
            <div className={css(s.info)}>
                <div className={css(s.col)}>
                    <div>Сумма:</div>
                    <div>{withdraw.withdrawMoney}</div>
                </div>
                <div className={css(s.col)}>
                    <div>Номер карты:</div>
                    <div>{transformCardValue(withdraw.value)}</div>
                </div>
                <div className={css(s.col)}>
                    <div>Дата:</div>
                    <div>{`${new Date(withdraw.date).toLocaleDateString()} ${new Date(withdraw.date).toLocaleTimeString()}`}</div>
                </div>
            </div>
            {withdraw.status === `pending` &&
                <div className={css(s.btnBox)}>
                    <div className={css(s.accessBtn)} onClick={accessHandler}>Выплата сделанна</div>
                    <div className={css(s.cancelBtn)} onClick={cancelHandler}>Отменить выплату</div>
                </div>}
            {(withdraw.status === `succeeded` || withdraw.status === `preSucceeded`) &&
                <div className={css(s.btnBox)}>
                    <div className={css(s.succeeded)}>Выплата сделанна</div>
                </div>}
            {withdraw.status === `cancel` &&
                <div className={css(s.btnBox)}>
                    <div className={css(s.canceled)}>Выплата отменена</div>
                </div>}
        </div>
    )
}

